const SERVICE_URL: string = (() => {
  return '';
})();

// List of organizations which still the old org-side
const OLD_ORG_SIDE_ACCESS_IDS = [
  '382cb8d4-7395-47e6-a040-48396f64b732', // [PROD] AXCIOM
  '282e04c4-cd6d-464a-bdca-12a1813e6d16', // [PROD] TE Connectivity
  '346bb50e-30f6-4610-9519-bf0fef71b2ca', // [PROD] HBA
  '728571d5-16dc-4aa5-914b-20ad1a01fed0', // [PROD] DOTDOTDASH
  '87d61596-b7da-43d4-ba8f-ad744d36e0b9', // [PROD] Trovata
  '2a399f80-f212-4745-99d4-ec36e1c151c4', // [PROD] Highspot
  '108dd0fc-dc79-4337-a420-669dc84ca97a', // [PROD] WBEA
  '5f829988-22ab-4ed1-9e8e-ad8410a99b0b', // [PROD] NAMIC
];

const IMAGE_URL: string = 'https://phoenixlive.imgix.net/';
const VIDEO_URL: string = 'https://phoenixlive.imgix.net/';
const STATIC_ZUDDL_URL: string = 'https://static.zuddl.com/';
// NOTE: This is the current production pusher key
const PUSHER_KEY: string = process.env.PUSHER_KEY || '9c601ab7d2d63ccac3a8';
const PUSHER_CLUSTER: string = process.env.PUSHER_CLUSTER || 'ap2';

// NOTE: This is the development key for the Cotter account
// const COTTER_API_KEY_ID = '925fd5de-1978-44e2-ac60-af550c226b37';
// NOTE: This is devin@zuddl.com key used for development/staging. The above development key is not working.
const COTTER_API_KEY_ID: string =
  process.env.COTTER_API_KEY_ID || '769baba2-c0b0-4899-abb2-76551aeed2af';

// NOTE: This defaults to the development environment, on WorkOS it's called "View Test Data"
//  environment (was previously called Sandbox mode)
const SSO_WORKOS_PROJECT_ID: string =
  process.env.SSO_WORKOS_PROJECT_ID || 'project_01ENT8HEW1JDGJ4A790N3S82VV';
const SSO_REDIRECT_URI: string = `${window.location.origin}/auth/sso/`;

// NOTE: This defaults to the development environment key
const GOOGLE_OAUTH_CLIENT_ID: string =
  process.env.GOOGLE_OAUTH_CLIENT_ID ||
  '428780082635-rip3a3456p2dvr2pvk3v1je10k0m1cdu.apps.googleusercontent.com';

// NOTE: This defaults to the development environment key
const FACEBOOK_APPLICATION_ID: string =
  process.env.FACEBOOK_APPLICATION_ID || '443258710211922';
// NOTE: If not provided, it will default to false
const FACEBOOK_ENABLED: boolean = process.env.FACEBOOK_ENABLED === 'true';

// NOTE: This defaults to the development environment key
const LINKEDIN_CLIENT_ID: string =
  process.env.LINKEDIN_CLIENT_ID || '86hgr5fzlmlr8p';

// NOTE: This defaults to the development environment key
const LOGROCKET_APP_ID: string =
  process.env.LOGROCKET_APP_ID || 'q5mjcq/zuddl-react';

// NOTE: using to disable communication section for existing events
const BULK_EMAIL_RELEASE_DATE_TIME: Date = new Date(
  '2022-02-05 18:30:54.680836+05:30',
);
// NOTE: This defaults to the development environment key
const OPTIMIZELY_SDK_KEY: string =
  process.env.OPTIMIZELY_SDK_KEY || 'C778uDrV5q5A5BUNNUhZ2';

const ZUDDL_LOGO_COLORED = `${IMAGE_URL}zuddl-vo/zuddl_logo.png`;
const ZUDDL_LOGO_WHITE = `${IMAGE_URL}zuddl-vo/zuddl-logo-white.png`;

const { MIRO_BOARD_CLIENT_ID } = process.env;

// This env var should be a string with event IDs separated by commas
// e.g. eventId1,eventId2,eventId3
const SINGLE_SESSION_ENABLED_EVENT_IDS: string =
  process.env.SINGLE_SESSION_ENABLED_EVENT_IDS?.replace(' ', '') || '';
// Split string into an array of strings
const SINGLE_SESSION_ENABLED_EVENT_ID_LIST: string[] = SINGLE_SESSION_ENABLED_EVENT_IDS.split(
  ',',
);

const GIPHY_WEB_SDK_KEY: string =
  process.env.GIPHY_WEB_SDK_KEY || 'YIaSHGjCliMDQ2SjPSTQockbiNF9uzyM';

// NOTE: using to disable communication section for existing events
const CALENDAR_INVITE_RELEASE_DATE_TIME: Date = process.env
  .CALENDAR_INVITE_RELEASE_DATE_TIME
  ? new Date(process.env.CALENDAR_INVITE_RELEASE_DATE_TIME)
  : new Date('2022-09-13 10:00:00.680836+05:30');

const { MUX_DATA_ENV_KEY } = process.env;

export {
  IMAGE_URL,
  VIDEO_URL,
  SERVICE_URL,
  SSO_REDIRECT_URI,
  SSO_WORKOS_PROJECT_ID,
  PUSHER_KEY,
  PUSHER_CLUSTER,
  COTTER_API_KEY_ID,
  GOOGLE_OAUTH_CLIENT_ID,
  FACEBOOK_APPLICATION_ID,
  FACEBOOK_ENABLED,
  LINKEDIN_CLIENT_ID,
  LOGROCKET_APP_ID,
  ZUDDL_LOGO_COLORED,
  ZUDDL_LOGO_WHITE,
  BULK_EMAIL_RELEASE_DATE_TIME,
  MIRO_BOARD_CLIENT_ID,
  SINGLE_SESSION_ENABLED_EVENT_ID_LIST,
  OPTIMIZELY_SDK_KEY,
  GIPHY_WEB_SDK_KEY,
  MUX_DATA_ENV_KEY,
  CALENDAR_INVITE_RELEASE_DATE_TIME,
  STATIC_ZUDDL_URL,
  OLD_ORG_SIDE_ACCESS_IDS,
};
