import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/networking`;

const getCurrentTime = () => new Date().getTime();

export default {
  getActiveNetworking(eventId: string){
    return axios.get(`${BASE_URL}/${eventId}/config/active`, {
      params: {
        timestamp: getCurrentTime(),
      }
    });
  },
  getLatestNetworking(eventId: string){
    return axios.get(`${BASE_URL}/${eventId}/config/latest`, {
      params: {
        timestamp: getCurrentTime(),
      }
    });
  },
  enableNetworking(eventId: string, payload: any){
    return axios.post(`${BASE_URL}/${eventId}/enable`, payload);
  },
  disableNetworking(eventId: string){
    return axios.post(`${BASE_URL}/${eventId}/disable`);
  },
  getNetworkingConfig(eventId: string){
    return axios.get(`${BASE_URL}/${eventId}/config`, {
      params: {
        timestamp: getCurrentTime(),
      }
    });
  },
  getNetworkingConfigByType(eventId: string, type: string, params: any = {}){
    return axios.get(`${BASE_URL}/${eventId}/config/${type}`, {
      params: {
        ...params,
        timestamp: getCurrentTime(),
      }
    });
  },
  getLoungeStatus(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/lounge/status`, {
      params: {
        timestamp: getCurrentTime(),
      }
    });
  },
  getLoungeDetails(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/lounge/status`, {
      params: {
        room_details: 1,
        timestamp: getCurrentTime()
      }
    });
  },
  enterLounge(eventId: string) {
    return axios.post(`${BASE_URL}/${eventId}/lounge/enter`);
  },
  leaveLounge(eventId: string) {
    return axios.post(`${BASE_URL}/${eventId}/lounge/leave`);
  },
  getActiveRoom(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/room`, {
      params: {
        timestamp: getCurrentTime(),
      }
    });
  },
  getRoomStatus(eventId: string, roomId: string) {
    return axios.get(`${BASE_URL}/${eventId}/room/${roomId}/status`, {
      params: {
        timestamp: getCurrentTime(),
      }
    });
  },
  getRoomDetails(eventId: string, roomId: string) {
    return axios.get(`${BASE_URL}/${eventId}/room/${roomId}/status`, {
      params: {
        participant_details: 1,
        timestamp: getCurrentTime()
      }
    });
  },
  leaveRoom(eventId: string, roomId: string) {
    return axios.post(`${BASE_URL}/${eventId}/room/${roomId}/leave`)
  },
  getNetworkingRoundStatus(eventId: string, networkingRoundId: string){
    return axios.get(`${BASE_URL}/${eventId}/round/${networkingRoundId}/status`, {
      params: {
        timestamp: getCurrentTime(),
      }
    });
  },
  forcedEndRoom(eventId: string, roomId: string){
    return axios.post(`${BASE_URL}/${eventId}/room/${roomId}/end`);
  },
  resetNetworking(eventId: string){
    return axios.post(`${BASE_URL}/${eventId}/reset`);
  }
};
