import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

export const ExpoIcon3 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M22.05 15.4444V22.0556C22.05 22.306 21.9499 22.5463 21.7718 22.7234C21.5936 22.9005 21.352 23 21.1 23H5.9C5.64804 23 5.40641 22.9005 5.22825 22.7234C5.05009 22.5463 4.95 22.306 4.95 22.0556V15.4444H4V13.5556L4.95 8.83333H22.05L23 13.5556V15.4444H22.05ZM6.85 15.4444V21.1111H20.15V15.4444H6.85ZM7.8 16.3889H15.4V19.2222H7.8V16.3889ZM4.95 6H22.05V7.88889H4.95V6Z"
        className={fillClassName}
      />
    </svg>
  );
};

export const ExpoIcon2 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M22.1345 21.3V23H4.86355V21.3H5.7271V15.5557C5.19547 15.2064 4.75968 14.7334 4.45839 14.1785C4.1571 13.6236 3.99965 13.0041 4 12.375C4 11.672 4.19344 10.9946 4.54663 10.4174L6.88857 6.425C6.96436 6.29579 7.07337 6.18849 7.20464 6.11388C7.33591 6.03928 7.48482 6 7.6364 6H19.3625C19.5141 6 19.663 6.03928 19.7943 6.11388C19.9256 6.18849 20.0346 6.29579 20.1104 6.425L22.4437 10.4047C22.9588 11.2469 23.1244 12.2522 22.906 13.2115C22.6876 14.1708 22.102 15.0104 21.271 15.5557V21.3H22.1345ZM8.13554 7.7L6.03452 11.2811C5.83036 11.7776 5.82423 12.3317 6.01736 12.8325C6.2105 13.3332 6.58864 13.7437 7.07612 13.9818C7.56359 14.2199 8.12443 14.268 8.64639 14.1165C9.16835 13.965 9.61293 13.625 9.89113 13.1646C10.1804 12.4532 11.2037 12.4532 11.4939 13.1646C11.6539 13.5594 11.9305 13.8979 12.2879 14.1363C12.6454 14.3747 13.0672 14.5022 13.499 14.5022C13.9308 14.5022 14.3527 14.3747 14.7101 14.1363C15.0676 13.8979 15.3442 13.5594 15.5042 13.1646C15.7935 12.4532 16.8168 12.4532 17.1069 13.1646C17.219 13.4362 17.3869 13.682 17.6002 13.8865C17.8134 14.0911 18.0673 14.2501 18.3459 14.3534C18.6245 14.4567 18.9219 14.5022 19.2192 14.487C19.5166 14.4718 19.8075 14.3961 20.0738 14.2649C20.34 14.1337 20.5758 13.9497 20.7663 13.7244C20.9568 13.4992 21.0979 13.2376 21.1807 12.956C21.2635 12.6745 21.2863 12.3792 21.2476 12.0886C21.2089 11.798 21.1095 11.5184 20.9558 11.2674L18.8617 7.7H8.1364H8.13554Z"
        className={fillClassName}
      />
    </svg>
  );
};

const ExpoIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      style={{
        enableBackground: 'new 0 0 50 50',
      }}
    >
      <g>
        <g>
          <path
            className={fillClassName}
            d="M39.8,11.2v8.2c0,1.3-0.7,2.5-1.6,3.3c-0.9,0.8-2,1.3-3.3,1.3c-1.1,0-2.2-0.4-3-1c-0.9-0.6-1.5-1.5-1.8-2.5
            c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2c-0.6,2-2.5,3.5-4.8,3.5c-1.1,0-2.1-0.4-3-1s-1.5-1.5-1.8-2.5c0-0.1-0.1-0.2-0.2-0.2
            s-0.2,0.1-0.2,0.2c-0.6,2-2.5,3.5-4.7,3.5c-1.3,0-2.4-0.5-3.3-1.3c0,0-0.1-0.1-0.1-0.1c-0.9-0.8-1.5-2-1.5-3.3v-8.2
            c0-0.8,0.6-1.4,1.4-1.4h26.8C39.2,9.8,39.8,10.4,39.8,11.2z"
          />
        </g>
        <path
          className={fillClassName}
          d="M35,25.8c1.2,0,2.2-0.3,3.2-0.8v13.1c0,0.8-0.6,1.4-1.4,1.4H13.2c-0.8,0-1.4-0.6-1.4-1.4v-13
          c1,0.5,2.1,0.8,3.3,0.8c1.9,0,3.7-0.8,5-2.1c1.3,1.3,3,2.1,4.9,2.1s3.7-0.8,5-2.1C31.3,25.1,33.1,25.8,35,25.8z"
        />
      </g>
    </svg>
  );
};

export default ExpoIcon;
