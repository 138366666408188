/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { extraReducers } from './plugins';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(history) {
  return (injectedReducers = {}) => {
    const rootReducer = combineReducers({
      router: connectRouter(history),
      ...injectedReducers,
      ...extraReducers,
    });

    return rootReducer;
  };
}
