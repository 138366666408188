import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/user_privacy_setting`;

export default {
    getUserPrivacySetting(eventId: string) {
        return axios.get(`${BASE_URL}/${eventId}/`);
    },
    upsertUserPrivacySetting(eventId: string, details) {
        return axios.post(`${BASE_URL}/${eventId}/`, details);
    },
    resetUserPrivacySetting(eventId: string) {
        return axios.post(`${BASE_URL}/${eventId}/reset/`);
    },
    getUserAccountPrivacySetting(eventId: string, accountId: string) {
        return axios.get(`${BASE_URL}/${eventId}/user/${accountId}`);
    }
}