import { put, call } from 'redux-saga/effects';
import produce from 'immer';
import api from '@/api';

const initialState = {
  activeIntegrations: [],
} as any;

export default {
  namespace: 'integration',
  initialState,
  reducers: {
    reset: () => {
      return {
        ...initialState,
      };
    },
    setActiveIntegrations: (state, { data }) =>
      produce(state, draft => {
        draft.activeIntegrations = data;
      }),
  },
  effects: {
    *getActiveIntegrations({ payload: { organizationId } }) {
      try {
        const { data } = yield call(
          api.integration.getActiveIntegrations,
          organizationId,
        );
        yield put({
          type: 'integration/setActiveIntegrations',
          payload: { data },
        });
      } catch (e) {
        console.log('erro fetching active integrations', e);
      }
    },
  },
};

const selectActiveIntegrations = state => {
  const { integration } = state;
  return integration?.activeIntegrations || {};
};

export { selectActiveIntegrations };
