import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/event`;
export default {
  addTag(tag) {
    return axios.post(`${BASE_URL}/${tag.eventId}/tags`, tag);
  },
  deleteById(tagId, eventId) {
    return axios.delete(`${BASE_URL}/${eventId}/tags/${tagId}`, tagId);
  },
  getAllTags(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/tags/`);
  },
  getAssignedTags(eventId, refType) {
    return axios.get(`${BASE_URL}/${eventId}/tags/assigned_tags?refType=${refType}`);
  },
};
