import { getforeGColorRgb, getShade, getShadeRgb, getTint, getTintRgb } from "./helpers";

export function setPrimaryColor(primaryColor, secondaryColor) {
  const root = document.documentElement;

  const darkTheme = document.getElementById('theme-DARK');
  const lightTheme = document.getElementById('theme-LIGHT');

  darkTheme && darkTheme.style.setProperty('--primary-base', primaryColor);
  lightTheme && lightTheme.style.setProperty('--primary-base', primaryColor);
  darkTheme && darkTheme.style.setProperty('--secondary-base', secondaryColor);
  lightTheme && lightTheme.style.setProperty('--secondary-base', secondaryColor);
}

export function setNavbarColor(navbarColor: string, primaryColor, secondaryColor) {
  const body = document.body
  const navbarColorWithoutOpacity = navbarColor?.length > 7 ? navbarColor?.slice(0, -2) : navbarColor

  body && body.style.setProperty('--navbar-background-base', navbarColor);
  body && body.style.setProperty('--navbar-primary-base', primaryColor);
  body && body.style.setProperty('--navbar-secondary-base', secondaryColor);
  body && body.style.setProperty('--navbar-background-base-without-opacity', navbarColorWithoutOpacity);
}

export function getCssVar(variableName) {
  const root = document.documentElement;
  const lightEl = document.getElementById('theme-LIGHT');
  const darkEl = document.getElementById('theme-DARK');
  const customEL = document.getElementById('theme-CUSTOM');
  const element = (() => {
    if (darkEl) {
      return darkEl;
    }
    if (lightEl) {
      return lightEl;
    }
    if(customEL) {
      return customEL;
    }
    return root;
  })();
  return getComputedStyle(element).getPropertyValue(`--${variableName}`);
}

export function setNewLandingPageColor(primaryColor, secondaryColor) {
  const landingPageContainer = document.getElementById('new-landing-page');

  landingPageContainer && landingPageContainer.style.setProperty('--nlp-primary-base', primaryColor);
  landingPageContainer && landingPageContainer.style.setProperty('--nlp-secondary-base', secondaryColor);
}

const themeRgb = [{
  title: "base-1-rgb",
  type: "shade",
  percent: 0.30
}, {
  title: "base-2-rgb",
  type: "shade",
  percent: 0.45
}, {
  title: "base-3-rgb",
  type: "shade",
  percent: 0.60
}];

const theme = [{
  title: "monochrome-base",
  type: "shade",
  percent: 0.15
}, {
  title: "base-1",
  type: "shade",
  percent: 0.30
}, {
  title: "base-2",
  type: "shade",
  percent: 0.45
}, {
  title: "base-3",
  type: "shade",
  percent: 0.60
}, {
  title: "base-4",
  type: "shade",
  percent: 0.75
}, {
  title: "mid-1",
  type: "tint",
  percent: 0.15
}, {
  title: "mid-2",
  type: "tint",
  percent: 0.30
}, {
  title: "mid-3",
  type: "tint",
  percent: 0.45
}, {
  title: "contrast-1",
  type: "tint",
  percent: 0.6
}, {
  title: "contrast-2",
  type: "tint",
  percent: 0.75
}, {
  title: "contrast-3",
  type: "tint",
  percent: 0.9
}, {
  title: "contrast-4",
  type: "tint",
  percent: 0.95
}];

export const setIllustrationColors = (theme, primaryColor, id) => {
  if(!theme && !primaryColor) return;
  const ele = document.getElementById(id);
  const base3Color = getShadeRgb(primaryColor, 0.6);
  switch (theme) {
    case "CUSTOM":
      ele?.style.setProperty('--illustration-color', base3Color);
      ele?.style.setProperty('--illustration-background', base3Color);
      break;
    case "LIGHT":
      ele?.style.setProperty('--illustration-color', base3Color);
      ele?.style.setProperty('--illustration-background', "#f3f3f4");
      break;
    case "DARK":
      ele?.style.setProperty('--illustration-color', "#363636");
      ele?.style.setProperty('--illustration-background', "#363636");
      break;
    default:
      break;
  }
}

export const setCustomColors = (primaryColor, secondaryColor, id='theme-CUSTOM') => {
  const element = document.getElementById(id);
  try {
    element?.style.setProperty('--primary-base', primaryColor);
    element?.style.setProperty('--secondary-base', secondaryColor);
    theme.forEach(variable => {
      const color = variable?.type === "shade" ? getShadeRgb(primaryColor, variable?.percent) : getTintRgb(primaryColor, variable?.percent);
      element?.style.setProperty(`--neutral-${variable?.title}`, color);
    });
    themeRgb.forEach(variable => {
      const color = variable?.type === "shade" ? getShade(primaryColor, variable?.percent) : getTint(primaryColor, variable?.percent);
      element?.style.setProperty(`--neutral-${variable?.title}`, color.join());
    });
  } catch(error) {
    console.log("something went wrong");
  }
}

export const setTextColor = (primaryColor, id) => {
  try {
    const element = document.getElementById(id);
    const background = getShade(primaryColor, 0.6);
    const foreground = getforeGColorRgb(background);
    [
      'contrast-1',
      'contrast-2',
      'contrast-3',
      'contrast-4',
      'monochrome-contrast',
    ].forEach(eachTitle => {
      element?.style.setProperty(`--neutral-${eachTitle}`, foreground);
      element?.style.setProperty(`--text-neutral-${eachTitle}`, foreground);
    });
  } catch (error) {
    console.log('something went wrong');
  }
};

export const setBodyTextColor = (
  primaryColor,
  secondaryColor,
  colorTheme,
  id,
) => {
  try {
    const element = document.getElementById(id);
    element && element.style.setProperty('--primary-base', primaryColor);
    element && element.style.setProperty('--secondary-base', secondaryColor);
    switch (colorTheme) {
      case 'CUSTOM':
        const background = getShade(primaryColor, 0.6);
        const foreground = getforeGColorRgb(background);
        element?.style.setProperty('--neutral-monochrome-contrast', foreground);
        element?.style.setProperty(
          '--text-neutral-monochrome-contrast',
          foreground,
        );
        element?.style.setProperty(
          '--neutral-contrast-4',
          getTintRgb(primaryColor, 0.95),
        );
        break;
      case 'LIGHT':
        element?.style.setProperty('--neutral-monochrome-contrast', '#000000');
        element?.style.setProperty(
          '--text-neutral-monochrome-contrast',
          '#000000',
        );
        element?.style.setProperty(
          '--neutral-contrast-4',
          'rgba(237, 237, 237, 1)',
        );
        break;
      case 'DARK':
      default:
        element?.style.setProperty('--neutral-monochrome-contrast', '#FFFFFF');
        element?.style.setProperty(
          '--text-neutral-monochrome-contrast',
          '#FFFFFF',
        );
        element?.style.setProperty('--neutral-contrast-4', 'rgb(52,53,54)');
        break;
    }
  } catch (error) {
    console.log('something went wrong');
  }
};

export const setCustomTheme = (primaryColor, secondaryColor) => {
  setCustomColors(primaryColor, secondaryColor, 'theme-CUSTOM');
};

export const setBaseColorsById = (primaryColor, secondaryColor, id) => {
  const wrapper = document.getElementById(id);
  wrapper && wrapper.style.setProperty('--primary-base', primaryColor);
  wrapper && wrapper.style.setProperty('--secondary-base', secondaryColor);
}