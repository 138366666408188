import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

export const StageIcon3 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M20 9.85714C20 8.83293 19.103 8 18 8H6C4.897 8 4 8.83293 4 9.85714V19.1429C4 20.1671 4.897 21 6 21H18C19.103 21 20 20.1671 20 19.1429V16.0479L24 19.1429V9.85714L20 12.9521V9.85714Z"
        className={fillClassName}
      />
    </svg>
  );
};

export const StageIcon2 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M14.9 18.4211V20.2105H18.5V22H9.5V20.2105H13.1V18.4211H5.9C5.66131 18.4211 5.43239 18.3268 5.2636 18.159C5.09482 17.9912 5 17.7636 5 17.5263V5.89474C5 5.65744 5.09482 5.42986 5.2636 5.26206C5.43239 5.09427 5.66131 5 5.9 5H22.1C22.3387 5 22.5676 5.09427 22.7364 5.26206C22.9052 5.42986 23 5.65744 23 5.89474V17.5263C23 17.7636 22.9052 17.9912 22.7364 18.159C22.5676 18.3268 22.3387 18.4211 22.1 18.4211H14.9ZM12.2 9.02632V14.3947L16.7 11.7105L12.2 9.02632Z"
        className={fillClassName}
      />
    </svg>
  );
};

const StageIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      style={{
        enableBackground: 'new 0 0 50 50',
      }}
    >
      <g>
        <g>
          <line className="st0" x1="25.5" y1="37" x2="25.5" y2="37" />
        </g>
        <g>
          <path
            className={fillClassName}
            d="M22.7,10.2c0,0.1,0,0-0.1,0.2c-1.1,2.3-4.4,7.2-11.4,7.7v-6.5c0-0.8,0.6-1.4,1.4-1.4
            C12.6,10.2,22.7,10.2,22.7,10.2z"
          />
          <path
            className={fillClassName}
            d="M38.5,11.6v6.5c-0.2,0-0.4,0-0.6,0l0,0c-7-0.4-10.2-5.3-11.3-7.7c0,0,0,0-0.1-0.1H37
            C37.9,10.3,38.5,10.8,38.5,11.6z"
          />
          <path
            className={fillClassName}
            d="M15.7,29.1h-4.3c-0.1,0-0.1,0-0.2,0v-8.5c1.6,0.8,3.9,2.8,4.4,7.4C15.7,28.4,15.7,28.7,15.7,29.1z"
          />
          <path
            className={fillClassName}
            d="M13.5,19.8c6.9-1.4,10-6.9,11.1-9.1c1,2.3,4.1,7.8,11,9.1C34,21.3,32.3,24,32.1,29l-14.4,0.1
            C17.5,24.1,15.4,21.3,13.5,19.8z"
          />
          <path
            className={fillClassName}
            d="M38.5,20.3V29h-0.1h-4.3c0-0.5,0.1-0.8,0.1-1.3c0.5-4.6,2.6-6.5,3.8-7.2l0,0C38.2,20.4,38.4,20.3,38.5,20.3z"
          />
        </g>
        <g>
          <g>
            <path
              className={fillClassName}
              d="M39.8,32.1v6.4c-0.1,2-2.3,3.6-5,3.6c-1.1,0-2.2-0.3-3-0.8s-1.5-1.1-1.8-1.9c0-0.1-0.1-0.2-0.2-0.2
              s-0.2,0.1-0.2,0.2c-0.6,1.6-2.5,2.7-4.8,2.7c-1.1,0-2.1-0.3-3-0.8c-0.8-0.5-1.5-1.2-1.8-2c-0.1-0.1-0.1-0.2-0.2-0.2
              s-0.2,0.1-0.2,0.2c-0.6,1.6-2.5,2.8-4.8,2.8c-1.3,0-2.5-0.4-3.4-1c-0.9-0.6-1.5-1.5-1.6-2.5v-6.4c0-0.6,0.6-1.1,1.4-1.1h27.2h0.1
              C39.3,31,39.8,31.5,39.8,32.1z"
            />
          </g>
        </g>
        <g>
          <path className="st2" d="M38.5,18.1L38.5,18.1L38.5,18.1L38.5,18.1z" />
        </g>
      </g>
    </svg>
  );
};

export default StageIcon;
