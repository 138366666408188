import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/event_feed`;
export default {
  create(eventId: string, feedData) {
    return axios.post(`${BASE_URL}/${eventId}`, feedData);
  },
  getEventFeed(eventId: string, fetchDirection: string, dateTime: string) {
    return axios.get((
      `${BASE_URL}/${eventId}?fetchDirection=${fetchDirection}${!!dateTime ? `&dateTime=${encodeURIComponent(dateTime)}` : ''}`)
    );
  },
  getOneFeedPost(eventFeedId: string) {
    return axios.get(`${BASE_URL}/post/${eventFeedId}`);
  },
  approve(eventFeedId: string) {
    return axios.post(`${BASE_URL}/approval/${eventFeedId}`);
  },
  delete(eventFeedId: string) {
    return axios.post(`${BASE_URL}/delete/${eventFeedId}`);
  },
  createPollOptions(optionsData) {
    return axios.post(`${BASE_URL}/poll_option`, optionsData);
  },
  getPollOptions(eventFeedId: string) {
    return axios.get(`${BASE_URL}/poll_option/${eventFeedId}`);
  },
  votePoll(voteData) {
    return axios.post(`${BASE_URL}/poll_vote/`, voteData);
  },
  changeVotePoll(voteData) {
    return axios.post(`${BASE_URL}/poll_vote/change`, voteData);
  },
  likeFeed(likeData) {
    return axios.post(`${BASE_URL}/like`, likeData);
  },
  removeLikeFeed(feedLikeId) {
    return axios.post(`${BASE_URL}/like/remove/${feedLikeId}`);
  },
  getUserLikes(eventId: string) {
    return axios.get(`${BASE_URL}/user_likes/${eventId}`);
  },
  getEventFeedLikeCommentCount(eventId: string, eventFeedIds: [string]) {
    return axios.get(`${BASE_URL}/${eventId}/count?eventFeedIds=${eventFeedIds}`);
  },
  postFeedComment(commentData) {
    return axios.post(`${BASE_URL}/comment`, commentData);
  },
  getFeedComments(eventFeedId) {
    return axios.get(`${BASE_URL}/comment/${eventFeedId}`);
  },
  removeFeedComment(feedCommentId) {
    return axios.post(`${BASE_URL}/comment/remove/${feedCommentId}`);
  },
  pinFeed(eventFeedId) {
    return axios.post(`${BASE_URL}/pin/${eventFeedId}`);
  },
  unpinFeed(eventFeedId) {
    return axios.post(`${BASE_URL}/un_pin/${eventFeedId}`);
  }
};
