import axios from './axios';
import { SERVICE_URL } from '@/config';

export default {
  getLandingPageLink(eventId: string) {
    return axios.get(`${SERVICE_URL}/builder/${eventId}/landingpage/urls`);
  },
  updateLandingPageVisitors(eventId: string){
    axios.post(`${SERVICE_URL}/analytics/${eventId}/landing_page/visitors`)
  },
};
