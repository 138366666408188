import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/profanity_filter`;

export default {
  updateEventProfanityWords(eventId: string, payload: any) {
    return axios.patch(`${BASE_URL}/${eventId}`, 
    {
      updatedWords : payload
    }
    );
  },

  getEventProfanityWords(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/v2`);
  },

  downloadProfanityWordsCsv(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/downloadCSV`);
  },

  deleteProfanityFilter(eventId: string) {
     return axios.delete(`${BASE_URL}/${eventId}`);
  }
};
