import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/virtual-bg`;

export default {
  get(eventId: string, refType = 'EVENT', refId = null) {
    return axios.get(`${BASE_URL}/${eventId}`, { params: { refType, refId } });
  },
};
