export const SEGMENT_TYPE = {
  STAGE: 'STAGE',
  LOBBY: 'LOBBY',
  ROOMS: 'ROOMS',
  NETWORKING: 'NETWORKING',
  EXPO: 'EXPO',
  SCHEDULE: 'SCHEDULE',
  IN_PERSON: 'IN_PERSON',
  NONE: 'NONE',
};

export const SEGMENT_FORMAT = {
  VIRTUAL: 'VIRTUAL',
  HYBRID: 'HYBRID',
  IN_PERSON: 'IN_PERSON',
};
