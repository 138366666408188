export enum INotificationTypes {
  DIRECT_MESSAGE = 'DIRECT_MESSAGE',
  POLL_CREATED = 'POLL_CREATED',
  MEETING_ROOM_REQUEST_ACCEPTED = 'MEETING_ROOM_REQUEST_ACCEPTED',
  MEETING_ROOM_REQUEST_CANCELLED = 'MEETING_ROOM_REQUEST_CANCELLED',
  QUESTION_UPVOTE = 'QUESTION_UPVOTE',
  MEETING_ROOM_BEFORE_NOTIFICATION = 'MEETING_ROOM_BEFORE_NOTIFICATION',
  RAISED_HAND_REQUEST_ACCEPTED = 'RAISED_HAND_REQUEST_ACCEPTED',
  MEETING_ROOM_CREATE = 'MEETING_ROOM_CREATE',
  RAISED_HAND_REQUESTS_DISMISSED = 'RAISED_HAND_REQUESTS_DISMISSED',
  MEETING_ROOM_INVITATIONS = 'MEETING_ROOM_INVITATIONS',
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  MEETING_ROOM_ONTIME_NOTIFICATION = 'MEETING_ROOM_ONTIME_NOTIFICATION',
  CONNECTION_REQUEST = 'CONNECTION_REQUEST',
  MEETING_INVITE = 'MEETING_INVITE',
  MEETING_REQUEST_DECLINED = 'MEETING_REQUEST_DECLINED',
  MEETING_INVITE_INSIDE_THE_ROOM = 'MEETING_INVITE_INSIDE_THE_ROOM',
  MEETING_REQUEST_ACCEPTED = 'MEETING_REQUEST_ACCEPTED',
  MEETING_REMINDER = 'MEETING_REMINDER',
  ASKED_QUESTION_ANSWERED = 'ASKED_QUESTION_ANSWERED',
  ASKED_QUESTION_UP_VOTED = 'ASKED_QUESTION_UP_VOTED',
  ROOM_ASKED_QUESTION_ANSWERED = 'ROOM_ASKED_QUESTION_ANSWERED',
  ROOM_ASKED_QUESTION_UP_VOTED = 'ROOM_ASKED_QUESTION_UP_VOTED',
  BOOTH_JOINING_REQUEST_ACCEPTED = 'BOOTH_JOINING_REQUEST_ACCEPTED',
  BOOTH_JOINING_REQUEST_REJECTED = 'BOOTH_JOINING_REQUEST_REJECTED',
  STAGE_JOINING_REQUEST_ACCEPTED = 'STAGE_JOINING_REQUEST_ACCEPTED',
  STAGE_JOINING_REQUEST_REJECTED = 'STAGE_JOINING_REQUEST_REJECTED',
  ORGANISER_ANNOUNCEMENT_START = 'ORGANISER_ANNOUNCEMENT_START',
  ORGANISER_ANNOUNCEMENT_STOPPED = 'ORGANISER_ANNOUNCEMENT_STOPPED',
  SPEAKER_ROLL_CALL_BACKSTAGE = 'SPEAKER_ROLL_CALL_BACKSTAGE',
  MEETING_REMINDER_BEFORE_10_MINUTES = 'MEETING_REMINDER_BEFORE_10_MINUTES',
  START_SESSION = 'START_SESSION',
  BRIEFCASE_REMINDER = 'BRIEFCASE_REMINDER',
  REGISTRATION_CONFIRMATION = 'REGISTRATION_CONFIRMATION',
  EVENT_INVITATION = 'EVENT_INVITATION',
  EVENT_REMINDER_ONE_DAY_TO_GO = 'EVENT_REMINDER_ONE_DAY_TO_GO',
  EVENT_REMINDER_ONE_HOUR_TO_GO = 'EVENT_REMINDER_ONE_HOUR_TO_GO',
  THANK_YOU_FOR_COMING = 'THANK_YOU_FOR_COMING',
  EVENT_REMINDER_FIVE_MINUTES_TO_GO = 'EVENT_REMINDER_FIVE_MINUTES_TO_GO',
  LOCKED_ROOM_REQUEST_ACCEPTED = 'LOCKED_ROOM_REQUEST_ACCEPTED',
  LOCKED_ROOM_JOIN_REMINDER = 'LOCKED_ROOM_JOIN_REMINDER',
  LOCKED_ROOM_REQUEST_CANCELLED = 'LOCKED_ROOM_REQUEST_CANCELLED',
};

export enum INotificationPositions {
  BELL = 'BELL',
  SNACKBAR = 'SNACKBAR',
  ANNOUNCEMENT_BAR = 'ANNOUNCEMENT_BAR',
  MY_INBOX = 'MY_INBOX'
};
