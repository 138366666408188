import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/discussion`;
export default {
  // Discussion Settings
  saveDiscussionSettings(eventId: string, settings) {
    return axios.post(`${SERVICE_URL}/event/${eventId}/discussion_room`, settings);
  },
  getDiscussionSettings(eventId: string, roomCategory: string) {
    return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_settings?roomCategory=${roomCategory}`);
  },
  getDiscussionTableDetails(eventId: string, discussionTableId) {
    return axios.get(`${BASE_URL}/${eventId}/details/${discussionTableId}`);
  },
  getDiscussionList(eventId: string, limit, offset, q, selectedTableId, selectedTagIds, roomZoneId) {
    let roomZone = '';
    if(roomZoneId) {
      roomZone = `&roomZoneId=${roomZoneId}`;
    }
    if (!limit && !q) {
      if (selectedTableId) {
        return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/list/details?selectedTableId=${selectedTableId}`);
      } else {
        return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/list/details${selectedTagIds ? `?tagIds=${selectedTagIds}${roomZone}` : ''}`);
      }
    }
    if (!q && q.length === 0) {
      if (selectedTableId) {
        return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/list/details?limit=${limit}&offset=${offset}&selectedTableId=${selectedTableId}&tagIds=${selectedTagIds}`);
      } else {
        return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/list/details?limit=${limit}&offset=${offset}&tagIds=${selectedTagIds}${roomZone}`);
      }
    }
    return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/list/details?limit=${limit}&offset=${offset}&q=${encodeURI(q)}&tagIds=${selectedTagIds}${roomZone}`);
  },
  getDiscussionListCount(eventId: string, q, selectedTagIds, roomZoneId) {
    let roomZone = '';
    if(roomZoneId) {
      roomZone = `&roomZoneId=${roomZoneId}`;
    }
    if (!q && q.length === 0) {
      return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/list/details/count?tagIds=${selectedTagIds}${roomZone}`);
    }
    return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/list/details/count?q=${encodeURI(q)}&tagIds=${selectedTagIds}${roomZone}`);
  },
  getDiscussionList_OrgSide(eventId: string) {
    return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/o/list/details`);
  },
  getDiscussionListShort(eventId: string) {
    return axios.get(`${SERVICE_URL}/event/${eventId}/discussion_tables/list`);
  },
  join(discussionTableId: string) {
    return axios.post(`${BASE_URL}/${discussionTableId}/join`);
  },
  leave(discussionTableId: string) {
    return axios.post(`${BASE_URL}/${discussionTableId}/leave`);
  },
  updateDiscussionName(discussionTableId: string, name: string) {
    return axios.post(`${BASE_URL}/${discussionTableId}/name`, { name });
  },
  getEventTableZones(eventId: string) {
    return axios.get(`${BASE_URL}/zone/list/${eventId}`);
  },
  getAllDiscussionTables(eventId: string) {
    return axios.get(`${BASE_URL}/list/${eventId}`);
  },
  updateDiscussionTable(discussionTableId: string, discussionTableObject) {
    return axios.post(`${BASE_URL}/${discussionTableId}/update`, discussionTableObject);
  },
  getDiscussionTable(discussionTableId: string) {
    return axios.get(`${BASE_URL}/${discussionTableId}`);
  },
  deleteDiscussionRoom(discussionTableId: string) {
    return axios.delete(`${BASE_URL}/${discussionTableId}`);
  },
  updateDiscussionRoomOrder(discussionTableObject) {
    return axios.post(`${BASE_URL}/order`, discussionTableObject);
  },
  getPreAllocatedParticipants(discussionTableId: string) {
    return axios.get(`${BASE_URL}/${discussionTableId}/pre_allocate/participants`);
  },
  updatePreAllocatedParticipants(discussionTableId: string, accountIds) {
    return axios.post(`${BASE_URL}/${discussionTableId}/pre_allocate/participants`, accountIds);
  },
  removePreAllocatedParticipants(discussionTableId: string, accountIds) {
    return axios.delete(`${BASE_URL}/${discussionTableId}/pre_allocate/participants/remove`, accountIds);
  },
  joinDyteMeeting(eventId: string, meetingId: string) {
    return axios.post(`${BASE_URL}/${eventId}/dyte/join/${meetingId}`);
  },
  getLockedRoomOwners(eventId, discussionTableId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/owners`);
  },
  addLockedRoomOwners(eventId: string, discussionTableId: string, accountIds: string) {
    return axios.post(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/owners`, accountIds);
  },
  addLockedRoomQueueParticipants(eventId: string, discussionTableId: string, accountId: string) {
    return axios.post(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/participants/${accountId}`);
  },
  getQueueParticipantsList(eventId: string, discussionTableId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/participants`);
  },
  updateQueueParticipantStatus(eventId: string, discussionTableId: string, accountId: string, queueStatus, isPushBack: boolean) {
    return axios.post(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/participants/status/${accountId}?status=${queueStatus}&isPushBack=${isPushBack}`);
  },
  updateManyQueueParticipantStatus(eventId: string, discussionTableId: string, queueStatus) {
    return axios.post(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/participants/status?status=${queueStatus}`);
  },
  queueParticipantReminder(eventId, discussionTableId: string, accountId) {
    return axios.post(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/participants/reminder/${accountId}`);
  },
  clearAllParticipantsQueue(eventId, discussionTableId: string) {
    return axios.post(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/clear_queue`);
  },
  getDiscussionTableParticipantCount(discussionTableId: string) {
    return axios.get(`${BASE_URL}/${discussionTableId}/participants/count`);
  },
  validateLockedRoomAccess(eventId, discussionTableId: string, accountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${discussionTableId}/locked_room/access/${accountId}`);
  },
  confirmPresence(discussionTableId: string) {
    return axios.post(`${BASE_URL}/${discussionTableId}/confirm_presence`);
  },
  getEmojiListByDiscussionTableId(discussionTableId: string) {
    return axios.get(`${BASE_URL}/${discussionTableId}/emoji/list`);
  },
};
