import organizationApi from '@/api/organization';
import { SEGMENT_TYPE } from '@/types/segment';

const zuddlUrls = {
  PRIVACY_POLICY_URL: 'https://www.zuddl.com/privacy-policy',
  TAWK_TO_ONBOARDING_GUIDE:
    'https://coda.io/@zuddldocs/zuddl-tawk-to-onboarding-flow',
};

const ZUDDL_DOMAINS = ['zuddl.com', 'zuddl.io'];

export const isZuddlDomain = () =>
  ZUDDL_DOMAINS.some(domain => window.location.hostname.includes(domain));

export const isCustomDomain =
  !isZuddlDomain() && window.location.hostname !== 'localhost';

export function getApiBaseUrl(organizationIdentifierPath: string): string {
  let temp = `${window.location.origin}${organizationIdentifierPath || ''}/api`;

  // Optional: Direct API access (Eg. api.prod.zuddl.com). Warning: Need CORS headers on backend APIs
  // Direct API access is only allowed on *.zuddl.com domains (Not for custom domains)
  // Custom domains should still use /api as written above as CORS is not enabled for them
  if (process.env.ZUDDL_API_URL && isZuddlDomain()) {
    temp = `${process.env.ZUDDL_API_URL}${
      organizationIdentifierPath || ''
    }/api`;
  }

  return temp;
}

export async function getBaseName(refId, refType) {
  // @ts-ignore
  if (window._zuddl_org_identifier_path) {
    // @ts-ignore
    return window._zuddl_org_identifier_path;
  }
  const organization = await organizationApi.getOrganizationBaseByRef(
    refId,
    refType,
  );
  const organizationIdentifier = organization?.data.identifier;
  // @ts-ignore
  window._zuddl_org_identifier_path =
    organizationIdentifier && organizationIdentifier.trim().length > 0
      ? `/${organizationIdentifier}`
      : '';
  // @ts-ignore
  return window._zuddl_org_identifier_path;
}

export function getStudioAppBasePath() {
  return `${process.env.NX_STUDIO_APP_URL}${
    // @ts-ignore
    window._zuddl_org_identifier_path || ''
  }`;
}

export function getEventAppBasePath() {
  // @ts-ignore
  return window._zuddl_org_identifier_path || '';
}

export function getSetupAppBasePath() {
  let temp = window.location.origin;

  if (temp.includes('app')) {
    temp = temp.replace('app', 'setup');
  } else if (temp.includes('localhost')) {
    temp = temp.replace('localhost:8080', 'localhost:1217');
  }

  return `${temp}${
    // @ts-ignore
    window._zuddl_org_identifier_path || ''
  }`;
}

export const getScheduleCalendarInviteUrl = (segment, magicLinkPageUrl: string) => {
  let ref = `/l/event/${segment.eventId}`

  const segmentType = segment.type;
  const segmentTypeRefId = segment.refId;

  if(segmentType === SEGMENT_TYPE.STAGE) {
    ref += `/stages/${segmentTypeRefId}`;
  } else if(segmentType === SEGMENT_TYPE.ROOMS) {
    if(!!segmentTypeRefId) {
      ref += `/discussions/${segmentTypeRefId}`;
    } else {
      ref += `/discussions`;
    }
  } else if(segmentType === SEGMENT_TYPE.EXPO) {
    if(!!segmentTypeRefId) {
      ref += `/booth/${segmentTypeRefId}`;
    } else {
      ref += `/expo`;
    }
  } else if(segmentType === SEGMENT_TYPE.LOBBY) {
    ref += '/lobby'
  } else if(segmentType === SEGMENT_TYPE.NETWORKING) {
    ref += '/networking'
  } else {
    // No ref if any other type of segment
    ref = '';
  }

  let landingUrl: URL;
  try {
    landingUrl = new URL(magicLinkPageUrl);
  } catch (error) {
    // For organisers magicLinkPageUrl will be empty hence it will be caught here.
    console.error('Error generating magic token link for calendar invite url', error);
    landingUrl = new URL(`${window.location.origin}${getEventAppBasePath()}/p/a/event/${segment.eventId}`);
  }

  if(!!ref) {
    landingUrl.searchParams.set('ref', ref);
  }

  return landingUrl.href;
}

export default zuddlUrls;
