import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

export const NetworkingIcon3 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M13.75 5.00049C9.47631 5.00049 6 8.47679 6 12.7505C6 15.6163 7.56722 18.1169 9.88619 19.4585L8.10628 20.6452C7.79111 20.8553 7.64989 21.2479 7.76011 21.6113C7.87033 21.9747 8.20444 22.2227 8.58333 22.2227H18.9167C19.2956 22.2227 19.6305 21.9747 19.7408 21.6113C19.851 21.2479 19.7098 20.8553 19.3946 20.6452L17.6147 19.4585C19.9328 18.1169 21.5 15.6163 21.5 12.7505C21.5 8.47679 18.0237 5.00049 13.75 5.00049ZM13.75 18.7783C10.427 18.7783 7.72222 16.0735 7.72222 12.7505C7.72222 9.42746 10.427 6.72271 13.75 6.72271C17.073 6.72271 19.7778 9.42746 19.7778 12.7505C19.7778 16.0735 17.073 18.7783 13.75 18.7783Z"
        className={fillClassName}
      />
      <path
        d="M13.75 8.44507C11.3759 8.44507 9.44446 10.3765 9.44446 12.7506C9.44446 15.1247 11.3759 17.0562 13.75 17.0562C16.1241 17.0562 18.0556 15.1247 18.0556 12.7506C18.0556 10.3765 16.1241 8.44507 13.75 8.44507ZM12.4583 12.7506C11.7453 12.7506 11.1667 12.172 11.1667 11.459C11.1667 10.746 11.7453 10.1673 12.4583 10.1673C13.1713 10.1673 13.75 10.746 13.75 11.459C13.75 12.172 13.1713 12.7506 12.4583 12.7506Z"
        className={fillClassName}
      />
    </svg>
  );
};

export const NetworkingIcon2 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M5 23C5 21.2824 5.68286 19.6352 6.89837 18.4206C8.11387 17.2061 9.76245 16.5238 11.4814 16.5238C13.2004 16.5238 14.849 17.2061 16.0645 18.4206C17.28 19.6352 17.9629 21.2824 17.9629 23H5ZM11.4814 15.7143C8.79569 15.7143 6.62036 13.5407 6.62036 10.8571C6.62036 8.17357 8.79569 6 11.4814 6C14.1672 6 16.3425 8.17357 16.3425 10.8571C16.3425 13.5407 14.1672 15.7143 11.4814 15.7143ZM17.4468 17.522C18.6861 17.8402 19.7933 18.541 20.6108 19.5246C21.4283 20.5081 21.9145 21.7243 22 23H19.5832C19.5832 20.8871 18.7731 18.9637 17.4468 17.522ZM15.8078 15.6795C16.4867 15.0727 17.0297 14.3295 17.4011 13.4984C17.7725 12.6674 17.9639 11.7673 17.9629 10.8571C17.9646 9.7509 17.6814 8.66282 17.1405 7.69757C18.0582 7.88181 18.8837 8.37787 19.4767 9.10144C20.0697 9.82502 20.3936 10.7314 20.3934 11.6667C20.3936 12.2434 20.2705 12.8136 20.0322 13.3389C19.7939 13.8642 19.4459 14.3325 19.0117 14.7125C18.5775 15.0925 18.067 15.3754 17.5144 15.5422C16.9618 15.7089 16.38 15.7557 15.8078 15.6795Z"
        className={fillClassName}
      />
    </svg>
  );
};

const NetworkingIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      className={fillClassName}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      styles={{ enableBackground: 'new 0 0 50 50' }}
      width={size}
      height={size}
    >
      <g>
        <circle
          class="st0"
          className={fillClassName}
          cx="16.8"
          cy="16.6"
          r="4.7"
        />
        <g>
          <g>
            <path
              class="st0"
              className={fillClassName}
              d="M35.6,28.1L32.5,39c-0.1,0.4-0.5,0.7-1,0.7H19.3c-0.4,0-0.8-0.3-1-0.7l-3.2-10.8c-0.2-0.6,0.3-1.3,1-1.3h3.8
				c1.3,2.8,4.1,3.3,5.6,3.3s4.4-0.7,5.5-3.3h3.7C35.3,26.9,35.8,27.4,35.6,28.1z"
            />
          </g>
        </g>
        <circle
          class="st0"
          className={fillClassName}
          cx="34.1"
          cy="16.6"
          r="4.7"
        />
        <circle
          class="st0"
          className={fillClassName}
          cx="25.3"
          cy="23.8"
          r="4.7"
        />
        <g>
          <path
            class="st0"
            className={fillClassName}
            d="M41.3,22.6l-2.7,9c-0.1,0.5-0.5,0.8-1,0.8H36l1.1-3.8c0.2-0.8,0.1-1.7-0.4-2.3c-0.5-0.7-1.2-1.1-2.1-1.1h-3.1
			c0.2-0.5,0.2-2.2-0.1-2.8c0.8,0.3,1.6,0.5,2.5,0.5c1.6,0,3-0.5,4.3-1.6h1.9c0.4,0,0.7,0.1,0.9,0.4C41.3,21.9,41.3,22.3,41.3,22.6z
			"
          />
        </g>
        <g>
          <path
            class="st0"
            className={fillClassName}
            d="M13.6,28.6l1.1,3.8H13c-0.4,0-0.9-0.3-1-0.8l-2.7-9c-0.1-0.3,0-0.7,0.2-0.9s0.6-0.4,0.9-0.4h2.2
			c1.2,1,2.6,1.6,4.2,1.6c0.9,0,1.7-0.2,2.5-0.5c-0.4,1-0.2,2.4-0.1,2.8h-3.1c-0.8,0-1.6,0.4-2.1,1.1S13.3,27.8,13.6,28.6z"
          />
        </g>
        <g>
          <line class="st1" x1="26" y1="35.4" x2="26" y2="35.4" />
        </g>
      </g>
    </svg>
  );
};

export default NetworkingIcon;
