import { put } from 'redux-saga/effects';

const SHOW = '@@CORE_LOADING/SHOW';
const HIDE = '@@CORE_LOADING/HIDE';
const NAMESPACE = 'loading';

// TODO: check if we want to implement only and except behavior
const createLoading = () => {
  const initialState = {
    global: false,
    models: {},
    effects: {},
  };

  const extraReducers = {
    [NAMESPACE](state = initialState, { type, payload }) {
      const { namespace, actionType } = payload || {};
      let ret;
      switch (type) {
        case SHOW:
          ret = {
            ...state,
            global: true,
            models: { ...state.models, [namespace]: true },
            effects: { ...state.effects, [actionType]: true },
          };
          break;
        case HIDE: {
          const effects = { ...state.effects, [actionType]: false };
          const models = {
            ...state.models,
            [namespace]: Object.keys(effects).some(actionType => {
              const _namespace = actionType.split('/')[0];
              if (_namespace !== namespace) return false;
              return effects[actionType];
            }),
          };
          const global = Object.keys(models).some(namespace => models[namespace]);
          ret = {
            ...state,
            global,
            models,
            effects,
          };
          break;
        }
        case 'loading/reset': 
            ret = { 
              ...initialState
            }
         break
        default:
          ret = state;
          break;
      }
      return ret;
    },
  };

  const onEffect = (effect, model, actionType) => {
    const { namespace } = model;
    return function* (...args) {
      yield put({ type: SHOW, payload: { namespace, actionType } });
      yield effect(...args);
      yield put({ type: HIDE, payload: { namespace, actionType } });
    };
  }

  return {
    extraReducers,
    onEffect,
  };
};

const selectLoading = ({ loading }) => loading;

export { selectLoading };
export default createLoading;
