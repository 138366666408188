import { ILoginType } from '@/components/landing-page-templates/ConfigurableLandingPage/types';
import { ISmartNetworkingProfileResponse } from '@/pages/live-event/DetailedProfileSettings/types';

export type IDateTimeWithTimezone = string;
export type IUUID = string;
export type IEmailField = string;

export enum IAccountRoleTypeOptions {
  ATTENDEE = 'ATTENDEE',
  ORGANIZER = 'ORGANIZER',
  SPEAKER = 'SPEAKER',
  SPONSOR = 'SPONSOR',
  MODERATOR = 'MODERATOR'
}

export enum IAccountStatusOptions {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  INVITED = 'INVITED',
  LOCKED = 'LOCKED',
  DEACTIVATED_ACCOUNT = 'DEACTIVATED_ACCOUNT',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
}

export interface IAccountModel {
  accountId: IUUID;
  bio: string;
  createdAt: IDateTimeWithTimezone;
  email: IEmailField;
  firstName: string;
  headline: string;
  hexColor: string;
  lastName: string;
  picUrl: string;
  company: string;
  designation: string;
  phoneNumber: string;
  roles: IAccountRoleTypeOptions;
  status: IAccountStatusOptions;
  updatedAt: IDateTimeWithTimezone;
  isGuestAccount: boolean;
}

export interface IChangePasswordPayload {
  payload: {
    oldPassword: string;
    password: string;
  };
}

export interface IResetPasswordPayload {
  payload: {
    password: string;
    resetRequestId: IUUID;
  };
}

export interface IUpdateAccountPayload {
  payload: {
    account: {
      accountId: IUUID;
      bio: string;
      email: IEmailField;
      firstName: string;
      headline: string;
      hexColor: string;
      lastName: string;
      picUrl: string;
      company: string;
      designation: string;
      phoneNumber: string;
    };
  };
}

export interface IValidateResetPasswordRequestPayload {
  payload: {
    resetRequestId: IUUID;
  };
}

export interface IResetPasswordRequestPayload {
  payload: {
    email: IEmailField;
  };
}
export enum IAccountRegistrationEnum {
  LOGIN_METHOD = 'LOGIN_METHOD',
  ENTER_PASSWORD = 'ENTER_PASSWORD',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  EMAIL_OTP_LOGIN = 'EMAIL_OTP_LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  COMPLETE = 'COMPLETE',
  // In the case of an invite only event with no access for a particular email/account
  NO_ACCESS = 'NO_ACCESS',
  GUEST_LOGIN = 'GUEST_LOGIN',
}

export interface IAccountState {
  checkingAuth: boolean;
  currentUser: IAccountModel | {};
  error: [];
  eventRoleMap: Object;
  isFetchingData: boolean;
  userFlowProgress: {};
  registrationStep?: IAccountRegistrationEnum;
  selectedLoginMethod?: ILoginType | null;
  smartNetworkingProfile?: null | ISmartNetworkingProfileResponse;
}

export interface ISSOStatePayload {
  ref?: string;
  eventId?: string;
}

export interface IGuestAuthPayload {
  payload: {
    firstName: string;
    lastName: string;
    email: IEmailField;
    ticketTypeId?: string;
  };
  eventId: string;
}

export enum INotificationDeliveryTypeOptions {
  EMAIL = 'EMAIL',
  PUSHERCHANNEL = 'PUSHER_CHANNEL',
}
