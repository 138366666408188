import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/notifications`;
export default {
  getNotifications(eventId, accountId, limit) {
    // return axios.get(`${BASE_URL}/${eventId}/?offset=0&limit=${limit}`);

    return Promise.resolve({ data: [] });
  },
  updateNotification(notificationId, notification) {
    return axios.patch(`${BASE_URL}/${notificationId}`, notification);
  },
  updateNotificationsStatus(eventId, accountId) {
    return axios.post(`${BASE_URL}/${eventId}/`);
  },
  getUnReadCount(eventId, accountId) {
    return axios.get(`${BASE_URL}/${eventId}/unread_count`);
  },
  getBellNotifications(eventId, offset) {
    return axios.get(`${BASE_URL}/${eventId}/?offset=${offset}&limit=10`);
  },
  getUnReadNotificationCount(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/unread_count`);
  },
  getUserNotifySetting(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/user_setting`);
  },
  updateBellClick(eventId) {
    return axios.patch(`${BASE_URL}/${eventId}/user_setting/bell_click`);
  },
  getTotalNotifications(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/total_notification`);
  },
  updateBellPopUpStatus(eventId, status) {
    return axios.patch(`${BASE_URL}/${eventId}/user_setting/bell_popup/${status}`);
  },
  getEventNotifySetting(eventId, roleType, deliveryType) {
    return axios.get(`${BASE_URL}/${eventId}/${deliveryType}/`, { params: { roleType } });
  },
  getEventNotifyTypeSetting(eventId, roleType, deliveryType, notificationType) {
    return axios.get(`${BASE_URL}/${eventId}/${deliveryType}/${notificationType}/`, { params: { roleType } });
  },
  updateEventNotificationSettingStatus(
    eventId,
    settingId,
    status,
    shouldUpdateCalendarInviteStatus,
  ) {
    return axios.patch(
      `${BASE_URL}/${eventId}/event_setting/${settingId}/${status}?shouldUpdateCalendarInviteStatus=${shouldUpdateCalendarInviteStatus}`,
    );
  },
  getEmailNotificationSettingDetails(eventId, settingId) {
    return axios.get(`${BASE_URL}/${eventId}/event_setting/${settingId}`);
  },
  getNotificationTemplate(eventId, settingId) {
    return axios.get(`${BASE_URL}/${eventId}/event_setting/${settingId}/template`);
  },
  saveCustomTemplateData(eventId, settingId, details) {
    return axios.post(`${BASE_URL}/${eventId}/event_setting/${settingId}`, details);
  },
  updateEventNotificationTemplateStatus(eventId, settingId, status) {
    return axios.patch(`${BASE_URL}/${eventId}/event_setting/${settingId}/template/${status}`);
  },
  updateCalendarInviteStatus(eventId, settingId, status) {
    return axios.patch(`${BASE_URL}/${eventId}/event_setting/${settingId}/calendar_invite/${status}`)
  },
  sendTestEmail(eventId, notification) {
    return axios.post(`${BASE_URL}/${eventId}/test/notification`, notification);
  },
  resendCalendarInviteEmail(eventId) {
    return axios.post(`${BASE_URL}/${eventId}/calendar_invite/resend_mail`);
  }
};
