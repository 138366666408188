import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/event_role`;
export default {
  // NOTE - this can be renamed to checkEventRoleExistByEmail or existsByEmail
  checkInviteOnlyAccess(email: string, eventId: string) {
    return axios.get(
      // NOTE - email needed to be URI encoded because if the email contains a '+' (plus sign)
      //  it will fail because the plus sign has a semantic meaning in the query string.
      `${BASE_URL}/exists?email=${encodeURIComponent(
        email.trim().toLowerCase(),
      )}&eventId=${eventId}`,
    );
  },
  getCalendarInviteSentStatus(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/calendar_invite/sent_status`);
  },
};
