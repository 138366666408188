import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useMemoizedSelector = (makeSelectorFn, args: any = undefined) => {
  const selector = useMemo(makeSelectorFn, []);

  if (!args) {
    return useSelector(selector);
  }
  return useSelector(state => selector(state, args));
};
