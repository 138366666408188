import axios from './axios';
import { SERVICE_URL } from '@/config';
import { isToday } from 'date-fns';
import moment from 'moment';

const BASE_URL = `${SERVICE_URL}/segment`;
export default {
  addSegmentToEvent(eventId, segment) {
    return axios.post(`${SERVICE_URL}/event/${eventId}/segment`, segment);
  },
  getEventSegmentList(eventId, refId) {
    return axios.get(`${SERVICE_URL}/event/${eventId}/segment/list`, {
      params: { r: refId },
    });
  },
  getEventUnfilteredSegmentList(eventId, refId) {
    return axios.get(`${BASE_URL}/${eventId}/list/all`, {
      params: { r: refId },
    });
  },
  updateSegment(segmentId, segment) {
    return axios.patch(`${BASE_URL}/${segmentId}`, segment);
  },
  deleteSegment(segmentId: string, eventId: string) {
    return axios.delete(`${BASE_URL}/${eventId}/${segmentId}`);
  },
  getMeetingIntervalTime(eventId) {
    return axios.get(`${SERVICE_URL}/event/${eventId}/scheduleSettings`);
  },
  updateMeetingIntervalTime(eventId, reqObj, scheduleSettingsId) {
    return axios.patch(`${SERVICE_URL}/event/${eventId}/scheduleSettings/${scheduleSettingsId}`, reqObj);
  },
  setMeetingIntervalTime(eventId, reqObj) {
    return axios.patch(`${SERVICE_URL}/event/${eventId}/scheduleSettings`, reqObj);
  },
  enableDisableOneOnOne(eventId, mode) {
    return axios.post(`${SERVICE_URL}/event/${eventId}/scheduleSettings/schedulesStateActive?active=${mode}`);
  },
  getAttendeeTimeSlots(eventId, accountId, zone, dateTime) {
    if (isToday(dateTime)) {
      const date = new Date();
      dateTime.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), 0);
      dateTime = moment(dateTime).format('YYYY-MM-DD[T]HH:mm:ssZ');
    } else {
      dateTime = moment(dateTime).format('YYYY-MM-DD[T][00]:[00]:[00]Z');
    }
    return axios.get(`${SERVICE_URL}/event/${eventId}/attendeeScheduleSettings/timeSlots/v2`, {
      params: { userTimeZone: zone, accountId, dateTime }
    }
    );
  },
  getEventPendingSegmentList(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/pending/list/`);
  },
  getCurrentRunningSession(eventId: string, stageId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${stageId}/current`);
  },
  updateSegmentsOrdering(eventId, segments) {
    return axios.put(`${BASE_URL}/${eventId}/ordering`, segments);
  },
  getSegmentLiveDuration(eventId: string, segmentId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${segmentId}/live_duration`);
  },
};
