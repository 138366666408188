import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/event`;
export default {
  addAttendeeAvailableTime(attendeeScheduleSettingsObj, eventId) {
    return axios.post(`${BASE_URL}/${eventId}/attendeeScheduleSettings`, attendeeScheduleSettingsObj);
  },
  deleteById(attendeeScheduleSettingsId, eventId) {
    return axios.delete(`${BASE_URL}/${eventId}/attendeeScheduleSettings/${attendeeScheduleSettingsId}`, attendeeScheduleSettingsId);
  },
  getattendeeAvailableTimeByDate(eventId, date, timeZone) {
    return axios.get(`${BASE_URL}/${eventId}/attendeeScheduleSettings/?date=${date}&userTimeZone=${timeZone}`);
  },
};
