export enum IToastTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface IToastPorps {
  position: string;
  autoDelete: boolean;
  dismissTime: number;
}