import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const AutoStreamIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 16 } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7692 3.88923H1.23077C1.06763 3.88923 0.935385 4.02148 0.935385 4.18461V11.5692C0.935385 11.7324 1.06763 11.8646 1.23077 11.8646H14.7692C14.9324 11.8646 15.0646 11.7324 15.0646 11.5692V4.18461C15.0646 4.02148 14.9324 3.88923 14.7692 3.88923ZM1.23077 2.95384C0.551034 2.95384 0 3.50488 0 4.18461V11.5692C0 12.249 0.551034 12.8 1.23077 12.8H14.7692C15.449 12.8 16 12.249 16 11.5692V4.18461C16 3.50488 15.449 2.95384 14.7692 2.95384H1.23077Z"
        className={fillClassName}
      />
      <path
        d="M3.32074 6.20186H4.09378L5.39795 9.81413H4.51974L4.2568 8.94441H3.10513L2.83168 9.81413H1.96924L3.32074 6.20186ZM4.06223 8.29346C4.02717 8.1903 3.99036 8.08359 3.95179 7.97331C3.91674 7.85949 3.88168 7.74922 3.84662 7.6425C3.81507 7.53579 3.78527 7.43619 3.75722 7.3437C3.72917 7.24766 3.70463 7.16762 3.6836 7.10359C3.66256 7.17118 3.63802 7.25299 3.60997 7.34904C3.58193 7.44152 3.55037 7.54112 3.51532 7.64784C3.48376 7.75455 3.4487 7.86304 3.41014 7.97331C3.37508 8.08359 3.34002 8.1903 3.30497 8.29346H4.06223Z"
        className={fillClassName}
      />
      <path
        d="M6.26503 6.20186V8.35215C6.26503 8.50866 6.28081 8.6385 6.31236 8.74166C6.34742 8.84481 6.393 8.92841 6.44909 8.99243C6.50869 9.05646 6.57881 9.10271 6.65944 9.13116C6.74008 9.15606 6.82597 9.16851 6.91712 9.16851C7.10644 9.16851 7.25018 9.10093 7.34834 8.96576C7.45001 8.82703 7.50085 8.62249 7.50085 8.35215V6.20186H8.36854V8.40017C8.36854 8.59226 8.34225 8.77545 8.28966 8.94975C8.23707 9.12405 8.14943 9.27878 8.02672 9.41396C7.90752 9.54557 7.75151 9.6505 7.55869 9.72876C7.36587 9.80702 7.12923 9.84615 6.84876 9.84615C6.57881 9.84615 6.35093 9.80702 6.16512 9.72876C5.98281 9.64695 5.83557 9.54023 5.72338 9.40862C5.61119 9.27345 5.52881 9.11871 5.47622 8.94441C5.42714 8.77011 5.4026 8.5887 5.4026 8.40017V6.20186H6.26503Z"
        className={fillClassName}
      />
      <path
        d="M11.1397 6.20186L11.0293 6.84748H10.2089V9.81413H9.34125V6.84748H8.49459V6.20186H11.1397Z"
        className={fillClassName}
      />
      <path
        d="M12.6888 6.15384C12.9657 6.15384 13.2041 6.19652 13.404 6.2819C13.6038 6.36727 13.7686 6.48821 13.8983 6.64472C14.028 6.80124 14.1227 6.99332 14.1823 7.22098C14.2454 7.44508 14.2769 7.69764 14.2769 7.97865C14.2769 8.25611 14.2419 8.51044 14.1718 8.74166C14.1051 8.96931 14.0035 9.16496 13.8667 9.32858C13.73 9.49221 13.5582 9.62027 13.3514 9.71275C13.1445 9.80168 12.9009 9.84615 12.6204 9.84615C12.3435 9.84615 12.1033 9.80702 11.9 9.72876C11.7001 9.64695 11.5354 9.52956 11.4056 9.37661C11.2759 9.22009 11.1795 9.03156 11.1164 8.81102C11.0568 8.59048 11.027 8.33792 11.027 8.05335C11.027 7.77589 11.0603 7.52156 11.1269 7.29034C11.197 7.05557 11.3005 6.8546 11.4372 6.68741C11.5739 6.51667 11.7457 6.38505 11.9526 6.29257C12.1629 6.20008 12.4083 6.15384 12.6888 6.15384ZM12.6152 6.77812C12.3873 6.77812 12.2208 6.88661 12.1156 7.10359C12.0139 7.31702 11.9631 7.61404 11.9631 7.99466C11.9631 8.17607 11.9736 8.34148 11.9946 8.49088C12.0192 8.64028 12.0577 8.77011 12.1103 8.88038C12.1664 8.9871 12.2418 9.07069 12.3364 9.13116C12.4311 9.19163 12.5503 9.22187 12.694 9.22187C12.9219 9.22187 13.0867 9.11871 13.1884 8.9124C13.29 8.70608 13.3409 8.41262 13.3409 8.03201C13.3409 7.85059 13.3286 7.68341 13.3041 7.53045C13.283 7.37749 13.2445 7.24588 13.1884 7.13561C13.1358 7.02178 13.0622 6.93463 12.9675 6.87416C12.8728 6.81013 12.7554 6.77812 12.6152 6.77812Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default AutoStreamIcon;
