import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/event/leaderboard`;
export default {

  getLeaderboardUsersRank(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/rank`);
  },

  getLeaderboardPointsList(eventId) {
    return axios.get(`${BASE_URL}/${eventId}`)
  },

  getOrgLeaderboardPointsList(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/org`)
  },

  getLeaderboardPointsForCurrentUser(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/rank/currentUser`)
  },

  updateLeaderboardEventSetup(eventId, leaderboardEventSetupList) {
    return axios.post(`${BASE_URL}/${eventId}/update`, leaderboardEventSetupList)
  },

  enableDisableLeaderboard(eventId, active) {
    return axios.post(`${BASE_URL}/${eventId}/leaderboardActiveState?active=${active}`)
  },

  disableLeaderboardPointsDistribution(eventId, active) {
    return axios.post(`${BASE_URL}/${eventId}/leaderboardPointsDistributionActiveState?active=${active}`)
  },

  archiveLeaderboardData(eventId) {
    return axios.post(`${BASE_URL}/${eventId}/archive`);
  },

};