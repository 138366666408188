import { IStageLayoutTemplate } from '@/custom-config/types';
import { ObjectValues } from '.';

export enum ChannelVideoStatus {
  PLAYED = "PLAYED",
  PLAYING = "PLAYING",
  PAUSED = "PAUSED",
  QUEUED = "QUEUED"
};

export interface IChannelVideo {
  channelVideoId: string;
  videoLink: string;
  channelId: number;
  eventId: string;
  channelVideoStatus: ChannelVideoStatus;
  playingTime: number;
}

export enum ChannlRefType {
  STAGE = "STAGE",
  BACKSTAGE = "BACKSTAGE",
  GREENROOM = "GREENROOM",
  SESSION = "SESSION",
  BOOTH = "BOOTH",
  NETWORKING = "NETWORKING",
  EVENT = "EVENT",
  CHAT = "CHAT",
  NETWORKING_ROUND = "NETWORKING_ROUND",
  NETWORKING_ROOM = "NETWORKING_ROOM",
  DEFAULT = "DEFAULT",
  CONNECTION = "CONNECTION",
  CONNECTION_REQUEST = "CONNECTION_REQUEST",
}

export interface IEventChannelSettingsType {
  channelId: number;
  hasWaterMark: boolean;
  hasContextClickEnabled: boolean;
  hasChat: boolean;
  hasPeople: boolean;
  hasFeed: boolean;
  hasQuestionModerate: boolean;
  stageLayoutTemplate: IStageLayoutTemplate;
  hasProfanityFilter: boolean;
  hasAccessibe: boolean;
  hasGif: boolean;
  hasChatEmoji: boolean;
  hasCertificate: boolean;
  hasMessageReactions: boolean;
}

export interface IChannelRequest {
  requestBy: string;
  firstName: string;
  lastName: string;
  company: string;
  designation: string;
  picUrl: string;
  hexColor: string;
}

export const ChannelRequestType = {
  RAISE_HAND: 'RAISE_HAND',
} as const;

export type IChannelRequestType = ObjectValues<typeof ChannelRequestType>;

export const ChannelRequestStatus = {
  LIVE: 'LIVE',
  COMPLETED: 'COMPLETED',
} as const;

export type IChannelRequestStatus = ObjectValues<typeof ChannelRequestStatus>;
