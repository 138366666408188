import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { XCircle, X } from 'react-feather';
// styles + types
import styles from './styles.module.scss';
import useOutsideClick from '@/hooks/use-outside-click';

const Modal = ({
  styleClass = '',
  autoTrigger,
  onShowChange,
  title,
  children,
  render,
  trigger,
  noHeader = false,
  className = '',
  onModalClose,
  disableClose = false,
  overlayClass = '',
  noBodyPadding = false,
  customBodyPaddingStyles = '',
  id,
  modalHeaderClass,
  modalBodyClass = '',
  onModalOutSideClick,
  hasHeader = false,
  headerChildren,
  headerClassName,
  hasFooter = false,
  footerChildren,
  footerClassName,
  isNewCrossIcon = false,
  newCrossIconSize = 10.61,
  handleCloseManually = false,
  modalCloseClass = '',
  modalCloseSize = 24,
  modalCloseIconColor = '#cdcdcd',
  rootId,
  bodyHeight,
  show: showFromProps,
  setShow: setShowFromProps,
}: any) => {
  const [showFromState, setShowFromState] = useState(false);

  const show = showFromProps === undefined ? showFromState : showFromProps;
  const setShow =
    setShowFromProps === undefined ? setShowFromState : setShowFromProps;

  useEffect(() => {
    if (autoTrigger) {
      setShow(true);
    }
  }, [autoTrigger]);

  useEffect(() => {
    if (onShowChange) {
      onShowChange(show);
    }
  }, [show]);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    if (onModalOutSideClick) {
      onModalOutSideClick();
    }
  });

  const modalRoot = rootId
    ? document.getElementById(rootId)
    : document.getElementById('modal') || document.getElementById('app-modal');

  return (
    <>
      {trigger && trigger({ setShow })}
      {show &&
        modalRoot &&
        createPortal(
          <div id={id} className={classnames(styles.overlay, overlayClass)}>
            {hasHeader && (
              <div
                className={classnames(styles.topModalHeader, headerClassName)}
              >
                {headerChildren && headerChildren}
              </div>
            )}
            <div
              className={classnames(
                styles.modal,
                `${styles[styleClass]}`,
                className,
              )}
              ref={ref}
            >
              {!noHeader ? (
                <header
                  className={
                    modalHeaderClass
                      ? classnames(styles.modalHeader, styles[modalHeaderClass])
                      : styles.modalHeader
                  }
                >
                  {title}
                  {!disableClose && (
                    <button
                      className={classnames(styles.modalClose, modalCloseClass)}
                      type="button"
                      onClick={() => {
                        if (!handleCloseManually) {
                          setShow(false);
                        }
                        if (onModalClose) {
                          onModalClose(setShow);
                        }
                      }}
                      title="close popup"
                    >
                      {isNewCrossIcon ? (
                        <X
                          size={newCrossIconSize}
                          color={modalCloseIconColor}
                        />
                      ) : (
                        <XCircle size={modalCloseSize} />
                      )}
                    </button>
                  )}
                </header>
              ) : null}
              <main
                style={{ height: bodyHeight }}
                className={classnames(
                  modalBodyClass ? styles[modalBodyClass] : styles.modalBody,
                  {
                    [customBodyPaddingStyles ||
                    styles.modalBodyPadding]: !noBodyPadding,
                  },
                )}
              >
                {children && children}
                {render({ setShow })}
              </main>
              {hasFooter && (
                <footer
                  className={classnames(styles.modalFooter, footerClassName)}
                >
                  {footerChildren && footerChildren}
                </footer>
              )}
            </div>
          </div>,
          modalRoot,
        )}
    </>
  );
};

export default Modal;
