export const VERY_SMALL_ICON_SIZE = 12;
export const SMALL_ICON_SIZE = 14;
export const PROFILE_ICON_SIZE = 16;
export const STANDARD_ICON_BUTTON_SIZE = 18;
export const HOVER_NAV_ICON_SIZE = 20;
export const EMOJI_ICON_SIZE = 24;
export const MEDIUM_ICON_SIZE = 28;
export const EMOJI_MEDIUM_SIZE = 32;
export const ICON_SIZE_LARGE = 36;
export const SMALL_EMPTY_STATE_ICON = 42;
export const EMPTY_STATE_ICON = 52;
export const VERY_LARGE_ICON_SIZE = 64;
