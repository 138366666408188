import api from "@/api";
import { isAfterEventMode, isBeforeEventMode, isLiveEventMode } from "@/components/landing-page-templates/ConfigLandingPage/DisplayMessageComponents/LandingPageViewModes";
import { IEventStatus } from "@/pages/public/MagicLinkPage/MagiclinkComponent/types";

import { useEffect, useState } from "react";

const useEventStatus = ({event, checkInEnabledBeforeEventMinutes, checkInDisableAfterEventMinutes}) => {
  const [isEventLive, setEventLive] = useState(false);
  const [isEventDone, setIsEventDone] = useState(false);
  const [isEventBefore, setIsEventBefore] = useState(false);
  const [eventStatus, setEventStatus] = useState<undefined | IEventStatus>(undefined);

  const [currentUtcDateTime, setCurrentUtcDateTime] = useState(null) as any;
  useEffect(() => {
    api.event.getCurrentUtcDateTime().then(res => {
      let d = new Date(res.data);
      let currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const convertedDate = d.toLocaleString('en-US', {
        timeZone: currentZone,
      });
      setCurrentUtcDateTime(convertedDate);
    });
  }, []);

  useEffect(() => {
    console.log('debugRedirect > status 1', event?.eventId, currentUtcDateTime);
    if (!event?.eventId || !currentUtcDateTime) {
      return;
    }
    // Get the event modes for conditional component rendering
    const _isEventLive = isLiveEventMode(
      event,
      checkInEnabledBeforeEventMinutes,
      currentUtcDateTime,
      checkInDisableAfterEventMinutes,
    )
    setEventLive(_isEventLive);

    const _isEventDone = isAfterEventMode(
      event,
      currentUtcDateTime,
      checkInDisableAfterEventMinutes,
    );
    setIsEventDone(_isEventDone);

    const _isEventBefore = isBeforeEventMode(
      event,
      checkInEnabledBeforeEventMinutes,
      currentUtcDateTime,
    );
    setIsEventBefore(_isEventBefore);

    if(_isEventLive) {
      setEventStatus(IEventStatus.ONGOING);
    } else if(_isEventDone) {
      setEventStatus(IEventStatus.COMPLETED);
    } else if(_isEventBefore) {
      setEventStatus(IEventStatus.UPCOMING);
    }
  }, [
    currentUtcDateTime,
    event,
    checkInEnabledBeforeEventMinutes,
    checkInDisableAfterEventMinutes,
  ]);

  return {
    isEventLive,
    isEventDone,
    isEventBefore,
    eventStatus,
    currentUtcDateTime
  }
}


export default useEventStatus;
