export interface IUserPrivacySetting {
    title: string;
    description: string;
    isActive: boolean;
    key: string;
}

export enum IUserPrivacyTypes {
    PRIVATE_MEETINGS = "PRIVATE_MEETINGS",
    PRIVATE_MESSAGING = "PRIVATE_MESSAGING",
    INVALID = "INVALID"
}

export enum IUserPrivacyErrors {
    PRIVATE_MESSAGING_BLOCKED = "PRIVATE_MESSAGING_BLOCKED",
    PRIVATE_MEETINGS_BLOCKED = "PRIVATE_MEETINGS_BLOCKED"
}