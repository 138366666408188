import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/message`;
export default {
  deleteMessage(messageId: string) {
    return axios.delete(`${BASE_URL}/${messageId}`);
  },
  updateMessagePinStatus(messageId: string, pinStatus: boolean) {
    return axios.put(`${BASE_URL}/${messageId}/pin/${pinStatus}`);
  },
  pinOnStage(messageId: number, stageId: string) {
    return axios.post(
      `${BASE_URL}/${messageId}/pin_engagement/stage/${stageId}`,
    );
  },
  unpinFromStage(messageId: number, stageId: string) {
    return axios.delete(
      `${BASE_URL}/${messageId}/pin_engagement/stage/${stageId}`,
    );
  },
};
