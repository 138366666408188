import account from './account';
import booth from './booth';
import channel from './channel';
import discussion from './discussion';
import event from './event';
import eventFeed from './eventFeed';
import eventRole from './eventRole';
import stage from './stage';
import segment from './segment';
import question from './question';
import poll from './poll';
import vimeo from './vimeo';
import sponsor from './sponsor';
import stream from './stream';
import networking from './networking';
import connection from './connection';
import meetingRoom from './meetingRoom';
import notifications from './notifications';
import feedback from './feedback';
import badge from './badge';
import organizationTeam from './organizationTeam';
import ticketType from './ticketType';
import recording from './recording';
import speaker from './speaker';
import eventTags from './eventTags';
import smartNetworking from './smartNetworking';
import leaderboard from './leaderboard';
import customOtp from './customotp';
import breakout from './breakout';
import bulkRegistration from './bulkRegistration';
import attendeeSheduleSettings from './attendeeSheduleSettings';
import ticketTypeBasedNetworking from './ticketTypeBasedNetworking';
import reports from './reports';
import integration from './integration';
import userPrivacySetting from './userPrivacySetting';
import message from './message';
import profanityFilter from './profanityFilter';
import rtmpOut from './rtmpOut';
import accessGroups from './accessGroups';
import pusher from './pusher';
import liveAnalytics from './liveanalytics';
import registration from './registration';
import pageBuilder from './pageBuilder';
import virtualBackgrounds from './virtualBackgrounds';
import eventCustomDomain from './eventCustomDomain';
import certificate from './certificate';
import cybersource from './cybersource';

const api = {
  // internal
  account,
  booth,
  event,
  discussion,
  segment,
  stage,
  channel,
  question,
  poll,
  eventFeed,
  eventRole,
  sponsor,
  stream,
  networking,
  connection,
  meetingRoom,
  notifications,
  feedback,
  badge,
  organizationTeam,
  ticketType,
  recording,
  speaker,
  eventTags,
  smartNetworking,
  leaderboard,
  customOtp,
  breakout,
  bulkRegistration,
  attendeeSheduleSettings,
  ticketTypeBasedNetworking,
  reports,
  userPrivacySetting,
  message,
  profanityFilter,
  rtmpOut,
  accessGroups,
  pusher,
  liveAnalytics,
  registration,
  pageBuilder,
  virtualBackgrounds,
  eventCustomDomain,
  certificate,
  cybersource,

  // external
  vimeo,
  integration,
};

export default api;
