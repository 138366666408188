import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
//Styles
import styles from './styles.module.scss';
//types
import { IToastImagesTypes } from './config';
import { IToastPorps, IToastTypes } from './types';
// models
import { selectToastList } from '@/models/global';

const Toast = (props: IToastPorps) => {
  const { position = 'bottomRight', autoDelete = true, dismissTime = 5000 } = props;
  const [isRunning, setIsRunning] = useState(true);
  const toastList = useSelector(selectToastList);

  const dispatch = useDispatch();
  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning && autoDelete && toastList.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);
    return () => {
      clearInterval(interval);
    }
  }, [toastList, autoDelete, dismissTime, isRunning]);
  
  const togglePause = () => {
    setIsRunning(!isRunning);
  };
  const deleteToast = id => {
    dispatch({
      type: 'global/removeToast',
      payload: id,
    });
    setIsRunning(true);
  }
  return (
    <div className={classnames(
      styles.notificationContainer,
      `${styles[position]}`,
    )}>
      {
        toastList &&
        toastList.map((item, i) =>
          <div
            key={item.id}
            className={classnames(styles.notification, `${styles[item.type]}`, `${styles[position]}`)}
            onMouseEnter={togglePause}
            onMouseLeave={togglePause}
          >
            <button onClick={() => deleteToast(item.id)}>
              <img src={IToastImagesTypes.close} alt="X"/>
              </button>
            <div className={styles.notificationImage}>
              <img src={IToastImagesTypes[item.type]} alt="" />
            </div>
            <div className={styles.messageContainer}>
              <p className={styles.notificationTitle}>
                {(() => {
                  if (item.title) {
                    return item.title;
                  }
                  if (IToastTypes.ERROR === item.type) {
                    return '';
                  }
                  return item.type;
                })()}
              </p>
              <p className={styles.notificationMessage}>{item.description}</p>
            </div>
            <div className={styles.loader}>
              <span className={styles.rabbit} style={{animationPlayState: isRunning ? 'running' : 'paused'}}></span>
            </div>  
          </div>
        )
      }
    </div>
  );
}

export default Toast;
