import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

export const LobbyIcon2 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      className={fillClassName}
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M20.1579 7.78947H19.2105V6.89474C19.2105 6.39995 18.7871 6 18.2632 6H6.89474C6.37084 6 5.94737 6.39995 5.94737 6.89474V12.2632V14.9474C5.94737 16.9238 7.64411 18.5263 9.73684 18.5263H15.4211C17.1813 18.5263 18.6525 17.3819 19.076 15.8421H20.1579C21.7277 15.8421 23 14.6405 23 13.1579V10.4737C23 8.99111 21.7277 7.78947 20.1579 7.78947ZM21.1053 13.1579C21.1053 13.6518 20.6808 14.0526 20.1579 14.0526H19.2105V13.1579V12.2632V9.57895H20.1579C20.6808 9.57895 21.1053 9.97979 21.1053 10.4737V13.1579ZM19.2105 20.3158H6.89474H5.94737H5C5 21.7957 6.27516 23 7.84211 23H18.2632C19.8301 23 21.1053 21.7957 21.1053 20.3158H20.1579H19.2105Z"
        className={fillClassName}
      />
    </svg>
  );
};

const LobbyIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      className={fillClassName}
      style={{
        enableBackground: 'new 0 0 50 50',
      }}
    >
      <g>
        <g>
          <path
            className={fillClassName}
            d="M38.9,37.9h-2.5c-0.3,0-0.6-0.3-0.6-0.6v-2.8h3.8v2.8C39.6,37.6,39.2,37.9,38.9,37.9z"
          />
        </g>
        <g>
          <path
            className={fillClassName}
            d="M13.7,37.9h-2.5c-0.3,0-0.6-0.3-0.6-0.6v-2.8h3.8v2.8C14.4,37.6,14,37.9,13.7,37.9z"
          />
        </g>
        <g>
          <path
            className={fillClassName}
            d="M26,23.3v-4.9h11.8v4.9c0,0.2-0.2,0.4-0.4,0.4h-11C26.2,23.7,26,23.5,26,23.3z"
          />
          <path
            className={fillClassName}
            d="M12.2,23.3v-4.9H24v4.9c0,0.2-0.2,0.4-0.4,0.4h-11C12.4,23.7,12.2,23.5,12.2,23.3z"
          />
          <path
            className={fillClassName}
            d="M43.5,19.8v13.3c0,0.8-0.6,1.4-1.4,1.4H7.9c-0.8,0-1.4-0.6-1.4-1.4V19.8c0-0.8,0.6-1.4,1.4-1.4h2.3v4.9
            c0,1.3,1.1,2.4,2.4,2.4h11c0.5,0,1-0.2,1.4-0.5c0.4,0.3,0.9,0.5,1.4,0.5h11c1.3,0,2.4-1.1,2.4-2.4v-4.9h2.3
            C42.9,18.4,43.5,19.1,43.5,19.8z"
          />
        </g>
        <g>
          <path
            className={fillClassName}
            d="M24,12.7v10.6c0,0.2-0.2,0.4-0.4,0.4h-11c-0.2,0-0.4-0.2-0.4-0.4V12.7c0-0.2,0.2-0.4,0.4-0.4h11
            C23.8,12.3,24,12.5,24,12.7z"
          />
        </g>
        <g>
          <path
            className={fillClassName}
            d="M37.8,12.7v10.6c0,0.2-0.2,0.4-0.4,0.4h-11c-0.2,0-0.4-0.2-0.4-0.4V12.7c0-0.2,0.2-0.4,0.4-0.4h11
            C37.6,12.3,37.8,12.5,37.8,12.7z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LobbyIcon;
