import axios, { AxiosResponse } from 'axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/reports`;

export default {
  getEventReportDownload(
    eventId: string,
    reportName: string,
  ): Promise<AxiosResponse<string>> {
    return axios.get(`${BASE_URL}/${eventId}/download/${reportName}`, {
      responseType: 'blob',
    });
  },

  getEventReportText(
    eventId: string,
    reportName: string    
  ): Promise<AxiosResponse<string>> {
    return axios.get(`${BASE_URL}/${eventId}/download/${reportName}`);
  },

  getCluvioEmbedUrl(
    eventId: string,
    dashboard: string,
  ): Promise<AxiosResponse<string>> {
    return axios.get(`${BASE_URL}/${eventId}/cluvioEmbedUrl/${dashboard}`);
  },
};
