import { IContentTextMarginTypes } from '@/types';
import { ITextStyles } from '@/components/ui/content/Text';

export interface IButtonProps {
  children?: any;
  className?: any;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  margin?: IContentTextMarginTypes;
  onBlur?: any;
  onClick?: any;
  size?: IButtonSize;
  styleClass?: IButtonTypes;
  textStyle?: ITextStyles;
  type: 'button' | 'submit' | 'reset' | undefined;
  style?: any;
  reverseChildren?: boolean;
  hasHover?: true | false;
  id?: string;
  ref?: any;
  tooltipFor?: string;
  tooltipProps?: any;
  dataTestId?: string;
  tabIndex?: number;
}

export enum IButtonSize {
  SMALL = 'SMALL',
  REGULAR = 'REGULAR',
}

export enum IButtonTypes {
  // SQUARE
  CONTRAST_NO_RADIUS = 'contrastNoRadius',
  CONTRAST_PRIMARY = 'contrast',
  CONTRAST_SECONDARY = 'contrastSecondary',
  CANCEL_BUTTON = 'cancelButton',
  DANGER_NO_BG = 'dangerNoBG',
  DANGER_PILL_RIGHT = 'dangerPillRight',
  DANGER_PRIMARY = 'danger',
  DANGER_SQUARE_FULL = 'dangerSquare',
  INVISIBLE_PRIMARY = 'invisiblePrimary',
  NAVIGATION = 'navigation',
  NEUTRAL_PRIMARY = 'neutral',
  NEUTRAL_SECONDARY = 'neutralSecondary',
  NEW_CONTRAST = 'newContrast',
  NEW_DANGER = 'newDanger',
  NEW_DANGER_SECONDARY = 'newDangerSecondary',
  NEW_PRIMARY = 'newPrimary',
  NEW_PRIMARY_LARGE = 'newPrimaryLarge',
  NEW_SECONDARY = 'newSecondary',
  NEW_SECONDARY_CHAT = 'newSecondaryChat',
  NEW_SUCCESS_SECONDARY = 'newSuccessSecondary',
  NEW_NEUTRAL = 'newNeutral',
  NO_BG = 'noBG',
  NO_BG_BLACK = 'noBGBlack',
  PRIMARY_NO_RADIUS_NO_BG_FULL = 'primaryNoRadiusNoBGFull',
  PRIMARY = 'primary',
  PRIMARY_NO_BG = 'primaryNoBG',
  PRIMARY_NO_BG_INLINE = 'primaryNoBGInline',
  PRIMARY_NO_RADIUS = 'primaryNoRadius',
  PRIMARY_PILL_LEFT = 'primaryPillLeft',
  PRIMARY_SQUARE_FULL = 'primarySquare',
  PRIMARY_SQUARE_NO_RADIUS_FULL = 'primarySquareNoRadius',
  DANGER_SQUARE_FULL_2 = 'dangerSquareFull',
  DANGER_SQUARE_NO_RADIUS_FULL = 'dangerSquareNoRadiusFull',
  PRIMARY_SQUARE_SMALL = 'primarySquareSmall',
  SECONDARY = 'secondary',
  SECONDARY_SQUARE_FULL = 'secondarySquare',
  SECONDARY_NO_BG = 'secondaryNoBGInline',
  SUCCESS_PILL_LEFT = 'successPillLeft',
  SUCCESS_PILL_RIGHT = 'successPillRight',
  SUCCESS_PRIMARY = 'success',
  PRIMARY_DISABLED = 'primaryDisabled',
  SUCCESS_PRIMARY_SQUARE = 'successSquare',
  SUCCESS_PRIMARY_FULL = 'successFull',
  WHITE_PRIMARY = 'whitePrimary',
  WHITE_SQUARE = 'whiteSquare',
  NO_BG_WHITE_SQUARE = 'noBGWhiteSquare',
  NO_BG_BLACK_SQUARE = 'noBGBlackSquare',
  NEW_SOLID_BLUE = 'newSolidBlue',
  NEW_SOLID_PRIMARY = 'newSolidPrimary',
  NO_BG_CONTRAST = 'noBGContrast',
  NO_BG_CONTRAST_FULL ='noBGContrastFull',
  NEW_BUTTON_PERVIEW = 'landingPagePreview',
  NEW_ADD_MEMBER_BUTTON = 'addMember',
  NEW_BUTTON_LANDINGPAGE = 'landingPageBtBgWhite',
  NEW_BUTTON_SCHEDULE = 'schedulePageBtOrange',
  // NOTE - these were added for the SignIn page redesign
  NEW_SIGNIN_BUTTON = 'newSignInButton',
  NEW_SOCIAL_BUTTON = 'newSocialButton',
  NEW_SOCIAL_BUTTON_FULL = 'newSocialButtonfull',

  SOCIAL_LOGIN_POPUP_BUTTON = 'socialLoginPopupButton',
  SOCIAL_LOGIN_POPUP_ICON = 'socialLoginPopupIcon',

  SOCIAL_LOGIN_INLINE_ICON = 'socialLoginInlineIcon',
  SOCIAL_LOGIN_INLINE_SSO_ICON = 'socialLoginInlineSSOIcon',

  // For networking page
  NTWRK_OUTLINED_DANGER_SQUARE = 'ntwrkOutlinedDangerSquare',
  NTWRK_OUTLINED_SUCCESS_SQUARE = 'ntwrkOutlinedSuccessSquare',
  NTWRK_PRIMARY_SQUARE = 'ntwrkPrimarySquare',
  NTWRK_SUCCESS_SQUARE = 'ntwrkSuccessSquare',
  NTWRK_DANGER_SQUARE = 'ntwrkDangerSquare',

  // For Q&A Tab
  QUESTION_ANSWERED = 'questionAnswered',

  //
  SQUARE = 'squareButton',
  BRAND_PRIMARY = 'BRAND_PRIMARY',

  // Snackbar button
  SNACK_BAR_BUTTON_PRIMARY = 'snackBarButtonPrimary',
  SNACK_BAR_BUTTON_SECONDARY = 'snackBarButtonSecondary',
  NEW_SNACK_BAR_BUTTON_PRIMARY = 'newSnackBarPrimary',

  // Backstage
  BCKSTG_DANGER_SQUARE = 'bckstgDangerSquare',
  BCKSTG_SUCCESS_SQUARE = 'bckstgSuccessSquare',
  BCKSTG_INPROGRESS_DISABLE = 'bckstgInProgressDisable',
  BCKSTG_SUCCESS_SQUARE_RADIUS = 'bckstgSuccessSquareRadius',
  BCKSTG_DANGER_SQUARE_RADIUS = 'bckstgDangerSquareRadius',
  BCKSTG_PAUSE_SESSION = 'bckstgPauseSquareRadius',

  // Functional (Grey Blue)
  FUNCTIONAL_PRIMARY = 'functionalPrimary',
  FUNCTIONAL_NEUTRAL = 'functionalNeutral',
  FUNCTIONAL_DANGER = 'functionalDanger',
  FUNCTIONAL_SUCCESS = 'functionalSuccess',

  FUNCTIONAL_PRIMARY_LARGE = 'functionalPrimaryLarge',
  FUNCTIONAL_NEUTRAL_LARGE = 'functionalNeutralLarge',

  LIVE_STREAM_STOP_PRIMARY = 'liveStreamStopPrimary',

  COLOURS_NEUTRAL_BASE_3 = 'coloursNeutralBase3',
  COLOURS_NEUTRAL_BASE_4 = 'coloursNeutralBase4',
  COLOURS_NEUTRAL_BASE_3_SVG_FILL = 'coloursNeutralBase3SvgFill',
  COLOURS_NEUTRAL_BASE_2 = 'coloursNeutralBase2',
  COLOURS_NEUTRAL_BASE_1 = 'coloursNeutralBase1',
  COLOURS_PRIMARY = 'coloursPrimary',
  COLOURS_MONOCHROME_CONTRAST = 'coloursMonochromeContrast',
  COLOURS_MONOCHROME_CONTRAST_OUTLINED = 'coloursMonochromeContrastOutlined',
  COLOURS_NEUTRAL_MID_1 = 'coloursNeutralMid1',
  COLOURS_WARNING_BASE = 'coloursWarningBase',
  // profile form
  BRAND_PRIMARY_SQUARE = 'brandPrimarySquare',
  // rooms
  ROOMS_DANGER_SQUARE_RADIUS = 'roomsDangerSquareRadius',
  // speaker (join bckstage)
  BTN_DANGER_SQUARE_RADIUS = 'btnDangerSquareRadius',
  // polls
  PRIMARY_SQUARE_NO_HOVER = 'primaryNoHover',
  PRIMARY_SQUARE_GREY_NO_HOVER = 'primaryBorderAndTextGreyBgNoHover',
  SUCCESS_BORDER_TEXT_SQUARE_NO_HOVER = 'successBorderAndTextBgNoHover',
  PRIMARY_SQUARE_NO_BG_INLINE_UNDERLINE = 'primaryNoBGInlineUnderline',
  // expo
  FUNCTIONAL_DANGER_EXIT_EXPO = 'functionalDangerExitExpo',
  NO_BG_DASHED_BORDER = 'noBGDashedBorder',
  // access groups
  NO_BG_GREY_BLUE = 'noBGGreyBlue',
  // Like "FUNCTIONAL" but with grey border and svg fill
  MONOCHROME_CONTRAST_OUTLINED_GREY_BASE_3 = 'coloursMonochromeContrastOutlinedGreyBase3',
  MONOCHROME_CONTRAST_OUTLINED_GREY_BASE_3_NO_FILL = 'coloursMonochromeContrastOutlinedGreyBase3NoFill',
  VIEW_AS_ATTENDEE_BUTTON = 'viewAsAttendee',
  NO_BG_WHITE='nobgwhite',
  POLL_NO_BG_BORDER = 'pollNoBgBorder',
}
