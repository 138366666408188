import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectUserAccessGroups, selectZoneConfig } from '@/models/event';
import { useEffect } from 'react';
import { IAccountRoleTypeOptions } from '@/models/account/types';
import { zoneTypes } from '@/models/event/types';
import { useUserAccessGroups } from './use-user-access-groups';
import { EFeatures } from '@/config/accessGroupsConfig';
import api from '@/api';

export const useRedirectAfterLogin = ({ currentUser }) => {
  const { eventId = '' } = useParams<{ eventId: string }>();
  const dispatch = useDispatch();

  const zoneConfig = useSelector(selectZoneConfig);
  const userAccessGroups = useSelector(makeSelectUserAccessGroups);
  const { hasZoneAccessInList, hasZoneAccessInListV2 } = useUserAccessGroups();

  useEffect(() => {
    if (
      currentUser?.accountId &&
      (!userAccessGroups || userAccessGroups.length === 0) &&
      eventId
    ) {
      dispatch({
        type: 'event/getUserAccessGroupsByEventId',
        payload: {
          eventId,
        },
      });
    }
  }, [userAccessGroups, currentUser, eventId]);

  const areBoothZonesAvailable = (
    zoneSetup: any,
    _eventId: string,
    accessList,
    boothZonesObj: any,
  ) =>
    Object.keys(boothZonesObj)
      ?.map(boothZoneId => {
        if (
          zoneSetup[boothZoneId] &&
          hasZoneAccessInListV2(EFeatures.CUSTOM_ZONES, accessList, boothZoneId)
        ) {
          const boothId = zoneConfig.filter(
            zone => zone.zoneId === boothZoneId,
          )[0]?.refId;

          return `/l/event/${_eventId}/booth/${boothId}`;
        }

        return undefined;
      })
      .filter(Boolean);

  const generateUrl = (
    role: IAccountRoleTypeOptions,
    zoneSetup: any,
    event_id: string,
    accessList,
    boothZonesObj?,
  ) => {
    // for attendee login
    if (role === IAccountRoleTypeOptions.ATTENDEE) {
      // lobby, stage, schedule, room, expo, networking
      if (
        zoneSetup[zoneTypes.LOBBY] &&
        hasZoneAccessInList(EFeatures.LOBBY, accessList)
      ) {
        return `/l/event/${event_id}/lobby`;
      }
      if (
        zoneSetup[zoneTypes.STAGE] &&
        hasZoneAccessInList(EFeatures.STAGE, accessList)
      ) {
        return `/l/event/${event_id}/stages`;
      }
      if (
        zoneSetup[zoneTypes.SCHEDULE] &&
        hasZoneAccessInList(EFeatures.SCHEDULE, accessList)
      ) {
        return `/l/event/${event_id}/schedule`;
      }
      if (
        (zoneSetup[zoneTypes.TABLE] || zoneSetup[zoneTypes.ROOMS]) &&
        hasZoneAccessInList(EFeatures.ROOM, accessList)
      ) {
        return `/l/event/${event_id}/discussions`;
      }
      if (
        zoneSetup[zoneTypes.EXPO] &&
        hasZoneAccessInList(EFeatures.EXPO, accessList)
      ) {
        return `/l/event/${event_id}/expo`;
      }
      if (
        zoneSetup[zoneTypes.NETWORKING] &&
        hasZoneAccessInList(EFeatures.NETWORKING, accessList)
      ) {
        return `/l/event/${event_id}/networking`;
      }
      // Custom Zones
      const boothZones = areBoothZonesAvailable(
        zoneSetup,
        event_id,
        accessList,
        boothZonesObj,
      );
      if (boothZones.length) {
        return boothZones[0];
      }

      return `/l/event/${event_id}/stages`;
    }

    // for organizer, moderator & speaker
    // stage, schedule, lobby, rooms, expo, networking
    if (
      zoneSetup[zoneTypes.STAGE] &&
      hasZoneAccessInList(EFeatures.STAGE, accessList)
    ) {
      return `/l/event/${event_id}/stages`;
    }
    if (
      zoneSetup[zoneTypes.SCHEDULE] &&
      hasZoneAccessInList(EFeatures.SCHEDULE, accessList)
    ) {
      return `/l/event/${event_id}/schedule`;
    }
    if (
      zoneSetup[zoneTypes.LOBBY] &&
      hasZoneAccessInList(EFeatures.LOBBY, accessList)
    ) {
      return `/l/event/${event_id}/lobby`;
    }
    if (
      (zoneSetup[zoneTypes.TABLE] || zoneSetup[zoneTypes.ROOMS]) &&
      hasZoneAccessInList(EFeatures.ROOM, accessList)
    ) {
      return `/l/event/${event_id}/discussions`;
    }
    if (
      zoneSetup[zoneTypes.EXPO] &&
      hasZoneAccessInList(EFeatures.EXPO, accessList)
    ) {
      return `/l/event/${event_id}/expo`;
    }
    if (
      zoneSetup[zoneTypes.NETWORKING] &&
      hasZoneAccessInList(EFeatures.NETWORKING, accessList)
    ) {
      return `/l/event/${event_id}/networking`;
    }
    // Custom Zones
    const boothZones = areBoothZonesAvailable(
      zoneSetup,
      event_id,
      accessList,
      boothZonesObj,
    );
    if (boothZones.length) {
      return boothZones[0];
    }

    return `/l/event/${event_id}/stages`;
  };

  // this method for live event side view
  const redirectPageAfterCheckIn = (
    role: IAccountRoleTypeOptions,
    zoneSetup: any,
  ) => {
    const boothsZonesObj = {};
    Object.entries(zoneSetup).forEach(zone => {
      if (
        ![
          'LOBBY',
          'STAGE',
          'TABLE',
          'NETWORKING',
          'SCHEDULE',
          'ROOMS',
          'EXPO',
        ].includes(zone[0])
      ) {
        Object.assign(boothsZonesObj, { [zone[0]]: zone[1] });
      }
    });

    return generateUrl(
      role,
      zoneSetup,
      eventId,
      userAccessGroups,
      boothsZonesObj,
    );
  };

  // this method for organizer side view
  const redirectPageWithEventId = async (
    role: IAccountRoleTypeOptions,
    zoneSetup: any,
    event_id: string,
  ) => {
    const { data } = await api.accessGroups.getLiveUserAllAccessGroups(
      event_id,
    );

    const boothsZonesObj = {};
    Object.entries(zoneSetup).forEach(zone => {
      if (
        ![
          'LOBBY',
          'STAGE',
          'TABLE',
          'NETWORKING',
          'SCHEDULE',
          'ROOMS',
          'EXPO',
        ].includes(zone[0])
      ) {
        Object.assign(boothsZonesObj, { [zone[0]]: zone[1] });
      }
    });

    return generateUrl(role, zoneSetup, event_id, data, boothsZonesObj);
  };

  return {
    redirectPageAfterCheckIn,
    redirectPageWithEventId,
  };
};
