import { SERVICE_URL } from '@/config';
import { ICheckoutResponse } from '@/types/cybersource';
import axios from './axios';

const BASE_URL = `${SERVICE_URL}/event`;

export default {
  getCheckoutDetails(eventId: string, flowContextTransactionId: string) {
    return axios.get<ICheckoutResponse>(
      `${BASE_URL}/${eventId}/cybersource/${flowContextTransactionId}/capture-context`,
    );
  },

  payerAuthenticationSetup(
    eventId: string,
    flowCtxTransactionId: string,
    transientToken: string,
  ) {
    return axios.post(
      `${BASE_URL}/${eventId}/cybersource/${flowCtxTransactionId}/payer-authentication-setup`,
      {
        transientToken,
      },
    );
  },

  payerAuthenticationEnrollment(
    eventId: string,
    flowCtxTransactionId: string,
    transientToken: string,
    referenceId: string,
  ) {
    return axios.post(
      `${BASE_URL}/${eventId}/cybersource/${flowCtxTransactionId}/payer-authentication-enrollment`,
      {
        transientToken,
        referenceId,
      },
    );
  },

  payerAuthenticationValidation(
    eventId: string,
    flowCtxTransactionId: string,
    transientToken: string,
  ) {
    return axios.post(
      `${BASE_URL}/${eventId}/cybersource/${flowCtxTransactionId}/payer-authentication-validation`,
      {
        transientToken,
      },
    );
  },

  createPayment(
    eventId: string,
    flowCtxTransactionId: string,
    transientToken: string,
  ) {
    return axios.post(
      `${BASE_URL}/${eventId}/cybersource/${flowCtxTransactionId}/create-payment`,
      {
        transientToken,
      },
    );
  },
};
