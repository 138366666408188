import axios from './axios';
import { SERVICE_URL } from '@/config';

export default {
  getAllTicketTypesByEventId(eventId: string) {
    return axios.get(
      `${SERVICE_URL}/event/${eventId}/ticket-type/v2/list/basic-info`,
    );
  },
  getTicketTypeById(eventId: string, ticketTypeId: string) {
    return axios.get(
      `${SERVICE_URL}/event/${eventId}/ticket-type/v2/${ticketTypeId}`,
    );
  },
};
