import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/networking_constraints`;

export default {
  createConstraint(eventId: string, constraint: any) {
    return axios.post(`${BASE_URL}/${eventId}/create`, constraint);
  },

  createManyConstraint(eventId: string, constraint: any) {
    return axios.post(`${BASE_URL}/${eventId}/create/many`, constraint);
  },

  getAllTicketTypeConstraints(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/view`);
  },

  getAllTicketTypeConstraintsDetails(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/view/detailed`);
  },

  updateManyConstraints(eventId: string, payload: any) {
    return axios.post(`${BASE_URL}/${eventId}/update/many`, payload);
  },

  getHasEmptyMatchingConstraints(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/view/constraints/exist`);
  },
};
