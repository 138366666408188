import React from 'react';
import { useParams } from 'react-router-dom';
// styles + types
import styles from './styles.module.scss';
import { ILiveEventColorThemes } from '@/types';
// hookes + models
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectEventById } from '@/models/event';

const PublicMenuLayout = ({ children }) => {
  let { eventId = '' } = useParams();

  const event = useMemoizedSelector(makeSelectEventById, eventId);

  const colorTheme = (() => {
    if (event && event.colorTheme) {
      return ILiveEventColorThemes[event.colorTheme];
    }
    return ILiveEventColorThemes.LIGHT;
  })();

  return (
    <div className={styles.publicMenuLayoutContainer} id={`theme-${colorTheme}`}>
      <main className={styles.mainContainer}>{children}</main>
      <div id="modal"></div>
      <div id="video-full"></div>
      <div id="fullscreen"></div>
    </div >
  );
};

export default PublicMenuLayout;
