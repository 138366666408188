import React from 'react';
import classnames from 'classnames';
// types
import { ILabelProps } from './types';
// styles
import styles from './styles.module.scss';

const Label = (props: ILabelProps) => {
  const {
    value,
    styleClass,
    className,
    onClick,
    readOnly,
    hasTooltip = false,
    clickable = false,
    wrapperClass = '',
    hideTooltip = false,
    isMandatory = false,
    isSponsorLandingPage = false,
  } = props;

  const pointerText =
    !!onClick || !!clickable ? styles.clickHover : styles.normalText;
  return (
    <div
      className={classnames(
        hasTooltip ? styles.labelWrapperAbs : styles.labelWrapper,
        {
          [styles[wrapperClass]]: wrapperClass && wrapperClass,
        },
      )}
    >
      {value && value.length > 40 && !hideTooltip && (
        <div className={styles.tooltip}>{value}</div>
      )}
      <span className={isMandatory && isSponsorLandingPage ? styles.isMandatory : ""}>
        <label
          onClick={onClick}
          className={classnames(
            readOnly ? styles.disabledText : pointerText,
            styleClass ? styles[styleClass] : "",
            value.length > 40 ? styles["overflowText"] : styles["showText"],
            isMandatory && !isSponsorLandingPage ? styles.isMandatory : "",
            className,
            )}
        >
          {value}
        </label>
      </span>
    </div>
  );
};

export default Label;
