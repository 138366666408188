import axios from './axios';
import { SERVICE_URL } from '@/config';

const getBaseUrl = eventId => `${SERVICE_URL}/${eventId}/breakout`;

export default {
  // Breakout Room Organizer API
  setBreakoutConfig(eventId, refId, refType, breakoutConfig) {
    return axios.post(
      `${getBaseUrl(eventId)}/config?refId=${refId}&refType=${refType}`,
      breakoutConfig,
    );
  },
  getStageBreakoutConfig(eventId, refId, refType) {
    return axios.get(
      `${getBaseUrl(eventId)}/config?refId=${refId}&refType=${refType}`,
    );
  },
  assignBreakout(eventId, breakoutId, breakoutAssignmentList) {
    return axios.post(
      `${getBaseUrl(eventId)}/${breakoutId}/assign`,
      breakoutAssignmentList,
    );
  },
  assignBreakoutCSV(eventId, breakoutId, breakoutAssignmentList) {
    return axios.post(
      `${getBaseUrl(eventId)}/${breakoutId}/assign_csv`,
      breakoutAssignmentList,
    );
  },
  getBreakoutAssignment(eventId, breakoutId) {
    return axios.get(`${getBaseUrl(eventId)}/${breakoutId}/assignment`);
  },
  deleteBreakoutRoom(eventId, breakoutId, breakoutRoomNumber) {
    return axios.post(
      `${getBaseUrl(eventId)}/${breakoutId}/delete_room/${breakoutRoomNumber}`,
    );
  },
  // Live Event API
  breakoutStage(eventId, breakoutId, breakoutUnassigned = false) {
    return axios.post(
      `${getBaseUrl(
        eventId,
      )}/${breakoutId}/breakout?breakoutUnassigned=${breakoutUnassigned}`,
    );
  },
  isStageInBreakout(eventId, stageId) {
    return axios.get(`${getBaseUrl(eventId)}/${stageId}/is_in_breakout`);
  },
  getUserBreakoutRoom(eventId, breakoutId) {
    return axios.get(`${getBaseUrl(eventId)}/${breakoutId}/my_breakout_room`);
  },
  bringBack(eventId, breakoutId) {
    return axios.post(`${getBaseUrl(eventId)}/${breakoutId}/bring_back`);
  },
  // This api only for fetching stage breakout basic info
  getBreakoutConfigByStageId(eventId: string, stageId: string) {
    return axios.get(`${getBaseUrl(eventId)}/config/${stageId}`);
  },
};
