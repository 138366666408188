import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const AudioIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 12"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="2.3999"
        width="2.5"
        height="7.2"
        rx="1.25"
        className={fillClassName}
      />
      <rect x="5" width="2.5" height="12" rx="1.25" className={fillClassName} />
      <rect
        x="10"
        y="2.3999"
        width="2.5"
        height="7.2"
        rx="1.25"
        className={fillClassName}
      />
    </svg>
  );
};

export default AudioIcon;
