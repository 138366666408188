import createLoading from './loading';

const plugins = [createLoading()];

const pluginConfig = plugins.reduce((acc, plugin) => {
  return {
    extraReducers: {
      ...acc.extraReducers,
      ...plugin.extraReducers
    },
    onEffect: [...acc.onEffect, plugin.onEffect]
  };
}, {
  extraReducers: {},
  onEffect: []
});

export const extraReducers = pluginConfig.extraReducers;
export const onEffect = pluginConfig.onEffect;
