/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Linkify from 'linkify-react';
import classnames from 'classnames';
// types
import { ITextProps } from './types';
import {
  IContentTextSizeTypes,
  IContentTextStyleTypes,
  IContentTextColorTypes,
  IContentTextMarginTypes,
} from '@/types';
// styles
import styles from './styles.module.scss';
import AddReadMore from '../../AddReadMore';

const Text = (props: ITextProps) => {
  const {
    textAlign,
    textColor = IContentTextColorTypes.DEFAULT,
    textSize = IContentTextSizeTypes.DEFAULT,
    textStyle = IContentTextStyleTypes.DEFAULT,
    textMargin = IContentTextMarginTypes.NO_MARGIN,
    textLineHeight = 1,
    text,
    linkify = false,
    block = false,
    className = '',
    linkColorSameAsText = false,
    readOnly = false,
    onClick,
    clickable = false,
    collapsible = false,
    collapseTextLength,
    underlineLinks = false,
  } = props;

  const pointerText =
    !!onClick || !!clickable ? styles.clickHover : styles.normalText;

  const formattedText = collapsible ? (
    <AddReadMore
      value={text}
      collapseTextLength={collapseTextLength}
      linkify
      style={styles[textColor]}
      linkColorSameAsText={linkColorSameAsText}
    />
  ) : (
    text
  );

  return block ? (
    <div
      className={classnames(
        styles[textColor],
        styles[textSize],
        styles[textStyle],
        styles[textMargin],
        readOnly ? styles.disabledText : pointerText,
        className,
      )}
      style={{
        textAlign,
        lineHeight: textLineHeight,
      }}
      onClick={onClick}
    >
      {linkify && !collapsible ? (
        <Linkify
          options={{
            className: linkColorSameAsText ? styles[textColor] : '',
            target: "_blank"
          }}
        >
          {formattedText}
        </Linkify>
      ) : (
        formattedText
      )}
    </div>
  ) : (
    <span
      className={classnames(
        styles[textColor],
        styles[textSize],
        styles[textStyle],
        styles[textMargin],
        readOnly ? styles.disabledText : pointerText,
        className,
        underlineLinks ? styles.linkText : '',
      )}
      style={{
        textAlign,
        lineHeight: textLineHeight,
      }}
      onClick={onClick}
    >
      {linkify && !collapsible ? (
        <Linkify
          options={{
            className: linkColorSameAsText ? styles[textColor] : '',
            target: "_blank"
          }}
        >
          {formattedText}
        </Linkify>
      ) : (
        formattedText
      )}
    </span>
  );
};

export {
  IContentTextSizeTypes as ITextSizes,
  IContentTextStyleTypes as ITextStyles,
  IContentTextColorTypes as ITextColors,
  IContentTextMarginTypes as ITextMargins,
};
export default Text;
