export enum ILandingPage {
  BASIC = 'BASIC',
  SPONSOR = 'SPONSOR',
  // TYPETHREE = 'TYPETHREE',
}

export enum IRegistrationAndCheckInType {
  NO_REGISTRATION = 'NO_REGISTRATION',
  REGISTRATION = 'REGISTRATION',
  DIRECT_CHECKIN = 'DIRECT_CHECKIN',
  INVITE_ONLY = 'INVITE_ONLY',
  EMAIL = 'EMAIL',
  OTP_EMAIL = 'OTP_EMAIL',
  NAME = 'NAME',
  DEFAULT_FORM = 'DEFAULT_FORM',
  CUSTOM_FORM = 'CUSTOM_FORM',
  CUSTOM_FORM_DAYS = 'CUSTOM_FORM_DAYS',
  OTP_EMAIL_REGISTRATION = 'OTP_EMAIL_REGISTRATION',
  OTP_EMAIL_DIRECT_REGISTRATION = 'OTP_EMAIL_DIRECT_REGISTRATION',
  SSO_EMAIL_DIRECT_CHECKIN = 'SSO_EMAIL_DIRECT_CHECKIN',
}

export enum IEventCheckinType {
  REGISTRATION = 'REGISTRATION',
  DIRECT_CHECKIN = 'DIRECT_CHECKIN',
  INVITE_ONLY = 'INVITE_ONLY',
  TICKETING = 'TICKETING',
  SSO_ONLY = 'SSO_ONLY',
}

export enum ITemplateType {
  SPONSOR = 'SPONSOR',
}

// NOTE - this enum almost matches the "AuthMode" enum on the backend
export enum ILoginType {
  EMAIL = 'EMAIL',
  EMAIL_OTP = 'EMAIL_OTP',
  SSO = 'SSO',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  // NOTE- this is not yet implemented with the new auth
  GUEST = 'GUEST',
}

// NOTE - this matches the "AuthType" enum type on the backend
export enum IAuthType {
  EMAIL = 'EMAIL',
  EMAIL_OTP = 'EMAIL_OTP',
  SSO = 'SSO',
  SOCIAL_LOGIN = 'SOCIAL_LOGIN',
  LEGACY_EMAIL = 'LEGACY_EMAIL',
  LEGACY_CUSTOM_FORM = 'LEGACY_CUSTOM_FORM',
}

export interface IAuthSelectField {
  label: IAuthType;
  value: IAuthType;
}

export interface IAuthOptions {
  attendeeAuthOptions: IAuthSelectField[];
  speakerAuthOptions: IAuthSelectField[];
}

export const IFormTypeSelection = [
  {
    label: 'Registration',
    value: IEventCheckinType.REGISTRATION,
  },
  {
    label: 'Direct Check In',
    value: IEventCheckinType.DIRECT_CHECKIN,
  },
  {
    label: 'Invite Only (Private Event)',
    value: IEventCheckinType.INVITE_ONLY,
  },
];

export type ITemplateDetails = {
  allowDayWiseRegistration: boolean;
  allowRegistrationAfterEventStart: boolean;
  backgroundImageUrl: string;
  checkInEnableTime: number;
  disclaimerMessage: string | null;
  eventEntryType: IEventCheckinType;
  eventId: string;
  eventLandingPageId: string;
  eventLandingPageSponsorDTOList: any;
  formBgColour: string;
  formFontColour: string;
  isCountDownRequired: boolean;
  isDisclaimerRequired: any;
  isPromptRequired: any;
  isSpeakerLandingPageSame: any;
  landingPageTemplateName: ILandingPage;
  logoImageUrl: string | null;
  logoImageUrlTwo: string | null;
  promptMessage: string | null;
  registrationCustomFieldList: {}[] | null;
  guestModeEnabled: boolean;
  attendeeAuthType: IAuthType[];
  speakerAuthType: IAuthType[];
  embeddingLogo?: string;
};

interface ILandingPageNoBackgroundStyle {
  color: string;
}
export interface ILandingPageCounterNoBackgroundStyle extends ILandingPageNoBackgroundStyle {}



interface ILandingPageStyle {
  backgroundColor: string;
  color: string;
}

export interface ILandingPageCounterStyle extends ILandingPageStyle {}
export interface ILandingPageFormContainerStyle extends ILandingPageStyle {}
export interface ILandingPageButtonStyle extends ILandingPageStyle {}

export const redirectPageAfterPaymentByEventId = {
  // redirectType : _blank , this will open url in separate window
  // redirectType : _self ,  this will open url in same window
  // prod event ids
  'df3f0b1a-4692-4db4-b137-4ed5390b11c5': {
    url: 'https://1729.world/thank-you-payment/',
    redirectType: '_self',
  },
  'f67c916f-63a3-4fee-8ac5-06c780653d23': {
    url: 'https://1729.world/thank-you-payment/',
    redirectType: '_self',
  },
  '16c118f9-3904-41b1-a871-014bd79b8f53': {
    url: 'https://1729.world/thank-you-payment/',
    redirectType: '_self',
  },
};
