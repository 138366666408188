import React from 'react';
import Linkify from 'linkify-react';
import * as linkifyjs from 'linkifyjs';

import useToggle from '@/hooks/use-toggle';
import { IAddReadMorePropTypes } from './types';

import styles from './styles.module.scss';

export const componentDecorator = (classname = '') => (href, text, key) => (
  <a className={classname} href={href} key={key} target="_blank">
    {text}
  </a>
);

const AddReadMore = (props: IAddReadMorePropTypes) => {
  const {
    value,
    collapseTextLength = 80,
    linkify,
    style,
    linkColorSameAsText,
  } = props;
  const { value: isReadMore, toggleValue: toggleIsReadMore } = useToggle(
    value?.length > collapseTextLength,
  );
  const toggleReadMore = e => {
    e?.preventDefault();
    e?.stopPropagation();
    toggleIsReadMore();
  };

  let Component: any = 'span';
  let linkifyProps = {};

  if (linkify) {
    Component = Linkify;
    linkifyProps = {
      options: {
        className: linkColorSameAsText ? style : '',
        target: '_blank',
      },
    };
  }

  if (!value) {
    return <span></span>;
  }

  if (!collapseTextLength || value?.length <= collapseTextLength) {
    return <Component {...linkifyProps}>{value}</Component>;
  }

  let truncatedTextToRender = React.useMemo(() => {
    // Checking if the text truncated is a link
    if(isReadMore){
      const textAtTruncation = value
        .slice(0, isReadMore ? collapseTextLength : undefined)
        .split(' ')
        .pop()
  
      const findLinksInText = linkifyjs.find(value)
        
      const collapsedTextContent: string = value.slice(0, collapseTextLength);
      const indexOfStartOfTheWholeText = collapsedTextContent.lastIndexOf(textAtTruncation);
      const indexOfEndOfTheWholeText = findLinksInText.find(item => item.start === indexOfStartOfTheWholeText)?.end
      
      const wholeTextAtTruncation: string = value.slice(indexOfStartOfTheWholeText, indexOfEndOfTheWholeText)
  
      // If the truncated text is a link
      if(linkifyjs.test(wholeTextAtTruncation)){
        // Truncate the string after the link if link length is < 50, else truncate before the link
        const newTruncatedText = value.slice(
          0,
          wholeTextAtTruncation.length < 50
            ? indexOfEndOfTheWholeText
            : indexOfStartOfTheWholeText,
        );
  
        return newTruncatedText;
      }
    }

    return value.slice(0, collapseTextLength);
  }, [isReadMore, value, collapseTextLength]);

  return (
    <Component {...linkifyProps}>
      {isReadMore ? `${truncatedTextToRender}... ` : `${value} `}
      <span onClick={toggleReadMore} className={styles.readOrHide}>
        {isReadMore ? 'read more...' : 'read less...'}
      </span>
    </Component>
  );
};

export default AddReadMore;
