import { put, select } from 'redux-saga/effects';
import produce from 'immer';
import shortid from 'shortid';
import { push } from 'connected-react-router';
// types
import { IToastTypes } from '@/components/ui/toast/types';
import { IGlobalIntialState } from './types';
import { median } from '@/utils/helpers';
import { VideoQualityTypes } from '@/components/PublishedStreamDisplay/StreamSettingsBar/utils';

const initialState = {
  toastList: [],
  stageFull: false,
  manualResolutionOverride: false,
  networkQuality: {
    uplink: [],
    downlink: [],
    averageUplink: undefined,
    averageDownlink: undefined,
  },
  currentProfileIndex: 0,
  currentProfile: {
    main: '360p_1',   // https://docs.agora.io/en/All/API%20Reference/web/interfaces/agorartc.videoencoderconfiguration.html
    screen: '720p_1', // https://docs.agora.io/en/Interactive%20Broadcast/API%20Reference/web_ng/globals.html#screenencoderconfigurationpreset
    noScreenNextIndex: 3,
    updated: new Date(),
  },
  speakerRollCall: null,
  useVirtualBackdrop: false,
  stageVolumeOn: false,
  showLeaderboard: false,
  showAnnouncementModal: false,
  channelSidePanelState: null,
  messageModal: null,
  editProfileModal: null,
  isRecordingMode: false,
  notifications: [],
  unReadNotifications: 0,
  totalNotifications: 0,
  selectedInboxAccountId: null,
  stageVideoQuality: VideoQualityTypes.AUTO,
  userNotifySetting: {},
  userCurrentScreen: null,
  unReadChatList: [],
  audioPlaybackDeviceId: 'default',
  showCompatibilityCheck: false,
  channelConnectionState: {},
  viewProfileModal: null,
  FTUEAnnoucementModal: null,
  userPrivacySettingModal: false,
  userPrivacySetting: [],
  myMeetingRoomsModal: false,
  isLobbyMobileViewEnabled: false,
  recapdLanguagesList: [{ id: 'OFF', value: 'OFF' }],
} as IGlobalIntialState;

const streamProfiles = [
  {
    main: '360p_1', // 640 × 480, 4:3, 15, 500
    screen: '720p_1', // 1280 × 720, 5 fps
  },
  // {
  //   main: '720p_3', // 1280x720, 16:9, 30, 1710
  //   screen: '720p_2',
  // }, {
  //   main: '720p_1', // 1280x720, 16:9, 15, 1130
  //   screen: '720p_2',
  // }, {
  //   main: '720p_1', // 1280x720, 16:9, 15, 1130
  //   screen: '720p_1'
  // }, {
  //   main: '480p_9', // 848 × 480, 16:9, 30, 930
  //   screen: '720p_1'
  // }, {
  //   main: '480p_1', // 640 × 480, 4:3, 15, 500
  //   screen: '720p_1',
  // }, {
  //   main: '360p_1', // 640 × 360, 16:9, 15, 400
  //   screen: '720p_1',
  // }, {
  //   main: '360p_7', // 480 × 360, 4:3, 15, 320
  //   screen: '720p_1',
  // }, {
  //   main: '360p_10', // 640 × 480, 4:3, 24, 1000
  //   screen: '720p_2',
  // },
];

export default {
  namespace: 'global',
  initialState,
  reducers: {
    reset: state => {
      return {
        ...initialState,
      };
    },
    setTriggerToast: (state, { data }) =>
      produce(state, draft => {
        draft.toastList = data;
      }),
    setStageFull: (state, stageFull) =>
      produce(state, draft => {
        draft.stageFull = stageFull;
      }),
    clear: () => {
      return {
        toastList: [],
      };
    },
    setSpeakerRollCall: (state, { eventId, stageId, title, body }) => {
      return {
        ...state,
        speakerRollCall: {
          eventId,
          stageId,
          title,
          body,
        },
      };
    },
    clearSpeakerRollCall: state => {
      return {
        ...state,
        speakerRollCall: null,
      };
    },
    setMessageModal: (state, payload) => {
      return {
        ...state,
        messageModal: payload,
      };
    },
    clearMessageModal: state => {
      return {
        ...state,
        messageModal: null,
      };
    },
    toggleStageVolume: state => {
      return {
        ...state,
        stageVolumeOn: !state.stageVolumeOn,
      };
    },
    setStageVolume: (state, volumeOn) => {
      return {
        ...state,
        stageVolumeOn: volumeOn,
      };
    },
    showLeaderboard: state => {
      return {
        ...state,
        showLeaderboard: true,
      };
    },
    hideLeaderboard: state => {
      return {
        ...state,
        showLeaderboard: false,
      };
    },
    showAnnouncementModal: state => {
      return {
        ...state,
        showAnnouncementModal: true,
      };
    },
    hideAnnouncementModal: state => {
      return {
        ...state,
        showAnnouncementModal: false,
      };
    },
    setEditProfileModal: (state, payload = {}) => {
      return {
        ...state,
        editProfileModal: payload,
      };
    },
    closeEditProfileModal: state => {
      return {
        ...state,
        editProfileModal: null,
      };
    },

    setViewProfileModal: (state, payload = {}) => ({
      ...state,
      viewProfileModal: payload,
    }),

    closeViewProfileModal: state => ({
      ...state,
      viewProfileModal: null,
    }),

    setStageVideoQuality: (state, { videoQuality }) => ({
      ...state,
      stageVideoQuality: videoQuality,
    }),
    updateUseVirtualBackdrop: (state, { useVirtualBackdrop }) =>
      produce(state, draft => {
        draft.useVirtualBackdrop = useVirtualBackdrop;
      }),
    updateScreenResolution(state, { screenProfile }) {
      if (!['480p_2', '720p_2', '480p_1', '720p_1'].includes(screenProfile)) {
        return state;
      }

      return produce(state, draft => {
        draft.currentProfile.screen = screenProfile;
      });
    },
    updateNetworkQuality(
      state,
      { uplinkNetworkQuality, downlinkNetworkQuality },
    ) {
      const stateR = produce(state, (draft: any) => {
        const uplinkHistory = draft.networkQuality.uplink;
        const downlinkHistory = draft.networkQuality.downlink;
        const sampleCount = 10;

        if (uplinkNetworkQuality !== 0) {
          if (uplinkHistory.length >= sampleCount) {
            uplinkHistory.shift();
          }
          uplinkHistory.push(uplinkNetworkQuality);

          const now = new Date();
          const medianValue = median(uplinkHistory) as number;
          draft.networkQuality.averageUplink = median(uplinkHistory);

          // define TODO
          if (
            (now.getTime() - state.currentProfile.updated.getTime()) / 1000 >=
            60 * 10
          ) {
            if (medianValue <= 2 && draft.currentProfileIndex >= 0) {
              const newIndex = draft.currentProfileIndex - 1;
              if (newIndex < 0) {
                return;
              }

              draft.currentProfileIndex = newIndex;
              draft.currentProfile = {
                ...streamProfiles[draft.currentProfileIndex],
                updated: new Date(),
              };
              console.log('upgrading profile >', draft.currentProfile);
              return;
            }
          }

          if (
            (now.getTime() - state.currentProfile.updated.getTime()) / 1000 >=
            sampleCount
          ) {
            if (medianValue <= 3.5) {
              return;
            }

            if (draft.currentProfileIndex >= streamProfiles.length - 1) {
              return;
            }

            if (
              medianValue <= 4.5 ||
              draft.currentProfileIndex === streamProfiles.length - 2
            ) {
              const newIndex = draft.currentProfileIndex + 1;
              draft.currentProfileIndex = newIndex;
              draft.currentProfile = {
                ...streamProfiles[draft.currentProfileIndex],
                updated: new Date(),
              };
              console.log('downgrading profile >', draft.currentProfile);
            } else {
              const newIndex = draft.currentProfileIndex + 2;
              draft.currentProfileIndex = newIndex;
              draft.currentProfile = {
                ...streamProfiles[draft.currentProfileIndex],
                updated: new Date(),
              };
              console.log('downgrading profile >', draft.currentProfile);
            }
          }
        }

        if (downlinkNetworkQuality !== 0) {
          if (downlinkHistory.length >= sampleCount) {
            downlinkHistory.shift();
          }
          downlinkHistory.push(downlinkNetworkQuality);
        }

        draft.networkQuality.averageDownlink = median(downlinkHistory);
      });
      return stateR;
    },
    setChannelSidePanelState: (state, channelSidePanelState) => {
      return {
        ...state,
        channelSidePanelState: channelSidePanelState,
      };
    },
    setRecordingMode: (state, value) => {
      return {
        ...state,
        isRecordingMode: value,
      };
    },
    addNotification: (state, value) => {
      return {
        ...state,
        notifications: [value, ...state.notifications],
      };
    },

    setNotification: (state, value) => {
      return {
        ...state,
        notifications: value,
      };
    },

    replaceNotification: (state, value) => {
      let notifications = [...state.notifications];
      const index = notifications.findIndex(element => element.id === value.id);
      if (index > -1) {
        notifications.splice(index, 1);
        notifications = [value].concat(notifications);
      }
      return {
        ...state,
        notifications,
      };
    },

    increaseUnReadNotifications: state => {
      return {
        ...state,
        unReadNotifications: state.unReadNotifications + 1,
      };
    },

    setUnReadNotifications: (state, value) => {
      return {
        ...state,
        unReadNotifications: value,
      };
    },

    setTotalNotifications: (state, value) => {
      return {
        ...state,
        totalNotifications: value,
      };
    },

    increaseTotalNotifications: state => {
      return {
        ...state,
        totalNotifications: state.totalNotifications + 1,
      };
    },

    setSelectedInboxAccountId: (state, value) => {
      return {
        ...state,
        selectedInboxAccountId: value,
      };
    },

    setUserNotifiySetting: (state, value) => {
      return {
        ...state,
        userNotifySetting: value,
      };
    },

    setUserCurrentScreen: (state, value) => {
      return {
        ...state,
        userCurrentScreen: value,
      };
    },

    setUnReadChatList: (state, value) => {
      return {
        ...state,
        unReadChatList: value,
      };
    },

    setAudioPlaybackDeviceId: (state, value) => ({
      ...state,
      audioPlaybackDeviceId: value,
    }),

    setShowCompatibilityCheck: (state, value) => ({
      ...state,
      showCompatibilityCheck: value,
    }),

    setChannelConnectionState: (state, value) => ({
      ...state,
      channelConnectionState: value,
    }),

    setFTUEAnnoucementModal: (state, payload = {}) => ({
      ...state,
      FTUEAnnoucementModal: payload,
    }),

    closeFTUEAnnoucementModal: state => ({
      ...state,
      FTUEAnnoucementModal: null,
    }),

    setUserPrivacySettingModal: (state, payload) => {
      return {
        ...state,
        userPrivacySettingModal: payload,
      };
    },

    setUserPrivacySetting:(state, payload) => {
      return {
        ...state,
        userPrivacySetting: payload,
      };
    },
    setMyMeetingRoomsModal: (state, payload = {}) => {
      return {
        ...state,
        myMeetingRoomsModal: payload,
      };
    },
    setLobbyMobileViewStatus: (state, payload) => {
      return {
        ...state,
        isLobbyMobileViewEnabled: payload,
      };
    },
    updateRecapdLanguagesList: (state, payload) => {
      return {
        ...state,
        recapdLanguagesList: payload,
      };
    },
  },
  effects: {
    *addSuccessToast({ payload }) {
      // let oldData = yield select(selectToastList);
      payload.id = shortid.generate();
      payload.type = IToastTypes.SUCCESS;
      yield put({
        type: 'global/setTriggerToast',
        payload: { data: [payload] },
      });
    },
    *addDangerToast({ payload }) {
      // let oldData = yield select(selectToastList);
      payload.id = shortid.generate();
      payload.type = IToastTypes.ERROR;
      yield put({
        type: 'global/setTriggerToast',
        payload: { data: [payload] },
      });
    },
    *addWarningToast({ payload }) {
      // let oldData = yield select(selectToastList);
      payload.id = shortid.generate();
      payload.type = IToastTypes.WARNING;
      yield put({
        type: 'global/setTriggerToast',
        payload: { data: [payload] },
      });
    },
    *addInfoToast({ payload }) {
      // let oldData = yield select(selectToastList);
      payload.id = shortid.generate();
      payload.type = IToastTypes.INFO;
      yield put({
        type: 'global/setTriggerToast',
        payload: { data: [payload] },
      });
    },
    *removeToast({ payload }) {
      let data = yield select(selectToastList);
      yield put({
        type: 'global/setTriggerToast',
        payload: { data: data.filter(item => item.id !== payload) },
      });
    },
    *followRollCall({ payload }) {
      const speakerRollCall = yield select(selectSpeakerRollCall);
      if (speakerRollCall) {
        const { eventId, stageId } = speakerRollCall;
        yield put({
          type: 'global/clearSpeakerRollCall',
        });
        yield put(push(`/l/event/${eventId}/stages/${stageId}/backstage`));
      }
    },
    *dismissRollCall() {
      yield put({
        type: 'global/clearSpeakerRollCall',
      });
    },
  },
};

const selectIsStageFull = ({ global }) => global && global.stageFull;
const selectToastList = ({ global }) => (global && global.toastList) || [];
const selectCurrentProfile = ({ global }) => global && global.currentProfile;
const selectSpeakerRollCall = ({ global }) => global && global.speakerRollCall;
const selectUseVirtualBackdrop = ({ global }) =>
  global && global.useVirtualBackdrop;
const selectIsStageVolumeOn = ({ global }) => global && global.stageVolumeOn;
const selectShowLeaderboard = ({ global }) => global && global.showLeaderboard;
const selectChannelSidePanelState = ({ global }) =>
  global && global.channelSidePanelState;
const selectShowAnnouncementModal = ({ global }) =>
  global && global.showAnnouncementModal;
const selectMessageModal = ({ global }) => global && global.messageModal;
const selectEditProfileModal = ({ global }) =>
  global && global.editProfileModal;
const selectRecordingMode = ({ global }) => global && global.isRecordingMode;
const selectNotifications = ({ global }) => global && global.notifications;
const selectUnReadNotifications = ({ global }) =>
  global && global.unReadNotifications;
const selectTotalNotifications = ({ global }) =>
  global && global.totalNotifications;
const selectSelectedInboxAccountId = ({ global }) =>
  global && global.selectedInboxAccountId;
const selectStageVideoQuality = ({ global }) =>
  global && global.stageVideoQuality;
const selectUserNotifiySetting = ({ global }) =>
  global && global.userNotifySetting;
const selectUserCurrentScreen = ({ global }) =>
  global && global.userCurrentScreen;
const selectUnReadChatList = ({ global }) => global && global.unReadChatList;
const selectNetworkQuality = ({ global }) => global && global.networkQuality;
const selectAudioPlaybackDeviceId = ({ global }) =>
  global && global.audioPlaybackDeviceId;
const selectShowCompatibilityCheck = ({ global }) =>
  global && global.showCompatibilityCheck;
const selectChannelConnectionState = ({ global }) =>
  global && global.channelConnectionState;
const selectViewProfileModal = ({ global }) =>
  global && global.viewProfileModal;
const selectFTUEAnnoucementModal = ({ global }) =>
  global && global.FTUEAnnoucementModal;
const selectUserPrivacySettingModal = ({ global }) =>
global && global.userPrivacySettingModal;
const selectUserPrivacySetting = ({ global }) =>
global && global.userPrivacySetting;
const selectMyMeetingRoomsModal = ({ global }) =>
  global && global.myMeetingRoomsModal;
const selectIsLobbyMobileViewEnabled = ({ global }) =>
  global && global.isLobbyMobileViewEnabled;
const selectRecapdLanguagesList = ({ global }) =>
  global && global.recapdLanguagesList;

export {
  selectShowLeaderboard,
  selectToastList,
  selectIsStageFull,
  selectCurrentProfile,
  selectSpeakerRollCall,
  selectUseVirtualBackdrop,
  selectIsStageVolumeOn,
  selectChannelSidePanelState,
  selectShowAnnouncementModal,
  selectMessageModal,
  selectEditProfileModal,
  selectRecordingMode,
  selectNotifications,
  selectUnReadNotifications,
  selectTotalNotifications,
  selectSelectedInboxAccountId,
  selectStageVideoQuality,
  selectUserNotifiySetting,
  selectUserCurrentScreen,
  selectUnReadChatList,
  selectNetworkQuality,
  selectAudioPlaybackDeviceId,
  selectShowCompatibilityCheck,
  selectChannelConnectionState,
  selectViewProfileModal,
  selectFTUEAnnoucementModal,
  selectUserPrivacySettingModal,
  selectUserPrivacySetting,
  selectMyMeetingRoomsModal,
  selectIsLobbyMobileViewEnabled,
  selectRecapdLanguagesList,
};
