import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/sponsor`;
export default {
  delete(sponsorId) {
    return axios.delete(`${BASE_URL}/${sponsorId}`);
  },
  update(sponsorId, sponsor) {
    return axios.post(`${BASE_URL}/${sponsorId}`, sponsor);
  },
};
