import {
  IRaisedHandRequestStatus,
  IStageParent,
} from '@/components/Stage/types';
import { IUseChannelPresentationResponse } from '@/services/messaging/useChannelPresentation.types';

export interface IPublishedStreamDisplayProps {
  attendeeChannel: any;
  broadcastPermissions: boolean;
  channelConfig: any;
  eventConfig: any;
  fillerImageUrl: string;
  frame?: IVideoFrameTypes;
  greenRoomStreams: any;
  handleUserKickout;
  localClient: any;
  localStream: any;
  moveUserToGreenRoom: any;
  moveUserToStage: any;
  mutationCtx: any;
  onBroadcastButtonClick: any;
  onScreenShareButtonClick: any;
  parent: IStageParent;
  parentHeight: number;
  parentWidth: number;
  primaryScreenOrientation: IScreenCalcOrientationOptions;
  primaryScreenOrientationSize: number;
  primaryStream: any;
  secondaryStreams: any;
  setBroadcastPermissions: (bool: boolean) => void;
  setStreamsDisplayMode: (displayMode: IStreamsDisplayMode) => void;
  streamDetailsMap: any;
  streamNamesMap: any;
  streams: any;
  streamsDisplayMode: IStreamsDisplayMode;
  timeLeftMs: number;
  showConfetti: boolean;
  presentationConfig: IUseChannelPresentationResponse;
  pluginConfig: any,
  volumeOn: boolean;
  toggleVolume: any;
  showVolumeToggle: boolean;
  breakoutConfig: any;
  refId: string;
  raisedHandRequestList: [];
  completeRaisedHandRequest: any;
  localAvConfig: any;
  eventRole: any;
  currentUser: any;
  myRaisedHandStatus?: IRaisedHandRequestStatus;
  dialInStreams: any;
  setShowTabIndex: any;
}

export enum IScreenCalcOrientationOptions {
  HEIGHT = 'height',
  WIDTH = 'width',
}

export enum IVideoFrameTypes {
  CIRCLE = 'circle',
  NO_FRAME_STACKED = 'noFrameStacked',
  PETAL = 'petal',
  SQUARE = 'square',
  SQUARE_ROUNDED = 'squareRounded',
  SQUARE_TO_EDGE = 'squareToEdge',
}

export enum IStreamsDisplayMode {
  GRID = 'grid',
  TABLE = 'table',
  SPOTLIGHT = 'spotlight',
  TWO_ROW_SPOTLIGHT = 'twoRowSpotlight',
  LINE = 'line',
  HORIZONTAL_SPOTLIGHT = 'horizontalSpotlight',
  CENTER_STAGE = 'centerStage',
  BOTTOM_SPONSOR_LAYOUT = 'bottomSponsorLayout',
  NETWORKING_ROOM_LAYOUT = 'networkingRoomLayout',
  RAZORPAY = 'razorpayLayout',
  CONDENSED = 'condensedLayout',
  PRESENTATION = 'presentationLayout',
  PRESENTATION_V2 = 'presentationLayoutV2',
}
