import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/smart_networking`;

interface IInterestsServicesParams {
  detailed?: boolean;
  inactive?: boolean;
  deleted?: boolean;
}

interface IFilteredProfilesPayload {
  interests: any[];
  designations: any[];
  companies: any[];
  limit: number;
  offset: number;
}

export default {
  getSettings(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/settings`);
  },
  updateSettings(eventId: string, payload) {
    return axios.patch(`${BASE_URL}/${eventId}/settings/update`, payload);
  },
  getInterestsList(eventId: string, paramsConfig: IInterestsServicesParams) {
    return axios.get(`${BASE_URL}/${eventId}/interests/list`, {
      params: paramsConfig,
    });
  },
  getServicesList(eventId: string, paramsConfig: IInterestsServicesParams) {
    return axios.get(`${BASE_URL}/${eventId}/services/list`, {
      params: paramsConfig,
    });
  },
  // force update interests
  upsertInterests(eventId: string, interests: any) {
    return axios.patch(
      `${BASE_URL}/${eventId}/interests/upsertMany`,
      interests,
    );
  },
  // force update services
  upsertServices(eventId: string, services: any) {
    return axios.patch(`${BASE_URL}/${eventId}/services/upsertMany`, services);
  },
  addInterest(eventId: string, interestName: string) {
    return axios.post(`${BASE_URL}/${eventId}/interests/add`, {
      interestName,
    });
  },
  updateInterestName(eventId: string, interestId: any, interestName: string) {
    return axios.patch(`${BASE_URL}/${eventId}/interests/edit/name`, {
      interestId,
      interestName,
    });
  },
  updateInterestIsActive(eventId: string, interestId: any, isActive: boolean) {
    return axios.patch(`${BASE_URL}/${eventId}/interests/edit/active`, {
      interestId,
      isActive,
    });
  },
  deleteInterest(eventId: string, interestId: any) {
    return axios.delete(`${BASE_URL}/${eventId}/interests/${interestId}`);
  },
  addService(eventId: string, serviceName: string) {
    return axios.post(`${BASE_URL}/${eventId}/services/add`, {
      serviceName,
    });
  },
  updateServiceName(eventId: string, serviceId: any, serviceName: string) {
    return axios.patch(`${BASE_URL}/${eventId}/services/edit/name`, {
      serviceId,
      serviceName,
    });
  },
  updateServiceIsActive(eventId: string, serviceId: any, isActive: boolean) {
    return axios.patch(`${BASE_URL}/${eventId}/services/edit/active`, {
      serviceId,
      isActive,
    });
  },
  deleteService(eventId: string, serviceId: any) {
    return axios.delete(`${BASE_URL}/${eventId}/services/${serviceId}`);
  },
  getNetworkingProfile(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/profile/view`);
  },
  getUserNetworkingProfile(eventId: string, accountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/profile/view/${accountId}`);
  },
  addNetworkingProfile(eventId: string, payload: any) {
    return axios.post(`${BASE_URL}/${eventId}/profile/add`, payload);
  },
  getSuggestedProfiles(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/profiles/suggested`);
  },
  getConnectedProfiles(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/profiles/connected`);
  },
  getFilteredProfiles(eventId: string, payload: IFilteredProfilesPayload) {
    return axios.post(`${BASE_URL}/${eventId}/profiles/filtered`, payload);
  },
  getCompanyFilteres(eventId: string, q: string) {
    return axios.get(`${BASE_URL}/${eventId}/filters/company`, {
      params: {
        q,
      },
    });
  },
  getDesignationFilteres(eventId: string, q: string) {
    return axios.get(`${BASE_URL}/${eventId}/filters/designation`, {
      params: {
        q,
      },
    });
  },
  getUserSocialLinks(eventId: string, accountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/social_links/${accountId}/list`);
  },
};
