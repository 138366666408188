import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

export const ScheduleIcon3 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M20.2222 6.8H18.4444V5H16.6667V6.8H11.3333V5H9.55556V6.8H7.77778C6.79733 6.8 6 7.6073 6 8.6V21.2C6 22.1927 6.79733 23 7.77778 23H20.2222C21.2027 23 22 22.1927 22 21.2V8.6C22 7.6073 21.2027 6.8 20.2222 6.8ZM16.144 20.3L13.9671 19.1408L11.7902 20.3L12.2062 17.8448L10.4444 16.1078L12.8782 15.7496L13.9671 13.5176L15.056 15.7496L17.4898 16.1078L15.7289 17.8457L16.144 20.3ZM20.2222 11.3H7.77778V9.5H20.2222V11.3Z"
        className={fillClassName}
      />
    </svg>
  );
};

export const ScheduleIcon2 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M7.77778 23H20.2222C21.2027 23 22 22.1927 22 21.2V8.6C22 7.6073 21.2027 6.8 20.2222 6.8H18.4444V5H16.6667V6.8H11.3333V5H9.55556V6.8H7.77778C6.79733 6.8 6 7.6073 6 8.6V21.2C6 22.1927 6.79733 23 7.77778 23ZM7.77778 9.5H20.2222V11.3H7.77778V9.5Z"
        className={fillClassName}
      />
    </svg>
  );
};

const ScheduleIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      style={{
        enableBackground: 'new 0 0 50 50',
      }}
    >
      <g>
        <g>
          <g>
            <path
              className={fillClassName}
              d="M40.2,11.3V19H10.9v-7.7c0-0.8,0.6-1.4,1.4-1.4h3c0,1.3,1,2.3,2.2,2.3s2.2-1.1,2.2-2.3h11.8
              c0,1.3,0.9,2.3,2.2,2.3c1.2,0,2.2-1.1,2.2-2.3h2.9C39.6,9.9,40.2,10.5,40.2,11.3z"
            />
            <path
              className={fillClassName}
              d="M10.9,21h29.3v17c0,0.8-0.6,1.4-1.4,1.4H12.3c-0.8,0-1.4-0.6-1.4-1.4V21z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ScheduleIcon;
