import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/booth`;
export default {
  getBoothDetails(boothId: string) {
    return axios.get(`${BASE_URL}/${boothId}`);
  },
  delete(boothId: string) {
    return axios.delete(`${BASE_URL}/${boothId}`);
  },
  update(boothId: string, booth) {
    return axios.post(`${BASE_URL}/${boothId}`, booth);
  },
  saveBoothLayoutByBoothId(boothId, layoutData) {
    return axios.post(`${BASE_URL}/${boothId}/layout`, layoutData);
  },
  getBoothLayoutByBoothId(boothId: string) {
    return axios.get(`${BASE_URL}/${boothId}/layout`);
  },
  updateBooth(boothId: string, boothObject) {
    return axios.post(`${BASE_URL}/${boothId}`, boothObject);
  },
  // emoji
  getEmojiListByBoothId(boothId: string) {
    return axios.get(`${BASE_URL}/${boothId}/emoji/list`);
  },
  // booth owners
  isBoothOwner(boothId: string) {
    return axios.get(`${BASE_URL}/${boothId}/owner`);
  },
  getBoothOwnersByBoothId(boothId: string) {
    return axios.get(`${BASE_URL}/${boothId}/owner/list`);
  },
  addBoothOwner(boothId: string, eventId: string, accountId: string) {
    return axios.post(`${BASE_URL}/${boothId}/${eventId}/owner`, {
      accountId,
    });
  },
  deleteBoothOwner(boothId: string, eventId: string, accountId: string) {
    return axios.delete(`${BASE_URL}/${boothId}/${eventId}/owner/${accountId}`);
  },
  registerInterest(boothId: string) {
    return axios.post(`${BASE_URL}/${boothId}/register_interest`);
  },
  registerCouponClaim(boothId: string) {
    return axios.post(`${BASE_URL}/${boothId}/register_coupon_claim`);
  },
  hasRegisteredInterest(boothId: string) {
    return axios.get(`${BASE_URL}/${boothId}/has_registered_interest`);
  },
  getBoothOwnersWithLiveStatus(eventId: string, boothId: string) {
    return axios.get(
      `${BASE_URL}/${eventId}/${boothId}/owner/list_with_status`,
    );
  },
};
