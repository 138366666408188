import axios from './axios';
import { SERVICE_URL } from '@/config';
const BASE_URL = `${SERVICE_URL}/zuddlotp`;

export default {
  initiateOTP(formData) {
    return axios.post(`${BASE_URL}/initiate`, formData);
  },
  verifyOTP(formData) {
    return axios.post(`${BASE_URL}/verify`, formData);
  },
  resendOTP(formData) {
    return axios.post(`${BASE_URL}/resend`, formData);
  },
  verifyOTPWithoutAuthCookie(formData, verificationId) {
    return axios.post(`${BASE_URL}/verify/${verificationId}`, formData);
  },
};
