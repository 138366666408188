import { ILiveEventZones } from '@/types';
import { IAccountModel, IAccountRoleTypeOptions } from '@/models/account/types';
import { SessionBroadcastStatus } from '@/components/PublishedStreamDisplay/SessionSelectBar/types';
import { IEventChannelSettingsType } from '@/types/channel';
import { StreamType } from '@/components/ChannelStreamsContainer/types';
import { ITicketTagTypes } from '@/types/tickets';
import { IRegistrationPageSource } from '@/components/landing-page-templates/ConfigLandingPage/types';

export type IDateTimeWithTimezone = string;
export type IUUID = string;
export type IEmailField = string;
export type TObjectType =
  | 'WIDGET'
  | 'CHANNEL_FILE'
  | 'RECORDING_FILES'
  | 'CHANNEL_BANNER';

export type TObjectSubType =
  | 'VIDEO'
  | 'IMAGE'
  | 'GOTO'
  | 'HOTSPOT'
  | 'BUTTON'
  | 'FILE'
  | 'IMAGE_CAROUSEL_NEW'
  | 'TICKER'
  | 'IFRAME'
  | 'BOOTH_OWNER_BUTTON'
  | 'COUPON_WIDGET'
  | 'ADD_TO_CALENDAR';

export enum IEventStatusOptions {
  DRAFT = 'DRAFT',
  PAST = 'PAST',
  PUBLISHED = 'PUBLISHED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum IEventVisibilityOptions {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  UNLISTED = 'UNLISTED',
}

export enum IAttendeeStatusOptions {
  INVITED = 'INVITED',
  REGISTERED = 'REGISTERED',
}

export enum IEventRoleOptions {
  ATTENDEE = 'ATTENDEE',
  ORGANIZER = 'ORGANIZER',
  SPEAKER = 'SPEAKER',
  SPONSOR = 'SPONSOR',
  MODERATOR = 'MODERATOR',
}

export interface IEventBackendModel {
  coverImagePath: string;
  createdAt: IDateTimeWithTimezone;
  description: string;
  endDateTime: IDateTimeWithTimezone;
  eventId: IUUID;
  startDateTime: IDateTimeWithTimezone;
  status: IEventStatusOptions;
  title: string;
  tz: string;
  updatedAt: IDateTimeWithTimezone;
  visibility: IEventVisibilityOptions;
}

export interface IEventFrontendModel extends IEventBackendModel {
  isBroadcasting: boolean;
}

export interface ISpeakerBackendModel {
  accountId: IUUID;
  bio: string;
  email: IEmailField;
  eventRoleId: IUUID;
  firstName: string;
  headline: string;
  lastName: string;
  picUrl: string;
  prefix: string | null;
}

export interface IAttendeeBackendModel {
  accountId: IUUID;
  email: IEmailField;
  eventId: IUUID;
  firstName: string;
  lastName: string;
  status: IAttendeeStatusOptions;
}

export interface IRoleBackendModel {
  accountId: IUUID;
  createdAt: IDateTimeWithTimezone;
  eventId: IUUID;
  eventRoleId: IUUID;
  role: IEventRoleOptions;
  status: IAttendeeStatusOptions;
  updatedAt: IDateTimeWithTimezone;
  ticketTypeId: IUUID;
}

export interface ITicketTypeBackendModel {
  ticketTypeId: IUUID;
  eventId: IUUID;
  name: string;
  currencyType: string;
  pricePerTicket: number;
  accessToStage: boolean;
  accessToTables: boolean;
  accessToExpo: boolean;
  accessToNetworking: boolean;
  active: boolean;
  ticketTypeSubZones: [];
}

export interface ITicketTypeBasicInfo {
  ticketTypeId: IUUID;
  eventId: string;
  name: string;
  currencyType: string;
  description: string;
  ticketTagType: ITicketTagTypes;
  pricePerTicket: number;
  active: boolean;
  numberOfTickets: number;
  price: number;
  regularPrice: number;
  minTicketsPerOrder: number;
  maxTicketsPerOrder: number;
  isLocked: boolean;
  isHide: boolean;
  ticketTypeOrder: number;
}

export type IEventFrontendPayload = IEventFrontendModel;
export type IEventFrontendModelArray = IEventFrontendModel[];
export type ISegmentFrontEndModel = {
  broadcastStatus: SessionBroadcastStatus;
  startDateTime: Date;
  startDate: string;
  hiddenSegment: boolean;
  stageId: IUUID;
};
export type ISegmentBackendModel = {
  broadcastStatus: SessionBroadcastStatus;
  startDateTime: Date;
  hiddenSegment: boolean;
  stageId: IUUID;
};
export type IPendingSegmentBackendModel = any;
export type ISponsorBackendModel = any;
export type IBoothBackendModel = any;
export type IDiscussionBackendModel = any;

export type IEventObjByIdMap = {
  [key in IEventFrontendModel['eventId']]: IEventFrontendModel;
};

export type IAttendeesByEventId = {
  [key in IAttendeeBackendModel['accountId']]: IAttendeeBackendModel;
};

export type IRolesByEventId = {
  [key in IRoleBackendModel['accountId']]: IRoleBackendModel;
};

export type ITicketTypeByEventId = {
  [key in ITicketTypeBackendModel['ticketTypeId']]: ITicketTypeBackendModel;
};

export type ISegmentsByEventId = {
  [key in ISegmentBackendModel['accountId']]: ISegmentBackendModel;
};

export type IPendingSegmentsByEventId = {
  [key in IPendingSegmentBackendModel['accountId']]: IPendingSegmentBackendModel;
};

export type ISpeakersByEventId = {
  [key in ISpeakerBackendModel['accountId']]: ISpeakerBackendModel;
};

export type ISponsorsByEventId = {
  [key in ISponsorBackendModel['accountId']]: ISponsorBackendModel;
};

export type IBoothsByEventId = {
  [key in ISponsorBackendModel['accountId']]: ISponsorBackendModel;
};

export type IDiscussionsByEventId = {
  [key in IDiscussionBackendModel['accountId']]: IDiscussionBackendModel;
};

export interface IAnnouncementModel {
  eventId: string;
  announcement: {
    announcementId?: string;
    text: string;
    startDateTime?: IDateTimeWithTimezone;
    endDateTime?: IDateTimeWithTimezone;
    zones: ILiveEventZones[];
  };
}

export type IAnnouncementByEventId = {
  [key in IAnnouncementModel['eventId']]: IAnnouncementModel;
};

export interface IEventState {
  isBoothOwner?: false;
  announcementsByEventId: IAnnouncementByEventId;
  attendeesByEventId: IAttendeesByEventId;
  boothsByEventId: IBoothsByEventId;
  discussionsByEventId: IDiscussionsByEventId;
  error: [];
  isFetchingData: boolean;
  objByIdMap: IEventObjByIdMap;
  rolesByEventId: IRolesByEventId;
  ticketTypeByEventId?: ITicketTypeByEventId;
  segmentsByEventId: ISegmentsByEventId;
  unfilteredSegmentsByEventId: ISegmentsByEventId;
  speakersByEventId: ISpeakersByEventId;
  sponsorsByEventId: ISponsorsByEventId;
  pendingSegmentsByEventId: IPendingSegmentsByEventId;
  breakoutRoomsConfigByStageId: IGetBreakoutRoomsConfigPayload | {};
  eventChannelSettings: IEventChannelSettingsType;
  registrationCustomFields: any;
  hasVjAccessIdSet: Set<number>;
  tawkToDetails: ITawkToHelpDeskModal;
  userAccessGroupsByEventId?: any;
  backstageTalkingUserNameByStageId?: any;
  stagesStudioIds: any;
  isVirtualBackgroundEnabled: boolean;
}

export enum EmbeddedVideoTypes {
  YOUTUBE = 'YOUTUBE',
  VIMEO = 'VIMEO',
}

export enum EmbeddedBoothSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum EmbeddedBoothCategory {
  PLATINUM = 'PLATINUM',
  GOLD = 'GOLD',
  SILVER = 'SILVER',
}

export enum EmbeddedBoothType {
  NORMAL = 'NORMAL',
  IMMERSIVE = 'IMMERSIVE',
  TWO_D = '_2D',
}

export enum LayoutType {
  GRID = 'GRID',
  MANUAL = 'MANUAL',
}

export interface IGetBreakoutRoomsConfigPayload {
  payload: {
    stageId: IUUID;
  };
}

export interface IGetEventDiscussionsListPayload {
  payload: {
    eventId: IUUID;
    limit?: number;
    offset?: number;
    q?: string;
    selectedTableId?: string;
    selectedTagIds?: any;
    roomZoneId?: string;
  };
}
export interface IGetEventBoothListPayload {
  payload: {
    eventId: IUUID;
  };
}
export interface IGetEventSponsorListPayload {
  payload: {
    eventId: IUUID;
    refId: IUUID;
  };
}
export interface IGetEventSegmentListPayload {
  payload: {
    eventId: IUUID;
    refId: IUUID;
  };
}
export interface IGetEventPendingSegmentListPayload {
  payload: {
    eventId: IUUID;
  };
}
export interface IGetEventRolePayload {
  payload: {
    eventId: IUUID;
    refId: IUUID;
  };
}

export interface IGetTicketTypePayload {
  payload: {
    eventId: IUUID;
    ticketTypeId: IUUID;
  };
}

export interface ISetEventBroadcastPayload {
  payload: {
    eventId: IUUID;
    isBroadcasting: boolean;
    stageId: IUUID;
  };
}

export interface IEventStage {
  hi: string;
}

export interface ISetEventStagesDataPayload {
  payload: {
    eventId: IUUID;
    stages: IEventStage[];
  };
}

export interface ICreateEventPayload {
  payload: {
    event: {
      coverImagePath: string;
      description: string;
      endDateTime: IDateTimeWithTimezone;
      startDateTime: IDateTimeWithTimezone;
      title: string;
      tz: string;
      visibility: IEventVisibilityOptions;
    };
  };
}

export interface IDeleteEventPayload {
  payload: {
    eventId: string;
  };
}

export interface IUpdateByIdPayload {
  payload: {
    event: {
      coverImagePath: string;
      createdAt: IDateTimeWithTimezone;
      description: string;
      endDateTime: IDateTimeWithTimezone;
      eventId: IUUID;
      nextUid: string;
      registrationTemplate: string;
      startDateTime: IDateTimeWithTimezone;
      status: IEventStatusOptions;
      title: string;
      tz: string;
      updatedAt: IDateTimeWithTimezone;
      visibility: IEventVisibilityOptions;
    };
  };
}

export interface IGetAllEventAttendeesPayload {
  payload: {
    eventId: IUUID;
  };
}

export interface IGetAllEventSpeakersPayload {
  payload: {
    eventId: IUUID;
    refId: IUUID;
    refresh: boolean;
  };
}

export interface IGetByIdPayload {
  payload: {
    eventId: IUUID;
    refId: IUUID;
  };
}

export interface IDuplicateEventPayload {
  payload: {
    eventId: string;
  };
}

export interface IAddAnnouncementPayload {
  payload: {
    eventId: string;
    announcement: IAnnouncementModel;
  };
}

export interface IDeleteAnnouncementPayload {
  payload: {
    eventId: string;
    announcementId: string;
    active: boolean;
  };
}

interface IDiscussionTableInfo {
  agoraResourceId: string;
  agoraSid: string;
  attendeeCreated: boolean;
  capacity: number;
  createdAt: IDateTimeWithTimezone;
  discussionTableId: IUUID;
  eventId: IUUID;
  name: string;
  participants: IAccountModel[];
  recordingUid: IUUID;
  tableNo: number;
  updatedAt: IDateTimeWithTimezone;
}

export interface IUpdateEventDiscussionTableNamePayload {
  payload: {
    name: string;
    discussion: IDiscussionTableInfo;
  };
}

export enum TargetZones {
  EXPO = 'EXPO',
  LOBBY = 'LOBBY',
  STAGE = 'STAGE',
  ROOMS = 'ROOMS',
  NETWORKING = 'NETWORKING',
  SCHEDULE = 'SCHEDULE',
}

export interface ICreateAnnouncementPayload {
  announcementNote: string;
  redirectionZone: string;
  targetZones: TargetZones[];
  redirectionTypes: RedirectionTypes[];
}

export enum zoneTypes {
  EXPO = 'EXPO',
  LOBBY = 'LOBBY',
  STAGE = 'STAGE',
  TABLE = 'TABLE',
  NETWORKING = 'NETWORKING',
  SCHEDULE = 'SCHEDULE',
  ROOMS = 'ROOMS',
  BOOTH = 'BOOTH',
}

export interface IZoneModel {
  active: boolean;
  endDateTime: IDateTimeWithTimezone;
  name: string;
  startDateTime: IDateTimeWithTimezone;
  type: zoneTypes;
}

export interface IZoneUpdateModel {
  active: boolean;
  endDateTime: IDateTimeWithTimezone;
  name: string;
  startDateTime: IDateTimeWithTimezone;
  type: zoneTypes;
  zoneId: IUUID;
}

export enum ILayoutFillTypes {
  SOLID_COLOR = 'SOLID_COLOR',
  IMAGE_BLUR = 'IMAGE_BLUR',
}

export enum RedirectionTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}
export interface IIsAttendeeRegisteredWithDisclaimerPayload {
  eventId: IUUID;
  disclaimerText: string;
}

export interface IUserClick {
  objectType: TObjectType;
  objectSubType: TObjectSubType;
  objectId: string;
  objectName: string;
  location: string;
  locationRefId: string | null;
}
export interface IStreamDetailsModel {
  uid: number;
  name: string;
  headline: string;
  bio: string;
  picUrl: string;
  accountId: string;
  role: IAccountRoleTypeOptions;
  type: StreamType;
  hasVjAccess: boolean;
}

export interface IStreamDetailsMap {
  [key: number]: IStreamDetailsModel;
}

export interface ITawkToHelpDeskModal {
  eventHelpdeskId: IUUID;
  eventId: IUUID;
  tawktoPropertyId: string;
  tawktoWidgetId: string;
}

export interface IRegistrationModel {
  eventId: IUUID;
  firstName: string;
  lastName: string;
  email: IEmailField;
  bio: string | null;
  designation: string | null;
  company: string | null;
  phoneNumber: string | null;
  country: string | null;
  customField: string;
  source: IRegistrationPageSource;
  utm: any;
  ticketTypeId?: string;
}
export interface IRegistrationRequestModel {
  eventId: IUUID;
  email: IEmailField;
}
