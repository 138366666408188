import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const AwardIcon = (props: IIconProps) => {
  const { color = IContentColors.BLUE, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8334 17.7858V25.8032C19.8334 25.9064 19.8061 26.0077 19.7541 26.0969C19.7022 26.1861 19.6275 26.2599 19.5377 26.3108C19.4479 26.3617 19.3462 26.3878 19.243 26.3866C19.1398 26.3853 19.0388 26.3567 18.9502 26.3037L14.0001 23.3333L9.04992 26.3037C8.9613 26.3568 8.86017 26.3854 8.75687 26.3866C8.65356 26.3878 8.55179 26.3615 8.46197 26.3105C8.37214 26.2595 8.29747 26.1855 8.2456 26.0962C8.19373 26.0068 8.16652 25.9053 8.16675 25.802V17.787C6.65725 16.5784 5.56041 14.9309 5.02778 13.072C4.49516 11.2131 4.55306 9.23462 5.19349 7.41005C5.83391 5.58548 7.02524 4.00487 8.60283 2.88665C10.1804 1.76844 12.0664 1.16783 14.0001 1.16783C15.9338 1.16783 17.8197 1.76844 19.3973 2.88665C20.9749 4.00487 22.1663 5.58548 22.8067 7.41005C23.4471 9.23462 23.505 11.2131 22.9724 13.072C22.4398 14.9309 21.3429 16.5784 19.8334 17.787V17.7858ZM14.0001 17.5C15.8566 17.5 17.6371 16.7625 18.9498 15.4497C20.2626 14.137 21.0001 12.3565 21.0001 10.5C21.0001 8.64348 20.2626 6.863 18.9498 5.55025C17.6371 4.23749 15.8566 3.5 14.0001 3.5C12.1436 3.5 10.3631 4.23749 9.05033 5.55025C7.73758 6.863 7.00008 8.64348 7.00008 10.5C7.00008 12.3565 7.73758 14.137 9.05033 15.4497C10.3631 16.7625 12.1436 17.5 14.0001 17.5ZM14.0001 15.1667C12.7624 15.1667 11.5754 14.675 10.7003 13.7998C9.82508 12.9247 9.33342 11.7377 9.33342 10.5C9.33342 9.26232 9.82508 8.07533 10.7003 7.20016C11.5754 6.32499 12.7624 5.83333 14.0001 5.83333C15.2378 5.83333 16.4247 6.32499 17.2999 7.20016C18.1751 8.07533 18.6667 9.26232 18.6667 10.5C18.6667 11.7377 18.1751 12.9247 17.2999 13.7998C16.4247 14.675 15.2378 15.1667 14.0001 15.1667Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default AwardIcon;
