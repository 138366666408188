import produce from 'immer';
import { push } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select } from 'redux-saga/effects';
import api from '@/api';
import { IReduxStoreState } from '@/models/global/types';
// types
import { _isNull, _isEmpty } from '@/utils/jsHelpers/obj';

const initialState = {
  error: [],
  isFetchingData: false,

  postsMapById: {},
  userFeedLikes: [],
  hasMoreFeed: true,
  fetchingMoreFeed: false,
};

export default {
  namespace: 'eventFeed',
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...initialState
      };
    },
    // Posts CRUD
    deletePost: (state, { eventFeedId }) =>
      produce(state, draft => {
        delete draft.postsMapById[eventFeedId];
      }),
    insertPost: (state, { postData }) =>
      produce(state, draft => {
        draft.postsMapById[postData.eventFeedId] = postData;
      }),
    addFeed: (state, { feedData }) =>
      produce(state, draft => {
        const feedMap = feedData.reduce((obj, post) => {
          obj[post.eventFeedId] = post;
          return obj;
        }, {});
        draft.postsMapById = {
          ...draft.postsMapById,
          ...feedMap
        }
      }),
    setDataFetchingError: (state, { error }) =>
      produce(state, draft => {
        draft.error.push(error);
      }),
    setDataFetchingState: (state, { isFetchingData }) =>
      produce(state, draft => {
        draft.isFetchingData = isFetchingData;
      }),
    // Feed Activity
    updateFeedActivity: (state, { feedActivity }) =>
      produce(state, draft => {
        draft.postsMapById = feedActivity.reduce((postsMap, postActivity) => {
          const { eventFeedId, feedLikeCount, feedCommentCount } = postActivity;
          postsMap[eventFeedId] = {
            ...postsMap[eventFeedId],
            feedLikeCount,
            feedCommentCount,
          }
          return postsMap;
        }, draft.postsMapById)
      }),
    // User Likes
    setUserLikes: (state, { userLikes }) =>
      produce(state, draft => {
        draft.userFeedLikes = userLikes;
      }),
    likePost: (state, { feedLikeData }) =>
      produce(state, draft => {
        const likedPost = draft.postsMapById[feedLikeData.eventFeedId];
        draft.userFeedLikes = [...draft.userFeedLikes, feedLikeData];
        draft.postsMapById = {
          ...draft.postsMapById,
          [feedLikeData.eventFeedId]: {
            ...likedPost,
            feedLikeCount: likedPost.feedLikeCount + 1
          }
        }
      }),
    unlikePost: (state, { feedLikeData }) =>
      produce(state, draft => {
        const { eventFeedId, feedLikeId } = feedLikeData;
        const unlikedPost = draft.postsMapById[eventFeedId];
        if(!unlikedPost) return

        draft.userFeedLikes = draft.userFeedLikes.filter(userLike => userLike.eventFeedId !== eventFeedId);
        draft.postsMapById = {
          ...draft.postsMapById,
          [feedLikeData.eventFeedId]: {
            ...unlikedPost,
            feedLikeCount: unlikedPost.feedLikeCount - 1
          }
        }
      }),
    // Pin Post
    pinPost: (state, { eventFeedId }) =>
      produce(state, draft => {
        const pinnedPost = draft.postsMapById[eventFeedId];
        if (!pinnedPost) {
          // Fetch the pinned post.
          return;
        }

        draft.postsMapById = {
          ...draft.postsMapById,
          [eventFeedId]: {
            ...pinnedPost,
            pinned: true
          }
        }
      }),
    unpinPost: (state, { eventFeedId }) =>
      produce(state, draft => {
        const unpinnedPost = draft.postsMapById[eventFeedId];
        if (!unpinnedPost) return;

        draft.postsMapById = {
          ...draft.postsMapById,
          [eventFeedId]: {
            ...unpinnedPost,
            pinned: false
          }
        }
      }),
    // Comments
  },
  effects: {
    *create({ payload: { eventId, postData } }) {
      try {
        yield put({
          type: 'eventFeed/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data: createdFeedData } = yield call(api.eventFeed.create, eventId, postData);
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: `Post Created Successfully` },
        });
        yield put({
          type: 'eventFeed/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (createdFeedData) {
          const { eventFeedId } = createdFeedData;
          const { data: completeFeedData } = yield call(api.eventFeed.getOneFeedPost, eventFeedId);
          yield put({ type: 'eventFeed/insertPost', payload: { postData: completeFeedData } });
        } else {
          yield put({
            type: 'eventFeed/setDataFetchingError',
            payload: {
              error: [
                { 'Create Event Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventFeedModel > create', e);
        yield put({
          type: 'global/addDangerToast',
          payload: { description: `Failed to create post` },
        });
        yield put({
          type: 'eventFeed/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *delete({ payload: { eventFeedId } }) {
      try {
        const { data } = yield call(api.eventFeed.delete, eventFeedId);
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: 'Post Deleted Successfully' },
        });

        yield put({ type: 'eventFeed/deletePost', payload: { eventFeedId } });
      } catch (e) {
        console.log('eventModel > delete', e);
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Post Deletion Failed' },
        });
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getFeed({ payload: { eventId, direction, referenceTimeStamp } }) {
      try {
        yield put({
          type: 'eventFeed/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data: feedArray } = yield call(api.eventFeed.getEventFeed, eventId, direction, referenceTimeStamp);
        
        yield put({
          type: 'eventFeed/setHasMoreData',
          payload: { hasMoreData: !(feedArray.length < 10) },
        });
        yield put({
          type: 'eventFeed/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (feedArray) {
          yield put({
            type: 'eventFeed/addFeed',
            payload: { feedData: feedArray },
          });
        } else {
          yield put({
            type: 'eventFeed/setDataFetchingError',
            payload: {
              error: [
                { 'Fetch All Events Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getAll', e);
        yield put({
          type: 'eventFeed/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getUserLikes({ payload: { eventId } }) {
      try {
        const { data: likesArray } = yield call(api.eventFeed.getUserLikes, eventId);
        
        if (likesArray) {
          yield put({
            type: 'eventFeed/setUserLikes',
            payload: { userLikes: likesArray },
          });
        } else {
          yield put({
            type: 'eventFeed/setDataFetchingError',
            payload: {
              error: [
                { 'Fetch All Events Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventFeedModel > getUserLikes', e);
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getFeedActivity({ payload: { eventId, eventFeedIds } }) {
      try {
        const { data: feedActivity } = yield call(api.eventFeed.getEventFeedLikeCommentCount, eventId, eventFeedIds);
        
        if (feedActivity) {
          yield put({
            type: 'eventFeed/updateFeedActivity',
            payload: { feedActivity },
          });
        } else {
          yield put({
            type: 'eventFeed/setDataFetchingError',
            payload: {
              error: [
                { 'Fetch All Events Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventFeedModel > getFeedActivity', e);
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *like({ payload: { eventFeedId, accountId } }) {
      try {
        const { data: feedLikeData } = yield call(api.eventFeed.likeFeed, { eventFeedId, accountId });

        if (feedLikeData) {
          yield put({
            type: 'eventFeed/likePost',
            payload: { feedLikeData },
          });
        } else {
          yield put({
            type: 'eventFeed/setDataFetchingError',
            payload: {
              error: [
                { 'Fetch EventFeeds Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventFeedModel > like', e);
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *unlike({ payload: { feedLikeData } }) {
      try {
        const { eventFeedId, feedLikeId } = feedLikeData;

        const { data } = yield call(api.eventFeed.removeLikeFeed, feedLikeId);

        yield put({
          type: 'eventFeed/unlikePost',
          payload: { feedLikeData },
        });
      } catch (e) {
        console.log('eventFeedModel > unlike', e);
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *pin({ payload: { eventFeedId } }) {
      try {
        const { data } = yield call(api.eventFeed.pinFeed, eventFeedId);

        yield put({
          type: 'eventFeed/pinPost',
          payload: { eventFeedId },
        });
      } catch (e) {
        console.log('eventFeedModel > pin', e);
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *unpin({ payload: { eventFeedId } }) {
      try {
        const { data } = yield call(api.eventFeed.unpinFeed, eventFeedId);

        yield put({
          type: 'eventFeed/unpinPost',
          payload: { eventFeedId },
        });
      } catch (e) {
        console.log('eventFeedModel > unpin', e);
        yield put({
          type: 'eventFeed/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
  },
};

const selectAllPostsArray = (state: IReduxStoreState) =>
  Object.values(state.eventFeed.postsMapById || {});

// event id => event obj
const selectAllPostsMap = (state: IReduxStoreState) =>
  (state.eventFeed && state.eventFeed.postsMapById) || {};

const selectUserLikes = (state: IReduxStoreState) =>
  (state.eventFeed && state.eventFeed.userFeedLikes) || [];

const makeSelectUserLikes = () =>
  createSelector(
    selectUserLikes,
    (feedLikes) => feedLikes || []
  );
  

const makeSelectApprovedFeed = () =>
  createSelector(
    selectAllPostsArray,
    (feed) => feed.filter(post => post.approved === true) || []
  );

const makeSelectPendingFeed = () =>
  createSelector(
    selectAllPostsArray,
    (feed) => feed.filter(post => !post.approved) || []
  );

export {
  selectAllPostsArray,
  makeSelectApprovedFeed,
  makeSelectPendingFeed,
  makeSelectUserLikes,
};
