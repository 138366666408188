import { BackendModule, createInstance } from 'i18next';
import ICU from 'i18next-icu';
import { getTranslations } from './api/i18n';

type LanguageVariants = string[];

export type Language = {
  name: string;
  code: string;
  variants: LanguageVariants;
};

export type LanguageData = {
  ENGLISH: Language;
  SPANISH: Language;
  GERMAN: Language;
  JAPANESE: Language;
};

const LANGUAGES: LanguageData = {
  ENGLISH: {
    name: 'English',
    code: 'en',
    variants: ['en-US', 'en-GB'],
  },
  SPANISH: {
    name: 'Spanish',
    code: 'es',
    variants: [],
  },
  GERMAN: {
    name: 'German',
    code: 'de',
    variants: [],
  },
  JAPANESE: {
    name: 'Japanese',
    code: 'ja',
    variants: ['ja-JP'],
  },
};

const i18nBackend: BackendModule = {
  type: 'backend',
  init: function init() {
    /* use services and options */
  },

  read: function read(language: string, namespace: string) {
    if (language === LANGUAGES.ENGLISH.code) return;
    // eslint-disable-next-line consistent-return
    return getTranslations(language, namespace).then(({ data }) => data);
  },
};

const SUPPORTED_LANGUAGES = Object.values(LANGUAGES).map(lang => lang.code);

const i18n = createInstance();

i18n
  .use(ICU)
  .use(i18nBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: SUPPORTED_LANGUAGES,
    debug: true,
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    partialBundledLanguages: true,
    ns: [],
    resources: {},
    react: {
      useSuspense: false,
    },
  });

export default i18n;
