import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

export const TableIcon2 = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        enableBackground: 'new 0 0 28 28',
      }}
    >
      <path
        d="M6 13.5556H12C12.552 13.5556 13 13.1324 13 12.6111V6.94444C13 6.42311 12.552 6 12 6H6C5.448 6 5 6.42311 5 6.94444V12.6111C5 13.1324 5.448 13.5556 6 13.5556ZM16 13.5556H22C22.553 13.5556 23 13.1324 23 12.6111V6.94444C23 6.42311 22.553 6 22 6H16C15.447 6 15 6.42311 15 6.94444V12.6111C15 13.1324 15.447 13.5556 16 13.5556ZM6 23H12C12.552 23 13 22.5769 13 22.0556V16.3889C13 15.8676 12.552 15.4444 12 15.4444H6C5.448 15.4444 5 15.8676 5 16.3889V22.0556C5 22.5769 5.448 23 6 23ZM16 23H22C22.553 23 23 22.5769 23 22.0556V16.3889C23 15.8676 22.553 15.4444 22 15.4444H16C15.447 15.4444 15 15.8676 15 16.3889V22.0556C15 22.5769 15.447 23 16 23Z"
        className={fillClassName}
      />
    </svg>
  );
};

const TableIcon = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 50 50"
      style={{
        enableBackground: 'new 0 0 50 50',
      }}
    >
      <g>
        <g>
          <path
            className={fillClassName}
            d="M18.5,32.3c0,1.2-0.5,2.4-1.4,3.3c-0.5,0.5-1,0.9-1.6,1.1C15,36.9,14.4,37,13.8,37c-1.2,0-2.4-0.5-3.3-1.4
            s-1.4-2-1.4-3.3c0-1.2,0.5-2.4,1.4-3.3c0.3-0.3,0.5-0.5,0.9-0.7c0.7-0.4,1.5-0.7,2.4-0.7c1.2,0,2.4,0.4,3.3,1.4
            C18,29.9,18.5,31,18.5,32.3z"
          />
        </g>
        <g>
          <path
            className={fillClassName}
            d="M29.8,13.8L29.8,13.8c0,2.6-2.1,4.7-4.7,4.7s-4.7-2.1-4.7-4.7l0,0c0-2.6,2.1-4.7,4.7-4.7S29.8,11.2,29.8,13.8
            z"
          />
        </g>
        <g>
          <path
            className={fillClassName}
            d="M29.8,32.2c0,2.5,1.4,4.7,3.5,5.7c-2.3,1.7-5.1,2.7-8.2,2.7s-5.9-1-8.3-2.8c0.5-0.3,1-0.7,1.5-1.1
            c1.2-1.2,1.8-2.8,1.8-4.5s-0.7-3.3-1.9-4.5c-1.2-1.1-2.8-1.8-4.5-1.8c-0.8,0-1.6,0.2-2.4,0.5c0.1-3.6,1.6-6.9,4-9.3
            c1-1,2.1-1.8,3.4-2.5c0.2,1.4,0.8,2.7,1.8,3.7c1.2,1.2,2.8,1.9,4.5,1.9s3.3-0.7,4.5-1.9c1-1,1.7-2.3,1.9-3.7
            c4.3,2.2,7.3,6.7,7.4,11.8C38,26,37,25.8,36.1,25.8C32.6,25.8,29.8,28.7,29.8,32.2z"
          />
        </g>
        <g>
          <path
            className={fillClassName}
            d="M40.9,32.3c0,1.2-0.5,2.4-1.4,3.3c-0.9,0.9-2,1.4-3.3,1.4c-0.5,0-1.1-0.1-1.5-0.3c-1.8-0.6-3.2-2.4-3.2-4.4
            c0-2.6,2.1-4.7,4.7-4.7c0.9,0,1.8,0.2,2.6,0.8c0.3,0.2,0.5,0.4,0.7,0.6C40.4,29.9,40.9,31,40.9,32.3z"
          />
        </g>
        <g>
          <path className="st1" d="M30.7,38.5L30.7,38.5z" />
        </g>
      </g>
    </svg>
  );
};

export default TableIcon;
