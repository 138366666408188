import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/access_groups`;

export default {
  // Access Groups
  viewGroups(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/groups`);
  },
  viewGroup(eventId: string, groupId: string) {
    return axios.get(`${BASE_URL}/${eventId}/group/${groupId}`);
  },
  addGroup(eventId: string, groupName: string) {
    return axios.post(`${BASE_URL}/${eventId}/group/${groupName}`);
  },
  updateGroup(eventId: string, groupId: string, payload: any) {
    return axios.patch(`${BASE_URL}/${eventId}/group/${groupId}`, payload);
  },
  deleteGroup(eventId: string, groupId: string) {
    return axios.delete(`${BASE_URL}/${eventId}/group/${groupId}`);
  },
  copyGroup(eventId: string, groupIdToCopy: string, groupName: string) {
    return axios.post(
      `${BASE_URL}/${eventId}/group/${groupIdToCopy}/copy/${groupName}`,
    );
  },

  // Features Mapping
  viewMappings(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}`);
  },
  viewMapping(eventId: string, groupId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${groupId}`);
  },
  addMapping(eventId: string, groupId: string, payload: any) {
    return axios.post(
      `${BASE_URL}/${eventId}/${groupId}/add/sub-feature`,
      payload,
    );
  },
  updateMappings(eventId: string, groupId: string, payload: any) {
    return axios.patch(`${BASE_URL}/${eventId}/${groupId}`, payload);
  },

  // DayAccess Mapping
  viewDayAccessForEvent(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/day_access`);
  },
  viewDayAccessMapping(eventId: string, groupId: string) {
    return axios.get(`${BASE_URL}/${eventId}/day_access/${groupId}`);
  },
  updateDayAccessMapping(eventId: string, groupId: string, payload: any) {
    return axios.patch(`${BASE_URL}/${eventId}/day_access/${groupId}`, payload);
  },

  // Ticket to Group Mapping
  updateTicketMapping(eventId: string, ticketGroupId: string, payload: any) {
    return axios.patch(
      `${BASE_URL}/${eventId}/ticket/${ticketGroupId}`,
      payload,
    );
  },

  // Users Mapping
  viewUsersMapping(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/user`);
  },
  viewUserMapping(eventId: string, accountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/user/${accountId}`);
  },
  addUserMapping(eventId: string, accountId: string, payload: any) {
    return axios.post(`${BASE_URL}/${eventId}/user/${accountId}`, payload);
  },
  updateUserMapping(eventId: string, accountId: string, payload: any) {
    return axios.patch(`${BASE_URL}/${eventId}/user/${accountId}`, payload);
  },
  getLiveUserAllAccessGroups(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/access_list`);
  },
  getCurrentUserAccessDatesList(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/day_access`);
  },
  getUserAccessDatesList(eventId: string, accountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/user_day_access/${accountId}`);
  },
  getAvailableDatesListForMeetingSetup(eventId: string, accountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/schedule/day_access/${accountId}`);
  },
};
