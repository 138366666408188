import axios from './axios';
import { SERVICE_URL } from '@/config';
import { IAnswerQuestion } from '@/components/PublishedQuestionSection/QuestionV2/types';

const BASE_URL = `${SERVICE_URL}/question`;
export default {
  upvoteQuestion(questionId) {
    return axios.post(`${BASE_URL}/${questionId}/upvote`);
  },
  answeringQuestion(questionId) {
    return axios.post(`${BASE_URL}/${questionId}/answering`);
  },
  answerQuestion(question: IAnswerQuestion) {
    return axios.post(`${BASE_URL}/${question.questionId}/answered`, question);
  },
  removeUpvote(questionId) {
    return axios.delete(`${BASE_URL}/${questionId}/upvote`);
  },
  deleteQuestionAndUpvote(questionId, isPinned = false) {
    return axios.delete(`${BASE_URL}/${questionId}/delete`, {
      params: {
        isPinned,
      },
    });
  },
  pinOnStage(questionId: string | String, stageId: string) {
    return axios.post(
      `${BASE_URL}/${questionId}/pin_engagement/stage/${stageId}`,
    );
  },
  unpinOnStage(questionId: string | String, stageId: string) {
    return axios.delete(
      `${BASE_URL}/${questionId}/pin_engagement/stage/${stageId}`,
    );
  },
  pinOnStage(questionId: string | String, stageId: string) {
    return axios.post(
      `${BASE_URL}/${questionId}/pin_engagement/stage/${stageId}`,
    );
  },
  unpinOnStage(questionId: string | String, stageId: string) {
    return axios.delete(
      `${BASE_URL}/${questionId}/pin_engagement/stage/${stageId}`,
    );
  },
};
