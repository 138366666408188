import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/rtmpOut`;

export default {
  viewAll(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/view`);
  },
  addCustomOutput(eventId: string, body: any) {
    return axios.post(`${BASE_URL}/${eventId}/add/custom`, body);
  },
  addSetting(eventId: string, body: any) {
    return axios.post(`${BASE_URL}/${eventId}/settings/add`, body);
  },
  viewSettings(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/settings/view`);
  },
  updateSettings(eventId: string, body: any) {
    return axios.patch(`${BASE_URL}/${eventId}/settings/update`, body);
  },
  deleteSettings(eventId: string, outputId: string) {
    return axios.patch(`${BASE_URL}/${eventId}/settings/delete/${outputId}`);
  },
};
