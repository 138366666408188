import axios from './axios';
import { SERVICE_URL } from '@/config';
import {
  IZoneModel,
  IZoneUpdateModel,
  IIsAttendeeRegisteredWithDisclaimerPayload,
  IUserClick,
  IStreamDetailsModel,
} from '@/models/event/types';
import { AnnouncementUserActionStatus } from '@/components/ui/Announcement/types';
import { IUserPrivacyTypes } from '@/types/userPrivacySetting';
import { ILobbyContacts } from '@/components/custom/widgets/BoothOwnerWidget/types';
import { EventMemberObject } from '@/types/event';

const BASE_URL = `${SERVICE_URL}/event`;
export default {
  create(event) {
    return axios.post(`${BASE_URL}/`, event);
  },
  deleteById(eventId) {
    return axios.delete(`${SERVICE_URL}/event/${eventId}`);
  },
  updateById(eventData) {
    const { eventId } = eventData;
    return axios.post(`${SERVICE_URL}/event/${eventId}`, eventData);
  },
  updateRegistrationTemplate(eventId, template) {
    return axios.post(`${BASE_URL}/${eventId}/registration`, {
      template,
    });
  },
  getIdBySlug(slug) {
    return axios.get(`${SERVICE_URL}/event/slug/${slug}`);
  },
  getAll() {
    return axios.get(`${SERVICE_URL}/event/list`);
  },
  searchAllEventMembers(eventId: string, params: EventMemberObject) {
    if (!params.userPrivacyType) {
      params.userPrivacyType = IUserPrivacyTypes.INVALID;
    }
    params.q = encodeURIComponent(params.q);
    return axios.get(
      `${BASE_URL}/${eventId}/member/l/list`, {
      params
    }
    );
  },
  searchAllEventMembersCount(eventId, q, refId, userPrivacyType = IUserPrivacyTypes.INVALID) {
    if (refId) {
      return axios.get(
        `${BASE_URL}/${eventId}/member/l/count`, {
        params: {
          q: encodeURIComponent(q), refId, userPrivacyType
        }
      }
      );
    }
    return axios.get(
      `${BASE_URL}/${eventId}/member/l/count`, {
      params: {
        q: encodeURIComponent(q), userPrivacyType
      }
    }
    );
  },
  getAttendeeDetailsByAccountId(eventId, accountId) {
    return axios.get(`${BASE_URL}/${eventId}/attendee/${accountId}`);
  },
  getAllSpeakers(eventId, refId) {
    return axios.get(`${BASE_URL}/${eventId}/speaker/list`, {
      params: { r: refId },
    });
  },
  getLiveEventOrganizers(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/liveEvent/organizer/list`);
  },
  getAllWithPagination(limit, offset, search) {
    return axios.get(`${SERVICE_URL}/event/list`, {
      params: {
        limit,
        offset,
        search,
      },
    });
  },
  getEventRole(eventId, refId) {
    return axios.get(`${BASE_URL}/${eventId}/role`, {
      params: { r: refId },
    });
  },
  getById(eventId, refId) {
    return axios.get(`${SERVICE_URL}/event/${eventId}`, {
      params: { r: refId },
    });
  },
  getEventVisibility(eventId) {
    return axios.get(`${SERVICE_URL}/event/${eventId}/visibility`);
  },
  /**
 * @deprecated The method should not be used
 */
  getImageUploadUrl(fileName: string) {
    return axios({
      method: 'post',
      url: `${SERVICE_URL}/file-upload/signed_put`,
      data: fileName,
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },

  getFileUploadUrl(fileName: string) {
    return axios({
      method: 'post',
      url: `${SERVICE_URL}/file-upload/signed_put`,
      data: fileName,
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },

  importAttendeesFromS3(
    eventId: string,
    s3FileUrl: string,
    canSendMail: boolean,
    ticketTypeId: string,
    fieldMappingObjList,
    fileExtension: string = 'csv',
  ) {
    return axios.post(`${BASE_URL}/${eventId}/attendee/import/v2`, {
      ticketTypeId: ticketTypeId,
      s3FileUrl: s3FileUrl,
      canSendMail: canSendMail,
      fileExtension: fileExtension,
      csvFieldMapping: fieldMappingObjList,
    });
  },

  inviteAttendees(
    eventId: string,
    attendeeList: string[],
    canSendMail: boolean = true,
    ticketTypeId: string,
  ) {
    let ticketType =
      ticketTypeId == null ? '' : `&ticketTypeId=${ticketTypeId}`;
    return axios.post(
      `${BASE_URL}/${eventId}/attendee/invite?canSendMail=${canSendMail}${ticketType}`,
      {
        emails: attendeeList,
      },
    );
  },
  registerEventAttendee(eventId: string) {
    return axios.post(`${BASE_URL}/${eventId}/attendee/register`);
  },
  registerOrUpdateEventRole(
    eventId: string,
    registrationInfo,
    canSendMail = false,
  ) {
    return axios.post(
      `${BASE_URL}/${eventId}/role/register?canSendMail=${canSendMail}`,
      registrationInfo,
    );
  },
  registerNewEventAttendeeAccount(
    eventId: string,
    registrationInfo,
    ticketTypeId
  ) {
    const ticketType = ticketTypeId ? `ticketTypeId=${ticketTypeId}` : '';
    return axios.post(
      `${BASE_URL}/${eventId}/attendee/new?${ticketType}`,
      registrationInfo,
    );
  },
  /**
 * @deprecated The method should not be used
 */
  uploadS3Image(s3Url: string, file: any) {
    return axios({
      method: 'put',
      url: `${s3Url}`,
      data: file,
      headers: {
        'Content-Type': file.type,
      },
    });
  },
  uploadFileToS3(s3Url: string, file: any) {
    return axios({
      method: 'put',
      url: `${s3Url}`,
      data: file,
      headers: {
        'Content-Type': file.type,
        'Access-Control-Allow-Origin': "*"
      },
    });
  },
  getCustomFieldsById(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/custom_field_keys`);
  },
  getCheckRecentLogin(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/location/checkRecentLogin`);
  },
  // Lobby Settings
  getLobbySettings(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/lobby/settings`);
  },
  saveLobbySettings(eventId: string, message: object) {
    return axios.post(`${BASE_URL}/${eventId}/lobby/settings`, message);
  },
  getLobbyContactsWithLiveStatus(eventId: string) {
    return axios.get<ILobbyContacts[]>(
      `${BASE_URL}/${eventId}/lobby/contacts/list_with_status`,
    );
  },
  // Sponsor
  addSponsor(eventId: string, sponsor) {
    return axios.post(`${BASE_URL}/${eventId}/sponsor`, sponsor);
  },
  getSponsorList(eventId: string, refId?: string) {
    return axios.get(`${BASE_URL}/${eventId}/sponsor/list`, {
      params: { r: refId },
    });
  },
  // Booths
  addBooth(eventId: string, booth) {
    return axios.post(`${BASE_URL}/${eventId}/booth`, booth);
  },
  getBoothList(eventId: string, showHidden = true) {
    return axios.get(
      `${BASE_URL}/${eventId}/booth/list?showHidden=${showHidden}`,
    );
  },
  getBoothListForAdmin(eventId: string, showHidden = true) {
    return axios.get(
      `${SERVICE_URL}/booth/${eventId}/o/booth/list?showHidden=${showHidden}`,
    );
  },
  getBoothListForPublicView(eventId: string) {
    return axios.get(
      `${BASE_URL}/${eventId}/booth/list/public`,
    );
  },
  updateLocation(eventId, location, locationRefId, subLocation, subLocationRefId) {
    return axios.post(`${BASE_URL}/${eventId}/location`, {
      location,
      locationRefId,
      subLocation,
      subLocationRefId,
    });
  },
  getOwnedBooths() {
    return axios.get(`${SERVICE_URL}/event/booth/owned/list`);
  },
  getOwnedBooth(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/booth/owned`);
  },
  addBoothZone(eventId: string, boothZoneDetails) {
    return axios.post(`${BASE_URL}/${eventId}/booth_zone`, boothZoneDetails);
  },
  getBoothZones(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/booth_zone/list`);
  },
  getBoothZonesForAdmin(eventId: string) {
    return axios.get(`${SERVICE_URL}/booth/${eventId}/o/booth_zone/list`);
  },
  deleteBoothZone(eventId: string, boothZoneId: string) {
    return axios.delete(
      `${BASE_URL}/${eventId}/booth_zone/${boothZoneId}/delete`,
    );
  },
  // Layouts
  getLobbyLayout(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/lobby/layout`);
  },
  getWidgetsByLayoutId(eventId, layoutId) {
    return axios.get(`${BASE_URL}/${eventId}/widget/${layoutId}`);
  },
  saveLobbyLayout(eventId, lobbyLayout) {
    return axios.post(`${BASE_URL}/${eventId}/lobby/layout`, lobbyLayout);
  },
  getStageLayout(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/stage/layout`);
  },
  getStageLayoutByStageId(eventId, stageId) {
    return axios.get(`${BASE_URL}/${eventId}/stage/${stageId}/layout`);
  },
  saveStageLayoutByStageId(eventId, stageId, stageLayoutIntoVideo) {
    return axios.post(
      `${BASE_URL}/${eventId}/stage/${stageId}/layout`,
      stageLayoutIntoVideo,
    );
  },
  saveStageLayout(eventId, stageLayout) {
    return axios.post(`${BASE_URL}/${eventId}/stage/layout`, stageLayout);
  },
  duplicateEvent(eventId) {
    return axios.post(`${BASE_URL}/${eventId}/duplicate`);
  },
  getZoneSetUp(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/zone/list/v3`);
  },
  getZoneSetUpAll(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/zone/list_all`);
  },
  configureZoneSetUp(eventId, values) {
    return axios.post(`${BASE_URL}/${eventId}/zone/update`, values);
  },
  updateZonesBulk(eventId, values) {
    return axios.post(`${BASE_URL}/${eventId}/zones/update`, values);
  },
  getTemplateIntroVideos(templateType) {
    return axios.get(
      `${BASE_URL}/template/introVideo?templateType=${templateType}`,
    );
  },
  getTemplateBackgroundSkins(templateType) {
    return axios.get(`${BASE_URL}/template/skin?templateType=${templateType}`);
  },
  // Stream
  getStreamDetails(eventId) {
    console.log('streamInfo > api');
    return axios.get<IStreamDetailsModel[]>(
      `${BASE_URL}/${eventId}/stream/details`,
    );
  },
  // Anouncement
  createAnnouncement(eventId: string, announcementDTO) {
    return axios.post(`${BASE_URL}/${eventId}/announcement`, announcementDTO);
  },
  getAllActiveAnnouncements(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/announcement/list`);
  },
  stopAnnouncement(eventId: string, announcementId: string) {
    return axios.post(
      `${BASE_URL}/${eventId}/announcement/stop?announcementId=${announcementId}`,
    );
  },
  setAnnouncementUserAction(
    eventId: string,
    announcementId: string,
    action: AnnouncementUserActionStatus,
  ) {
    return axios.post(
      `${BASE_URL}/${eventId}/announcement/${announcementId}/action?status=${action.toString()}`,
    );
  },
  // zone Config
  createZone(eventId: string, zone: IZoneModel) {
    return axios.post(`${BASE_URL}/${eventId}/zone`, zone);
  },
  getAllActiveZone(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/zone/list`);
  },
  updateZone(eventId: string, zoneUpdate: IZoneUpdateModel) {
    return axios.post(`${BASE_URL}/${eventId}/zone/update`, zoneUpdate);
  },
  getAllEventRoles(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/eventRole`);
  },
  // Speaker Roll Call
  speakerRollCall({ eventId, speakerId, stageId }) {
    return axios.post(`${BASE_URL}/${eventId}/speaker/call`, {
      stageId,
      speakerId,
    });
  },

  // lobby widget
  saveWidget(eventId, layoutId, widgetObj) {
    return axios.post(
      `${BASE_URL}/${eventId}/layout/${layoutId}/widget`,
      widgetObj,
    );
  },
  deleteWidget(eventId, layoutId, widgetId) {
    return axios.delete(
      `${BASE_URL}/${eventId}/layout/${layoutId}/widget/${widgetId}`,
    );
  },
  // Business Cards
  saveBusinessCard({ eventId, accountId }) {
    return axios.post(`${BASE_URL}/${eventId}/business_card`, {
      accountId,
    });
  },
  removeBusinessCard({ eventId, accountId }) {
    return axios.delete(`${BASE_URL}/${eventId}/business_card/${accountId}`);
  },
  getSavedBusinessCards(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/business_card/list`);
  },

  getIndustries() {
    return axios.get(`${BASE_URL}/values/industries`);
  },
  getSalutation() {
    return axios.get(`${BASE_URL}/values/salutation`);
  },
  getCountry() {
    return axios.get(`${BASE_URL}/values/countries`);
  },
  // Landing Page
  getLandingPageByEventById(eventId) {
    return axios.get(`${BASE_URL}/landing_page/${eventId}`);
  },
  saveLandingPageStylingTab(eventId, values) {
    return axios.post(`${BASE_URL}/landing_page/${eventId}`, values);
  },
  saveLandingPageSponsorsTab(eventId, eventLandingPageId, values) {
    return axios.post(
      `${BASE_URL}/landing_page/${eventId}/sponsor/${eventLandingPageId}`,
      values,
    );
  },
  updateLandingPageSponsorsTab(eventId, eventLandingPageSponsorId, values) {
    return axios.patch(
      `${BASE_URL}/landing_page/${eventId}/sponsor/${eventLandingPageSponsorId}`,
      values,
    );
  },
  deleteLandingPageSponsor(eventId, eventLandingPageSponsorId) {
    return axios.delete(
      `${BASE_URL}/landing_page/${eventId}/sponsor/${eventLandingPageSponsorId}`,
    );
  },
  updateLandingPageStylingTab(eventId, eventLandingPageId, values) {
    return axios.patch(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}`,
      values,
    );
  },
  updateLandingPageSettings(eventId, eventLandingPageId, values) {
    return axios.patch(
      `${BASE_URL}/landing_page/${eventId}/settings/${eventLandingPageId}`,
      values,
    );
  },
  saveLandingPageInformationTab(eventId, eventLandingPageId, values) {
    return axios.post(
      `${BASE_URL}/landing_page/${eventId}/customFieldKey/${eventLandingPageId}`,
      values,
    );
  },
  updateLandingPageInformationTab(eventId, eventLandingPageId, values) {
    return axios.patch(
      `${BASE_URL}/landing_page/${eventId}/customFieldKey/${eventLandingPageId}`,
      values,
    );
  },
  removeEventCustomField(eventId, registrationCustomFieldId) {
    return axios.delete(
      `${BASE_URL}/landing_page/${eventId}/customFieldKey/${registrationCustomFieldId}`,
    );
  },
  // Event Landing Page - Info Tab
  fetchNewLandingPageView(eventId, eventLandingPageId) {
    return axios.get(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/new`,
    );
  },
  fetchInfoTabView(eventId, eventLandingPageId) {
    return axios.get(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/information_tab_view`,
    );
  },
  saveInfoTabView(eventId, eventLandingPageId, infoTabView) {
    return axios.post(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/information_tab_view`,
      infoTabView
    );
  },
  fetchEventDisclaimers(eventId, eventLandingPageId) {
    return axios.get(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/disclaimers`
    );
  },
  bulkDeleteDisclaimers(eventId, eventLandingPageId, disclaimerIds) {
    return axios.delete(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/disclaimers/bulk`,
      { data: disclaimerIds }
    );
  },
  bulkDeleteRegistrationFields(eventId, eventLandingPageId, registrationCustomFieldIds) {
    return axios.delete(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/registration_custom_fields/bulk`,
      { data: registrationCustomFieldIds }
    );
  },
  // Event Landing Page - Content Tab
  fetchContentTabView(eventId, eventLandingPageId) {
    return axios.get(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/content_tab_view`,
    );
  },
  saveContentTabView(eventId, eventLandingPageId, contentTabView) {
    return axios.post(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/content_tab_view`,
      contentTabView
    );
  },
  bulkDeleteFAQs(eventId, eventLandingPageId, faqIds) {
    return axios.delete(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/faqs/bulk`,
      { data: faqIds }
    );
  },
  bulkDeleteSponsors(eventId, eventLandingPageId, sponsorIds) {
    return axios.delete(
      `${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/sponsors/bulk`,
      { data: sponsorIds }
    );
  },
  // event background
  saveEventBackground(eventId, formValues) {
    return axios.post(`${BASE_URL}/${eventId}/eventBackground`, formValues);
  },
  getEventBackground(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/eventBackground`);
  },
  updateEventBackground(eventId, formValues, eventBackgroundImageId) {
    return axios.patch(
      `${BASE_URL}/${eventId}/eventBackground/${eventBackgroundImageId}`,
      formValues,
    );
  },
  deleteEventBackground(eventId, eventBackgroundImageId) {
    return axios.delete(
      `${BASE_URL}/${eventId}/eventBackground/${eventBackgroundImageId}`,
    );
  },
  // Registered Interests
  listRegisteredInterests(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/registered_interests/list`);
  },
  // Archive Polls, Q&As, Messages
  archiveEventData(eventId: string) {
    return axios.post(`${BASE_URL}/${eventId}/data/archive`);
  },
  downloadRegistrationsCsv(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/registrations/downloadCSV`);
  },
  // Compute Backstage Access
  hasBackStageAccess(eventId: string, stageId: string) {
    return axios.get(
      `${BASE_URL}/${eventId}/stage/${stageId}/backstage/access`,
    );
  },
  // get event checklist status
  getEventChecklistStatus(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/checklist`);
  },
  updateBoothZonesOrders(eventId: string, ordersList) {
    return axios.post(`${BASE_URL}/${eventId}/booth_zone/order`, ordersList);
  },
  // Fetch user inbox
  fetchMyInbox(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/inbox`)
  },
  getOneToOneChatMessages(eventId: string, receiverAccountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/attendee/message/list`, {
      params: {
        receiverAccountId,
      },
    });
  },
  getCurrentUtcDateTime() {
    return axios.get(`${BASE_URL}/current_utc_date_time`);
  },
  updateMessageReadTime(eventId: string, senderAccountId: string) {
    return axios.patch(
      `${BASE_URL}/${eventId}/attendee/message/read_time/${senderAccountId}`,
    );
  },

  getUnReadChatList(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/attendee/unread_chat`);
  },
  getGaTrackingId(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/ga_tracking`);
  },
  saveGaTrackingId(eventId, gaTrackingId) {
    return axios.post(`${BASE_URL}/${eventId}/ga_tracking`, { gaTrackingId });
  },
  getEventFeatureFlagConfig(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/feature_flag`);
  },
  getCsvFieldMappingByEventById(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/csv/mapping`);
  },
  createCsvFieldMapping(eventId, csvFieldMappingObj) {
    return axios.post(`${BASE_URL}/${eventId}/csv/mapping`, csvFieldMappingObj);
  },
  updateCsvFieldMapping(eventId, csvFieldMappingObj) {
    return axios.patch(`${BASE_URL}/${eventId}/csv/mapping`, csvFieldMappingObj);
  },
  enableScheduleSync(eventId: string, active: boolean) {
    return axios.post(
      `${BASE_URL}/${eventId}/scheduleSyncActiveState?active=${active}`,
    );
  },
  enableHelpdesk(eventId: string, active: boolean) {
    return axios.post(
      `${BASE_URL}/${eventId}/enableHelpdesk?active=${active}`,
    );
  },
  // Translation Settings
  getEventSettings(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/settings`);
  },
  saveEventTranslationSettings(eventId, enableTranslations) {
    return axios.post(`${BASE_URL}/${eventId}/settings/translations`, { enableTranslations });
  },
  // Widget click tracker
  saveWidgetClick(eventId: string, userClickDTO: IUserClick) {
    return axios.post(`${BASE_URL}/${eventId}/click`, userClickDTO);
  },
  getTawktoDetails(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/tawkto`);
  },
  getLandingPageCarouselImages(eventLandingPageId) {
    return axios.get(`${BASE_URL}/landing_page/${eventLandingPageId}/landingPageCarouselImages`);
  },
  getLandingPageBannerMedia(eventLandingPageId) {
    return axios.get(`${BASE_URL}/landing_page/${eventLandingPageId}/banner/media`);
  },
  saveLandingPageCarouselImages(eventId, eventLandingPageId, body) {
    return axios.post(`${BASE_URL}/landing_page/${eventId}/${eventLandingPageId}/saveCarouselImages`, body);
  },
  configTawkto(eventId) {
    return axios.post(`${BASE_URL}/${eventId}/tawkto/config`);
  },
  updateEventCustomFields(eventId, customField) {
    return axios.post(`${BASE_URL}/${eventId}/customFields`, customField);
  },
  getIsEventPriorCheckInAccess(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/isEventPriorCheckInAccess`);
  },
  getEventTicketGroupFilter(eventId: string, email: string) {
    return axios.get(`${BASE_URL}/${eventId}/ticket-group/filter`, {
      params: {
        email,
      },
    });
  },
  getPresentationLayoutStatus(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/presentation-layout/status`);
  },
  getMembersDetailsByAccountIds(eventId: string, accountIds: string[]) {
    return axios.post(`${BASE_URL}/${eventId}/members/details`, accountIds);
  },
  getEventCreatorDetails(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/creator`);
  },
  getSeriesLiveAndUpcomingEvents(seriesEventId: string) {
    return axios.get(
      `${SERVICE_URL}/series/${seriesEventId}/live-and-upcoming-events-list`,
      {
        withCredentials: false,
      },
    );
  },
};
