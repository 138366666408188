import React from 'react';
// styles + types
import styles from './styles.module.scss';
import { ILoaderProps } from './types';

const Loader = (props: ILoaderProps) => {
  const { styleClass = 'default' } = props;
  return <div className={styles[styleClass]}></div>;
};

export default Loader;
