
import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/event`;
export default {
  getLeaderboard(eventId) {
    return axios.get(
      `${BASE_URL}/leaderboard/${eventId}/rank`,
    );
  },
  downloadLeaderboardCsv(eventId) {
    return axios.get(
      `${BASE_URL}/leaderboard/${eventId}/downloadCSV`,
    );
  },
};
