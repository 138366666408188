import { AxiosResponse } from 'axios';
import { IOrganizationBase } from './types';
import axios from '../axios';

const BASE_URL = '/organization';
export default {
  getOrganizationBaseByRef(
    refId: string,
    refType: string,
  ): Promise<AxiosResponse<IOrganizationBase>> {
    return axios.get(`${BASE_URL}?refId=${refId}&refType=${refType}`);
  },
};
