import InfoImage from '@/public/images/info.svg';
import SuccessImage from '@/public/images/check.svg';
import DangerImage from '@/public/images/errorToast.svg';
import WarningImage from '@/public/images/warning.svg';
import SlimClose from '@/public/images/slimClose.svg';

export enum IToastImagesTypes {
  success = SuccessImage,
  error = DangerImage,
  info = InfoImage,
  warning = WarningImage,
  close = SlimClose,
}
