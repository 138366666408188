import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/speaker`;

export default {
  fetchSpeakerByEmail(email: string, eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/search/email?email=${email}`);
  },
  fetchSpeakersListWithSearch(eventId : string, limit, offset, q: string) {
    return axios.get(`${BASE_URL}/${eventId}/list/search?limit=${limit}&offset=${offset}&q=${q}`,);
  },
  fetchAllSpeakersPublicView(eventId : string) {
    return axios.get(`${BASE_URL}/${eventId}/public/list`);
  },
  fetchSpeakersCountWithSearch(eventId: string, q: string) {
    return axios.get( `${BASE_URL}/${eventId}/list/count?q=${q}`,);
  },
  deleteSpeaker(eventId: string, speakerId: string) {
    return axios.delete(`${BASE_URL}/${eventId}/${speakerId}`,);
  },
  resendSpeakerInvitation(eventId: string, email: string) {
    return axios.post( `${BASE_URL}/${eventId}/resend/invite?email=${encodeURIComponent(email)}`,);
  },
  fetchSpeakerById(speakerId: string, eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${speakerId}`);
  },
  importSpeakersCsv( eventId: string, file, canSendMail: boolean = false) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(`${BASE_URL}/${eventId}/import?canSendMail=${canSendMail}`,
      formData,
      config,
    );
  },
  fetchSpeakersList(eventId, limit, offset) {
    return axios.get(`${BASE_URL}/${eventId}/list?limit=${limit}&offset=${offset}`,);
  },
  fetchAllSpeakersList(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/list`,);
  },
  getAllSpeakers(eventId: string, refId) {
    return axios.get(`${BASE_URL}/${eventId}/list`, {
      params: { r: refId },
    });
  },
  verifyMToken(eventId: string, magicTokenId: string, speakerId: string) {
    return axios.post(
      `${BASE_URL}/${eventId}/magic/token/verify/${magicTokenId}/${speakerId}`
    );
  },
  getSpeakerEmail(speakerId: string) {
    return axios.get(`${BASE_URL}/${speakerId}/email`);
  },
};
