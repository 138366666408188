import React from 'react';
// components
import Modal from '@/components/ui/modals/Modal';
import Button, {
  IButtonTypes,
  IButtonMargins,
} from '@/components/ui/buttons/Button';
import { ITextStyles } from '@/components/ui/content/Text';
// Styles
import styles from './styles.module.scss';

const DeleteConfirmPopup = props => {
  const {
    handleDeleteConfirm,
    title,
    showPopup,
    handleDeleteCancel,
    desc,
    primaryText,
    secondaryText,
    modalHeaderClass,
    className,
    dataTestId,
  } = props;
  return (
    <Modal
      id={'delete-confirmation-model'}
      title={
        !title || title === '' ? 'Are you sure you want to delete?' : title
      }
      disableClose={true}
      modalHeaderClass={
        modalHeaderClass ? modalHeaderClass : 'functionalModalHeader'
      }
      autoTrigger={showPopup}
      className={styles[className]}
      render={({ setShow }) => (
        <>
          <div className={styles.modalTextRow}>
            {!desc || desc === '' ? null : desc}
          </div>
          <div className={styles.modalButtonRow}>
            <Button
              label={
                !secondaryText || secondaryText === ''
                  ? 'No, Cancel'
                  : secondaryText
              }
              styleClass={IButtonTypes.NO_BG_CONTRAST}
              margin={IButtonMargins.MEDIUM_MARGIN_LEFT}
              type="button"
              onClick={() => {
                handleDeleteCancel()({ setShow });
              }}
              textStyle={ITextStyles.DEFAULT}
              className={styles.noBgButton}
            />
            <Button
              label={
                !primaryText || primaryText === '' ? 'Yes, Delete' : primaryText
              }
              styleClass={IButtonTypes.FUNCTIONAL_PRIMARY}
              margin={IButtonMargins.MEDIUM_MARGIN_LEFT}
              type="button"
              onClick={() => {
                handleDeleteConfirm()({ setShow });
              }}
              textStyle={ITextStyles.DEFAULT}
              dataTestId="delete-confirm-popup-yes-option"
            />
          </div>
        </>
      )}
    />
  );
};

export default DeleteConfirmPopup;
