import axios from 'axios';

export default {
  getEmbedDetails(url, width, height) {
    return axios.get('https://vimeo.com/api/oembed.json', {
      params: {
        url,
        width,
        height
      }
    });
  },
};
