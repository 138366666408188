export const _pick = (obj, keys) => {
  console.log('obj', obj);
  console.log('keys', keys);
  let finalObj;

  keys.array.forEach(element => {

  }); (k => {
    console.log('k', k);
    if (k in obj) {
      finalObj[k] = obj[k]
    }
  });

  return finalObj;
}

export const _isObject = (obj) => {
  return obj && typeof obj === 'object' && !Array.isArray(obj);
}

export const _isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0;
}

export const _isUndefined = (obj) => {
  return obj === undefined;
}

export const _exclude = (obj, keys) => {
  let finalObj;

  keys.forEach(k => {
    if (!(k in obj)) {
      finalObj[k] = obj[k]
    }
  });

  return finalObj;
}

export const _isNull = (obj) => {
  return obj === null;
}