import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
//api
import api from '@/api';
// components
import Loader from '@/components/ui/Loader';
// utils
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { _isUndefined, _isEmpty as _isEmptyObj } from '@/utils/jsHelpers/obj';
import { useRedirectAfterLogin } from '@/hooks/use-redirect-after-login';
//types
import { zoneTypes } from '@/models/event/types';
import { IAccountRoleTypeOptions } from '@/models/account/types';
// models
import {
  makeSelectEventById
} from '@/models/event';
import MagicLinkConfirmationModal from '@/components/landing-page-templates/ConfigLandingPage/ConfigAuthComponents/MagicLinkConfirmationModal';
import { IEventStatus } from './types';
import useEventStatus from '@/hooks/use-event-status';

const MagiclinkComponent = ({
  currentUser,
  landingPageObject,
  isSpeakerPage
}) => {

  const {
    checkInEnableTime,
    checkInDisableTime,
    magicLinkEnabled,
    speakerMagicLinkEnabled,
  } = landingPageObject;

  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  let { re, mt, ref } = searchParams;
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();
  const event = useMemoizedSelector(makeSelectEventById, eventId);

  const { redirectPageAfterCheckIn } = useRedirectAfterLogin({ currentUser });


  const checkInEnabledBeforeEventMinutes = checkInEnableTime;
  const checkInDisableAfterEventMinutes = checkInDisableTime;
  const { eventStatus } = useEventStatus({event, checkInEnabledBeforeEventMinutes, checkInDisableAfterEventMinutes})

  const redirectToLandingPage = async (isTokenVerified: boolean) => {

    const redirectEventId = event.seriesParentEventId ? event.seriesParentEventId : eventId;
    let attendeeLandingPageUrl;
    let speakerLandingPageUrl;
    await api.pageBuilder.getLandingPageLink(redirectEventId).then(({ data }) => {
      attendeeLandingPageUrl = data.attendeeUrl;
      speakerLandingPageUrl = data.speakerUrl;
    }).catch(e => {console.error(e)});

    console.log('debugRedirect > 1', isSpeakerPage, isTokenVerified, attendeeLandingPageUrl,speakerLandingPageUrl, eventStatus, event.seriesParentEventId, redirectEventId);
    if (isSpeakerPage) {
      if(isTokenVerified && event.seriesParentEventId){
        handleCheckIn();
      } else if (isTokenVerified && eventStatus !== IEventStatus.COMPLETED) {
        handleCheckIn();
      } else if (speakerLandingPageUrl){
        window.location.replace(speakerLandingPageUrl);
      } else {
        history.push(`/p/event/${redirectEventId}`);
      }
    } else {
      if(isTokenVerified && eventStatus === IEventStatus.ONGOING){
        handleCheckIn();
      } else if (attendeeLandingPageUrl){
        window.location.replace(attendeeLandingPageUrl);
      } else {
        history.push(`/p/a/event/${redirectEventId}`);
      }
    }
  };

  const [
    magicLinkWithDifferentEmail,
    setMagicLinkWithDifferentEmail,
  ] = useState('');
  const [confirmDifferentEmailLogin, setConfirmDifferentEmailLogin] = useState(
    false,
  );


  //Attendee magic link logic:
  useEffect(() => {
    console.log('debugRedirect > magic 1', eventStatus);
    //If logging in with speaker magic link we are returning here
    //Until event status is loaded we will not validate magic link
    //Bcs According to event status we have to redirect to parent series landing page.
    if (isSpeakerPage || !event?.eventId || !landingPageObject?.eventLandingPageId || !eventStatus) {
      return;
    }
    console.log('debugRedirect > magic 2', eventStatus);
    (async () => {
      if (magicLinkEnabled && re && mt) {
        if(event.seriesParentEventId && eventStatus !== IEventStatus.ONGOING) {
          redirectToLandingPage(false);
          return;
        }
        let isDifferentEmail = false;
        if (
          currentUser.accountId &&
          !confirmDifferentEmailLogin
        ) {
          const { data } = await api.registration.getRegisteredEmail(re);
          if (data && currentUser.email !== data) {
            setMagicLinkWithDifferentEmail(data);
            isDifferentEmail = true;
            return;
          }
        }
        if (!isDifferentEmail) {
          api.registration
            .verifyMToken(event.eventId, mt, re)
            .then(res => {
              const data = res.data;
              dispatch({ type: 'account/setUserData', payload: { data } });
              redirectToLandingPage(true);
            })
            .catch(e => {
              redirectToLandingPage(false);
            });
        }
      } else {
        redirectToLandingPage(false);
      }
    })();
  }, [event, landingPageObject, confirmDifferentEmailLogin, eventStatus]);

  //Speaker magic link logic:
  useEffect(() => {
    //If logging in with Attendee magic link we are returning here
    if (!isSpeakerPage || !event?.eventId || !landingPageObject?.eventLandingPageId || !eventStatus) {
      return;
    }

    (async () => {
      if (speakerMagicLinkEnabled && re && mt) {
        console.log('debugRedirect > magic 3', currentUser.accountId, confirmDifferentEmailLogin);
        let isDifferentEmail = false;
        if (
          currentUser.accountId &&
          !confirmDifferentEmailLogin
        ) {
          const { data } = await api.speaker.getSpeakerEmail(re);
          if (data != null && currentUser.email !== data) {
            console.log('debugRedirect > magic 4', data);
            setMagicLinkWithDifferentEmail(data);
            isDifferentEmail = true;
            return;
          }
        }
        if (!isDifferentEmail) {
          api.speaker
            .verifyMToken(eventId, mt, re)
            .then(res => {
              const data = res.data;
              dispatch({ type: 'account/setUserData', payload: { data } });
              redirectToLandingPage(true);
            })
            .catch(e => {
              redirectToLandingPage(false);
            });
        }
      } else {
        redirectToLandingPage(false);
      }
    })();
  }, [eventId, event, landingPageObject, confirmDifferentEmailLogin, eventStatus]);

  const redirectPage = (redirectUrl: string) => {
    history.push(redirectUrl);
  };

  const handlePostLogin = async (zoneSetup, zoneSetupList) => {
    let role;
    await api.event.getEventRole(eventId, undefined).then(({ data }) => {
      role = data.role;
    }).catch(e => console.error(e));
    if(!role) {
        // NOTE - this is a failed case (couldn't get the role from event role)
        //  so defaulting to handling it for an attendee.
        console.error('debugSeriesRedirect > 1');
        redirectPage(redirectPageAfterCheckIn(IAccountRoleTypeOptions.ATTENDEE, zoneSetup));
        return;
    }

    if (role === IAccountRoleTypeOptions.ATTENDEE) {
      console.error('debugSeriesRedirect > 2');
      redirectPage(redirectPageAfterCheckIn(role, zoneSetup));
    } else if (
      role === IAccountRoleTypeOptions.SPEAKER ||
      role === IAccountRoleTypeOptions.ORGANIZER ||
      role === IAccountRoleTypeOptions.MODERATOR
    ) {
        console.error('debugSeriesRedirect > 4');
        redirectPage(redirectPageAfterCheckIn(role, zoneSetup));
    } else if (zoneSetupList && zoneSetupList.length > 0) {
      console.error('debugSeriesRedirect > 5');
      redirectPage(redirectPageAfterCheckIn(IAccountRoleTypeOptions.ATTENDEE, zoneSetup));
    } else {
      // Handle default case of event with no zone configuration
      console.error('debugSeriesRedirect > 6');
      redirectPage(`/l/event/${eventId}/stages`);
    }

  }

  //here are checking for enabled zones in the event and redirecting user to appropriate zone according to role
  const handleCheckIn = () => {

    if (ref) {
      // this redirect will not check for role or zone access or if zone is present
      // it will redirect to the url in the ref param after login only if the event is ongoing
      redirectPage(ref);
      return;
    }
    const { eventId } = event;

    // NOTE - this was previously using the account's roles field; it should be using the event role's specific role
    //  e.g. a speaker at one event should not be considered a speaker for all events.
      api.event.getZoneSetUp(eventId).then(async ({ data: zoneSetupList }) => {
        const zoneSetup = {};
        zoneSetupList.forEach(item => {
          if (item.type === zoneTypes.BOOTH) {
            Object.assign(zoneSetup, { [item.zoneId]: item.active && item.isVisible });
          } else {
            Object.assign(zoneSetup, { [item.type]: item.active && item.isVisible });
          }
        });
        await handlePostLogin(zoneSetup, zoneSetupList);
      });
  };
/* This modal is triggered when user is already logged in and tries to access magic link of different email id. */
  return (
      <MagicLinkConfirmationModal
        magicLinkWithDifferentEmail={magicLinkWithDifferentEmail}
        currentUser={currentUser}
        setConfirmDifferentEmailLogin={setConfirmDifferentEmailLogin}
        setMagicLinkWithDifferentEmail={setMagicLinkWithDifferentEmail}
        redirectToLandingPage={redirectToLandingPage}
      />
  );
};

export default MagiclinkComponent;
