import axios from './axios';
import { SERVICE_URL } from '@/config';
import {
  IRegistrationModel,
  IRegistrationRequestModel,
} from '@/models/event/types';

const BASE_URL = `${SERVICE_URL}/event/user_registration`;
export default {
  saveRegistration(registrationObject: IRegistrationModel) {
    return axios.post(
      `${BASE_URL}`, registrationObject
    );
  },
  verifyMToken(eventId: string, magicTokenId: string, registrationId: string) {
    return axios.post(
      `${BASE_URL}/${eventId}/magic/token/verify/${magicTokenId}/${registrationId}`
    );
  },
  resendMagicLink(registrationRequestObject: IRegistrationRequestModel) {
    return axios.post(
      `${BASE_URL}/magic/resend`, registrationRequestObject
    );
  },
  getRegistrationByEmail(registrationRequestObject: IRegistrationRequestModel) {
    return axios.post(
      `${BASE_URL}/details`, registrationRequestObject
    );
  },
  createEventRoleAndRegistrationForSsoOnlyEvent(eventId: string, registrationObject: IRegistrationModel) {
    return axios.post(
      `${BASE_URL}/${eventId}/create_event_role_registration`, registrationObject
    );
  },
  updateRegistration(eventId: string, registrationId: string, registrationObject: IRegistrationModel) {
    return axios.post(
      `${BASE_URL}/${eventId}/${registrationId}/update`, registrationObject
    );
  },
  resendRegistrationMail(eventId: string, registrationId: string) {
    return axios.post(`${BASE_URL}/${eventId}/${registrationId}/resend_mail`);
  },
  resendTransactionMail(eventId: string, transactionId: string) {
    return axios.post(
      `${SERVICE_URL}/vivenu/${eventId}/transaction/${transactionId}/resend_mail`,
    );
  },
  getRegisteredEmail(registrationId: string) {
    return axios.get(`${BASE_URL}/${registrationId}/email`);
  },
  getRSVPStatus(userToken: string) {
    return axios.get(`${BASE_URL}/rsvp-status?ut=${userToken}`);
  },
  declineRSVPStatus(userToken: string) {
    return axios.post(`${BASE_URL}/rsvp-status/decline?ut=${userToken}`);
  },
  getLandingPageUrlWithMagicLink(eventId: string) {
    return axios.get(
      `${BASE_URL}/${eventId}/magic-token-landing-url`
    );
  },
};
