import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/pusher`;
export default {
  getAccountEventConnectionCount(eventId: string, accountId: string) {
    return axios.get(
      `${BASE_URL}/channel_status?channel=private-event-account-${eventId}-${accountId}`,
    );
  },
};
