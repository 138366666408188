import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { makeSelectEventById } from '@/models/event';
import api from '@/api';
import {
  ILandingPage,
  ITemplateDetails,
} from '@/components/landing-page-templates/ConfigurableLandingPage/types';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';

const useTemplateData = eventId => {
  const dispatch = useDispatch();
  const event = useMemoizedSelector(makeSelectEventById, eventId);

  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const refId = searchParams.r;
  const [templateDetails, setTemplateDetails] = useState<ITemplateDetails>();
  useEffect(() => {
    api.event.getLandingPageByEventById(eventId).then(({ data }) => {
      setTemplateDetails(data);
      if (data ?.landingPageTemplateName !== ILandingPage.BASIC) {
        dispatch({
          type: 'event/getEventSponsorList',
          payload: {
            eventId,
            refId,
          },
        });
        dispatch({
          type: 'event/getAllEventSpeakers',
          payload: {
            eventId,
            refId,
          },
        });
        dispatch({
          type: 'event/getEventUnfilteredSegmentList',
          payload: {
            eventId,
            refId,
          },
        });
      }
    });

    dispatch({
      type: 'event/getById',
      payload: {
        eventId: eventId,
        refId,
      },
    });
  }, [eventId]);

  return {
    event,
    templateDetails: templateDetails,
  };
};
export default useTemplateData;
