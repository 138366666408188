// types
import { IScreenCalcOrientationOptions } from '@/components/PublishedStreamDisplay/types';
import { IFormFieldRow } from '@/types/form';
import { ISelectFieldOptionsArray } from '@/types/selectField';
import { IMAGE_URL, STATIC_ZUDDL_URL } from '@/config';
import { INotificationTypes } from '@/types/notification';
import { INotificationPayload } from '@/types/notificationPayload';
import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { IEventRoleOptions } from '@/types/eventRole';
import {
  format,
  parse,
  setHours,
  setMinutes,
  getHours,
  getMinutes,
  setSeconds,
  set,
  eachDayOfInterval,
} from 'date-fns';
import api from '@/api';
import moment from 'moment';
import { FIELDS as EVENT_FORM_FIELDS } from '@/components/forms/CreateEditEventForm/config';
import { matchPath } from 'react-router';
import hexRgb from 'hex-rgb';
import Parser from 'html-react-parser';

/**
 * Takes data coming from the backend, an array of  model objects
 * and converts them into a map of => {[key: id]: [value: model]}.
 * It is used for easier access and modification throughout the site.
 *
 *  @param objects - model objects returned from the backend
 *  @returns objAsIdMap
 */
export const formatObjsAsIdMap = (objs: any) =>
  objs.reduce((finalMap, currentObj) => {
    const mapCopy = { ...finalMap };
    const { _id: id } = currentObj;
    mapCopy[id] = currentObj;
    return mapCopy;
  }, {});

export function* sequenceGenerator(minVal, maxVal) {
  let currVal = minVal;

  while (currVal < maxVal) yield currVal++;
}

export function average(values) {
  let total = 0;
  for (let i = 0; i < values.length; i++) {
    total += values[i];
  }
  var avg = total / values.length;
}

export function median(values) {
  if (values.length === 0) return undefined;

  const copy = [].concat(values);
  copy.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(copy.length / 2);

  if (copy.length % 2) return copy[half];

  return (copy[half - 1] + copy[half]) / 2.0;
}

export function getArrayWithLimitedLength(length) {
  var array = new Array();

  array.push = function () {
    if (this.length >= length) {
      this.shift();
    }
    return Array.prototype.push.apply(this, arguments);
  };

  return array;
}

/**
 * Generates React-Select formatted options array for a given set of tags
 *
 *  @param input array - a string array
 *  @returns {ISelectFieldOptionsArray} selectFieldOptionsArray
 */
export const generateSelectFieldOptionsFromStringArray = (
  stringArray: string[],
  prefix?: string,
  suffix?: string,
) =>
  stringArray.map((s: string) => ({
    label: `${prefix || ''}${s}${suffix || ''}`,
    value: s,
  })) as ISelectFieldOptionsArray;

export const generateSelectFieldOptionsFromEnum = (stringArray: string[]) =>
  stringArray.map((s: string) => {
    const label = s
      .toLowerCase()
      .split('_')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
    return {
      label,
      value: s,
    };
  }) as ISelectFieldOptionsArray;

export const booleanSelectFieldOptions = [
  {
    label: 'true',
    value: true,
  },
  {
    label: 'false',
    value: false,
  },
];

export const monthList3Letter = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const monthListFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const dayList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const dayListFull = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getTimeComponents = (dateObj: Date, use24Format = false) => {
  let hours = dateObj.getHours();
  let isAM = true;
  const minutes = dateObj.getMinutes();

  if (hours >= 12) {
    if (hours !== 12) {
      !use24Format && (hours -= 12);
    }
    isAM = false;
  }
  let strHours = hours.toString();
  strHours = strHours.length === 1 ? `0${strHours}` : strHours;

  let strMinutes = minutes.toString();
  strMinutes = strMinutes.length === 1 ? `0${strMinutes}` : strMinutes;

  const timeOfDay = isAM ? 'AM' : 'PM';

  return [strHours, strMinutes, timeOfDay];
};

export const timeLeftMsToMins = (timeLeftMs: number) => {
  const hrsLeft = Math.floor((timeLeftMs / (1000 * 60 * 60)) % 24);
  const minsLeft = Math.floor((timeLeftMs / (1000 * 60)) % 60);
  const secsLeft = Math.floor((timeLeftMs / 1000) % 60);

  const hrsLeftString = hrsLeft.toString().padStart(2, '0');
  const minsLeftString = minsLeft.toString().padStart(2, '0');
  const secsLeftString = secsLeft.toString().padStart(2, '0');

  if (hrsLeft === 0) {
    return `${minsLeftString}:${secsLeftString}`;
  }
  return `${hrsLeftString}:${minsLeftString}:${secsLeftString}`;
};

export const formatDateTimeForEventTable = (inputDateString: string) => {
  const d = new Date(inputDateString);
  const year = d.getFullYear();
  const date = d.getDate();
  const dayIndex = d.getDay();
  const monthIndex = d.getMonth();

  const dateString = `${dayList[dayIndex]}, ${monthList3Letter[monthIndex]} ${date}, ${year}`;
  const [hours, minutes, timeOfDay] = getTimeComponents(d);
  const timeString = `${hours}:${minutes} ${timeOfDay}`;

  return [dateString, timeString];
};

export const handleCustomVideoStreamPlay = (videoTrack, customId, opts?) => {
  if (!videoTrack) return;

  if (videoTrack && videoTrack.isPlaying) {
    videoTrack.stop();
  }

  try {
    const { fit = 'cover', hideVideoStreams = false } = opts || {};
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    videoTrack && !hideVideoStreams && videoTrack.play(customId, { fit: fit });
  } catch (err) {
    console.error(err);
  }
};

export const handleAudioStreamPlay = (
  audioTrack,
  volumeOn,
  audioPlaybackDeviceId,
) => {
  if (!audioTrack) {
    console.log('handleAudioStreamPlay > no track >', audioTrack);
    console.error('handleAudioStreamPlay > no track >', audioTrack);
    return;
  }

  if (audioTrack.isPlaying) {
    console.log('handleAudioStreamPlay > already playing >', audioTrack);
    console.error('handleAudioStreamPlay > already playing >', audioTrack);
    if (!volumeOn) {
      console.error(
        'handleAudioStreamPlay > no volume > stopping audio >',
        audioTrack,
      );
      audioTrack.stop();
    }
    return;
  }

  console.log('handleAudioStreamPlay >', audioTrack);
  console.error('handleAudioStreamPlay >', audioTrack, audioPlaybackDeviceId);
  try {
    if (audioTrack) {
      console.log('handleAudioStreamPlay > play >', audioTrack);
      console.error('handleAudioStreamPlay > play >', audioTrack);
      if (volumeOn) {
        try {
          audioTrack.setPlaybackDevice(audioPlaybackDeviceId);
        } catch (err) {
          console.error(
            'handleAudioStreamPlay > audioPlaybackDeviceId > err >',
            err,
          );
        }
        audioTrack.play();
      }
    }
  } catch (err) {
    console.log('handleAudioStreamPlay > err >', err);
    console.error('handleAudioStreamPlay > err >', err);
    console.error(err);
  }
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
};

export const getFirstNameLastName = (fullName: string) => {
  if (fullName.length > 0) {
    const nameArray: any =
      fullName.split(/(\s+)/).filter(e => e.trim().length > 0) || [];
    let firstName;
    let lastName;
    if (nameArray.length === 2) {
      [firstName, lastName] = nameArray;
    } else {
      [firstName, ...lastName] = nameArray;
      lastName = lastName[lastName.length - 1];
    }
    if (lastName == undefined) {
      lastName = '';
    }

    return [firstName, lastName];
  }
  return ['', ''];
};

export const getNameInitial = (name: string) => {
  if (name && name.trim().length > 0) {
    return name.trim().charAt(0).toUpperCase();
  }
  return '';
};

export const getFullName = (firstName: string, lastName: string) => {
  let fullName = '';

  if (typeof firstName === 'string' && firstName.length > 0) {
    fullName += firstName;
  }

  if (typeof lastName === 'string' && lastName.length > 0) {
    if (fullName.length > 0) {
      fullName += ` ${lastName}`;
    } else {
      fullName += lastName;
    }
  }

  return fullName;
};

export const formatStartEndTimeOnly = (event, show24HrFormat = false) => {
  const { startDateTime, endDateTime } = event;
  const startDateObj = new Date(startDateTime);
  const endDateObj = new Date(endDateTime);

  const startDay = startDateObj.getDate();
  const startMonth = startDateObj.getMonth();
  const startYear = startDateObj.getFullYear();
  const [startHours, startMinutes, startTimeOfDay] = getTimeComponents(
    startDateObj,
    show24HrFormat,
  );

  const endDay = endDateObj.getDate();
  const endMonth = endDateObj.getMonth();
  const endYear = endDateObj.getFullYear();
  const [endHours, endMinutes, endTimeOfDay] = getTimeComponents(
    endDateObj,
    show24HrFormat,
  );

  if (startDay !== endDay || startMonth !== endMonth || startYear !== endYear) {
    return '';
  }
  // format in ddd, MMMM D, YYYY
  return show24HrFormat
    ? `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`
    : `${startHours}:${startMinutes} ${startTimeOfDay} - ${endHours}:${endMinutes} ${endTimeOfDay}`;
};

export const formatStartEndDateTime = (event, shortForm = false) => {
  if (!event) {
    return {
      dateLine1: '',
      dateLine2: '',
    };
  }

  if (Object.keys(event).length === 0) {
    return {
      dateLine1: '',
      dateLine2: '',
    };
  }

  const { startDateTime, endDateTime } = event;
  const startDateObj = new Date(startDateTime);
  const endDateObj = new Date(endDateTime);

  let dateLine1;
  let dateLine2;
  const startDay = startDateObj.getDate();
  const startMonth = startDateObj.getMonth();
  const startYear = startDateObj.getFullYear();
  const [startHours, startMinutes, startTimeOfDay] = getTimeComponents(
    startDateObj,
  );

  const endDay = endDateObj.getDate();
  const endMonth = endDateObj.getMonth();
  const endYear = endDateObj.getFullYear();
  const [endHours, endMinutes, endTimeOfDay] = getTimeComponents(endDateObj);

  if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
    // format in ddd, MMMM D, YYYY
    if (shortForm) {
      dateLine1 = `${monthList3Letter[startMonth]} ${startDay}`;
    } else {
      dateLine1 = `${dayList[startDateObj.getDay()]}, ${
        monthList3Letter[startMonth]
      } ${startDay}, ${startYear}`;
    }
    dateLine2 = `${startHours}:${startMinutes} ${startTimeOfDay} - ${endHours}:${endMinutes} ${endTimeOfDay}`;
  } else {
    if (shortForm) {
      dateLine1 = `${monthList3Letter[startMonth]} ${startDay} ${startHours}:${startMinutes} ${startTimeOfDay} - `;
      dateLine2 = `${monthList3Letter[endMonth]} ${endDay} ${endHours}:${endMinutes} ${endTimeOfDay}`;
    } else {
      dateLine1 = `${dayList[startDateObj.getDay()]}, ${
        monthList3Letter[startMonth]
      } ${startDay}, ${startYear}
      ${startHours}:${startMinutes} ${startTimeOfDay} - `;
      dateLine2 = `${dayList[endDateObj.getDay()]}, ${
        monthList3Letter[endMonth]
      } ${endDay}, ${endYear}
      ${endHours}:${endMinutes} ${endTimeOfDay}`;
    }
  }

  return {
    dateLine1,
    dateLine2,
  };
};

export const formatDateFilter = dateTime => {
  const dateObj = new Date(dateTime);

  const day = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();

  const dateFilter = `${dayList[dateObj.getDay()]}, ${
    monthList3Letter[month]
  } ${day}, ${year}`;
  return dateFilter;
};

export const formatDateWithDayMonthOnly = dateTime => {
  const dateObj = new Date(dateTime);

  const day = dateObj.getDate();
  const month = dateObj.getMonth();

  const dateFilter = `${day} ${monthList3Letter[month]}`;
  return dateFilter;
};

export const shortFormDateComparator = (dateA, dateB) => {
  const [weekDayofA, monthOfA, dayOfA, yearOfA] = dateA.split(/[ ,]+/);
  const [weekDayofB, monthOfB, dayOfB, yearOfB] = dateB.split(/[ ,]+/);

  const zeroPad = num => (num.length == 1 ? `0${num}` : num);

  const formattedDateA = [
    yearOfA,
    zeroPad(`${monthList3Letter.indexOf(monthOfA) + 1}`),
    zeroPad(dayOfA),
  ].join('-');
  const formattedDateB = [
    yearOfB,
    zeroPad(`${monthList3Letter.indexOf(monthOfB) + 1}`),
    zeroPad(dayOfB),
  ].join('-');

  return formattedDateA > formattedDateB
    ? 1
    : formattedDateA == formattedDateB
    ? 0
    : -1;
};

const getAllFieldObjsInForm = (formFieldsObject: IFormFieldRow[]) =>
  formFieldsObject.map(formSectionRow => formSectionRow.fields).flat();

export const getFormInitialState = (formFieldsObject: IFormFieldRow[]) => {
  const allFieldObjsInForm = getAllFieldObjsInForm(formFieldsObject);
  return allFieldObjsInForm.reduce((finalObj, field) => {
    const { initialState, name } = field;
    if (initialState) {
      finalObj[name] = initialState;
    } else {
      finalObj[name] = ''; // TODO: should just be undefined and each input can handle its undefined state its own way
    }
    return finalObj;
  }, {});
};

export const getCreateEditEventFormInitialState = (
  formFieldsObject: IFormFieldRow[],
  eventData,
) => {
  if (eventData && Object.keys(eventData).length) {
    return eventData;
  } else {
    return getFormInitialState(formFieldsObject);
  }
};

export const stripImgUrl = url => {
  if (url) {
    if (url.indexOf(IMAGE_URL) >= 0) {
      return url.split(IMAGE_URL)[1];
    }
    return url;
  }
  return '';
};

const removeQueryParams = (url: string) => {
  if (url) {
    if (url.indexOf('?') >= 0) {
      return url.split('?')[0];
    }
    return url;
  }
  return '';
};

// take the given dimensions and determine whether to use height or width for calculations
export const getScreenCalcOrientation = (height: number, width: number) => {
  const videoRatio = 16 / 9;
  const ratio = width / height;
  console.log('V RATIO', videoRatio);
  if (ratio > videoRatio) {
    // we still need it to stay in the bounds of the container
    // if it's exceeding container width when calculated
    // then stick to width
    if (height * videoRatio > width) {
      return IScreenCalcOrientationOptions.WIDTH;
    }
    return IScreenCalcOrientationOptions.HEIGHT;
  } else if (ratio === videoRatio) {
    return IScreenCalcOrientationOptions.WIDTH;
  } else {
    if (width / videoRatio > height) {
      return IScreenCalcOrientationOptions.HEIGHT;
    }
    return IScreenCalcOrientationOptions.WIDTH;
  }
};

export const addScreenCalcOrientationToClassName = (
  screenCalcOrientation: string,
  baseClass: string,
) => {
  if (!screenCalcOrientation) {
    return `default${baseClass}`;
  }
  return `${screenCalcOrientation}Based${baseClass}`;
};

export const isObjectEmpty = obj => {
  return obj && Object.keys(obj).length === 0;
};

export const searchByKeyword = (
  listOfItems: Array<object>,
  field: string | Array<string>,
  searchKey: string,
  objectName?: string,
) => {
  if (typeof searchKey !== 'string' || searchKey.length === 0) {
    return listOfItems;
  }
  let searchLower = searchKey.toLowerCase();
  let filteredItems = new Array();
  listOfItems.map(item => {
    if (
      objectName &&
      typeof field == 'string' &&
      item[objectName][field].toLowerCase().includes(searchLower)
    ) {
      filteredItems.push(item);
    }
    if (
      !objectName &&
      typeof field == 'string' &&
      item[field].toLowerCase().includes(searchLower)
    ) {
      filteredItems.push(item);
    }
    Array.isArray(field) &&
      field.map(test => {
        if (item[test] && item[test].toLowerCase().includes(searchLower)) {
          filteredItems.push(item);
        }
      });
  });
  return Array.from(new Set(filteredItems));
};

const getUriTextFromChannelRefType = (refType: string): string => {
  switch (refType) {
    case 'STAGE':
    case 'BACKSTAGE':
      return 'stages';
    case 'BOOTH':
      return 'booth';
    default:
      return 'stages';
  }
};

export const getNotificationRedirectUri = (
  type: INotificationTypes,
  refStringified: string,
  extraData?: any,
  currentZone: string,
): string => {
  let refObject: any;
  try {
    refObject = JSON.parse(refStringified || '{}');
  } catch (error) {
    refObject = {};
  }

  if (extraData) {
    refObject = Object.assign({}, refObject, extraData);
  }

  switch (type) {
    case INotificationTypes.DIRECT_MESSAGE: {
      const {
        channelId = '',
        channelRefId = '',
        channelRefType = '',
        fromAccountId = '',
      } = refObject;
      return `/${getUriTextFromChannelRefType(
        channelRefType,
      )}/${channelRefId}?rt=c&rct=${channelId}&rid=${fromAccountId}`;
    }

    case INotificationTypes.POLL_CREATED: {
      const { channelRefType = '', channelRefId = '', pollId = '' } = refObject;
      return `/${getUriTextFromChannelRefType(
        channelRefType,
      )}/${channelRefId}?rt=p&rid=${pollId}`;
    }

    case INotificationTypes.MEETING_ROOM_REQUEST_ACCEPTED: {
      return '/discussions?drt=up';
    }

    case INotificationTypes.MEETING_ROOM_REQUEST_CANCELLED: {
      return '/discussions?drt=pa';
    }

    case INotificationTypes.MEETING_ROOM_CREATE: {
      return '/discussions?drt=pe';
    }

    case INotificationTypes.MEETING_ROOM_INVITATIONS:
    case INotificationTypes.MEETING_ROOM_ONTIME_NOTIFICATION: {
      const { discussionTableId = '' } = refObject;
      return `/discussions?cur=${discussionTableId}&parentRef=${currentZone}`;
    }

    case INotificationTypes.QUESTION_UPVOTE: {
      const {
        questionId = '',
        channelRefType = '',
        channelRefId = '',
      } = refObject;
      return `/${getUriTextFromChannelRefType(
        channelRefType,
      )}/${channelRefId}?rt=q&rct=n&rid=${questionId}`;
    }
    case INotificationTypes.QUESTION_ANSWERED: {
      const {
        questionId = '',
        channelRefType = '',
        channelRefId = '',
      } = refObject;
      return `/${getUriTextFromChannelRefType(
        channelRefType,
      )}/${channelRefId}?rt=q&rct=a&rid=${questionId}`;
    }

    case INotificationTypes.RAISED_HAND_REQUEST_ACCEPTED:
    case INotificationTypes.RAISED_HAND_REQUESTS_DISMISSED: {
      const { channelRefType = '', channelRefId = '' } = refObject;
      return `/${getUriTextFromChannelRefType(
        channelRefType,
      )}/${channelRefId}?rt=r`;
    }

    case INotificationTypes.CONNECTION_REQUEST:
      return '/briefcase';

    default:
      return '';
  }
};

export const getNotificationPayload = (
  notification: any,
  currentZone: string,
): INotificationPayload => {
  let notificationPayload = {} as INotificationPayload;
  if (notification.message) {
    notificationPayload.title = notification.message.title;
    notificationPayload.body = notification.message.body;
  }
  notificationPayload.isSeen = false;
  notificationPayload.type = notification.notificationType;
  notificationPayload.position = notification.placeOfNotification;
  notificationPayload.senderAccountId = notification.fromId;
  notificationPayload.receiverAccountId = notification.toId;
  notificationPayload.eventId = notification.eventId;
  notificationPayload.updatedAt = notification.updatedAt;
  notificationPayload.isActive = true;
  const type = notification.notificationType;
  let refObject: any;
  const fromAccountId = notification.fromId;
  try {
    refObject = JSON.parse(notification.referenceObject || '{}');
  } catch (error) {
    refObject = {};
  }

  switch (type) {
    case INotificationTypes.DIRECT_MESSAGE: {
      const {
        channelId = '',
        channelRefId = '',
        channelRefType = '',
      } = refObject;
      notificationPayload.landingUrl = `/${getUriTextFromChannelRefType(
        channelRefType,
      )}/${channelRefId}?rt=c&rct=${channelId}&rid=${fromAccountId}`;
      notificationPayload.redirectionLabel = 'View Message';
      return notificationPayload;
    }

    case INotificationTypes.START_SESSION: {
      const { stageId = '' } = refObject;
      notificationPayload.redirectionLabel = 'Go to Session';
      notificationPayload.landingUrl = `/stages/${stageId}/`;
      return notificationPayload;
    }

    case INotificationTypes.BRIEFCASE_REMINDER: {
      notificationPayload.redirectionLabel = 'View Briefcase';
      notificationPayload.landingUrl = '/briefcase';
      return notificationPayload;
    }

    case INotificationTypes.MEETING_INVITE: {
      notificationPayload.landingUrl = '/discussions?drt=pe';
      notificationPayload.firstActionLabel = 'Reject';
      notificationPayload.secondActionLabel = 'Accept';
      notificationPayload.isCardClickAble = true;
      notificationPayload.meetingRoomId = refObject.meetingRoomId;
      //update title
      const meetingStartTime = refObject.meetingStartTime;
      if (meetingStartTime && notificationPayload.title) {
        const meetingUtcTime = moment.utc(meetingStartTime).toDate();
        const localDateTime = moment(meetingUtcTime).local().toDate();
        const [hours, minutes, timeOfDay] = getTimeComponents(localDateTime);
        const timeString = `${hours}:${minutes}${timeOfDay.toLowerCase()}`;
        const year = localDateTime.getFullYear();
        const month = localDateTime.getMonth();
        const userMeetingTimeText = `${timeString} on ${
          monthListFull[month]
        } ${localDateTime.getDate()}, ${year}`;
        let title = notificationPayload.title;
        title = title.replace('{userDateTime}', userMeetingTimeText);
        notificationPayload.title = title;
      }
      notificationPayload.isUserActionRequired = true;
      return notificationPayload;
    }

    case INotificationTypes.MEETING_INVITE_INSIDE_THE_ROOM: {
      notificationPayload.redirectionLabel = 'Join Room';
      const { discussionTableId = '' } = refObject;
      notificationPayload.landingUrl = `/discussions?cur=${discussionTableId}&parentRef=${currentZone}`;
      return notificationPayload;
    }

    case INotificationTypes.LOCKED_ROOM_REQUEST_ACCEPTED: {
      notificationPayload.redirectionLabel = 'Join Room';
      const { discussionTableId = '' } = refObject;
      notificationPayload.landingUrl = `/discussions/${discussionTableId}`;
      return notificationPayload;
    }

    case INotificationTypes.LOCKED_ROOM_JOIN_REMINDER: {
      notificationPayload.redirectionLabel = 'Join Room';
      const { discussionTableId = '' } = refObject;
      notificationPayload.landingUrl = `/discussions/${discussionTableId}`;
      return notificationPayload;
    }

    case INotificationTypes.MEETING_REQUEST_ACCEPTED: {
      notificationPayload.landingUrl = '/discussions?drt=up';
      notificationPayload.redirectionLabel = 'View Meeting';
      return notificationPayload;
    }

    case INotificationTypes.MEETING_REQUEST_DECLINED: {
      notificationPayload.landingUrl = '/discussions?drt=pa';
      notificationPayload.redirectionLabel = 'View Meeting';
      notificationPayload.isActive = false;
      return notificationPayload;
    }

    case INotificationTypes.MEETING_ROOM_CREATE: {
      notificationPayload.landingUrl = '/discussions?drt=pe';
      return notificationPayload;
    }

    case INotificationTypes.MEETING_REMINDER: {
      const { discussionTableId = '' } = refObject;
      notificationPayload.landingUrl = `/discussions?cur=${discussionTableId}&parentRef=${currentZone}`;
      notificationPayload.redirectionLabel = 'View Meeting';
      return notificationPayload;
    }

    case INotificationTypes.MEETING_ROOM_INVITATIONS:
    case INotificationTypes.MEETING_ROOM_ONTIME_NOTIFICATION: {
      const { discussionTableId = '' } = refObject;
      notificationPayload.landingUrl = `/discussions?cur=${discussionTableId}&parentRef=${currentZone}`;
      return notificationPayload;
    }

    case INotificationTypes.ASKED_QUESTION_UP_VOTED: {
      const {
        questionId = '',
        channelRefType = '',
        channelRefId = '',
      } = refObject;
      notificationPayload.landingUrl = `/${getUriTextFromChannelRefType(
        channelRefType,
      )}/${channelRefId}?rt=q&rct=n&rid=${questionId}`;
      notificationPayload.redirectionLabel = 'Visit Stage';
      return notificationPayload;
    }

    case INotificationTypes.ROOM_ASKED_QUESTION_UP_VOTED: {
      notificationPayload.landingUrl = `/discussions/${refObject.channelRefId}`;
      notificationPayload.redirectionLabel = 'Visit Room';
      return notificationPayload;
    }

    case INotificationTypes.ASKED_QUESTION_ANSWERED: {
      const {
        questionId = '',
        channelRefType = '',
        channelRefId = '',
      } = refObject;
      notificationPayload.landingUrl = `/${getUriTextFromChannelRefType(
        channelRefType,
      )}/${channelRefId}?rt=q&rct=a&rid=${questionId}`;
      notificationPayload.redirectionLabel = 'Visit Stage';
      return notificationPayload;
    }

    case INotificationTypes.ROOM_ASKED_QUESTION_ANSWERED: {
      notificationPayload.landingUrl = `/discussions/${refObject.channelRefId}`;
      notificationPayload.redirectionLabel = 'Visit Room';
      return notificationPayload;
    }

    case INotificationTypes.BOOTH_JOINING_REQUEST_ACCEPTED: {
      return getBoothJoiningPayload(notificationPayload, refObject);
    }

    case INotificationTypes.BOOTH_JOINING_REQUEST_REJECTED: {
      notificationPayload.isActive = false;
      return getBoothJoiningPayload(notificationPayload, refObject);
    }

    case INotificationTypes.STAGE_JOINING_REQUEST_REJECTED: {
      notificationPayload.isActive = false;
      return getStageJoiningPayload(notificationPayload, refObject);
    }

    case INotificationTypes.STAGE_JOINING_REQUEST_ACCEPTED: {
      return getStageJoiningPayload(notificationPayload, refObject);
    }

    case INotificationTypes.SPEAKER_ROLL_CALL_BACKSTAGE: {
      const { stageId } = refObject;
      notificationPayload.stageId = stageId;
      return notificationPayload;
    }

    case INotificationTypes.CONNECTION_REQUEST:
      notificationPayload.landingUrl = '/briefcase';
      return notificationPayload;

    default:
      return notificationPayload;
  }
};

export const getBoothJoiningPayload = (
  notificationPayload: INotificationPayload,
  refObject,
) => {
  const { channelRefType = '', channelRefId = '' } = refObject;
  notificationPayload.redirectionLabel = 'Go to Booth';
  notificationPayload.landingUrl = `/${getUriTextFromChannelRefType(
    channelRefType,
  )}/${channelRefId}?rt=r`;
  return notificationPayload;
};

export const getStageJoiningPayload = (
  notificationPayload: INotificationPayload,
  refObject,
) => {
  const { channelRefType = '', channelRefId = '' } = refObject;
  notificationPayload.redirectionLabel = 'Go to Stage';
  notificationPayload.landingUrl = `/${getUriTextFromChannelRefType(
    channelRefType,
  )}/${channelRefId}?rt=r`;
  return notificationPayload;
};

export const getSimpleDate = date => {
  const startDateObj = new Date(date);
  const startDay = startDateObj.getDate();
  const startMonth = startDateObj.getMonth();
  const startYear = startDateObj.getFullYear();
  return `${startDay}-${monthList3Letter[startMonth]}-${startYear}`;
};

export const getImageUrlWithSize = (
  baseUrl,
  width,
  height,
  fit?,
  aspectRatio?,
) => {
  // if image url empty or match with prefix url only
  if (!baseUrl || baseUrl === IMAGE_URL) {
    return null;
  }
  const fileUrl = fileWithURL(baseUrl);
  // Replace with static url for GIFs uploaded from setup (without ext.)
  if (fileUrl?.toLowerCase().includes('image/gif')) {
    if (
      fileUrl.indexOf(STATIC_ZUDDL_URL) === -1 &&
      fileUrl.indexOf(IMAGE_URL) === -1
    ) {
      return `${STATIC_ZUDDL_URL}${fileUrl}`;
    }

    return fileUrl.indexOf(STATIC_ZUDDL_URL) === -1
      ? fileUrl.replace(IMAGE_URL, STATIC_ZUDDL_URL)
      : fileUrl;
  }
  // size ignored if image if gif
  if (fileUrl?.toLowerCase().endsWith('.gif')) {
    return fileUrl;
  }
  // size ignored if width or height is 0 or undefined or empty string.
  if (width && height) {
    return `${fileUrl}?w=${width}&h=${height}${fit ? `&fit=${fit}` : ''}${
      aspectRatio ? `&ar=${aspectRatio}` : ''
    }`;
  }
  if (width && !height) {
    return `${fileUrl}?w=${width}${fit ? `&fit=${fit}` : ''}`;
  }
  if (!width && height) {
    return `${fileUrl}?h=${height}${fit ? `&fit=${fit}` : ''}`;
  }
  return fileUrl;
};

export function getZoneSetUpObj(data) {
  let zoneSetupObj = {} as any;
  data.forEach(item => {
    switch (item.type) {
      case 'STAGE':
        Object.assign(zoneSetupObj, { stages: item.active });
        break;
      case 'TABLE':
        Object.assign(zoneSetupObj, { discussions: item.active });
        break;
      case 'EXPO':
        Object.assign(zoneSetupObj, { expo: item.active });
        break;
      case 'LOBBY':
        Object.assign(zoneSetupObj, { lobby: item.active });
        break;
      case 'NETWORKING':
        Object.assign(zoneSetupObj, { networking: item.active });
        break;
      case 'SCHEDULE':
        Object.assign(zoneSetupObj, { schedule: item.active });
        break;
    }
  });
  return zoneSetupObj;
}

/**
 *  Construct release string from GIT env vars
 *  These vars would be set in the .env file on the deployment server
 *  See .env.example file for more info
 */
export function gitReleaseHash() {
  if (process.env.GIT_BRANCH && process.env.GIT_COMMIT) {
    return process.env.GIT_BRANCH + '.' + process.env.GIT_COMMIT;
  }
  return undefined;
}

export function getVideoMuxUrl(muxPlaybackId) {
  if (!muxPlaybackId) return;
  return 'https://stream.mux.com/' + muxPlaybackId + '.m3u8';
}

export function getMuxVideoThumbnailUrl(videoFileName) {
  return (
    'https://image.mux.com/' +
    videoFileName.replace('https://stream.mux.com/', '').replace('.m3u8', '') +
    '/thumbnail.png?height=200&time=0&fit_mode=crop&width=307'
  );
}

export const getFullDate = date => {
  const startDateObj = new Date(date);
  const startDay = startDateObj.getDate();
  const startMonth = startDateObj.getMonth();
  const startYear = startDateObj.getFullYear();
  return `${dayList[startDateObj.getDay()]}, ${startDay} ${
    monthListFull[startMonth]
  } ${startYear}`;
};

export const getTime = date => {
  const startDateObj = new Date(date);
  const [startHours, startMinutes, startTimeOfDay] = getTimeComponents(
    startDateObj,
  );
  return `${startHours}:${startMinutes} ${startTimeOfDay}`;
};

export function useDebounced(callback, delay) {
  const debounced = useCallback(
    debounce((...args) => callback(...args), delay),
    [delay],
  );
  return debounced;
}

export function setOnboardingTour(introObject) {
  try {
    if (!introObject) return;
    var _introItems = introObject._introItems;
    if (_introItems.length === 0) {
      introObject.exit(true);
      return;
    }
    for (let a = 0; a < _introItems.length; a++) {
      if (!_introItems[a].element.id) {
        introObject.exit(true);
        return;
      }
    }
  } catch (e) {
    console.error('setOnboardingTour err > ', e);
  }
}

export const getFullDateForRecording = date => {
  const startDateObj = new Date(date);
  const startDay = startDateObj.getDate();
  const startMonth = startDateObj.getMonth();
  return `${dayListFull[startDateObj.getDay()]}, ${
    monthListFull[startMonth]
  } ${startDay}`;
};

const DATE_TIME_STRING_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const parseDate = date => {
  if (typeof date === 'string') {
    return parse(date, "yyyy-MM-dd'T'HH:mm:ssXXX", Date.now());
  }
  return date;
};

export const combineDateAndTime = (dateValueString, timeValueString) => {
  const dateObject = parseDate(dateValueString);
  const timeObject = parseDate(timeValueString);

  let combinedDate = setHours(dateObject, getHours(timeObject));
  combinedDate = setMinutes(combinedDate, getMinutes(timeObject));
  combinedDate = setSeconds(combinedDate, 0);
  return format(combinedDate, DATE_TIME_STRING_FORMAT);
};

/**
 * adding event listener to disable right click
 */
export const disableContextMenuClick: any = () => {
  window.oncontextmenu = (e: any) => e.preventDefault();
};

/**
 * adding event listener to enable right click
 */
export const enableContextMenuClick: any = () => {
  window.oncontextmenu = null;
};

/**
 * checking if the given string is a valid URL
 */
export const isValidURL = (url: string): boolean => {
  var pattern = new RegExp(
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
  );
  return !!pattern.test(url);
};

export const fileWithURL = file => {
  if (file) {
    if (file === `${IMAGE_URL}null` || file === IMAGE_URL) {
      return '';
    }
    // Replace with static url for GIFs uploaded from setup (without ext.)
    if (file?.includes('image/gif')) {
      if (
        file.indexOf(STATIC_ZUDDL_URL) === -1 &&
        file.indexOf(IMAGE_URL) === -1
      ) {
        return `${STATIC_ZUDDL_URL}${file}`;
      }

      return file.indexOf(STATIC_ZUDDL_URL) !== -1
        ? file
        : file.replace(IMAGE_URL, STATIC_ZUDDL_URL);
    }
    if (file.indexOf('phoenixlive') !== -1) {
      return file;
    }
    if (file.indexOf('stream') !== -1) {
      return file;
    }
    if (isValidURL(file)) {
      return file;
    }
    return `${IMAGE_URL}${file}`;
  }
  return '';
};

export const getNotificationTime = (date: Date): string => {
  const timeDiff = moment(date).fromNow(true);
  const timeDiffWords = timeDiff.split(' ');
  let notificationTime = timeDiffWords[0];
  if (timeDiffWords[0] === 'a' || timeDiffWords[0] === 'an') {
    notificationTime = '1';
  }
  const timeUnit = timeDiffWords[timeDiffWords.length - 1];
  return notificationTime + timeUnit[0];
};

export const notificationMapper = (element, eventId) => {
  let payload = getNotificationPayload(element);
  payload.notificationId = element.notificationId;
  payload.isActive = true;
  payload.landingUrl =
    payload.landingUrl && '/l/event/' + eventId + payload.landingUrl;
  payload.createdAt = element.updatedAt;
  payload.id = element.notificationId;
  return payload;
};

const getOrdinal = value => {
  if (value > 3 && value < 21) return 'th';
  switch (value % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getDateWithDayDateMonth = (date: Date) => {
  const meetingUtcTime = moment.utc(date).toDate();
  const localDateTime = moment(meetingUtcTime).local().toDate();
  const dateValue = localDateTime.getDate();
  const monthValue = localDateTime.getMonth();
  return `${dayListFull[localDateTime.getDay()]}, ${dateValue}${getOrdinal(
    dateValue,
  )} ${monthListFull[monthValue]}`;
};

export const getDateWithDayDateMonthYear = (date: Date) => {
  const meetingUtcTime = moment.utc(date).toDate();
  const localDateTime = moment(meetingUtcTime).local().toDate();
  const dateValue = localDateTime.getDate();
  const monthValue = localDateTime.getMonth();
  const yearValue = localDateTime.getFullYear();
  return `${dayListFull[localDateTime.getDay()]}, ${dateValue}${getOrdinal(
    dateValue,
  )} ${monthListFull[monthValue]} ${yearValue}`;
};

export const datesAreOnSameDay = (first: Date, second: Date) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

export const uploadFileToS3 = async (s3Url: string, file: any) => {
  const s3UploadResponse = await api.event.uploadFileToS3(s3Url, file);
  return s3UploadResponse;
};

export const isLiveEventOrganizer = role => {
  if (
    role === IEventRoleOptions.ORGANIZER ||
    role === IEventRoleOptions.MODERATOR
  ) {
    return true;
  } else {
    return false;
  }
};

export const secondsToHms = d => {
  d = Number(d);
  if (Number.isNaN(d)) {
    return '00:00';
  }
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  const hDisplay = h > 9 ? `${h}:` : `0${h}:`;
  const mDisplay = m > 9 ? `${m}:` : `0${m}:`;
  const sDisplay = s > 9 ? `${s}` : `0${s}`;
  if (hDisplay !== '00:') {
    return hDisplay + mDisplay + sDisplay;
  }
  return mDisplay + sDisplay;
};

export const createEventDefaultValues = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const eventStartTime = set(new Date(), {
    hours: 9,
    minutes: 0,
    seconds: 0,
  });
  const eventEndTime = set(new Date(), {
    hours: 18,
    minutes: 0,
    seconds: 0,
  });
  const defaultValues = {
    [EVENT_FORM_FIELDS.START_DATE_TIME]: format(
      eventStartTime,
      DATE_TIME_STRING_FORMAT,
    ),
    [EVENT_FORM_FIELDS.END_DATE_TIME]: format(
      eventEndTime,
      DATE_TIME_STRING_FORMAT,
    ),
    [EVENT_FORM_FIELDS.TIMEZONE]:
      userTimeZone === 'Asia/Calcutta' ? 'Asia/Kolkata' : userTimeZone,
    [EVENT_FORM_FIELDS.PRIMARY_COLOR]: '#FF532D',
    [EVENT_FORM_FIELDS.SECONDARY_COLOR]: '#FFFFFF',
    [EVENT_FORM_FIELDS.NAVBAR_COLOR]: '#131313',
    [EVENT_FORM_FIELDS.NAVBAR_PRIMARY_COLOR]: '#FF532D',
    [EVENT_FORM_FIELDS.NAVBAR_SECONDARY_COLOR]: '#727272',
  };
  return defaultValues;
};

export const PinnedMessagesContext = React.createContext({
  pinnedContainerHeight: 0,
  setPinnedContainerHeight: null,
});

export const convertInAnotherTimeZone = (date, timeZone) => {
  return new Date(
    new Date(date).toLocaleString('en-US', { timeZone: timeZone }),
  );
};

export const getGMTValueFromTimeZone = (tz: string) => {
  const convertedDate = convertInAnotherTimeZone(new Date(), tz);
  const dateStr = Intl.DateTimeFormat('en-US', {
    timeZone: tz,
    dateStyle: 'full',
    timeStyle: 'long',
  }).format(convertedDate);
  return dateStr.split(' ').at(-1);
};

export const getEventDays = (startDateTime, endDateTime) => {
  const startDate = Date.parse(startDateTime);
  const endDate = Date.parse(endDateTime);
  const dateOptions = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });
  const options = [] as any;
  let dayNumber = 1;
  dateOptions.forEach(dateObject => {
    options.push({
      date: dateObject,
      dayNumber: dayNumber,
    });
    dayNumber += 1;
  });

  return options;
};

export const isStringNullOrEmpty = (value: string) => {
  if (!value || value.trim().length === 0) {
    return true;
  }
  return false;
};

export const getCouponExpiryDate = (date: Date) => {
  const meetingUtcTime = moment.utc(date).toDate();
  const localDateTime = moment(meetingUtcTime).local().toDate();
  const dateValue = localDateTime.getDate();
  const year = localDateTime.getFullYear();
  return `${dateValue}${getOrdinal(dateValue)} ${
    monthList3Letter[localDateTime.getMonth()]
  } ${year}`;
};

/**
 * Reference from https://stackoverflow.com/a/21648508/5815636
 * @param hex
 * @returns rgb in array
 */
export const hexToRgb = hex => hexRgb(hex, { format: 'array' });
export const getTint = (hex: string, percent: number) => {
  const rgb = hexToRgb(hex);
  const derived = rgb
    .slice(0, 3)
    .map(value => Number(value) + Math.round((255 - Number(value)) * percent));
  if (rgb && rgb[3]) {
    derived.push(rgb[3]);
  }
  return derived;
};

export const getTintRgb = (hex: string, percent: number) => {
  const rgbtint = getTint(hex, percent);
  return `rgba(${rgbtint})`;
};
export const getShade = (hex: string, percent: number) => {
  const rgb = hexToRgb(hex);
  const derived = rgb
    .slice(0, 3)
    .map(value => Math.round(Number(value) * percent));
  if (rgb && rgb[3]) {
    derived.push(rgb[3]);
  }
  return derived;
};

export const getShadeRgb = (hex: string, percent: number) => {
  const rgbshade = getShade(hex, percent);
  return `rgba(${rgbshade})`;
};

const getforeGColor = cols => {
  const color = Math.round(
    (parseInt(cols[0]) * 299 +
      parseInt(cols[1]) * 587 +
      parseInt(cols[2]) * 114) /
      1000,
  );
  return color > 125 ? [0, 0, 0] : [255, 255, 255];
};

export const getforeGColorRgb = cols => {
  const fontColor = getforeGColor(cols);
  return `rgb(${fontColor.join(', ')})`;
};

export const getUserAgent = () => {
  try {
    return window.navigator.userAgent;
  } catch (error) {
    return 'UNKNOWN';
  }
};

const componentToHex = c => {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
};

const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const getForeGroundColor = hex => {
  const rgbBackgrounColor = getShade(hex, 0.6);
  const [r, g, b] = getforeGColor(rgbBackgrounColor);
  return rgbToHex(r, g, b);
};

export const isLiveEventUrlValid = currentUrl => {
  const match = matchPath(currentUrl, {
    path: [
      '/l/event/:eventId/lobby',
      '/l/event/:eventId/stages/:stageId/backstage',
      '/l/event/:eventId/stages',
      '/l/event/:eventId/stages/:stageId',
      '/l/event/:eventId/schedule',
      '/l/event/:eventId/resources',
      '/l/event/:eventId/briefcase',
      '/l/event/:eventId/sponsors',
      '/l/event/:eventId/discussions',
      '/l/event/:eventId/discussions/:discussionTableId',
      '/l/r/event/:eventId/discussions/:discussionTableId',
      '/l/event/:eventId/discussions/zone/:tableZoneId',
      '/l/event/:eventId/quick-networking',
      '/l/event/:eventId/networking',
      '/l/event/:eventId/expo',
      '/l/event/:eventId/expo/zone/:boothZoneId',
      '/l/event/:eventId/booth/:boothId',
      '/l/event/:eventId/photobooth',
      '/l/event/:eventId/profile',
      '/l/r/event/:eventId/stages/:stageId',
      '/l/a/r/event/:eventId/stages/:stageId',
      '/l/event/:eventId/stages/:stageId/gallery',
      '/l/event/:eventId/stages/:stageId/slot/:slotNumber/av',
      '/l/event/:eventId/stages/:stageId/slot/:slotNumber',
      '/l/event/:eventId',
    ],
    exact: true,
    strict: false,
  });
  if (match) {
    return true;
  }
  return false;
};

export const getBackstageToStageAVStatus = (audioOn, videoOn, name) => {
  const isOrganizer = name === 'yourself';
  let title = `Send ${name} on stage?`;
  let description = `${
    isOrganizer ? 'Go onstage' : 'Send them on stage'
  } anyway?`;

  if (audioOn && videoOn) {
    title = `Send ${name} on stage?`;
    description = `Are you sure you want to send ${name} on stage?`;
  } else if (!audioOn && !videoOn) {
    title = `${
      isOrganizer ? 'Your' : `${name}'s`
    } camera and mic are turned off`;
  } else if (!audioOn) {
    title = `${isOrganizer ? 'Your' : `${name}'s`} mic is turned off`;
  } else if (!videoOn) {
    title = `${isOrganizer ? 'Your' : `${name}'s`} camera is turned off`;
  }

  return {
    title,
    description,
  };
};

function ordinal_suffix_of(i) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export const getEventDatesForTicketingMapping = event => {
  if (!event) {
    return '';
  }

  if (Object.keys(event).length === 0) {
    return '';
  }

  const { startDateTime, endDateTime } = event;
  const startDateObj = new Date(
    new Date(startDateTime).toLocaleString('en-US', { timeZone: event.tz }),
  );
  const endDateObj = new Date(
    new Date(endDateTime).toLocaleString('en-US', { timeZone: event.tz }),
  );

  const startDay = startDateObj.getDate();
  const startMonth = startDateObj.getMonth();
  const startYear = startDateObj.getFullYear();

  const endDay = endDateObj.getDate();
  const endMonth = endDateObj.getMonth();
  const endYear = endDateObj.getFullYear();

  if (startDay === endDay && startMonth === endMonth && startYear === endYear) {
    return `${ordinal_suffix_of(startDay)} ${
      monthList3Letter[startMonth]
    } ${startYear}`;
  }
  const stDate = `${ordinal_suffix_of(startDay)} ${
    monthList3Letter[startMonth]
  } - `;
  const endDate = `${ordinal_suffix_of(endDay)} ${
    monthList3Letter[endMonth]
  } ${endYear}`;
  return `${stDate}${endDate}`;
};

export const addOpacity = (color: string, opacity: number) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const ScheduleMeetingRoomContext = React.createContext({
  isMyMeetingRoomsModalView: false,
  setMyMeetingRoomsModal: false,
});

export const getUtmJsonFromUrl = () => {
  const utmParams = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_term',
    'utm_content',
    'utm_id',
  ];
  try {
    let url = window.location.href as string | null;
    try {
      // this case is only needed for pay by check flow
      if (window.sessionStorage.getItem('vivenu_parent_page_url')) {
        url = window.sessionStorage.getItem('vivenu_parent_page_url');
      }
    } catch (e) {
      console.error(e);
    }
    const querystring = url?.split('?') as any;
    const utmObject = {};
    if (querystring?.length > 1) {
      const pairs = querystring[1].split('&');
      for (let i = 0; i < pairs.length; i += 1) {
        const keyValue = pairs[i].split('=');
        if (utmParams.includes(keyValue[0])) {
          utmObject[keyValue[0]] = decodeURIComponent(keyValue[1]);
        }
      }
    }
    if (Object.keys(utmObject).length === 0) {
      return null;
    }
    return JSON.stringify(utmObject);
  } catch (err) {
    console.error('getUtmJsonFromUrl error---', err);
    return null;
  }
};

export const getRichTextFromHtmlString = (text: any) => {
  if (!text) return '';
  return Parser(text);
};

export const isValidImage = (imgUrl: string | null) => {
  if (!imgUrl) return false;

  const stripUrl = stripImgUrl(imgUrl);
  const url = removeQueryParams(stripUrl).trim();

  if (url && url.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getSrcSetByImgHeight = (url: string, height: string) => {
  if (!url) {
    return url;
  }

  const [baseUrl, search] = url?.split('?');
  const fractionalHeights = [0.9, 0.8, 0.7, 0.6, 0.5];
  const searchParams = new URLSearchParams(search);

  return fractionalHeights
    .map(fHeight => {
      searchParams.set('h', (fHeight * Number(height)).toString());
      return `${baseUrl}?${searchParams.toString()}`;
    })
    .join(',');
};

export const getSrcSetForLobbyMobileView = (url: string) => {
  if (!url) {
    return url;
  }
  const dprs = ['1 1x', '2 2x', '3 3x'];
  return dprs.map(dpr => `${url}&dpr=${dpr}`).join(',');
};

export const prefixPicUrlIfNeeded = (picUrl: string) => {
  if (!picUrl) {
    return '';
  }
  // Replace with static url for GIFs uploaded from setup (without ext.)
  if (picUrl.toLowerCase().includes('image/gif')) {
    if (
      picUrl.indexOf(STATIC_ZUDDL_URL) === -1 &&
      picUrl.indexOf(IMAGE_URL) === -1
    ) {
      return `${STATIC_ZUDDL_URL}${picUrl}`;
    }
    return picUrl.indexOf(STATIC_ZUDDL_URL) !== -1
      ? picUrl
      : picUrl.replace(IMAGE_URL, STATIC_ZUDDL_URL);
  }
  if (picUrl.startsWith(IMAGE_URL)) {
    return picUrl;
  }

  return `${IMAGE_URL}${picUrl}`;
};

export const validateEmailForRegistration = (
  email: string,
  restrictedEmailDomainsList: string[] | null,
  emailRestrictionType: 'NONE' | 'DENY' | 'ALLOW',
) => {
  if (!email) {
    return 'This field is required';
  }
  if (email.trim().length === 0 || email.trim().indexOf('@') < 0) {
    return 'This field is required';
  }
  if (
    emailRestrictionType &&
    emailRestrictionType !== 'NONE' &&
    restrictedEmailDomainsList
  ) {
    const enteredEmail = email.trim().toLowerCase();
    const emailDomain = enteredEmail.substring(enteredEmail.indexOf('@') + 1);
    if (
      emailRestrictionType === 'DENY' &&
      restrictedEmailDomainsList.includes(emailDomain)
    ) {
      return 'This email domain is restricted. Try a different email.';
    }
    if (
      emailRestrictionType === 'ALLOW' &&
      !restrictedEmailDomainsList.includes(emailDomain)
    ) {
      return 'This email domain is restricted. Try a different email.';
    }
  }
  return null;
};

export const isEventCompleted = eventDetails => {
  if (!eventDetails?.endDateTime) {
    return false;
  }
  const endDateTimeInEventTz = convertInAnotherTimeZone(
    eventDetails?.endDateTime,
    eventDetails?.tz,
  );
  const currentTime = convertInAnotherTimeZone(new Date(), eventDetails?.tz);
  return currentTime.getTime() >= endDateTimeInEventTz.getTime();
};

export const scrollToUnfilledFields = (scrollFieldDetails: any) => {
  const {
    errors,
    formFieldRef,
    registrationFieldRef,
    mandatoryDisclaimerErrors,
    disclaimerRef,
  } = scrollFieldDetails;

  if (Object.keys(errors).length > 0) {
    formFieldRef.current &&
      formFieldRef.current.scrollIntoView({ behavior: 'smooth' });
  } else if (registrationFieldRef) {
    registrationFieldRef.current &&
      registrationFieldRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  } else if (!!Object.keys(mandatoryDisclaimerErrors).length) {
    disclaimerRef.current &&
      disclaimerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
  }
};

export const removeAsteriskFromLabel = (label: string) =>
  label.replace('*', '').trim();

export const getIsCookieEnabled = () => {
  try {
    let isCookieEnabled = window.navigator.cookieEnabled ? true : false;
    if (
      typeof window.navigator.cookieEnabled == 'undefined' &&
      !isCookieEnabled
    ) {
      document.cookie = 'zuddlTestCookie';
      isCookieEnabled =
        document.cookie.indexOf('zuddlTestCookie') != -1 ? true : false;
    }
    return isCookieEnabled;
  } catch (error) {
    console.error('getIsCookieEnabled----', error);
  }
  return true; //default enabled
};

export const isRegistrationInActive = (
  errMessage: string | null | undefined,
) => {
  if (!errMessage) {
    return false;
  }
  return (
    errMessage === 'REGISTRATION_PENDING' ||
    errMessage === 'REGISTRATION_REJECTED' ||
    errMessage === 'REGISTRATION_BLOCKED' ||
    errMessage === 'REGISTRATION_DECLINED' ||
    errMessage === 'EMAIL_RESTRICTED'
  );
};

export const determineEventStatus = ({
  startDateTime,
  endDateTime,
  status,
}: {
  startDateTime: string;
  endDateTime: string;
  status: string;
}) => {
  if (status && status !== 'PUBLISHED') {
    return status;
  }
  // PUBLISHED : have separate status based on event timings.
  const now = new Date();
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);

  if (now > start && now < end) {
    return 'ONGOING';
  }
  if (now > end) {
    return 'COMPLETED';
  }
  if (now < start) {
    return 'UPCOMING';
  }

  return 'UPCOMING';
};

export const extractDayAndTimeStrings = dateTime => {
  const datetimeInCurrentTz = new Date(dateTime);

  const timeString24H = format(datetimeInCurrentTz, 'HH:mm');
  const timeString = format(datetimeInCurrentTz, 'hh:mm a');
  const dateString = format(datetimeInCurrentTz, 'ccc, eo LLL');

  return {
    timeString,
    timeString24H,
    dateString,
  };
};

export const checkIfOverflowing = (element) => {
  return element ? element.scrollWidth > element.clientWidth 
  : false;
}
