import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useOrganizationQuery } from '@/api/organization';
import { isCustomDomain } from '@/utils/zuddlUrls';

export function RedirectWithOrganizationIdentifier({
  refParamName,
  refType,
  children,
}) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const baseName = history.createHref({ pathname: '/' });
  const idParamValue = params[refParamName];
  const shouldDoRedirect = baseName === '/' && !isCustomDomain;

  const {
    data: organizationBase,
    isLoading: isOrganizationBaseLoading,
    isSuccess: isOrganizationBaseSuccess,
  } = useOrganizationQuery.getOrganizationBaseByRef(idParamValue, refType, {
    enabled: shouldDoRedirect,
  });

  useEffect(() => {
    if (!organizationBase || isOrganizationBaseLoading) {
      return;
    }
    if (isOrganizationBaseSuccess && organizationBase?.identifier) {
      window.location.href = `${window.location.origin}/${organizationBase?.identifier}${location.pathname}${location.search}`;
    }
  }, [
    organizationBase,
    isOrganizationBaseLoading,
    isOrganizationBaseSuccess,
    location,
  ]);

  if (shouldDoRedirect) {
    return null; /* or show loader? */
  }
  return children;
}
