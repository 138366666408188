import { put } from 'redux-saga/effects';
import produce from 'immer';
import { IQuestionIntialState } from './types';

const initialState = {
  upvote: {
    questionId: '',
  },
  askedQuestion: {
    questionId: '',
  },
} as IQuestionIntialState;

export default {
  namespace: 'question',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    setUpvotedQuestion: (state, { data }) =>
      produce(state, draft => {
        draft.upvote = data;
      }),
    setAskedQuestion: (state, { data }) =>
      produce(state, draft => {
        draft.askedQuestion = data;
      }),
  },
  effects: {
    *toggleQuestionUpvote({ payload }) {
      yield put({
        type: 'question/setUpvotedQuestion',
        payload: { data: payload },
      });
    },
    *setNewlyAskedQuestion({ payload }) {
      yield put({
        type: 'question/setAskedQuestion',
        payload: { data: payload },
      });
    },
  },
};

export const selectUpvotedQuestionId = state => {
  const { question } = state;
  return question?.upvote ?? {};
};

export const selectAskedQuestionId = state => {
  const { question } = state;
  return question?.askedQuestion ?? {};
};
