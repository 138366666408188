import React, { useMemo } from 'react';
import shortid from 'shortid';
import classnames from 'classnames';
// components
import Loader from '@/components/ui/Loader';
import Text, { ITextStyles, ITextColors } from '@/components/ui/content/Text';
// types
import { IButtonProps, IButtonTypes, IButtonSize } from './types';
import { IContentTextMarginTypes } from '@/types';
// styles
import styles from './styles.module.scss';

const Button = (props: IButtonProps) => {
  const {
    children,
    disabled,
    label = '',
    loading,
    onBlur,
    onClick,
    styleClass = '',
    className,
    size = IButtonSize.REGULAR,
    margin = IContentTextMarginTypes.NO_MARGIN,
    type = 'button',
    textStyle,
    style,
    reverseChildren = false,
    hasHover = false,
    id,
    ref,
    tooltipFor,
    tooltipProps,
    dataTestId,
    tabIndex = 0,
  } = props;

  // Set default button label text to CAPITALIZED if a style is not defined
  const buttonTextStyle =
    textStyle === undefined ? ITextStyles.DEFAULT : textStyle;

  const buttonId = useMemo(() => {
    return shortid.generate();
  }, []);

  const composeClassName = classnames(className, `${styles[styleClass]}`, {
    [styles.disabled]: disabled,
    [styles.buttonSmall]: size === IButtonSize.SMALL,
    [styles[margin]]: margin,
    [styles.reverseRow]: reverseChildren,
    [styles.animateOnHover]: hasHover,
  });

  if (children) {
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        tabIndex={tabIndex}
        ref={ref}
        id={id || buttonId}
        title={label}
        aria-label={label}
        className={composeClassName}
        disabled={loading || disabled}
        onClick={onClick}
        onBlur={onBlur}
        type={type}
        style={style}
        data-for={tooltipFor}
        {...tooltipProps}
      >
        {loading ? <Loader /> : null}
        {children}
      </button>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      tabIndex={tabIndex}
      ref={ref}
      id={id || buttonId}
      title={label}
      aria-label={label}
      className={composeClassName}
      disabled={loading || disabled}
      onBlur={onBlur}
      onClick={onClick}
      type={type}
      style={style}
      data-for={tooltipFor}
      {...tooltipProps}
      data-testid={dataTestId}
    >
      {loading ? <Loader /> : null}
      <Text
        textStyle={buttonTextStyle}
        text={label}
        textColor={ITextColors.INHERIT}
        readOnly={disabled}
        clickable={true}
      />
    </button>
  );
};

export default Button;
export { IButtonTypes, IButtonSize, IContentTextMarginTypes as IButtonMargins };
