import { put, delay } from 'redux-saga/effects';
import produce from 'immer';
import shortid from 'shortid';
//types
import { NotificationTypes } from './types';
import { createSelector } from 'reselect';
import { IGlobalIntialState } from '../global/types';

const initialState = {} as IGlobalIntialState;

const getInitRefState = () => {
  return {
    messages: [],
    privateChat: {
      hasNew: false
    },
    publicChat: {
      hasNew: false
    },
    polls: {
      hasNew: false
    },
    questions: {
      hasNew: false
    },
    raisedHands: {
      hasNew: false
    }
  };
};

export default {
  namespace: 'notifications',
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...initialState
      };
    },
    initMessageState: (state, { channelId }) =>
      produce(state, draft => {
        try {
          draft[channelId] = getInitRefState();
        } catch (e) {
          console.error('initMessageState ` > ', e);
        }
      }),
    clearChannelNotifications: (state, { channelId }) =>
      produce(state, draft => {
        try {
          delete draft[channelId];
        } catch (e) {
          console.error('clearChannelNotifications ` > ', e);
        }
      }),
    addNotification: (state, { channelId, key, notification }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications[key].hasNew = true;
          channelNotifications.messages = [];
          channelNotifications.messages.push(notification);
        }
      } catch (e) {
        console.error('addNotification ` > ', e);
      }
    }),
    loadPolls: (state, { channelId }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.polls.hasNew = true;
        }
      } catch (e) {
        console.error('loadPolls ` > ', e);
      }
    }),
    addPrivateChat: (state, { channelId }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.privateChat.hasNew = true;
        }
      } catch (e) {
        console.error('addPrivateChat ` > ', e);
      }
    }),
    addPublicChat: (state, { channelId }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.publicChat.hasNew = true;
        }
      } catch (e) {
        console.error('addPublicChat ` > ', e);
      }
    }),
    newQuestionNotification: (state, { channelId }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.questions.hasNew = true;
        }
      } catch (e) {
        console.error('newQuestionNotification ` > ', e);
      }
    }),
    clearNotification: (state, { channelId, uid }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.messages = channelNotifications.messages.filter(message => message.uid !== uid);
        }
      } catch (e) {
        console.error('clearNotification ` > ', e);
      }
    }),
    answeringQuestion: (state, { channelId, question }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.messages = [];
          channelNotifications.messages.push({
            type: NotificationTypes.ANSWERING_QUESTION,
            question
          });
        }
      } catch (e) {
        console.error('answeringQuestion ` > ', e);
      }
    }),
    clearAnsweringQuestion: (state, { channelId, questionId }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.messages = channelNotifications.messages.filter(message => {
            return !(message.type === NotificationTypes.ANSWERING_QUESTION &&
              message.question.questionId === questionId);
          });
        }
      } catch (e) {
        console.error('clearAnsweringQuestion ` > ', e);
      }
    }),
    answeredQuestion: (state, { channelId, question }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.messages = [];
          channelNotifications.messages.push({
            type: NotificationTypes.ANSWERED_QUESTION,
            question
          });
        }
      } catch (e) {
        console.error('answeredQuestion ` > ', e);
      }
    }),
    clearAnsweredQuestion: (state, { channelId, questionId }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        if (channelNotifications) {
          channelNotifications.messages = channelNotifications.messages.filter(message => {
            return !(message.type === NotificationTypes.ANSWERED_QUESTION &&
              message.question.questionId === questionId);
          });
        }
      } catch (e) {
        console.error('clearAnsweredQuestion ` > ', e);
      }
    }),
    // ==================================================================
    clearTabNotifications: (state, { channelId, icon }) => produce(state, draft => {
      try {
        const channelNotifications = draft[channelId];
        switch (icon) {
          case 'ZONE_POLL':
            channelNotifications.polls && (channelNotifications.polls.hasNew = false);
            channelNotifications.messages = channelNotifications.messages.filter(n => n.type !== NotificationTypes.NEW_POLL);
            break;
          case 'ZONE_QNA':
            channelNotifications.questions && (channelNotifications.questions.hasNew = false);
            break;
          case 'ZONE_CHAT':
            channelNotifications.privateChat && (channelNotifications.privateChat.hasNew = false);
            channelNotifications.publicChat && (channelNotifications.publicChat.hasNew = false);
            break;
          case 'ZONE_FILES':
            channelNotifications.files && (channelNotifications.files.hasNew = false);
            break;
          case 'ZONE_RAISED_HANDS':
            channelNotifications.raisedHands && (channelNotifications.raisedHands.hasNew = false);
            channelNotifications.messages = channelNotifications.messages.filter(n => n.type !== NotificationTypes.NEW_POLL);
            break;
        }
      } catch (e) {
        console.error('clearTabNotifications ` > ', e);
      }
    }),
  },
  effects: {
    *newPollNotification({ payload: { channelId, poll } }) {
      try {
        console.log('VEDHA newPollNotification ', poll);
        const uid = shortid.generate();
        yield put({
          type: 'notifications/addNotification',
          payload: {
            channelId,
            key: 'polls',
            notification: {
              uid,
              type: NotificationTypes.NEW_POLL,
              poll
            }
          }
        });
        yield delay(30000);
        yield put({
          type: 'notifications/clearNotification',
          payload: {
            channelId,
            uid
          }
        });
      } catch (e) {
        console.error('newPollNotification ` > ', e);
      }
    },
    // *newRaisedHandNotification({ payload }) {
    //   // add to state
    //   // wait some time
    //   // remove from state
    // },
  },
};

const selectNotifications = (state) => state.notifications || {};
const makeSelectNotificationsByChannelId = () => createSelector(
  selectNotifications,
  (_, channelId: number) => channelId,
  (notificationsMap, channelId) => notificationsMap[channelId] || {}
);

export { makeSelectNotificationsByChannelId };
