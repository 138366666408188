import axios from './axios';
import { SERVICE_URL } from '@/config';
import { VerifySuccess } from 'cotter/lib/binder';
import { IGuestAuthPayload } from '@/models/account/types';
import { getUserAgent } from '@/utils/helpers';

const BASE_URL = `${SERVICE_URL}/account`;
const PASSWORD_URL = `${BASE_URL}/password`;
export default {
  login_landingPage(
    user: { email: any; password: any; firstName?: any },
    eventId: string | undefined,
    isAttendeeRegistration: boolean | false,
  ) {
    return axios.post(
      `${BASE_URL}/login${!eventId ? '' : `?eventId=${eventId}`}${
        !isAttendeeRegistration
          ? ''
          : `&isAttendeeRegistration=${isAttendeeRegistration}`
      }`,
      user,
    );
  },
  loginAsGuest(
    user: { email: any; firstName?: any },
    eventId: string | undefined,
  ) {
    return axios.post(
      `${BASE_URL}/login/guest${!eventId ? '' : `?eventId=${eventId}`}`,
      user,
    );
  },
  checkExistsByEmail(email: string) {
    return axios.get(`${BASE_URL}/exists`, {
      params: {
        email,
      },
    });
  },
  checkExistsByEmail_v2(email: string) {
    return axios.get(`${BASE_URL}/exists/v2`, {
      params: {
        email,
      },
    });
  },
  checkAccountLoginType(email: string) {
    return axios.post(
      `${BASE_URL}/login/t?email=${email.trim().toLowerCase()}`,
    );
  },
  getUserData() {
    return axios.get(`${BASE_URL}/details`);
  },
  getProfileIndustries() {
    return axios.get(`${BASE_URL}/profile/industries`);
  },
  updateProfile(accountDetails) {
    console.log('updateProfile > accountDetails', accountDetails);
    return axios.post(`${BASE_URL}`, accountDetails);
  },
  changePassword(passwordData) {
    console.log('passwordData', passwordData);
    return axios.post(`${PASSWORD_URL}/change`, passwordData);
  },
  resetPassword(newPassword) {
    return axios.post(`${PASSWORD_URL}/reset`, newPassword);
  },
  validateResetPasswordRequest(resetRequestId) {
    return axios.get(
      `${PASSWORD_URL}/reset/validate?resetRequestId=${resetRequestId}`,
    );
  },
  resetPasswordRequest(email) {
    return axios.post(`${PASSWORD_URL}/reset/request`, email);
  },
  getUserFlows() {
    return axios.get(`${BASE_URL}/user_flow`);
  },
  updateUserFlows(flowProgress) {
    return axios.post(`${BASE_URL}/user_flow`, flowProgress);
  },
  googleLoginV2(
    code: string,
    eventId: string,
    organizationId: string,
    isAttendeeRegistration: boolean,
  ) {
    return axios.post(`${SERVICE_URL}/auth/google/v2`, undefined, {
      params: {
        code: code,
        clientType: 'WEB',
        isAttendeeRegistration: isAttendeeRegistration,
        eventId: eventId,
        organizationId: organizationId,
      },
    });
  },
  facebookLogin_landingPage(
    authJson: any,
    eventId: string,
    organizationId: string,
    isAttendeeRegistration: Boolean,
  ) {
    return axios.post(
      `${SERVICE_URL}/auth/facebook?${
        !eventId ? '' : `&eventId=${eventId.trim()}`
      }${
        !isAttendeeRegistration
          ? ''
          : `&isAttendeeRegistration=${isAttendeeRegistration}`
      }${!organizationId ? '' : `&organizationId=${organizationId.trim()}`}`,
      authJson,
    );
  },
  linkedInLogin_landingPage(
    code: string,
    eventId: string,
    organizationId: string,
    redirectUri: string,
    isAttendeeRegistration: Boolean,
  ) {
    return axios.get(
      `${SERVICE_URL}/auth/linkedin?code=${code}&redirectUri=${redirectUri}${
        !eventId ? '' : `&eventId=${eventId.trim()}`
      }${
        !isAttendeeRegistration
          ? ''
          : `&isAttendeeRegistration=${isAttendeeRegistration}`
      }${!organizationId ? '' : `&organizationId=${organizationId.trim()}`}`,
    );
  },
  verifyWorkOSCode(code, state) {
    return axios.get(
      `${SERVICE_URL}/sso/verify/?code=${code}${
        !state ? '' : `&state=${encodeURI(state)}`
      }`,
    );
  },
  verifyWorkOSCode_landingPage(code, state, isAttendeeRegistration) {
    return axios.get(
      `${SERVICE_URL}/sso/verify/?code=${code}${
        !state ? '' : `&state=${encodeURI(state)}`
      }${
        !isAttendeeRegistration
          ? ''
          : `&isAttendeeRegistration=${isAttendeeRegistration}`
      }`,
    );
  },
  getWorkOSConnectionsByDomain(domain: string) {
    return axios.get(`${SERVICE_URL}/sso/connections/?domain=${domain}`);
  },
  getWorkOSConnection(event_id: string, organizationId: string) {
    return axios.get(
      `${SERVICE_URL}/sso/connection/?event_id=${event_id}${
        !organizationId ? '' : `&organization_id=${organizationId}`
      }`,
    );
  },
  getWorkOSConnection_landingPage(
    event_id: string,
    organizationId: string,
    isAttendeeRegistration: Boolean,
  ) {
    return axios.get(
      `${SERVICE_URL}/sso/connection/?event_id=${event_id}${
        !organizationId ? '' : `&organization_id=${organizationId}`
      }${
        !isAttendeeRegistration
          ? ''
          : `&isAttendeeRegistration=${isAttendeeRegistration}`
      }`,
    );
  },
  createGuestAuthSession(payload: any, eventId: string) {
    return axios.post(`${SERVICE_URL}/auth/guest?eventId=${eventId}`, payload);
  },
  loginWithTicketCode(payload: any) {
    return axios.post(`${SERVICE_URL}/auth/ticket-code`, payload);
  },
  verifyCotterEmailExists(payload: any) {
    return axios.post(`${SERVICE_URL}/event/email`, payload);
  },
  verifyCotterCode(payload: VerifySuccess) {
    return axios.post(`${SERVICE_URL}/otp/verify`, payload);
  },
  verifyCotterCodeAndRegister(payload: VerifySuccess) {
    return axios.post(`${SERVICE_URL}/otp/verify/register`, payload);
  },
  verifyCotterCodeAndRegisterOtpLogin(
    isOtpLogin: boolean,
    payload: VerifySuccess,
  ) {
    return axios.post(
      `${SERVICE_URL}/otp/verify/register?isOtpLogin=${isOtpLogin}`,
      payload,
    );
  },
  profileView() {
    return axios.get(`${BASE_URL}/profile/view`);
  },
  profileUpdate(eventId: string, payload) {
    return axios.post(
      `${BASE_URL}/profile/update?event_id=${eventId}`,
      payload,
    );
  },
  profileUpdateNew(payload) {
    return axios.post(`${BASE_URL}/profile/update/new`, payload);
  },
  loginToken(token: string) {
    return axios.post(`${BASE_URL}/login/token`, token);
  },
  webviewLoginToken(token: string) {
    return axios.post(`${BASE_URL}/webview/login/token`, token);
  },
  renewToken() {
    return axios.post(`${BASE_URL}/token/renew`);
  },
  getStudioLoginToken() {
    return axios.get(`${BASE_URL}/token/studio`);
  },
  createNewUserSession(eventId: string) {
    return axios.post(`${BASE_URL}/session/create?eventId=${eventId}`, null, {
      headers: {
        'Content-Type': 'application/json',
        userAgentCustom: getUserAgent(),
      },
    });
  },
  checkEventAttendeeLoginLimit(eventId: string) {
    return axios.get(`${BASE_URL}/event/${eventId}/session/limit/check`);
  },
  getActiveUserSessions(eventId) {
    return axios.get(`${BASE_URL}/${eventId}/active/sessions`);
  },
  inValidateUserSession(eventId: string, userSessionId: string) {
    return axios.post(
      `${BASE_URL}/${eventId}/invalidate/session/${userSessionId}`,
    );
  },
  logout() {
    return axios.get(`${BASE_URL}/logout`);
  },
};
