import { getUserAgent } from '@/utils/helpers';
import { useDeviceData } from 'react-device-detect';
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const userDeviceData = useDeviceData(getUserAgent());
  config.headers['csrf-header'] = '63TkxpZ4zL';
  //config.headers['ui-version'] = process.env.APP_VERSION || '0.0.0';
  config.headers['X-User-Agent'] = getUserAgent();
  // user device type
  config.headers['X-UD'] = userDeviceData?.device?.type || 'UNKNOWN';
  // use device os type
  config.headers['X-UO'] = userDeviceData?.os?.name || 'UNKNOWN';
  return config;
});

const MAX_RETRIES = 2;
export const setGlobalErrorResponseInterceptor = errorHandler => {
  axios.interceptors.response.use(
    response => response,
    error => {
      // Retry for weird 400 error from tomcat
      // Axios retry -> https://github.com/axios/axios/issues/164
      const status = error.response ? error.response.status : null;
      if (
        status === 400 &&
        (error.response.headers['content-type'] || '').includes('text/html') &&
        error.config
      ) {
        error.config.__requestRetriesSofar =
          error.config.__requestRetriesSofar || 0;
        if (error.config.__requestRetriesSofar < MAX_RETRIES) {
          console.error(
            'Retrying request - ',
            error.config.url,
            ' ',
            error.config.method,
          );
          error.config.__requestRetriesSofar += 1;
          return axios.request(error.config);
        }
      }

      errorHandler(error);
      return Promise.reject(error);
    },
  );
};
export default axios;
