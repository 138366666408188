import axios from './axios';
import { SERVICE_URL } from '@/config';
import { ISolutionStatusOptions } from '@/types/solutionStatus';

const BASE_URL = `${SERVICE_URL}/core/integration`;
const EVENT_SERVICE_BASE_URL = `${SERVICE_URL}/integration`;
export default {
  getAllIntegrations() {
    return axios.get(`${BASE_URL}`);
  },

  getActiveIntegrations(organizationId: string) {
    return axios.get(`${EVENT_SERVICE_BASE_URL}/active`, {
      params: {
        organizationId,
      },
    });
  },

  getEmbeddedTrayUrl(integrationPartnerId: string) {
    return axios.get(`${BASE_URL}/${integrationPartnerId}/embedded_tray_url`);
  },

  updateSolutionInstanceStatus(
    integrationPartnerId: string,
    solutionStatus: ISolutionStatusOptions,
  ) {
    return axios.put(
      `${BASE_URL}/${integrationPartnerId}/solution_instance/status`,
      null,
      {
        params: {
          solutionStatus: solutionStatus,
        },
      },
    );
  },
};
