import * as introJs from 'intro.js';
import 'intro.js/introjs.css';
import './intro-styles.css';
import api from '@/api';

export const ZONE_FLOW = "ZONE_FLOW";

export const FLOW_STEPS = {
  Lobby: "navbar-menu-lobby",
  Schedule: "navbar-menu-schedule",
  Stage: "navbar-menu-stage",
  Room: "navbar-menu-room",
  Expo: "navbar-menu-expo",
  Networking: "navbar-menu-networking",
}
export const customFlowSteps = ({ customName, name }) => {
  const CUSTOM_FLOW_STEP_INTRO = {
    Lobby: `You can get a quick overview of the event here at the ${customName}`,
    Schedule: `You can get a quick overview of the event here at the ${customName}`,
    Stage: `The main live event happens here on the ${customName}!`,
    Room: `You can meet new people and start conversations here on the ${customName}.`,
    Expo: `At the ${customName}, you can explore various booths and have conversations with the booth owners`,
    Networking: 'Start matching with people live in the event and holding a quick networking call with them',
  }
  return CUSTOM_FLOW_STEP_INTRO[name];

};
export const getIntro = (onExitCallback, zoneConfig, currentZone) => {
  const intro = introJs();
  let intro_steps = [];
  // checking current zone is enabled or not
  const currentZoneConfig = zoneConfig.find(
    item =>
      (item.isZoneSetup === undefined || item.isZoneSetup === true) &&
      currentZone === item.route,
  );
  let firstVisibleZoneName = '';
  if (currentZoneConfig) {
    const { name } = currentZoneConfig;
    const zoneDiv = document.getElementById(FLOW_STEPS[name]);
    if (zoneDiv && !zoneDiv?.hidden) {
      firstVisibleZoneName = name;
      intro_steps.push({
        element: `#${FLOW_STEPS[name]}`,
        intro: customFlowSteps(currentZoneConfig),
        position: 'bottom',
      });
    }
  }
  // Other zone on boarding
  zoneConfig.map((menuOption, index) => {
    const { name, isZoneSetup} = menuOption;
    var zoneDiv = document.getElementById(FLOW_STEPS[name]);
    if((isZoneSetup === undefined || isZoneSetup === true) && zoneDiv && !zoneDiv?.hidden && firstVisibleZoneName !== name){
      intro_steps.push({
        element: `#${FLOW_STEPS[name]}`,
        intro: customFlowSteps(menuOption),
        position: 'bottom',
      });
    }
  });

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip row-center',
    tooltipPosition: 'top-center',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    keyboardNavigation : false,
    showBullets: false,
    showProgress: false,
    nextLabel : 'Next &nbsp; &gt;',
    doneLabel : 'Done &nbsp; &check;',

    steps: intro_steps
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: ZONE_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if (stepNumber > intro_steps.length) return;

    const updatedFlowState = { flowName: ZONE_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(()=>{
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if(stepNumber >= intro_steps.length && nextButton){
      nextButton.style.display = 'none';
    }
  })

  return intro;
}