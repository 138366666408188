import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/feedback`;

export default {
  checkIfAlreadySubmitted(eventId: string, type: string, zone?: string) {
    return axios.get(`${BASE_URL}/${eventId}/response/exist`, {
      params: {
        type, zone
      }
    })
  },
  submitResponse(eventId: string, responseJson: any){
    return axios.post(`${BASE_URL}/${eventId}/response/add`, responseJson)
  },
  getFilledFeedbacks(eventId: string){
    return axios.get(`${BASE_URL}/${eventId}/response/filled`);
  }
}
