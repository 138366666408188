import classnames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
// components
import Loader from '@/components/ui/Loader';
import { X, ExternalLink } from 'react-feather';
// styles + types
import { ICON_SIZE_LARGE } from '@/styles/iconSizes';
import styles from './styles.module.scss';
import { IImageProps } from './types';
import { IContentColors } from '@/types';
import { createPortal } from 'react-dom';
import { getCssVar } from '@/utils/cssVars';
import Label from '../../content/Label';
import BinIcon from '@/components/ui/new-icons/Bin';
import DeleteConfirmPopup from '@/components/DeleteConfirmPopup';

const Image = (props: IImageProps) => {
  const {
    deletable,
    fullScreenOnClick,
    fullScreenUrl,
    imageStyle,
    onDelete,
    onLoad,
    onClick,
    readOnly,
    src,
    styleClass = 'imageContainer',
    resizeProps,
    deleteModalTitle,
    showLoader = true,
    dataTestId,
    alt = '',
    removeTabIndex = false
  } = props;

  const [showImage, setShowImage] = useState(false);
  const [showFullscreenImage, setShowFullscreenImage] = useState(false);
  const fullscreenRoot = document.getElementById('fullscreen');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [imgLoadErr, setLoadImgErr] = useState(false);

  const handleOnLoad = e => {
    e.preventDefault();
    setShowImage(true);
  };

  const handleOnErr = () => {
    setLoadImgErr(true);
  }

  const handleImageDelete = () => ({setShow}) => {
    setShowDeletePopup(false);
    setShow(false);
    onDelete();
    setShowImage(!showImage);
  };

  const handleDeleteCancel = () => ({setShow}) => {
    setShowDeletePopup(false);
    setShow(false);
  }

  useEffect(() => {
    if (showImage) {
      onLoad && onLoad();
    }
  }, [showImage, src]);

  //return null if src empty or undefined
  if (!src) {
    return null;
  }

  const imgElement = (() => {

    if (fullScreenOnClick) {
      return (
        <img
          alt={alt}
          className={classnames(
            styles[styleClass],
            { [styles.fullScreenImage]: showFullscreenImage },
          )}
          onLoad={handleOnLoad}
          onError={handleOnErr}
          onClick={() => setShowFullscreenImage(true)}
          src={showFullscreenImage ? fullScreenUrl : src}
          imageStyle={imageStyle}
          style={!showImage ? { display: 'none' } : resizeProps ? { height: resizeProps.height + 'px' } : {}}
          data-testid={dataTestId}
        />
      );
    }
    return (
      <img
        alt={alt}
        className={styles[styleClass]}
        onLoad={handleOnLoad}
        src={src}
        imageStyle={imageStyle}
        onError={handleOnErr}
        onClick={onClick}
        style={
          resizeProps ? (!showImage ? { display: 'none' } : (resizeProps ? { height: resizeProps.height + 'px' } : {})) : {}
        }
        data-testid={dataTestId}
        decoding='async'
      />
    );
  })();

  if (showImage) {
    if (fullScreenOnClick) {
      if (!showFullscreenImage) {
        return (
          <div className={classnames(styles[styleClass], styles.clickableImage)}>{imgElement}</div>
        );
      }

      return (
        <>
          fullscreenRoot && createPortal((
          <div
            className={classnames(styles[styleClass], styles.fullScreenImageBG)}
          >
            <div tabIndex={removeTabIndex ?undefined : 0} className={styles.fullScreenImageBGExitIcon}>
              <X
                size={ICON_SIZE_LARGE}
                color={getCssVar(IContentColors.WHITE)}
                onClick={() => setShowFullscreenImage(false)}
              />
            </div>
            {imgElement}
          </div>
          ), fullscreenRoot);
        </>
      );
    }
    return (
      <div tabIndex={removeTabIndex ?undefined : 0} className={styles[styleClass]}>
        {deletable && !readOnly ? (
          <div>
            <div onClick={e => { e.stopPropagation(); setShowDeletePopup(true) }} className={styles.imageDelete} data-testid={`${dataTestId}-delete-button`}>
              <BinIcon size={16} color={IContentColors.WHITE} />
              <Label className={styles.deleteText} value={'Delete'} />
            </div>
            <DeleteConfirmPopup showPopup={showDeletePopup} handleDeleteCancel={handleDeleteCancel} handleDeleteConfirm={handleImageDelete} title={deleteModalTitle} />
            <a href={src.split('?')[0]} target="_blank">
              <div className={styles.showPreview}>
                <ExternalLink size="16px" />
                <Label className={styles.showPreviewText} value={'Show Preview'} />
              </div>
            </a>
          </div>
        ) : null}
        {imgElement}
      </div>
    );
  }
  return (
    <div tabIndex={removeTabIndex ?undefined : 0} className={styles[styleClass]} >
      {!imgLoadErr && showLoader && <Loader /> }
      {imgElement}
    </div>
  );
};

export default Image;
