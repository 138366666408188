import produce from 'immer';
import { push } from 'connected-react-router';
import { createSelector } from 'reselect';
import { call, put, select } from 'redux-saga/effects';
import api from '@/api';
import { IReduxStoreState } from '@/models/global/types';
import {
  ChannelVideoStatus,
  ChannlRefType as ChannelRefType,
} from '@/types/channel';
// types
import {
  IAddAnnouncementPayload,
  IAttendeeStatusOptions,
  ICreateEventPayload,
  IDeleteAnnouncementPayload,
  IDeleteEventPayload,
  IDuplicateEventPayload,
  IEventState,
  IGetAllEventAttendeesPayload,
  IGetAllEventSpeakersPayload,
  IGetByIdPayload,
  IGetEventBoothListPayload,
  IGetEventDiscussionsListPayload,
  IGetEventPendingSegmentListPayload,
  IGetEventRolePayload,
  IGetEventSegmentListPayload,
  IGetEventSponsorListPayload,
  ISetEventBroadcastPayload,
  ISetEventStagesDataPayload,
  IUpdateByIdPayload,
  IUpdateEventDiscussionTableNamePayload,
  IUUID,
  zoneTypes,
} from './types';
import { IEventRoleOptions } from '@/types/eventRole';
import { _isNull } from '@/utils/jsHelpers/obj';
import { isEmpty } from 'lodash';
import { makeSelectUserAccountId, selectCurrentUser } from '../account';
import { SessionBroadcastStatus } from '@/components/PublishedStreamDisplay/SessionSelectBar/types';
import { isLiveEventOrganizer, fileWithURL } from '@/utils/helpers';
import {
  initLogRocket,
  shouldTrackEventRoleInLogrocket,
} from '@/utils/logrocket';

const initialState = {
  isBoothOwner: false,
  announcementsByEventId: {},
  boothsByEventId: {},
  boothOwnerByEventId: {},
  currentlyPlayingVideoMap: {},
  currentLiveStreamMap: {},
  currentOverlayMap: {},
  discussionsByEventId: {},
  error: [],
  isFetchingData: false,
  objByIdMap: {},
  rolesByEventId: {},
  segmentsByEventId: {},
  unfilteredSegmentsByEventId: {},
  pendingSegmentsByEventId: {},
  speakersByEventId: {},
  sponsorsByEventId: {},
  stagesByEventId: {},
  streamInfoByEventId: {},
  channelSettingsByChannelId: {},
  boothCategory: '',
  isRefreshChecklist: false,
  zoneConfig: [],
  zonesSetupByEventId: {},
  boothZonesConfig: {},
  currentPresentationMap: {},
  currentPluginMap: {},
  recordingSessionObj: {},
  breakoutRoomsConfigByStageId: {},
  liveSessionByStageId: {},
  featureFlagConfig: {},
  eventChannelSettings: {},
  settingsByEventId: {},
  hasVjAccessIdSet: new Set(),
  tawkToDetails: {},
  userAccessGroupsByEventId: null,
  useDayAccessList: null,
  layoutByStageId: {},
  isEventPriorCheckInAccess: null,
  isHideChannelSidePanelBar: false,
  isHideContactUsWidget: false,
  isHideMyInboxSidePanelBar: false,
  isHideNotificationSidePanelBar: false,
  backstageTalkingUserNameByStageId: {},
  stagesStudioIds: {},
  virtualBackgrounds: {},
  roomsOwnerAccIds: {},
  presentationLayoutStatus: {},
  currentlyPlayedRecordingSessionObj: null,
  isLiteModeEnabled: false,
} as IEventState;

export default {
  namespace: 'event',
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...initialState
      };
    },
    deleteEvent: (state, { eventId }) =>
      produce(state, draft => {
        delete draft.objByIdMap[eventId];
      }),
    insertEvent: (state, { event }) =>
      produce(state, draft => {
        const eventCopy = { ...event };
        eventCopy.isBroadcasting = false;
        draft.objByIdMap[event.eventId] = eventCopy;
      }),
    updateEvent: (state, { event }) =>
      produce(state, draft => {
        const eventCopy = { ...event };
        draft.objByIdMap[event.eventId] = eventCopy;
      }),
    setEvents: (state, { events }) =>
      produce(state, draft => {
        draft.objByIdMap = events.reduce((obj, row) => {
          if (!('isBroadcasting' in row)) {
            row.isBroadcasting = false;
          }
          obj[row.eventId] = row;
          return obj;
        }, {});
      }),
    setEventSettings: (state, { eventId, settings }) =>
      produce(state, draft => {
        draft.settingsByEventId[eventId] = settings;
      }),
    setDataFetchingError: (state, { error }) =>
      produce(state, draft => {
        draft.error.push(error);
      }),
    setDataFetchingState: (state, { isFetchingData }) =>
      produce(state, draft => {
        draft.isFetchingData = isFetchingData;
      }),
    setSpeakerList: (state, { eventId, speakerList }) =>
      produce(state, draft => {
        draft.speakersByEventId[eventId] = speakerList;
      }),
    saveEventRole: (state, { eventId, eventRole }) =>
      produce(state, draft => {
        if(shouldTrackEventRoleInLogrocket(eventRole)) {
          initLogRocket();
        }
        draft.rolesByEventId[eventId] = eventRole;
      }),
    setRegistrationCustomFields: (state, { eventId, registrationCustomFields, customFieldDetails }) =>
      produce(state, draft => {
        draft.registrationCustomFields = registrationCustomFields;
        draft.registrationCustomFields.customField = customFieldDetails;
      }),
    setHasVjAccessIdSet: (state, { hasVjAccessIdSet }) =>
      produce(state, draft => {
        draft.hasVjAccessIdSet = hasVjAccessIdSet;
      }),
    addSegment: (state, { eventId, segment }) =>
      produce(state, draft => {
        if (draft.segmentsByEventId[eventId]) {
          draft.segmentsByEventId[eventId].push(segment);
        } else {
          draft.segmentsByEventId[eventId] = [segment];
        }
      }),
    addStage: (state, { eventId, stage }) =>
      produce(state, draft => {
        // const stageByEventCopy = { ...state.stagesByEventId };
        // const eventStages = stageByEventCopy[eventId];
        if (draft.stagesByEventId[eventId]) {
          draft.stagesByEventId[eventId] = {
            ...draft.stagesByEventId[eventId],
            [stage.stageId]: stage,
          };
        } else {
          draft.stagesByEventId[eventId] = { [stage.stageId]: stage };
        }
        // if (draft.stagesByEventId[eventId]) {
        //   draft.stagesByEventId[eventId].push(stage);
        // } else {
        //   draft.stagesByEventId[eventId] = [stage];
        // }
      }),
    setEventUnfilteredSegmentList: (state, { eventId, segments }) =>
      produce(state, draft => {
        draft.unfilteredSegmentsByEventId[eventId] = segments;
      }),
    setEventSegmentList: (state, { eventId, segments }) =>
      produce(state, draft => {
        draft.segmentsByEventId[eventId] = segments;
      }),
    setEventPendingSegmentList: (state, { eventId, segments }) =>
      produce(state, draft => {
        draft.pendingSegmentsByEventId[eventId] = segments;
      }),
    setEventSponsorList: (state, { eventId, sponsors }) =>
      produce(state, draft => {
        draft.sponsorsByEventId[eventId] = sponsors;
      }),
    setEventBoothList: (state, { eventId, booths }) =>
      produce(state, draft => {
        draft.boothsByEventId[eventId] = booths;
      }),
    setEventDiscussionList: (state, { eventId, discussions }) =>
      produce(state, draft => {
        const discussionsByIdMap = discussions.reduce((final, discussion) => {
          const finalCopy = { ...final };
          const { discussionTableId } = discussion;
          finalCopy[discussionTableId] = discussion;
          return finalCopy;
        }, {});

        draft.discussionsByEventId[eventId] = discussionsByIdMap;
      }),
    setChannelSettingsDataByChannelId: (state, { data }) =>
      produce(state, draft => {
        draft.channelSettingsByChannelId = data || {};
      }),
    setBoothCategorys: (state, data) =>
      produce(state, draft => {
        draft.boothCategory = data || '';
      }),
    setEventDiscussionTableInfo: (state, { discussion }) =>
      produce(state, draft => {
        const { discussionTableId, eventId } = discussion;
        draft.discussionsByEventId[eventId][discussionTableId] = discussion;
      }),
    setRegistrationTemplate: (state, { eventId, template }) =>
      produce(state, draft => {
        draft.objByIdMap[eventId].registrationTemplate = template;
      }),
    setEventBroadcasting: (state, { eventId, isBroadcasting, stageId }) => {
      return produce(state, draft => {
        const stagesByEventIdCopy = { ...draft.stagesByEventId };

        if (eventId in stagesByEventIdCopy) {
          if (stageId in stagesByEventIdCopy[eventId]) {
            stagesByEventIdCopy[eventId][
              stageId
            ].isBroadcasting = isBroadcasting;
          }
        }
        draft.stagesByEventId = stagesByEventIdCopy;
      });
    },
    setEventStages: (state, { eventId, stages }) => {
      return produce(state, draft => {
        const stagesByEventId = stages.reduce((final, stage) => {
          const { stageId } = stage;
          const stageCopy = { ...stage };
          if ('broadcasting' in stage) {
            stageCopy.isBroadcasting = stage.broadcasting;
          }
          if (!(eventId in final)) {
            final[eventId] = {};
          }
          final[eventId][stageId] = stageCopy;

          return final;
        }, {});
        draft.stagesByEventId = stagesByEventId || {};
      });
    },
    setCurrentOverlay: (state, { channelId, overlayImage }) =>
      produce(state, draft => {
        draft.currentOverlayMap[channelId] = overlayImage;
      }),
    clearCurrentOverlay: (state, { channelId }) =>
      produce(state, draft => {
        draft.currentOverlayMap[channelId] = undefined;
      }),
    setCurrentLiveStream: (state, { channelId, liveStream }) => {
      if (!liveStream) {
        return state;
      }
      return produce(state, draft => {
        const currentLiveStream = draft.currentLiveStreamMap[channelId];
        if (!currentLiveStream || (currentLiveStream.channelLiveStreamId !== liveStream.channelLiveStreamId)) {
          draft.currentLiveStreamMap[channelId] = liveStream;
        }
      });
    },
    clearCurrentLiveStream: (state, { channelId, liveStream }) =>
      produce(state, draft => {
        const currentLiveStream = draft.currentLiveStreamMap[channelId];
        if (currentLiveStream && currentLiveStream.channelLiveStreamId === liveStream.channelLiveStreamId) {
          draft.currentLiveStreamMap[channelId] = null;
        }
      }),
    setCurrentlyPlayingVideo: (state, { channelId, videoInfo }) =>
      produce(state, draft => {
        draft.currentlyPlayingVideoMap[channelId] = videoInfo;
      }),
    setCurrentlyPlayingVideoIfNotPlaying: (state, { channelId, videoInfo }) =>
      produce(state, draft => {
        if (draft.currentlyPlayingVideoMap[channelId]) {
          return;
        }
        draft.currentlyPlayingVideoMap[channelId] = videoInfo;
      }),
    setCurrentPresentation: (state, { channelId, presentation }) =>
      produce(state, draft => {
        draft.currentPresentationMap[channelId] = presentation;
      }),
    updateCurrentPresentation: (state, { channelId, updateObj }) =>
      produce(state, draft => {
        const presentation = draft.currentPresentationMap[channelId];
        if (presentation?.presentationId === updateObj?.presentationId) {
          const newPresentation = { ...presentation, ...updateObj };
          draft.currentPresentationMap[channelId] = newPresentation;
        }
      }),
    setCurrentPlugin: (state, { channelId, plugin }) =>
      produce(state, draft => {
        draft.currentPluginMap[channelId] = plugin;
      }),
    addAnnouncement: (state, { eventId, announcementData }) =>
      produce(state, draft => {
        const { announcementId } = announcementData;
        if (!(eventId in draft.announcementsByEventId)) {
          draft.announcementsByEventId[eventId] = {};
        }
        draft.announcementsByEventId[eventId][
          announcementId
        ] = announcementData;
      }),
    deleteAnnouncement: (state, { eventId, announcementId }) =>
      produce(state, draft => {
        const { announcementsByEventId } = draft;
        if (eventId in draft.announcementsByEventId) {
          if (announcementId in draft.announcementsByEventId[eventId]) {
            delete announcementsByEventId[eventId][announcementId];
          }
        }
        draft.announcementsByEventId = announcementsByEventId;
      }),
    setAnnouncements: (state, { eventId, announcementsData }) =>
      produce(state, draft => {
        const announcementsByEventId = announcementsData.reduce(
          (final, announcement) => {
            const { active, announcementId } = announcement;
            if (active) {
              if (!(eventId in final)) {
                final[eventId] = {};
              }
              final[eventId][announcementId] = announcement;
            }
            return final;
          },
          {},
        );
        draft.announcementsByEventId = announcementsByEventId || {};
      }),
    setIsBoothOwner: (state, { boothId, isBoothOwner }) =>
      produce(state, draft => {
        draft.boothOwnerByEventId[boothId] = true;
        draft.isBoothOwner = true;
      }),
    clearIsBoothOwner: state =>
      produce(state, draft => {
        draft.isBoothOwner = false;
      }),
    refreshEventCheckList: (state, { isRefreshChecklist }) =>
      produce(state, draft => {
        draft.isRefreshChecklist = isRefreshChecklist;
      }),
    setZoneConfig: (state, { zoneConfig }) =>
      produce(state, draft => {
        draft.zoneConfig = zoneConfig;
      }),
    setZonesSetupByEventId: (state, { zonesSetupByEventId }) =>
      produce(state, draft => {
        draft.zonesSetupByEventId = zonesSetupByEventId;
      }),
    setBoothZonesConfig: (state, { boothZonesConfig }) =>
      produce(state, draft => {
        draft.boothZonesConfig = boothZonesConfig;
      }),
    setRecordingSession: (state, { stageId, sessionObj }) =>
      produce(state, draft => {
        draft.recordingSessionObj[stageId] = sessionObj;
      }),
    setCurrentlyPlayedRecordingSession: (state, { sessionObj }) =>
      produce(state, draft => {
        draft.currentlyPlayedRecordingSessionObj = sessionObj;
      }),
    setLiveStageSessionStatus: (state, { eventId, sessionObj }) =>
      produce(state, draft => {
        if (!draft.pendingSegmentsByEventId[eventId]) {
          draft.pendingSegmentsByEventId[eventId] = [sessionObj];
        } else {
          const segments = draft.pendingSegmentsByEventId[eventId];
          for (let a = 0; a < segments.length; a += 1) {
            if (segments[a].segmentId === sessionObj.segmentId) {
              segments[a].broadcastStatus = sessionObj.broadcastStatus;
              draft.pendingSegmentsByEventId[eventId] = segments;
              return;
            }
          }
          draft.pendingSegmentsByEventId[eventId] = [...segments, sessionObj];
        }
      }),
    setBreakoutRoomsConfig: (state, { stageId, config }) =>
      produce(state, draft => {
        draft.breakoutRoomsConfigByStageId[stageId] = config;
      }),
    updateBreakoutRoomsConfigStatus: (state, { stageId, status }) =>
      produce(state, draft => {
        draft.breakoutRoomsConfigByStageId[stageId].inBreakout = status;
      }),
    addLiveSession: (state, { stageId, sessionData }) =>
      produce(state, draft => {
        draft.liveSessionByStageId[stageId] = sessionData;
      }),
    deleteLiveSession: (state, { stageId }) =>
      produce(state, draft => {
        const { liveSessionByStageId } = draft;
        if (stageId in draft.liveSessionByStageId) {
          delete liveSessionByStageId[stageId];
        }
        draft.liveSessionByStageId = liveSessionByStageId;
      }),
    setEventChannelSettings: (state, { data }) =>
      produce(state, draft => {
        draft.eventChannelSettings = data;
      }),
    setEventFeatureFlagConfig: (state, { eventId, config }) =>
      produce(state, draft => {
        draft.featureFlagConfig[eventId] = config;
      }),
    setHelpDesk: (state, { eventId, active }) =>
      produce(state, draft => {
        draft.objByIdMap[eventId].enableHelpdesk = active;
      }),
    setTawkToDetails: (state, { tawkToDetails }) =>
      produce(state, draft => {
        draft.tawkToDetails = tawkToDetails;
      }),
    setScheduleSync: (state, { eventId, active }) =>
      produce(state, draft => {
        draft.objByIdMap[eventId].enableScheduleSync = active;
      }),
    setUserAccessGroups: (state, { data }) =>
      produce(state, draft => {
        draft.userAccessGroupsByEventId = data;
      }),
    setUserDayAccessList: (state, { data }) =>
      produce(state, draft => {
        draft.userDayAccessList = data;
      }),
    setStageLayout: (state, { stageId, layout }) =>
      produce(state, draft => {
        draft.layoutByStageId[stageId] = layout;
      }),
    setIsEventPriorCheckInAccess: (state, { data }) =>
      produce(state, draft => {
        draft.isEventPriorCheckInAccess = data;
      }),
    hideChannelSideBar: (state, { isHideChannelSidePanelBar }) =>
      produce(state, draft => {
        draft.isHideChannelSidePanelBar = isHideChannelSidePanelBar;
      }),
    hideContactUsWidget: (state, { isHideContactUsWidget }) =>
      produce(state, draft => {
        draft.isHideContactUsWidget = isHideContactUsWidget;
      }),
    hideMyInboxSidePanelBar: (state, { isHideMyInboxSidePanelBar }) =>
      produce(state, draft => {
        draft.isHideMyInboxSidePanelBar = isHideMyInboxSidePanelBar;
      }),
    hideNotificationSidePanelBar: (state, { isHideNotificationSidePanelBar }) =>
      produce(state, draft => {
        draft.isHideNotificationSidePanelBar = isHideNotificationSidePanelBar;
      }),
    setBackstageTalkingUserInfo: (state, { stageId, name }) =>
      produce(state, draft => {
        // no need to update name if same name in redux
        if (
          name &&
          draft.backstageTalkingUserNameByStageId[stageId] &&
          name === draft.backstageTalkingUserNameByStageId[stageId]
        ) {
          return;
        }
        draft.backstageTalkingUserNameByStageId[stageId] = name;
      }),
    setStagesStudioIds: (state, { stageId, studioId }) =>
      produce(state, draft => {
        draft.stagesStudioIds[stageId] = studioId;
      }),
    setVirtualBackgrounds: (state, { refId, payload }) =>
      produce(state, draft => {
        draft.virtualBackgrounds[refId] = payload;
      }),
    setRoomsOwnerAccIds: (state, { roomId, ownerAccIds }) =>
      produce(state, draft => {
        draft.roomsOwnerAccIds[roomId] = ownerAccIds;
      }),
    setPresentationLayoutStatus: (state, { eventId, status }) =>
      produce(state, draft => {
        draft.presentationLayoutStatus[eventId] = status;
      }),
    setLiteModeStatus: (state, { isLiteModeEnabled }) =>
      produce(state, draft => {
        draft.isLiteModeEnabled = isLiteModeEnabled;
      }),
  },
  effects: {
    *create({ payload: { event } }: ICreateEventPayload) {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data: createdEventData } = yield call(api.event.create, event);
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: `Event ${event.title} Created Successfully` },
        });
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (createdEventData) {
          const { eventId } = createdEventData;
          yield put({
            type: 'event/insertEvent',
            payload: { event: createdEventData },
          });
          yield put(push(`/event/${eventId}/details`));
        } else {
          console.log(
            'event Model > createdEventData > no DATA',
            createdEventData,
          );
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                { 'Create Event Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > create', e);
        yield put({
          type: 'global/addDangerToast',
          payload: { description: `Event ${event.title} Failed to create` },
        });
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *delete({ payload: { eventId } }: IDeleteEventPayload) {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data: deletedEventData } = yield call(
          api.event.deleteById,
          eventId,
        );
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: 'Event Deleted Successfully' },
        });
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (deletedEventData) {
          yield put({ type: 'event/deleteEvent', payload: { eventId } });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                { 'Delete Event Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > delete', e);
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Event Deletion Failed' },
        });
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getAll() {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const {
          data: { data: eventsData },
        } = yield call(api.event.getAll);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (eventsData) {
          yield put({
            type: 'event/setEvents',
            payload: { events: eventsData },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                { 'Fetch All Events Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getAll', e);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getAllEventSpeakers({ payload }: IGetAllEventSpeakersPayload) {
      const { eventId, refId, refresh } = payload;
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        let speakerList = yield select(makeSelectSpeakerListByEventId(eventId));
        if (speakerList.length === 0 || refresh) {
          const { data: responseData } = yield call(
            api.speaker.getAllSpeakers,
            eventId,
            refId,
          );
          speakerList = responseData;
        } else {
          speakerList = yield select(makeSelectSpeakerListByEventId(eventId));
        }
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        if (speakerList) {
          yield put({
            type: 'event/setSpeakerList',
            payload: { eventId, speakerList },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Get All Event Speakers Error':
                    'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getAllEventSpeakers', e);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getById({ payload: { eventId, refId } }: IGetByIdPayload) {
      if (isEmpty(eventId)) {
        console.warn(
          `"event/getById" was called but eventId is empty! [eventId: ${eventId}]`,
        );
        return;
      }
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const event = yield call(api.event.getById, eventId, refId);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (event) {
          yield put({
            type: 'event/insertEvent',
            payload: { event: event.data },
          });
          const tawkTo = yield call(api.event.getTawktoDetails, eventId);
          yield put({
            type: 'event/setTawkToDetails',
            payload: { tawkToDetails: tawkTo.data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                { 'Get Event by Id Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getById', e);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *updateById({ payload: { event } }: IUpdateByIdPayload) {
      console.log('eventModel > updateById', event);
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data: eventData } = yield call(api.event.updateById, event);
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: `Event ${event.title} Updated Successfully` },
        });
        console.log('updateById', eventData);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (eventData) {
          yield put({
            type: 'event/insertEvent',
            payload: { event: eventData },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Update Event by Id Error': 'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > updateById', e);
        yield put({
          type: 'global/addDangerToast',
          payload: { description: `Event ${event.title} Update Failed` },
        });
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getEventRole({ payload: { eventId, refId } }: IGetEventRolePayload) {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data } = yield call(api.event.getEventRole, eventId, refId);

        if (data) {
          if (data.ticketTypeId != null) {
            const res = yield call(api.ticketType.getTicketTypeById, eventId, data.ticketTypeId);
            data['ticketType'] = res.data;
          }
          yield put({
            type: 'event/saveEventRole',
            payload: { eventId, eventRole: data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                { 'Get Event Role Error': 'No data returned from backend.' },
              ],
            },
          });
        }
      } catch (e) {
        console.error('eventModel > getEventRole', e);
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      } finally {
        // Update state since we are no longer fetching data
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
      }
    },
    *getEventSegmentList({
      payload: { eventId, refId },
    }: IGetEventSegmentListPayload) {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data } = yield call(
          api.segment.getEventSegmentList,
          eventId,
          refId,
        );
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (data) {
          yield put({
            type: 'event/setEventSegmentList',
            payload: { eventId, segments: data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Get Event Segment List Error':
                    'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getEventSegmentList', e);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getEventUnfilteredSegmentList({
      payload: { eventId, refId },
    }: IGetEventSegmentListPayload) {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data } = yield call(
          api.segment.getEventUnfilteredSegmentList,
          eventId,
          refId,
        );
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (data) {
          yield put({
            type: 'event/setEventUnfilteredSegmentList',
            payload: { eventId, segments: data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Get Event Segment List Error':
                    'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getEventUnfilteredSegmentList', e);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getEventPendingSegmentList({
      payload: { eventId },
    }: IGetEventPendingSegmentListPayload) {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data } = yield call(
          api.segment.getEventPendingSegmentList,
          eventId,
        );
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (data) {
          yield put({
            type: 'event/setEventPendingSegmentList',
            payload: { eventId, segments: data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Get Event Pending Segment List Error':
                    'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getEventSponsorList({
      payload: { eventId, refId },
    }: IGetEventSponsorListPayload) {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data } = yield call(api.event.getSponsorList, eventId, refId);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (data) {
          yield put({
            type: 'event/setEventSponsorList',
            payload: { eventId, sponsors: data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Get Event Sponsor List Error':
                    'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getEventSponsorList', e);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getEventBoothList({
      payload: { eventId, showHidden, isAdminView = false },
    }: IGetEventBoothListPayload) {
      try {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const { data } = yield call(
          isAdminView ? api.event.getBoothListForAdmin : api.event.getBoothList,
          eventId,
          showHidden,
        );
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });

        if (data) {
          yield put({
            type: 'event/setEventBoothList',
            payload: { eventId, booths: data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Get Event Sponsor List Error':
                    'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getEventBoothList', e);
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getEventDiscussionsList({
      payload: { eventId, limit, offset, q, selectedTableId, selectedTagIds, roomZoneId },
    }: IGetEventDiscussionsListPayload) {
      try {
        const { data } = yield call(api.discussion.getDiscussionList, eventId, limit, offset, q, selectedTableId, selectedTagIds, roomZoneId);
        yield put({
          type: 'event/setEventDiscussionList',
          payload: { eventId, discussions: data },
        });
      } catch (e) {
        console.log('eventModel > getEventDiscussionsList', e);
      }
    },
    *getEventDiscussionsListOrgSide({
      payload: { eventId },
    }: IGetEventDiscussionsListPayload) {
      try {
        const { data } = yield call(api.discussion.getDiscussionList_OrgSide, eventId);
        yield put({
          type: 'event/setEventDiscussionList',
          payload: { eventId, discussions: data },
        });
      } catch (e) {
        console.log('eventModel > getEventDiscussionsList', e);
      }
    },
    *updateEventDiscussionTableName({
      payload: { name, discussion },
    }: IUpdateEventDiscussionTableNamePayload) {
      try {
        const { discussionTableId } = discussion;
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const {
          data: { data: discussionsData },
        } = yield call(
          api.discussion.updateDiscussionName,
          discussionTableId,
          name,
        );

        yield put({
          type: 'global/addSuccessToast',
          payload: { description: 'Table Name Updated' },
        });

        if (discussionsData) {
          discussionsData.name = name;
          yield put({
            type: 'event/setEventDiscussionTableInfo',
            payload: { discussion: discussionsData },
          });
        }
      } catch (e) {
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Table Name Update Failed' },
        });
      }
    },
    *playVideo({ payload: { channelId, channelVideoId, playingTime } }) {
      try {
        const { data: channelVideo } = yield call(
          api.channel.updateChannelVideoStatus,
          channelId,
          {
            channelVideoId,
            channelVideoStatus: ChannelVideoStatus.PLAYING,
            playingTime: Math.round(playingTime),
          },
        );
        yield put({
          type: 'event/setCurrentlyPlayingVideo',
          payload: {
            channelId,
            videoInfo: channelVideo,
          },
        });
      } catch (e) {
        console.log('eventModel > playVideo', e);
      }
    },
    *pauseVideo({ payload: { channelId, channelVideoId, playingTime } }) {
      try {
        const { data: channelVideo } = yield call(
          api.channel.updateChannelVideoStatus,
          channelId,
          {
            channelVideoId,
            channelVideoStatus: ChannelVideoStatus.PAUSED,
            playingTime: Math.round(playingTime),
          },
        );
        yield put({
          type: 'event/setCurrentlyPlayingVideo',
          payload: {
            channelId,
            videoInfo: channelVideo,
          },
        });
      } catch (e) {
        console.log('eventModel > playVideo', e);
      }
    },
    *completeCurrentlyPlayingVideo({ payload: { channelId, video } }) {
      try {
        yield call(api.channel.updateChannelVideoStatus, channelId, {
          channelVideoId: video.channelVideoId,
          channelVideoStatus: ChannelVideoStatus.PLAYED,
          playingTime: 0,
        });
        yield put({
          type: 'event/setCurrentlyPlayingVideo',
          payload: {
            channelId,
            videoInfo: undefined,
          },
        });
      } catch (e) {
        console.log('eventModel > completeCurrentlyPlayingVideo', e);
      }
    },
    *duplicateEvent({ payload: { eventId } }: IDuplicateEventPayload) {
      try {
        yield call(api.event.duplicateEvent, eventId);
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: 'Event Duplicated Successfully' },
        });
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
      } catch (e) {
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Event Duplication Failed' },
        });
      }
    },
    *addEventAnnouncement({
      payload: { eventId, announcement },
    }: IAddAnnouncementPayload) {
      try {
        const { data: announcementData } = yield call(
          api.event.createAnnouncement,
          eventId,
          announcement,
        );
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: 'Added Event Notification Successfully' },
        });
        if (announcementData) {
          announcementData.status = 'NEW';
          yield put({
            type: 'event/addAnnouncement',
            payload: { eventId, announcementData },
          });
        }
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
      } catch (e) {
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Event Duplication Failed' },
        });
      }
    },
    *deleteEventAnnouncement({
      payload: { eventId, announcementId },
    }: IDeleteAnnouncementPayload) {
      try {
        const announcementData = yield call(
          api.event.stopAnnouncement,
          eventId,
          announcementId
        );
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: 'Deleted Event Notification Successfully' },
        });
        if (announcementData.status === 200) {
          yield put({
            type: 'event/deleteAnnouncement',
            payload: { eventId, announcementId },
          });
        }
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
      } catch (e) {
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Deleting Announcement Failed' },
        });
      }
    },
    *getAllEventAnnouncements({ payload }) {
      try {
        const { eventId } = payload;
        const { data: announcementsData } = yield call(
          api.event.getAllActiveAnnouncements,
          eventId,
        );
        if (announcementsData) {
          yield put({
            type: 'event/setAnnouncements',
            payload: { eventId, announcementsData: announcementsData },
          });
        }
      } catch (e) { }
    },
    *setEventIsBroadcasting({
      payload: { eventId, isBroadcasting, stageId },
    }: ISetEventBroadcastPayload) {
      try {
        yield put({
          type: 'event/setEventBroadcasting',
          payload: { eventId, isBroadcasting, stageId },
        });
      } catch (e) {
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Deleting Announcement Failed' },
        });
      }
    },
    *setEventStageData({
      payload: { eventId, stages },
    }: ISetEventStagesDataPayload) {
      try {
        yield put({
          type: 'event/setEventStages',
          payload: {
            eventId,
            stages,
          },
        });
      } catch (e) {
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Setting stage data' },
        });
      }
    },
    *setChannelSettingsByChannelId({ payload: channelId }) {
      try {
        const { data } = yield call(
          api.channel.getChannelSettingsByChannelId,
          channelId,
        );
        yield put({
          type: 'event/setChannelSettingsDataByChannelId',
          payload: { data },
        });
      } catch (e) {
        console.log('get settings > by stage Id', e);
      }
    },
    *setBoothCategory({ payload }) {
      try {
        yield put({
          type: 'event/setBoothCategorys',
          payload: payload,
        });
      } catch (e) {
        console.log('get settings > by stage Id', e);
      }
    },
    *getZoneConfig({ payload: { eventId } }) {
      try {
        const { data } = yield call(api.event.getZoneSetUpAll, eventId);

        if (data) {
          yield put({
            type: 'event/setZoneConfig',
            payload: { zoneConfig: data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Get Event Zone Config Error':
                    'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getEventZoneConfig', e);
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getBoothZoneConfig({ payload: { eventId } }) {
      try {
        const { data } = yield call(api.event.getZoneSetUp, eventId);

        if (data) {
          const boothZonesMap = data
            .filter(z => z.type === zoneTypes.BOOTH)
            .reduce(
              (obj: any, item: any) =>
                Object.assign(obj, { [item.refId]: item }),
              {},
            );
          yield put({
            type: 'event/setBoothZonesConfig',
            payload: { boothZonesConfig: boothZonesMap },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [
                {
                  'Get Event Booth Zone Config Error':
                    'No data returned from backend.',
                },
              ],
            },
          });
        }
      } catch (e) {
        console.log('eventModel > getEventBoothZoneConfig', e);
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *setCurrentRunningSession({ payload }) {
      try {
        const { eventId, stageId } = payload;
        const { data: currentRunningSession } = yield call(
          api.segment.getCurrentRunningSession,
          eventId,
          stageId,
        );
        yield put({
          type: 'event/setRecordingSession',
          payload: { stageId, sessionObj: currentRunningSession || '' },
        });
        // eslint-disable-next-line no-empty
      } catch (e) { }
    },
    *getBreakoutRoomsConfig({ payload }) {
      try {
        const { eventId, stageId } = payload;
        const { data } = yield call(
          api.breakout.getStageBreakoutConfig,
          eventId,
          stageId,
          ChannelRefType.STAGE,
        );
        yield put({
          type: 'event/setBreakoutRoomsConfig',
          payload: { stageId, config: data },
        });
        // eslint-disable-next-line no-empty
      } catch (e) { }
    },
    *getEventFeatureFlagConfig({ payload }) {
      const { eventId } = payload;
      try {
        const { data } = yield call(
          api.event.getEventFeatureFlagConfig,
          eventId,
        );
        yield put({
          type: 'event/setEventFeatureFlagConfig',
          payload: { eventId, config: data },
        });
      } catch (e) {
        yield put({
          type: 'event/setEventFeatureFlagConfig',
          payload: { eventId, config: {} },
        });
      }
    },
    *getEventSettings({ payload }) {
      const { eventId } = payload;
      try {
        const { data } = yield call(
          api.event.getEventSettings,
          eventId,
        );
        yield put({
          type: 'event/setEventSettings',
          payload: { eventId, settings: data },
        });
      } catch (e) {
        yield put({
          type: 'event/setEventSettings',
          payload: { eventId, settings: {} },
        });
      }
    },
    *getUserAccessGroupsByEventId({ payload }) {
      const { eventId } = payload;
      try {
        const { data } = yield call(
          api.accessGroups.getLiveUserAllAccessGroups,
          eventId,
        );
        yield put({
          type: 'event/setUserAccessGroups',
          payload: { data },
        });
      } catch (e) {
        yield put({
          type: 'event/setUserAccessGroups',
          payload: { data: null },
        });
      }
    },
    *getUserDayAccessListByEventId({ payload }) {
      const { eventId } = payload;
      try {
        const { data } = yield call(
          api.accessGroups.getCurrentUserAccessDatesList,
          eventId,
        );
        yield put({
          type: 'event/setUserDayAccessList',
          payload: { data },
        });
      } catch (e) {
        yield put({
          type: 'event/setUserDayAccessList',
          payload: { data: null },
        });
      }
    },
    *getStageLayoutByStageId({ payload }) {
      const { eventId, stageId } = payload;
      if (!eventId || !stageId) {
        return;
      }
      try {
        const { data } = yield call(
          api.event.getStageLayoutByStageId,
          eventId,
          stageId,
        );
        yield put({
          type: 'event/setStageLayout',
          payload: { stageId, layout: data },
        });
      } catch (e) {
        yield put({
          type: 'event/setStageLayout',
          payload: { stageId, layout: null },
        });
      }
    },
    *getIsEventPriorCheckInAccess({ payload }) {
      const { eventId } = payload;
      try {
        const { data } = yield call(
          api.event.getIsEventPriorCheckInAccess,
          eventId,
        );
        yield put({
          type: 'event/setIsEventPriorCheckInAccess',
          payload: { data },
        });
      } catch (e) {
        yield put({
          type: 'event/setIsEventPriorCheckInAccess',
          payload: { data: null },
        });
      }
    },
    *getStudioIdForStageId({ payload }) {
      const { stageId } = payload;
      if (!stageId) {
        return;
      }
      try {
        const { data } = yield call(api.stage.getStudioIdForStage, stageId);
        yield put({
          type: 'event/setStagesStudioIds',
          payload: { stageId, studioId: data },
        });
      } catch (e) {
        yield put({
          type: 'event/setStagesStudioIds',
          payload: { stageId, studioId: null },
        });
      }
    },
    *getVirtualBackground({ payload }) {
      // Extend to fetch background of specific venue
      const { eventId } = payload;
      if (!eventId) {
        return;
      }
      try {
        const { data } = yield call(api.virtualBackgrounds.get, eventId);
        if (!data.type || !data.bgValue) {
          throw new Error('No data found');
        }

        const virtualBg = {
          type: data.type,
          bgValue: fileWithURL(data.bgValue),
        };
        yield put({
          type: 'event/setVirtualBackgrounds',
          payload: { refId: data.refId, payload: virtualBg },
        });
      } catch (e) {
        yield put({
          type: 'event/setVirtualBackgrounds',
          payload: { eventId, payload: null },
        });
      }
    },
    *getPresentationLayoutStatus({ payload }) {
      const { eventId } = payload;
      if (!eventId) {
        return;
      }
      try {
        const { data } = yield call(
          api.event.getPresentationLayoutStatus,
          eventId,
        );
        yield put({
          type: 'event/setPresentationLayoutStatus',
          payload: { eventId, status: data },
        });
      } catch (e) {
        yield put({
          type: 'event/setPresentationLayoutStatus',
          payload: { eventId, status: null },
        });
      }
    },
  },
};

const selectAllEventsArray = (state: IReduxStoreState) =>
  Object.values(state.event.objByIdMap || {});

// event id => event obj
const selectEventsObjByIdMap = (state: IReduxStoreState) =>
  (state.event && state.event.objByIdMap) || {};

// get event by id
const makeSelectEventById = () =>
  createSelector(
    selectEventsObjByIdMap,
    (_, eventId: IUUID) => eventId,
    (objByIdMap, eventId) => objByIdMap[eventId] || {},
  );

// event title
const makeSelectEventTitleById = () =>
  createSelector(makeSelectEventById(), event => (event ? event.title : ''));

// event date
const makeSelectEventDateById = () =>
  createSelector(makeSelectEventById(), event => {
    if (event) {
      return {
        startDateTime: event.startDateTime,
        endDateTime: event.endDateTime,
      };
    }
    return {
      startDateTime: Date.now(),
      endDateTime: Date.now(),
    };
  });

const makeSelectEventStartDateById = () =>
  createSelector(
    makeSelectEventById(),
    event => event.startDateTime || Date.now(),
  );

const makeSelectEventTimeToStartById = () =>
  createSelector(makeSelectEventStartDateById(), eventStartTime => {
    const now = new Date().getMilliseconds();
    const eventStartTimeDateObj = new Date(eventStartTime).getMilliseconds();
    const diffInMs = Math.abs(eventStartTimeDateObj - now);
    const numMinutes = new Date(diffInMs).getMinutes();
    const numSecs = new Date(diffInMs).getSeconds();
    return `${numMinutes}:${numSecs}`;
  });

const makeSelectEventImageById = () =>
  createSelector(
    makeSelectEventById(),
    event =>
      (event && event.coverImagePath ? event.coverImagePath : event.logoUrl) ||
      '',
  );

const makeSelectEventDescriptionById = () =>
  createSelector(
    makeSelectEventById(),
    event => (event && event.description) || '',
  );

// event speaker list
const selectSpeakerListMap = (state: IReduxStoreState) =>
  (state.event && state.event.speakersByEventId) || {};

const makeSelectSpeakerListByEventId = (eventId: IUUID) =>
  createSelector(selectSpeakerListMap, speakerMap => speakerMap[eventId] || []);

// event segment list
const selectSegmentListMap = (state: IReduxStoreState) =>
  (state.event && state.event.segmentsByEventId) || {};
// event segment list without user access filter
const selectUnfilteredSegmentListMap = (state: IReduxStoreState) =>
  (state.event && state.event.unfilteredSegmentsByEventId) || {};

const makeSelectSegmentListByEventId = (eventId: IUUID) =>
  createSelector(selectSegmentListMap, segmentMap => {
    const segments = segmentMap[eventId] || [];
    return segments
      .concat([])
      .filter(item => !item.isDummy)
      .sort((a, b) => {
        const aStart = new Date(a.startDateTime);
        const bStart = new Date(b.startDateTime);
        return aStart.getTime() - bStart.getTime();
      });
  });

const makeSelectUnfilteredSegmentListByEventId = (eventId: IUUID) =>
  createSelector(selectUnfilteredSegmentListMap, segmentMap => {
    const segments = segmentMap[eventId] || [];
    return segments
      .concat([])
      .filter(item => !item.isDummy)
      .sort((a, b) => {
        const aStart = new Date(a.startDateTime);
        const bStart = new Date(b.startDateTime);
        return aStart.getTime() - bStart.getTime();
      });
  });

const makeSelectSegmentListByEventIdWithDummy = (eventId: IUUID) =>
  createSelector(selectSegmentListMap, segmentMap => {
    const segments = segmentMap[eventId] || [];
    return segments.concat([]).sort((a, b) => {
      const aStart = new Date(a.startDateTime);
      const bStart = new Date(b.startDateTime);
      return aStart.getTime() - bStart.getTime();
    });
  });

// event pending segment list
const selectPendingSegmentListMap = (state: IReduxStoreState) =>
  (state.event && state.event.pendingSegmentsByEventId) || {};

const makeSelectPendingSegmentListByEventId = (eventId: IUUID) =>
  createSelector(selectPendingSegmentListMap, segmentMap => {
    const segments = segmentMap[eventId] || [];
    return segments
      .concat([])
      .filter(item => item.broadcastStatus !== SessionBroadcastStatus.COMPLETED)
      .sort((a, b) => {
        const aStart = new Date(a.startDateTime);
        const bStart = new Date(b.startDateTime);
        return aStart.getTime() - bStart.getTime();
      });
  });

// event sponsor list
const selectSponsorListMap = (state: IReduxStoreState) =>
  (state.event && state.event.sponsorsByEventId) || {};

const makeSelectSponsorListByEventId = (eventId: IUUID) =>
  createSelector(selectSponsorListMap, sponsorMap => sponsorMap[eventId] || []);

// event booth list
const selectBoothListMap = (state: IReduxStoreState) =>
  (state.event && state.event.boothsByEventId) || {};

const makeSelectBoothListByEventId = (eventId: IUUID) =>
  createSelector(selectBoothListMap, boothMap => boothMap[eventId] || []);

// event discussion list
const selectDiscussionListMap = (state: IReduxStoreState) =>
  (state.event && state.event.discussionsByEventId) || {};

const makeSelectDiscussionListByEventId = () =>
  createSelector(
    selectDiscussionListMap,
    (_, eventId: IUUID) => eventId,
    (discussionListMap, eventId) => {
      if (discussionListMap && discussionListMap[eventId]) {
        return Object.values(discussionListMap[eventId]);
      }
      return [];
    },
  );

// event stages
const selectStagesByEventIdObj = state =>
  (state.event && state.event.stagesByEventId) || {};

const makeSelectStagesByEventId = (eventId: IUUID) =>
  // createSelector(selectStagesByEventIdObj, stagesByEventIdMap => stagesByEventIdMap[eventId] || []);
  createSelector(
    selectStagesByEventIdObj,
    (_, eventId: IUUID) => eventId,
    (stagesByEventIdMap, eventId) => {
      return (
        (!_isNull(stagesByEventIdMap) &&
          stagesByEventIdMap &&
          stagesByEventIdMap[eventId] &&
          Object.values(stagesByEventIdMap[eventId])) ||
        []
      );
    },
  );

const makeSelectStageByEventId = () =>
  createSelector(
    selectStagesByEventIdObj,
    (_, params) => params,
    (stagesByEventIdMap, params) => {
      const { eventId, stageId } = params;
      return stagesByEventIdMap[eventId]?.[stageId] || [];
    },
  );

const selectHasVjAccessIdSet = (state: IReduxStoreState) =>
  (state.event && state.event.hasVjAccessIdSet) || new Set();

const selectStreamInfoMap = state =>
  (state.event && state.event.streamInfoByEventId) || {};

const makeSelectStreamInfoByEventId = () =>
  createSelector(
    selectStreamInfoMap,
    (_, eventId: IUUID) => eventId,
    (streamInfoMap, eventId) => streamInfoMap[eventId] || {},
  );

const makeSelectEventRoleForEvent = () =>
  createSelector(
    (state: IReduxStoreState) => (state.event && state.event.rolesByEventId) || {},
    (_, eventId: IUUID) => eventId,
    (rolesByEventId, eventId) => rolesByEventId[eventId] || {},
  );

const selectRegistrationCustomFields = (state: IReduxStoreState) => {
  return (state.event && state.event.registrationCustomFields) || undefined;
}

const selectTawkToDetails = (state: IReduxStoreState) => {
  return (state.event && state.event.tawkToDetails) || undefined;
}

const makeSelectEventUserRole = () =>
  createSelector(makeSelectEventRoleForEvent(), eventRole => eventRole.role);

const ownerToBoothMapping = {
  "706e5700-ede7-48a3-a351-f188aeaf51e9": '04cb0245-b177-4714-a97a-481ef332ca1e', // bosch
  "4eaae2e0-3aef-4a81-820b-35ae4a64451f": '1420caac-d7f6-4096-80c8-ff1e244b19cb', // capgemini
  "65614271-c66c-4254-ac58-96e5a8bc41cc": '8feccb4d-df24-47e6-9e44-e951d1fd8b7c', // cyient
  "1405734d-40a1-4d46-bc28-92037532da19": '30d47029-65f2-429d-9b64-0ce39308d1fc', // hcl
  "b132d910-c617-446a-ab16-ab4e506f70e1": '01221e45-0b07-4623-8eee-bdc06d52b679', // infosys
  "3ae5226f-3fb8-40d3-b603-0cd13fb9ca4d": '8af0a1ce-b624-4fed-b922-db809259dc1c', // karnatakagov
  "458d469a-4cf0-427e-a5e7-d34af7b6dc81": '021bd24b-e348-4781-ad10-8981fac7ea4a', // ltts
  "ae47a37f-8605-4455-82d3-1d07e24914d2": '2168e8d6-5fe1-4357-976b-dd2ea4856378', // midc
  "44f83ab7-b648-4924-9dc4-b3ac0e88fe8d": 'd6d51f5d-3b0d-42ab-8450-d838cb1e40ed', // quest
  "e6efc15d-4d84-4fb6-90e3-410186ec8c5f": '9983d6bf-326f-4eee-ab9b-81159ff9c027', // wipro
  "5f277201-ab2f-4ef2-a6b3-0af3a8cace49": 'a173fa09-d276-4d07-be24-a6cc06d54134'
};

const makeSelectIsSpeaker = () =>
  createSelector(
    (state) => state && state.router && state.router.location && state.router.location.pathname,
    selectCurrentUser,
    makeSelectEventUserRole(),
    (pathname, currentUser, userRole) => {
      if (pathname.includes('booth')) {
        return false;
      }

      if (userRole === IEventRoleOptions.SPEAKER) {
        return true;
      };

      return false;
    }
  );

const makeSelectIsOrganizer = () =>
  createSelector(
    makeSelectEventUserRole(),
    (state) => state.event.isBoothOwner,
    (state) => state && state.router && state.router.location && state.router.location.pathname,
    (userRole, isBoothOwner, pathname) => (pathname.includes('booth') && isBoothOwner) || (isLiveEventOrganizer(userRole)),
  );
const makeSelectIsOrganizerForEvent = () =>
  createSelector(
    makeSelectEventUserRole(),
    (state) => state.event.isBoothOwner,
    (state) => state && state.router && state.router.location && state.router.location.pathname,
    (userRole) => (isLiveEventOrganizer(userRole)),
  );

const makeSelectIsOrganizerAlone = () =>
  createSelector(
    makeSelectEventUserRole(),
    (userRole) => userRole === IEventRoleOptions.ORGANIZER,
  );

const makeSelectIsLiveEventOrganizer = () =>
  createSelector(
    makeSelectEventUserRole(),
    (userRole) => userRole === IEventRoleOptions.ORGANIZER || userRole === IEventRoleOptions.MODERATOR,
  );

const makeSelectIsModerator = () =>
  createSelector(
    makeSelectEventUserRole(),
    (userRole) => userRole === IEventRoleOptions.MODERATOR,
  );

const makeSelectIsHost = () =>
  createSelector(
    makeSelectIsSpeaker(),
    makeSelectIsOrganizer(),
    makeSelectIsModerator(),
    (isSpeaker, isOrganizer, isModerator) => isSpeaker || isOrganizer || isModerator,
  );

const makeSelectIsAttendee = () =>
  createSelector(
    makeSelectEventUserRole(),
    userRole => userRole === IEventRoleOptions.ATTENDEE,
  );

const makeSelectEventStageIsBroadcasting = () =>
  createSelector(
    selectStagesByEventIdObj,
    (_, inputArgs) => inputArgs,
    (stagesByEventId, inputArgs) => {
      const { eventId, stageId } = inputArgs;
      return (
        (stagesByEventId &&
          stagesByEventId[eventId] &&
          stagesByEventId[eventId][stageId] &&
          stagesByEventId[eventId][stageId].isBroadcasting) ||
        false
      );
    },
  );

const makeSelectEventIsBroadcasting = () =>
  createSelector(
    selectStagesByEventIdObj,
    (_, eventId) => eventId,
    (stagesByEventId, eventId) => {
      const stages =
        (stagesByEventId &&
          stagesByEventId[eventId] &&
          Object.values(stagesByEventId[eventId])) ||
        [];
      return stages.reduce((final, currentStage) => {
        const { isBroadcasting = false } = currentStage;
        return final || isBroadcasting;
      }, false);
    },
  );

const selectIsFetchingData = state => state.event && state.event.isFetchingData;

const selectError = state => state.event && state.event.error;

const selectBoothZoneConfig = state => {
  if (!state || !state.event) return {};
  const { event } = state;
  return (event && event.boothZonesConfig) || {};
};

const selectEventAnnouncementMap = state =>
  (state.event && state.event.announcementsByEventId) || {};

const makeSelectEventAnnouncement = () =>
  createSelector(
    selectEventAnnouncementMap,
    (_, eventId: IUUID) => eventId,
    (announcementInfoMap, eventId) => {
      if (announcementInfoMap && announcementInfoMap[eventId]) {
        return Object.values(announcementInfoMap[eventId])[0] || {};
      }
      return {};
    },
  );

const makeSelectEventAnnouncementByZone = () =>
  createSelector(
    selectEventAnnouncementMap,
    selectBoothZoneConfig,
    (_, inputArgs) => inputArgs,
    (announcementInfoMap, boothZonesMap, inputArgs) => {
      const { eventId, zone = '' } = inputArgs;
      if (announcementInfoMap) {
        const announcementsForEventObj = announcementInfoMap[eventId] || {};
        const announcementsForEvent = Object.values(announcementsForEventObj);

        const announcementForZone = announcementsForEvent.filter(
          notif =>
            notif &&
            notif.targetZones &&
            notif.targetZones.includes(zone.toUpperCase()),
        );
        if (announcementForZone.length) {
          return announcementForZone[0];
        }

        const announcementForBoothZones = announcementsForEvent.filter(
          notif =>
            notif &&
            notif.redirectionSubZone &&
            boothZonesMap &&
            boothZonesMap[notif.redirectionSubZone],
        );
        if (announcementForBoothZones.length) {
          return announcementForBoothZones[0];
        }

        return {};
      }
    },
  );

const selectCurrentOverlayMap = state =>
  state.event && state.event.currentOverlayMap;

const makeSelectCurrentOverlayForChannel = () =>
  createSelector(
    selectCurrentOverlayMap,
    (_, channelId) => channelId,
    (currentOverlayMap, channelId) => {
      return currentOverlayMap[channelId]
    }
  );

const selectCurrentlyPlayingVideoMap = state =>
  state.event && state.event.currentlyPlayingVideoMap;

const makeSelectCurrentlyPlayingVideoForChannel = () =>
  createSelector(
    selectCurrentlyPlayingVideoMap,
    (_, channelId) => channelId,
    (currentlyPlayingVideoMap, channelId) => {
      return currentlyPlayingVideoMap[channelId]
    }
  );

const selectCurrentPresentationMap = state =>
  state.event && state.event.currentPresentationMap;

const makeSelectCurrentPresentationForChannel = () =>
  createSelector(
    selectCurrentPresentationMap,
    (_, channelId) => channelId,
    (currentPresentationMap, channelId) =>
      currentPresentationMap[channelId] || null,
  );

const selectCurrentPluginMap = state =>
  state.event && state.event.currentPluginMap;

const makeSelectCurrentPluginForChannel = () =>
  createSelector(
    selectCurrentPluginMap,
    (_, channelId) => channelId,
    (currentPluginMap, channelId) =>
      currentPluginMap[channelId] || null,
  );

const selectCurrentLiveStreamForChannel = state =>
  state.event && state.event.currentLiveStreamMap;

const makeSelectCurrentLiveStreamForChannel = () =>
  createSelector(
    selectCurrentLiveStreamForChannel,
    (_, channelId) => channelId,
    (currentLiveStreamMap, channelId) => {
      return currentLiveStreamMap[channelId]
    }
  );

const makeStageSettingsByChannelId = (state) => {
  const { event } = state;
  return (event && event.channelSettingsByChannelId) || {};
};

const makeSelectBoothCategory = state => {
  const { event } = state;
  return (event && event.boothCategory) || '';
};

const makeIsRefreshEventChecklist = (state) => {
  const { event } = state;
  return (event && event.isRefreshChecklist) || false;
};

const selectZoneConfig = (state) => {
  if (!state || !state.event) return [];
  const { event } = state;
  return (event && event.zoneConfig) || [];
};

const makeSelectZonesSetupByEventId = (state) => {
  const { event } = state;
  return (event && event.zonesSetupByEventId) || {};
};

const selectCurrentRecordingSession = state =>
  (state.event && state.event.recordingSessionObj) || {};

const makeSelectRecordingSessionObj = () =>
  createSelector(
    selectCurrentRecordingSession,
    (_, stageId: IUUID) => stageId,
    (recordingSession, stageId) => recordingSession[stageId] || '',
  );

// event id => event obj
const selectBreakoutRoomsConfigObjByIdMap = (state: IReduxStoreState) =>
  (state.event && state.event.breakoutRoomsConfigByStageId) || {};

// get event by id
const makeSelectBreakoutRoomsConfigById = () =>
  createSelector(
    selectBreakoutRoomsConfigObjByIdMap,
    (_, stageId: IUUID) => stageId,
    (selectBreakoutRoomsConfig, stageId) =>
      selectBreakoutRoomsConfig[stageId] || {},
  );

const makeSelectLiveSessions = state => {
  const { event } = state;
  return (event && event.liveSessionByStageId) || null;
};

const makeSelectFeatureFlagConfigById = () =>
  createSelector(
    (state) => state.event && state.event.featureFlagConfig || {},
    (_, eventId: IUUID) => eventId,
    (featureFlagConfig, eventId) =>
      featureFlagConfig[eventId] || {},
  );

const makeSelectEventChannelSettings = (state: IReduxStoreState) =>
  state.event?.eventChannelSettings || undefined;

const makeSelectEventSettingsById = () =>
  createSelector(
    (state) => state.event && state.event.settingsByEventId || {},
    (_, eventId: IUUID) => eventId,
    (settingsByEventId, eventId) =>
      settingsByEventId[eventId] || {},
  );

const makeSelectUserAccessGroups = state => {
  const { event } = state;
  return (event && event.userAccessGroupsByEventId) || null;
};

const makeSelectUserDayAccessList = state => {
  const { event } = state;
  return (event && event.userDayAccessList) || null;
};

const makeSelectStageLayoutByStageId = () =>
  createSelector(
    state => (state.event && state.event.layoutByStageId) || {},
    (_, stageId: IUUID) => stageId,
    (layoutByStageId, stageId) => layoutByStageId[stageId] || {},
  );
const makeIsEventPriorCheckInAccess = state => {
  const { event } = state;
  if (event && event.isEventPriorCheckInAccess !== null) {
    return event.isEventPriorCheckInAccess;
  }
  return null;
};

const makeIsHideChannelSideBar = state => {
  const { event } = state;
  return (event && event.isHideChannelSidePanelBar) || false;
};

const makeIsHideContactUsWidget = state => {
  const { event } = state;
  return (event && event.isHideContactUsWidget) || false;
};

const makeIsHideMyInboxSidePanelBar = state => {
  const { event } = state;
  return (event && event.isHideMyInboxSidePanelBar) || false;
};

const makeIsHideNotificationSidePanelBar = state => {
  const { event } = state;
  return (event && event.isHideNotificationSidePanelBar) || false;
};

const selectBackstageTalkingUserObjByIdMap = (state: IReduxStoreState) =>
  (state.event && state.event.backstageTalkingUserNameByStageId) || {};

// get event by id
const makeSelectBackstageTalkingUserByStageId = () =>
  createSelector(
    selectBackstageTalkingUserObjByIdMap,
    (_, stageId: IUUID) => stageId,
    (selectBackstageTalkingUser, stageId) =>
      selectBackstageTalkingUser[stageId] || null,
  );

const makeSelectStudioIdByStageId = () =>
  createSelector(
    state => (state.event && state.event.stagesStudioIds) || {},
    (_, stageId: IUUID) => stageId,
    (selectStudioIdByStageId, stageId) =>
      selectStudioIdByStageId[stageId] || null,
  );

const makeSelectVirtualBgByRefId = () =>
  createSelector(
    state => state.event?.virtualBackgrounds || {},
    (_, refId: IUUID) => refId,
    (selectVirtualBackgroundByRef, refId) =>
      selectVirtualBackgroundByRef[refId] || null,
  );

const makeSelectRoomsOwnerAccIds = () =>
  createSelector(
    state => (state.event && state.event.roomsOwnerAccIds) || {},
    (_, roomId: IUUID) => roomId,
    (selectRoomsOwnerAccIds, roomId) => selectRoomsOwnerAccIds[roomId] || null,
  );

const makeSelectIsRoomOwner = () =>
  createSelector(
    makeSelectRoomsOwnerAccIds(),
    makeSelectUserAccountId(),
    (roomOwners: string[] | undefined, accountId: string) =>
      roomOwners?.some(o => o === accountId) ?? false,
  );

const makeSelectPresentationLayoutByEventId = () =>
  createSelector(
    state => (state.event && state.event.presentationLayoutStatus) || {},
    (_, eventId: IUUID) => eventId,
    (selectPresentationLayoutMap, eventId) =>
      selectPresentationLayoutMap[eventId] || false,
  );

const makeSelectRoomByRoomId = () =>
  createSelector(
    selectDiscussionListMap,
    (_, params) => params,
    (discussionListMap: any, params) => {
      const { eventId, discussionTableId } = params;
      if (discussionListMap && discussionListMap[eventId]) {
        return (
          Object.values(discussionListMap[eventId])?.find(
            (r: any) => r.discussionTableId === discussionTableId,
          ) || null
        );
      }
      return null;
    },
  );

const makeSelectCurrentlyPlayedRecordingSessionObj = state => {
  const { event } = state;
  return (event && event.currentlyPlayedRecordingSessionObj) || null;
};

const makeIsLiteModeEnabled = state => {
  const { event } = state;
  return (event && event.isLiteModeEnabled) || false;
};

export {
  makeSelectBoothListByEventId,
  makeSelectDiscussionListByEventId,
  makeSelectEventAnnouncement,
  makeSelectEventAnnouncementByZone,
  makeSelectEventStageIsBroadcasting,
  makeSelectEventIsBroadcasting,
  makeSelectEventById,
  makeSelectEventDateById,
  makeSelectEventDescriptionById,
  makeSelectEventImageById,
  makeSelectEventRoleForEvent,
  makeSelectEventTimeToStartById,
  makeSelectEventTitleById,
  selectRegistrationCustomFields,
  makeSelectEventUserRole,
  makeSelectIsAttendee,
  makeSelectIsHost,
  makeSelectIsOrganizer,
  makeSelectIsOrganizerForEvent,
  makeSelectIsOrganizerAlone,
  makeSelectIsLiveEventOrganizer,
  makeSelectIsSpeaker,
  makeSelectSegmentListByEventId,
  makeSelectUnfilteredSegmentListByEventId,
  makeSelectSpeakerListByEventId,
  makeSelectSponsorListByEventId,
  makeSelectStageByEventId,
  makeSelectStagesByEventId,
  selectHasVjAccessIdSet,
  makeSelectStreamInfoByEventId,
  selectAllEventsArray,
  makeSelectCurrentlyPlayingVideoForChannel,
  selectError,
  selectIsFetchingData,
  makeStageSettingsByChannelId,
  makeSelectCurrentOverlayForChannel,
  makeSelectBoothCategory,
  makeSelectCurrentLiveStreamForChannel,
  makeIsRefreshEventChecklist,
  selectZoneConfig,
  selectBoothZoneConfig,
  makeSelectZonesSetupByEventId,
  makeSelectCurrentPresentationForChannel,
  makeSelectCurrentPluginForChannel,
  makeSelectRecordingSessionObj,
  makeSelectPendingSegmentListByEventId,
  makeSelectSegmentListByEventIdWithDummy,
  makeSelectBreakoutRoomsConfigById,
  makeSelectLiveSessions,
  makeSelectFeatureFlagConfigById,
  makeSelectEventChannelSettings,
  makeSelectEventSettingsById,
  selectTawkToDetails,
  makeSelectUserAccessGroups,
  makeSelectUserDayAccessList,
  makeSelectStageLayoutByStageId,
  makeIsEventPriorCheckInAccess,
  makeIsHideChannelSideBar,
  makeIsHideContactUsWidget,
  makeIsHideMyInboxSidePanelBar,
  makeIsHideNotificationSidePanelBar,
  makeSelectBackstageTalkingUserByStageId,
  makeSelectStudioIdByStageId,
  makeSelectVirtualBgByRefId,
  makeSelectRoomsOwnerAccIds,
  makeSelectIsRoomOwner,
  makeSelectPresentationLayoutByEventId,
  makeSelectRoomByRoomId,
  makeSelectCurrentlyPlayedRecordingSessionObj,
  makeIsLiteModeEnabled,
};
