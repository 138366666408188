import { Subject } from 'rxjs';

let breadcrumbs: any = [];
try {
  const savedBreadcrumbs = localStorage.getItem('breadCrumbs');
  breadcrumbs = savedBreadcrumbs ? JSON.parse(savedBreadcrumbs) : [];
  localStorage.removeItem('breadCrumbs');
} catch (e) {
  console.error(e);
}
const subject = new Subject();

export const breadcrumbService = {
  addBreadcrumb: (id, label, href) => {
    const breadcrumbToAdd = { id: id, label: label, href: href };
    const isBreadcrumbPresentAlready = breadcrumbs.findIndex(breadcrumb => breadcrumb.id == id);
    if (isBreadcrumbPresentAlready == -1) {
      // If new breadcrumb, push it to the list
      breadcrumbs = [...breadcrumbs, breadcrumbToAdd];
    } else {
      // If breadcrumb is present already
      // Either this is triggered due to re-render 
      // Or when trying to click back button
      breadcrumbs = [...breadcrumbs.splice(0, isBreadcrumbPresentAlready), breadcrumbToAdd];
    }
    subject.next(breadcrumbs);
  },
  clearBreacrumbs: () => {
    breadcrumbs = [];
    subject.next(breadcrumbs);
  },
  onClickBreadcrumb: (index) => {
    breadcrumbs = breadcrumbs.slice(0, index + 1);
    subject.next(breadcrumbs);
  },
  breadcrumbListener: () => subject.asObservable(),
  saveBreadCrumbs: () => {
    breadcrumbs.pop();
    localStorage.setItem('breadCrumbs', JSON.stringify(breadcrumbs));
  },
};
