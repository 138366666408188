import React from 'react';
// components
import Modal from '@/components/ui/modals/Modal';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
// config styles + types
import styles from './styles.module.scss';

const MagicLinkConfirmationModal = ({
  magicLinkWithDifferentEmail,
  currentUser,
  setConfirmDifferentEmailLogin,
  setMagicLinkWithDifferentEmail,
  redirectToLandingPage,
  isEmbedView=false
}) => {

  return (
    <Modal
      title={'MagicLink login confirmation'}
      noHeader={true}
      noBodyPadding={true}
      modalBodyClass="modalMagicLinkConfirmation"
      overlayClass={isEmbedView && styles['magicLinkEmbedViewOverlay']}
      autoTrigger={magicLinkWithDifferentEmail}
      modalHeaderClass="modalHeaderStyleTwo"
      modalCloseIconColor={'black'}
      isNewCrossIcon={true}
      render={({ setShow }) => (
        <div>
          <div className={styles.magicLinkPopupContainer}>
            <div>Link you are trying to access is registered with <b>{magicLinkWithDifferentEmail}</b></div>
            <div className={styles.magicLinkPopupText}>If continued you will be logged out of all current sessions of <b>{currentUser.email}</b></div>
          </div>
          <div className={styles.actions}>
            <Button
              label="Change email"
              className={styles.confirm}
              onClick={() => {
                setConfirmDifferentEmailLogin(true);
                setMagicLinkWithDifferentEmail('');
                setShow(false);
              }}
              styleClass={IButtonTypes.SUCCESS_PRIMARY}
              type="button"
            />
            <Button
              className={styles.cancel}
              label="Cancel"
              onClick={() => {
                setShow(false);
                redirectToLandingPage(true);
                setMagicLinkWithDifferentEmail('');
              }}
              styleClass={IButtonTypes.DANGER_NO_BG}
              type="button"
            />
          </div>
        </div>
      )}
    />
  )
};

export default MagicLinkConfirmationModal;