import { useSelector } from 'react-redux';
import { makeSelectUserAccessGroups } from '@/models/event';
import { EFeatures } from '@/config/accessGroupsConfig';
import { EAccessGroupDynFeatureTypes } from '@/types/accessGroups';


export const useUserAccessGroups = () => {
  const userAccessGroups = useSelector(makeSelectUserAccessGroups);

  const hasZoneAccess = (zoneName, boothZoneId?) => {
    if (!userAccessGroups || userAccessGroups.length === 0) {
      return true;
    }
    let zone = zoneName.trim().toLowerCase();
    if (zone === 'rooms') {
      zone = 'room';
    }
    let hasAccess = false;
    userAccessGroups.forEach(feature => {
      if (
        feature.featureName.toLowerCase() === zone &&
        feature.isActive &&
        !feature.subFeatureId &&
        !feature.dynSubFeatureRefId
      ) {
        hasAccess = true;
      }

      if (boothZoneId) {
        if (
          feature.featureName === EFeatures.CUSTOM_ZONES &&
          feature.isActive &&
          feature.dynSubFeatureRefType === EAccessGroupDynFeatureTypes.ZONE &&
          feature.dynSubFeatureRefId === boothZoneId
        ) {
          hasAccess = true;
        }
      }
    });

    return hasAccess;
  };

  const hasSubFeatureAccess = (featureName, subFeatureName) => {
    if (!userAccessGroups || userAccessGroups.length === 0) {
      return true;
    }
    let hasAccess = false;
    let zone = featureName.trim().toLowerCase();
    if (zone === 'rooms') {
      zone = 'room';
    }
    userAccessGroups.forEach(feature => {
      if (
        feature.featureName.toLowerCase() === zone &&
        feature.subFeatureName === subFeatureName &&
        feature.isActive &&
        feature.featureId &&
        feature.subFeatureId &&
        !feature.dynSubFeatureRefId &&
        !feature.dynSubFeatureRefType
      ) {
        hasAccess = true;
      }
    });
    return hasAccess;
  };

  const hasDynamicFeatureAccess = (refType, refId) => {
    if (!userAccessGroups || userAccessGroups.length === 0) {
      return true;
    }
    let hasAccess = false;
    let zone = refType.trim().toLowerCase();
    if (zone === 'rooms') {
      zone = 'room';
    }
    userAccessGroups.forEach(feature => {
      if (
        feature.dynSubFeatureRefType &&
        feature.dynSubFeatureRefType.toLowerCase() === zone &&
        feature.isActive &&
        feature.dynSubFeatureRefId &&
        feature.dynSubFeatureRefId === refId
      ) {
        hasAccess = true;
      }
    });
    return hasAccess;
  };

  const isPrivateChatEnabled = userGroupIds => {
    if (!userAccessGroups || userAccessGroups.length === 0) {
      return true;
    }
    let hasAccess = false;
    const hasPrivateChatAccess = hasZoneAccess(EFeatures.PVT_CHAT);
    if (!hasPrivateChatAccess) {
      return hasAccess;
    }
    if (!userGroupIds) {
      return true;
    }
    const privateChatAccessList = userAccessGroups.filter(
      item =>
        item.featureName === EFeatures.PVT_CHAT &&
        item.dynSubFeatureRefType &&
        item.dynSubFeatureRefType ===
          EAccessGroupDynFeatureTypes.ACCESS_GROUP &&
        item.isActive,
    );
    privateChatAccessList.forEach(item => {
      userGroupIds.forEach(userGroup => {
        if (userGroup.groupId === item.dynSubFeatureRefId && item.isActive) {
          hasAccess = true;
        }
      });
    });
    return hasAccess;
  };

  const isPrivateMeetingEnabled = userGroupIds => {
    if (!userAccessGroups || userAccessGroups.length === 0) {
      return true;
    }
    let hasAccess = false;
    const hasPrivateMettingAccess = hasZoneAccess(EFeatures.PVT_MEETING);
    if (!hasPrivateMettingAccess) {
      return hasAccess;
    }
    if (!userGroupIds) {
      return true;
    }
    const privateMeetingAccessList = userAccessGroups.filter(
      item =>
        item.featureName === EFeatures.PVT_MEETING &&
        item.dynSubFeatureRefType &&
        item.dynSubFeatureRefType ===
          EAccessGroupDynFeatureTypes.ACCESS_GROUP &&
        item.isActive,
    );
    privateMeetingAccessList.forEach(item => {
      userGroupIds.forEach(userGroup => {
        if (userGroup.groupId === item.dynSubFeatureRefId && item.isActive) {
          hasAccess = true;
        }
      });
    });
    return hasAccess;
  };

  const hasZoneAccessInList = (zoneName, accessList) => {
    if (!accessList || accessList.length === 0) {
      return true;
    }
    let zone = zoneName.trim().toLowerCase();
    if (zone === 'rooms') {
      zone = 'room';
    }

    return accessList.some(
      feature =>
        feature.featureName.toLowerCase() === zone &&
        feature.isActive &&
        !feature.subFeatureId &&
        !feature.dynSubFeatureRefId,
    );
  };

  // Similar to hasZoneAccessInList but also includes check for boothZones
  const hasZoneAccessInListV2 = (zoneName, accessList, boothZoneId?) => {
    if (!accessList || accessList.length === 0) {
      return true;
    }
    let zone = zoneName.trim().toLowerCase();
    if (zone === 'rooms') {
      zone = 'room';
    }

    if (boothZoneId) {
      return accessList.some(
        feature =>
          feature.featureName === EFeatures.CUSTOM_ZONES &&
          feature.isActive &&
          feature.dynSubFeatureRefType === EAccessGroupDynFeatureTypes.ZONE &&
          feature.dynSubFeatureRefId === boothZoneId,
      );
    }

    return accessList.some(
      feature =>
        feature.featureName.toLowerCase() === zone &&
        feature.isActive &&
        !feature.subFeatureId &&
        !feature.dynSubFeatureRefId,
    );
  };

  return {
    hasZoneAccess,
    hasSubFeatureAccess,
    hasDynamicFeatureAccess,
    isPrivateChatEnabled,
    isPrivateMeetingEnabled,
    hasZoneAccessInList,
    hasZoneAccessInListV2,
  };
};
