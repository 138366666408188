export const isAfterEventMode = (event, currentUtcDateTime, checkInDisableAfterEventMinutes) => {
  const currentTime = new Date(currentUtcDateTime);
  const eventEndTime = new Date(event.endDateTime);
  const pageAvailableTime = new Date(
    eventEndTime.getTime() + Number(checkInDisableAfterEventMinutes) * 60000,
  );
  return currentTime >= pageAvailableTime;
};

export const isBeforeEventMode = (
  event,
  allowCheckinBeforeMin,
  currentUtcDateTime,
) => {
  const currentTime = new Date(currentUtcDateTime);
  const eventStartTime = new Date(event.startDateTime);
  const pageAvailableTime = new Date(
    eventStartTime.getTime() - Number(allowCheckinBeforeMin) * 60000,
  );
  return currentTime <= pageAvailableTime;
};

export const isLiveEventMode = (
  event,
  allowCheckinBeforeMin,
  currentUtcDateTime,
  checkInDisableAfterEventMinutes,
) =>
  !isBeforeEventMode(event, allowCheckinBeforeMin, currentUtcDateTime) &&
  !isAfterEventMode(event, currentUtcDateTime, checkInDisableAfterEventMinutes);
