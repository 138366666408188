import axios from './axios';
import { SERVICE_URL } from '@/config';
const BASE_URL = `${SERVICE_URL}/analytics`;

export default {
  getAllAttendees(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/live/zoneAttendee`);
  },
  getAttendeesByZone(eventId: string, locationName: string) {
    return axios.get(
      `${BASE_URL}/${eventId}/live/subLocation?locationName=${locationName}`,
    );
  },
};
