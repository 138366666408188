import React from 'react';
// types
import { zoneTypes } from '@/models/event/types';
// icons
import LobbyIcon, { LobbyIcon2 } from '@/components/ui/new-icons/Lobby';
import ScheduleIcon, {
  ScheduleIcon2,
  ScheduleIcon3,
} from '@/components/ui/new-icons/Schedule';
import StageIcon, {
  StageIcon2,
  StageIcon3,
} from '@/components/ui/new-icons/Stage';
import TableIcon, { TableIcon2 } from '@/components/ui/new-icons/Tables';
import NetworkingIcon, {
  NetworkingIcon2,
  NetworkingIcon3,
} from '@/components/ui/new-icons/Networking';
import ExpoIcon, { ExpoIcon2, ExpoIcon3 } from '@/components/ui/new-icons/Expo';
import AwardIcon from '@/components/ui/new-icons/Award';
import { MEDIUM_ICON_SIZE } from '@/styles/iconSizes';
import { IContentColors } from '@/types';
import { IMAGE_URL } from '@/config';

export const ZONE_LOGO_URL = {
  lobbyIcon:
    'https://phoenixlive.imgix.net/cadfc88a-012a-47b7-a3e9-92203b72ca61.png',
  lobbyIcon2:
    'https://phoenixlive.imgix.net/ab931a06-875e-482e-aba9-d9e41cbc8578.png',
  scheduleIcon:
    'https://phoenixlive.imgix.net/f133d5a7-9401-4c32-be93-07eff9284f26.png',
  scheduleIcon2:
    'https://phoenixlive.imgix.net/af1a0a60-547f-431a-acfa-9ef696384a10.png',
  scheduleIcon3:
    'https://phoenixlive.imgix.net/acef393f-1f13-425b-a1e1-36fcbd26886a.png',
  stageIcon:
    'https://phoenixlive.imgix.net/2edb8f89-23dd-4600-ba30-951c1a694453.png',
  stageIcon2:
    'https://phoenixlive.imgix.net/d491eeba-2551-40a7-bfc4-840ab0a52c3b.png',
  stageIcon3:
    'https://phoenixlive.imgix.net/7983c43e-d69b-4d35-815c-84a57413e533.png',
  tableIcon:
    'https://phoenixlive.imgix.net/025c51e9-672d-427b-a9fe-9caee2d3b076.png',
  tableIcon2:
    'https://phoenixlive.imgix.net/09b03440-fe78-4f9d-bcf5-395df7fcbcf8.png',
  expoIcon:
    'https://phoenixlive.imgix.net/be6b10b2-a1b2-4270-925c-f391db1d4b1a.png',
  expoIcon2:
    'https://phoenixlive.imgix.net/a6831a8d-ee41-470d-b51f-706d5ded4366.png',
  expoIcon3:
    'https://phoenixlive.imgix.net/5df1b342-887c-4251-b1fd-8e3b0944104e.png',
  networkingIcon:
    'https://phoenixlive.imgix.net/9e24a5f8-96e3-44af-b215-9aebe47dec02.png',
  networkingIcon2:
    'https://phoenixlive.imgix.net/d8484f02-8cfb-4eee-bb8f-ec3cc0824078.png',
  networkingIcon3:
    'https://phoenixlive.imgix.net/7fdb3fe1-a022-48f5-aed3-43edd0d5ea47.png',
};

export const EVENT_ZONES = [
  {
    key: zoneTypes.LOBBY,
    Icon: LobbyIcon,
    logoName: 'lobbyIcon',
    title: 'Lobby',
    description:
      'Zone acting as reception area for your event that can be completely branded',
    zoneOrder: 1,
    logoUrl: ZONE_LOGO_URL['lobbyIcon'],
  },
  {
    key: zoneTypes.SCHEDULE,
    Icon: ScheduleIcon,
    logoName: 'scheduleIcon',
    title: 'Schedule',
    description:
      'Zone that lists the schedule for the event with interactive options for attendees',
    zoneOrder: 2,
    logoUrl: ZONE_LOGO_URL['scheduleIcon'],
  },
  {
    key: zoneTypes.STAGE,
    Icon: StageIcon,
    logoName: 'stageIcon',
    title: 'Stage',
    description: 'Zone where main event and sessions occur.',
    zoneOrder: 3,
    logoUrl: ZONE_LOGO_URL['stageIcon'],
  },
  {
    key: zoneTypes.TABLE,
    Icon: TableIcon,
    logoName: 'tableIcon',
    title: 'Rooms',
    description:
      'Zones to hold discussion for people to meet and network in rooms of customizable sizes',
    zoneOrder: 4,
    logoUrl: ZONE_LOGO_URL['tableIcon'],
  },
  {
    key: zoneTypes.NETWORKING,
    Icon: NetworkingIcon,
    logoName: 'networkingIcon',
    title: 'Networking',
    description:
      'Zone allowing attendees to meet and network with other attendees in the event.',
    zoneOrder: 6,
    logoUrl: ZONE_LOGO_URL['networkingIcon'],
  },
  {
    key: zoneTypes.EXPO,
    Icon: ExpoIcon,
    logoName: 'expoIcon',
    title: 'Expo',
    description: 'Zone listing full customizable interactive booths.',
    zoneOrder: 5,
    logoUrl: ZONE_LOGO_URL['expoIcon'],
  },
];

// any new icon added here plz add in ZONE_LOGO_URL also
export const ZONE_LOGOS = {
  lobbyIcon: LobbyIcon,
  lobbyIcon2: LobbyIcon2,
  scheduleIcon: ScheduleIcon,
  scheduleIcon2: ScheduleIcon2,
  scheduleIcon3: ScheduleIcon3,
  stageIcon: StageIcon,
  stageIcon2: StageIcon2,
  stageIcon3: StageIcon3,
  tableIcon: TableIcon,
  tableIcon2: TableIcon2,
  expoIcon: ExpoIcon,
  expoIcon2: ExpoIcon2,
  expoIcon3: ExpoIcon3,
  networkingIcon: NetworkingIcon,
  networkingIcon2: NetworkingIcon2,
  networkingIcon3: NetworkingIcon3,
};

export const getIconOptions = (zoneKey, isZoneActive) => {
  const zoneLogoNames = [] as string[];
  switch (zoneKey) {
    case zoneTypes.SCHEDULE:
      zoneLogoNames.push('scheduleIcon', 'scheduleIcon2', 'scheduleIcon3');
      break;
    case zoneTypes.STAGE:
      zoneLogoNames.push('stageIcon', 'stageIcon2', 'stageIcon3');
      break;
    case zoneTypes.TABLE:
      zoneLogoNames.push('tableIcon', 'tableIcon2');
      break;
    case zoneTypes.EXPO:
      zoneLogoNames.push('expoIcon', 'expoIcon2', 'expoIcon3');
      break;
    case zoneTypes.NETWORKING:
      zoneLogoNames.push(
        'networkingIcon',
        'networkingIcon2',
        'networkingIcon3',
      );
      break;
    case zoneTypes.LOBBY:
    default:
      zoneLogoNames.push('lobbyIcon', 'lobbyIcon2');
      break;
  }
  const zoneIconOptions = [] as any;
  zoneLogoNames.map(iconName => {
    const Icon = ZONE_LOGOS[iconName];
    const iconOption = {
      label: (
        <Icon
          size={MEDIUM_ICON_SIZE}
          color={
            isZoneActive ? IContentColors.BLUE : IContentColors.NEUTRAL_MID_1
          }
        />
      ),
      value: iconName,
    };
    zoneIconOptions.push(iconOption);
  });
  return zoneIconOptions;
};

export const EVENT_ADVANCED_SECTIONS = [
  {
    key: 'LEADERBOARD',
    Icon: AwardIcon,
    title: 'Leaderboard',
    description:
      'Allow attendees, organizers and speaker to chat on both public and private channels. ',
  },
  {
    key: 'EVENT_FEED',
    title: 'Event Feed',
    description:
      'Feature coming soon. For now we have disabled it for you by default',
  },
  {
    key: 'FEEDBACK',
    title: 'Feedback',
    description:
      'Feature coming soon. For now we have disabled it for you by default',
  },
];

export const FORM_SECTIONS = {
  INFO: 'INFO',
  EVENT_ZONES: 'EVENT_ZONES',
  BRANDING: 'BRANDING',
};

export const FIELDS = {
  // Info Section
  TITLE: 'title',
  HOSTNAME: 'hostName',
  VISIBILITY: 'visibility',
  TAGLINE: 'tagLine',
  HOST_URL: 'hostUrl',
  DESCRIPTION: 'description',
  TIMEZONE: 'tz',
  START_DATE_TIME: 'startDateTime',
  END_DATE_TIME: 'endDateTime',

  // Zones Section
  /* Each zone is treated as  a field (to make form updates simpletr). */
  ZONE_LOBBY: zoneTypes.LOBBY,
  ZONE_SCHEDULE: zoneTypes.SCHEDULE,
  ZONE_STAGE: zoneTypes.STAGE,
  ZONE_TABLE: zoneTypes.TABLE,
  ZONE_NETWORKING: zoneTypes.NETWORKING,
  ZONE_EXPO: zoneTypes.EXPO,

  // Branding Section
  COLOR_THEME: 'colorTheme',
  LOGO_URL: 'logoUrl',
  PRIMARY_COLOR: 'primaryColor',
  SECONDARY_COLOR: 'secondaryColor',
  NAVBAR_COLOR: 'navbarColor',
  NAVBAR_PRIMARY_COLOR: 'navbarPrimaryColor',
  NAVBAR_SECONDARY_COLOR: 'navbarSecondaryColor',
  BACKGROUND_IMAGE_URL: 'backgroundImageUrl',
};

export const EVENT_LOGO_TOOLTIP_TEXT =
  'The logo will be visible in the top navigation bar to the attendees in the live event. Upload a minimum 200*80 pixel png file.';
export const BREAKER_CREATIVE_TOOLTIP_TEXT =
  'Upload a 1920*1080 pixel jpeg file. The default background for your event. The background will be set for different sections in the event';
export const PRIMARY_COLOR_TOOLTIP_TEXT =
  'This color will be used for all primary actions & highlight sections';
export const ACCENT_COLOR_TOOLTIP_TEXT =
  'This color will be used to display any information/text on top of the primary color';
export const NAVBAR_BASE_COLOR_TOOLTIP_TEXT =
  'The base color of the Navbar';
export const NAVBAR_SELECTED_ZONE_COLOR_TOOLTIPTEXT =
  'Color highlight for the current zone';
  export const NAVBAR_TEXT_COLOR_TOOLTIPTEXT =
  'Text color for the zone titles';

export const getZoneDefaultConfig = zonekey => {
  const zoneDetails = EVENT_ZONES.find(zone => zone.key === zonekey);
  if (!zoneDetails) {
    return null;
  }
  return { name: zoneDetails.title, type: zoneDetails.key, active: false };
};

export const getImageURL = fieldValue => {
  if (fieldValue && !fieldValue.includes(IMAGE_URL)) {
    return `${IMAGE_URL}${fieldValue}`;
  }
  return fieldValue;
};
