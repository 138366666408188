import HighDefinitionIcon from '@/components/ui/new-icons/HighDefinition';
import LowDefinitionIcon from '@/components/ui/new-icons/LowDefinition';
import AudioIcon from '@/components/ui/new-icons/Audio';
import AutoStreamIcon from '@/components/ui/new-icons/AutoStream';

export enum VideoQualityTypes {
  AUTO = 'Auto',
  HIGH_DEFINITION = 'HighDefinition',
  LOW_DEFINITION = 'LowDefinition',
  AUDIO_ONLY = 'AudioOnly',
}

export const videoQualityOptions = [
  {
    label: 'Auto',
    value: VideoQualityTypes.AUTO,
    icon: AutoStreamIcon,
  },
  {
    label: 'High Definition',
    value: VideoQualityTypes.HIGH_DEFINITION,
    icon: HighDefinitionIcon,
  },
  {
    label: 'Low Definition',
    value: VideoQualityTypes.LOW_DEFINITION,
    icon: LowDefinitionIcon,
  },
  {
    label: 'Audio Only',
    value: VideoQualityTypes.AUDIO_ONLY,
    icon: AudioIcon,
  },
];

export const videoQualityOptionsRooms = [
  {
    label: 'Auto',
    value: VideoQualityTypes.AUTO,
    icon: AutoStreamIcon,
  },
  {
    label: 'Audio Only',
    value: VideoQualityTypes.AUDIO_ONLY,
    icon: AudioIcon,
  },
];

export enum DefaultVideoQuality {
  DEFAULT_VIDEO_QUALITY = VideoQualityTypes.AUTO,
}

// videoStreamType 0 mean high definition and 1 means low definition
export const HIGH_QUALITY_VIDEO_STREAM_TYPE = 0;
export const LOW_QUALITY_VIDEO_STREAM_TYPE = 1;

// fallBackOption 2 means auto change and 0 means disable auto change stream quality
export const FALL_BACK_OPTION_AUTO_CHANGE = 2;
export const FALL_BACK_OPTION_DISABLE = 0;

export const hideInterprefyButtonEventIds = [
  'f15cc074-7a50-42d7-910e-2b392d2de1fd', // [LOCAL] Abhi's Event
  'ccfd2ace-25b3-49ce-9142-50e4c28a9603', // [PROD] Global Africa Business Initiative 2023
  'd70fe9be-c6ef-4448-af9d-c86de6de6939' // [PROD] GABI 2023 (Gala Dinner)
];

export const isAutomatedTroubleshootEnabled = true;
