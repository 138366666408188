import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import Keys from './query.keys';
import api from './apis';
import { IOrganizationBase } from './types';

const getOrganizationBaseByRef = (
  refId: string,
  refType: string,
  options?: UseQueryOptions,
) =>
  useQuery<IOrganizationBase, Error>(
    Keys.baseRef(refId, refType),
    () => api.getOrganizationBaseByRef(refId, refType).then(({ data }) => data),
    {
      enabled: options?.enabled,
    },
  );

export default {
  getOrganizationBaseByRef,
};
