import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const LowDefinitionIcon = (props: IIconProps) => {
  const { color = IContentColors.WHITE, size = 16 } = props;
  const fillClassName = styles[`fill-${color}`];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={fillClassName}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66683 3.33333V12.6667H13.3335V3.33333H2.66683ZM2.00016 2H14.0002C14.177 2 14.3465 2.07024 14.4716 2.19526C14.5966 2.32029 14.6668 2.48986 14.6668 2.66667V13.3333C14.6668 13.5101 14.5966 13.6797 14.4716 13.8047C14.3465 13.9298 14.177 14 14.0002 14H2.00016C1.82335 14 1.65378 13.9298 1.52876 13.8047C1.40373 13.6797 1.3335 13.5101 1.3335 13.3333V2.66667C1.3335 2.48986 1.40373 2.32029 1.52876 2.19526C1.65378 2.07024 1.82335 2 2.00016 2ZM9.66683 7V9H10.6668C10.7552 9 10.84 8.96488 10.9025 8.90237C10.965 8.83986 11.0002 8.75507 11.0002 8.66667V7.33333C11.0002 7.24493 10.965 7.16014 10.9025 7.09763C10.84 7.03512 10.7552 7 10.6668 7H9.66683ZM8.66683 6H10.6668C11.0205 6 11.3596 6.14048 11.6096 6.39052C11.8597 6.64057 12.0002 6.97971 12.0002 7.33333V8.66667C12.0002 9.02029 11.8597 9.35943 11.6096 9.60948C11.3596 9.85952 11.0205 10 10.6668 10H8.66683V6Z"
        className={fillClassName}
      />
      <path
        d="M6.05554 9H4V10H5.1111H5.99999H6.05554C6.35023 10 6.63284 9.8683 6.84122 9.63388C7.04959 9.39946 7.16665 9.08152 7.16665 8.75C7.16665 8.41848 7.04959 8.10054 6.84122 7.86612C6.63284 7.6317 6.35023 7.5 6.05554 7.5H5.11111C5.05217 7.5 4.99565 7.47366 4.95398 7.42678C4.9123 7.37989 4.88889 7.3163 4.88889 7.25C4.88889 7.1837 4.9123 7.12011 4.95398 7.07322C4.99565 7.02634 5.05217 7 5.11111 7H7.05554V6H5.99999H5.55556H5.11111C4.81643 6 4.53381 6.1317 4.32544 6.36612C4.11706 6.60054 4 6.91848 4 7.25C4 7.58152 4.11706 7.89946 4.32544 8.13388C4.53381 8.3683 4.81643 8.5 5.11111 8.5H6.05554C6.11448 8.5 6.171 8.52634 6.21268 8.57322C6.25435 8.62011 6.27776 8.6837 6.27776 8.75C6.27776 8.8163 6.25435 8.87989 6.21268 8.92678C6.171 8.97366 6.11448 9 6.05554 9Z"
        className={fillClassName}
      />
    </svg>
  );
};

export default LowDefinitionIcon;
