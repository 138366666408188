import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/meeting_room`;
export default {
  getMeetingDetails(meetingRoomId) {
    return axios.get(`${BASE_URL}/${meetingRoomId}`);
  },
  meetingRoomRequest(meetingRoomObject) {
    return axios.post(`${BASE_URL}/request`, meetingRoomObject);
  },

  meetingStatusUpdate(meetingRoomId, meetingRoomRequestStatus) {
    return axios.post(`${BASE_URL}/${meetingRoomId}/status/${meetingRoomRequestStatus}`)
  },

  meetingStatusUpdateByDiscussionTableId(discussionTableId, meetingRoomRequestStatus) {
    return axios.post(`${BASE_URL}/discussionTable/${discussionTableId}/status/${meetingRoomRequestStatus}`)
  },

  getMeetingRoomRequest(requestedAccountId) {
    return axios.get(`${BASE_URL}/request/${requestedAccountId}`);
  },

  getMeetingRoomApproval(requestedAccountId) {
    return axios.get(`${BASE_URL}/approval/${requestedAccountId}`);
  },

  sendMeetingInvitation(meetingRoomId , requestedAccountId) {
    return axios.post(`${BASE_URL}/${meetingRoomId}/sendInvitation/${requestedAccountId}`);
  },

  getAllMeetingRooms(eventId) {
    return axios.get(`${BASE_URL}/all/${eventId}`);
  },

  getMeetingsAndSegemntsStatus(eventId) {
    return axios.get(`${SERVICE_URL}/event/${eventId}/attendeeFavourites/`);
  },

  setMeetingsAndSegemntsStatus(eventId, object) {
    return axios.post(`${SERVICE_URL}/event/${eventId}/attendeeFavourites/`, object);
  },

  deleteMeetingsAndSegemntsStatus(eventId, refId) {
    return axios.delete(`${SERVICE_URL}/event/${eventId}/attendeeFavourites/${refId}`);
  },

};