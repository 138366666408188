import { takeEvery } from 'redux-saga/effects';
import { useInjectReducer, useInjectSaga } from './redux-injectors';
import { onEffect } from './plugins';

function getSagaFromModel(model) {
  return function* saga() {
    // eslint-disable-next-line no-restricted-syntax
    for (const effect in model.effects) {
      const actionType = `${model.namespace}/${effect}`;
      if (Object.prototype.hasOwnProperty.call(model.effects, effect)) {
        let effectFn = model.effects[effect];
        for (const fn of onEffect) {
          effectFn = fn(effectFn, model, actionType);
        }

        yield takeEvery(actionType, effectFn);
      }
    }
  };
}

function getReducerFromModel(model) {
  if (!model || !model.reducers || Object.keys(model.reducers).length <= 0) {
    // eslint-disable-next-line no-unused-vars
    return () => model.initialState || null;
  }

  // eslint-disable-next-line consistent-return
  return (state = model.initialState || null, action) => {
    const [modelName, reducerName] = action.type.split("/");
    if (modelName !== model.namespace) {
      return state;
    }

    if (reducerName === 'clear') {
      return model.initialState;
    }

    if (model.reducers[reducerName]) {
      return model.reducers[reducerName](state, action.payload);
    }
    return state;
  };
}

const useInjectModel = model => {
  useInjectReducer({
    key: model.namespace,
    reducer: getReducerFromModel(model),
  });
  useInjectSaga({
    key: model.namespace,
    saga: getSagaFromModel(model),
    mode: model.sagaMode,
  });
};

export default useInjectModel;
export { useInjectModel };
