import produce from 'immer';
import { call, put } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { createSelector } from 'reselect';
import LogRocket from 'logrocket';
// import Cohere from "cohere-js";
// import posthog from 'posthog-js';
import { SSO_REDIRECT_URI, SSO_WORKOS_PROJECT_ID } from '@/config';
// api
import api from '@/api';
// helpers
import { _isEmpty } from '@/utils/jsHelpers/obj';
import { push } from 'connected-react-router';
// styles and types
import {
  IUpdateAccountPayload,
  IChangePasswordPayload,
  IResetPasswordPayload,
  IResetPasswordRequestPayload,
  IValidateResetPasswordRequestPayload,
  IAccountState,
  ISSOStatePayload,
  IAccountRegistrationEnum,
} from './types';
import { IReduxStoreState } from '@/models/global/types';
import { ZONE_FLOW } from '@/components/onboarding/zoneFlows';
import { initLogRocket, shouldTrackUserInLogrocket } from '@/utils/logrocket';
import { breadcrumbService } from '@/services/page-navigation/breadcrumbs';
import { IEventRoleOptions } from '@/types/eventRole';

const initialState = {
  checkingAuth: true,
  currentUser: {},
  eventRoleMap: {},
  isFetchingData: false,
  userFlowProgress: {},
  error: [],
  registrationStep: IAccountRegistrationEnum.LOGIN_METHOD,
  selectedLoginMethod: null,
  smartNetworkingProfile: null,
  userLandingPageURL: ''
} as IAccountState;

export default {
  namespace: 'account',
  initialState,
  reducers: {
    updateCheckAuth: (state, { value }) =>
      produce(state, draft => {
        draft.checkingAuth = value;
      }),
    setDataFetchingError: (state, { error }) =>
      produce(state, draft => {
        draft.error.push(error);
      }),
    setDataFetchingState: (state, { isFetchingData }) =>
      produce(state, draft => {
        draft.isFetchingData = isFetchingData;
      }),
    setUserData: (state, { data }) =>
      produce(state, draft => {
        const shouldTrack = shouldTrackUserInLogrocket(data.accountId);
        console.error('debugLR > ', shouldTrack, data.accountId);

        if (shouldTrack) {
          initLogRocket();
        }

        LogRocket.identify(data.accountId, {
          name: `${data.firstName}`,
        });

        delete data.roles;
        draft.currentUser = {
          ...data,
          name: `${data.firstName} ${data.lastName}`,
        };
      }),
    setUserFlowProgress: (state, { userFlowProgress }) =>
      produce(state, draft => {
        draft.userFlowProgress = userFlowProgress;
      }),
    updateUserFlowProgress: (state, { flowName, stepNumber }) =>
      produce(state, draft => {
        draft.userFlowProgress = {
          ...draft.userFlowProgress,
          [flowName]: stepNumber,
        };
      }),
    setSelectedLoginMethod: (state, { selectedLoginMethod }) =>
      produce(state, draft => {
        draft.selectedLoginMethod = selectedLoginMethod;
      }),
    setRegistrationStep: (state, { registrationStep }) =>
      produce(state, draft => {
        draft.registrationStep = registrationStep;
      }),
    setAccountAndRegistrationStep: (state, { userData, registrationStep }) =>
      produce(state, draft => {
        LogRocket.identify(userData.accountId, {
          name: `${userData.firstName}`,
        });
        // freshpaint.identify(userData.accountId, {
        //   name: `${userData.firstName}`,
        // });
        draft.currentUser = {
          ...userData,
          name: `${userData.firstName} ${userData.lastName}`,
        };
        draft.registrationStep = registrationStep;
      }),
    setSmartNetworkingProfile: (state, payload) =>
      produce(state, draft => {
        draft.smartNetworkingProfile = payload;
      }),
    setUserLandingPageURL: (state, payload) => produce(state, draft => {
      draft.userLandingPageURL = payload;
    }),
    reset: () => ({
      ...initialState,
      checkingAuth: false,
    }),
  },
  effects: {
    *checkAuth({ payload: { ref } }) {
      yield put({
        type: 'account/updateCheckAuth',
        payload: {
          value: false,
        },
      });

      yield put({ type: 'account/getUserData' });
    },
    *changePassword({ payload }: IChangePasswordPayload) {
      try {
        yield put({
          type: 'account/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        yield call(api.account.changePassword, payload);
        yield put({
          type: 'account/setDataFetchingState',
          payload: { isFetchingData: false },
        });
      } catch (e) {
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getUserData() {
      try {
        const { data } = yield call(api.account.getUserData);
        yield put({ type: 'account/setUserData', payload: { data } });
      } catch (e) {
        console.log('ERROR FETCHING', e);
      }
    },
    *update({ payload }: IUpdateAccountPayload) {
      try {
        console.log('update', payload);
        yield put({
          type: 'account/setDataFetchingState',
          payload: { isFetchingData: true },
        });
        const data = yield call(api.account.updateProfile, payload.account);
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: 'Profile Details Updated Successfully' },
        });
        yield put({
          type: 'account/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        if (!_isEmpty(data)) {
          yield put({
            type: 'account/setUserData',
            payload: { data: data.data },
          });
        } else {
          yield put({
            type: 'event/setDataFetchingError',
            payload: {
              error: [{ 'Update Error': 'No data returned from backend.' }],
            },
          });
        }
      } catch (e) {
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Profile Details Update Failed' },
        });
        yield put({
          type: 'event/setDataFetchingState',
          payload: { isFetchingData: false },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *ssoLogin({ payload }) {
      // Generate the WorkOS signin URL and redirect the user to that page
      const { ssoConnectionId, redirectRef, eventId } = payload;
      // Generate SSO JSON state payload
      const ssoJsonPayload: ISSOStatePayload = {};
      if (redirectRef) {
        ssoJsonPayload.ref = redirectRef;
      }
      if (eventId) {
        ssoJsonPayload.eventId = eventId;
      }
      const ssoJsonString = JSON.stringify(ssoJsonPayload);
      const loginURL =
        'https://api.workos.com/sso/authorize?response_type=code' +
        `&client_id=${SSO_WORKOS_PROJECT_ID}` +
        `&redirect_uri=${SSO_REDIRECT_URI}` +
        `&state=${ssoJsonString}` +
        `&connection=${ssoConnectionId}`;
      window.location.assign(loginURL);
    },
    *resetPasswordRequest({ payload }: IResetPasswordRequestPayload) {
      try {
        yield call(api.account.resetPasswordRequest, payload);
        yield put({
          type: 'global/addSuccessToast',
          payload: { description: 'Reset link sent to your email' },
        });
      } catch (e) {
        let errorMessage = 'Failed to request password reset link';
        if (e.message.includes('401')) {
          errorMessage = "Account doesn't exist";
        }
        yield put({
          type: 'global/addDangerToast',
          payload: { description: errorMessage },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *resetPassword({ payload }: IResetPasswordPayload) {
      try {
        yield call(api.account.resetPassword, payload);
        yield put({
          type: 'global/addSuccessToast',
          payload: {
            description: 'Redirecting to the login page.',
          },
        });
      } catch (e) {
        yield put({
          type: 'global/addDangerToast',
          payload: { description: 'Password Reset Failed' },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *validateResetPasswordRequest({
      payload: { resetRequestId },
    }: IValidateResetPasswordRequestPayload) {
      try {
        yield call(api.account.validateResetPasswordRequest, resetRequestId);
      } catch (e) {
        yield put({
          type: 'global/addInfoToast',
          payload: { description: 'Reset Password Token Link Expired' },
        });
        yield put({
          type: 'event/setDataFetchingError',
          payload: { error: e },
        });
      }
    },
    *getUserLandingPageUrl({payload: {eventId}}) {
      try {
        const data = yield call(api.registration.getLandingPageUrlWithMagicLink, eventId);
        yield put({
          type: 'account/setUserLandingPageURL',
          payload: data.data,
        });
      } catch (error) {
        console.error('Error fetching landing page url', error)
      }
    },
    *logout({ payload: { eventId, userEventRole } }) {
      yield put({ type: 'account/reset' });
      yield put({ type: 'event/reset' });
      yield put({ type: 'loading/reset' });
      yield put({ type: 'global/reset' });
      yield put({ type: 'eventFeed/reset' });
      yield put({ type: 'language/reset' });
      yield put({ type: 'notifications/reset' });
      yield put({ type: 'integration/reset' });

      // Call the logout endpoint
      yield call(api.account.logout);

      // posthog.reset();
      // yield put(push('/a/signin'));
      localStorage.removeItem('breadCrumbs');
      breadcrumbService.clearBreacrumbs();
      // After logout redirecting attendees to landing page
      if (eventId) {
        const { data } = yield api.pageBuilder.getLandingPageLink(eventId);
        if (data) {
          if (userEventRole === IEventRoleOptions.ATTENDEE) {
            window.location.replace(data.attendeeUrl);
          } else {
            window.location.replace(data.speakerUrl);
          }
        }
      } else {
        yield put(push('/a/signin'));
      }
    },
    *logoutNoRedirect() {
      yield put({ type: 'account/reset' });
      // Call the logout endpoint
      yield call(api.account.logout);
      // posthog.reset();
      window.location.reload();
    },
  },
};

/**
 * Direct selector to the language domain
 */
const selectCurrentUser = (state: IReduxStoreState) => {
  const { account } = state;
  return (account && account.currentUser) || {};
};

const selectCurrentUserName = (state: IReduxStoreState) => {
  const { account } = state;
  const accountWithDefaults = (account && account.currentUser) || {};
  const { firstName = '', lastName = '' } = accountWithDefaults;
  return [firstName, lastName].join(' ');
};

const makeSelectUserAccountId = () =>
  createSelector(selectCurrentUser, currentUser => currentUser.accountId || '');

const makeSelectUserFullName = () =>
  createSelector(selectCurrentUser, currentUser =>
    currentUser.firstName && currentUser.lastName
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : '',
  );

const makeSelectUserFirstName = () =>
  createSelector(selectCurrentUser, currentUser => currentUser.firstName || '');

const makeSelectUserLastName = () =>
  createSelector(selectCurrentUser, currentUser => currentUser.lastName || '');

const selectIsCheckingAuth = (state: IReduxStoreState) => {
  const { account } = state;
  return account.checkingAuth;
};

const selectUserFlowProgress = (state: IReduxStoreState) => {
  const { account } = state;
  const { userFlowProgress } = account;
  return userFlowProgress.updatedAt ? userFlowProgress : null;
};

const makeSelectShowFlow = flowName =>
  createSelector(selectUserFlowProgress, userFlowProgress => {
    if (!userFlowProgress) return false;

    // First flow is ZONE_FLOW
    if (flowName !== ZONE_FLOW) {
      const shownZoneFlow = userFlowProgress[ZONE_FLOW];
      if (!shownZoneFlow) return false;
    }

    const stepNumber = userFlowProgress[flowName];
    if (stepNumber === -1) return false;
    if (stepNumber > 0) return false;

    return true;
  });

const selectRegistrationStep = (state: IReduxStoreState) => {
  const { account } = state;
  return (account && account.registrationStep) || {};
};

const selectSelectedLoginMethod = (state: IReduxStoreState) => {
  const { account } = state;
  return (account && account.selectedLoginMethod) || undefined;
};

const selectUserSmartNetworkingProfile = ({ account }) =>
  account?.smartNetworkingProfile;

const selectUserLandingPageUrl = ({ account }) =>
  account?.userLandingPageURL;

export {
  makeSelectUserAccountId,
  makeSelectUserFirstName,
  makeSelectUserFullName,
  makeSelectUserLastName,
  selectCurrentUser,
  selectCurrentUserName,
  selectIsCheckingAuth,
  makeSelectShowFlow,
  selectRegistrationStep,
  selectSelectedLoginMethod,
  selectUserSmartNetworkingProfile,
  selectUserLandingPageUrl
};
