import React, { lazy, Suspense } from 'react';

interface Props {
  fallback?: React.ReactNode | null;
}
/**
 * Used to fetch lazy component and when not found it again tries to fetch the component
 * @param lazyComponent Component which needs to lazyloaded
 * @param attemptsLeft Number of attempts left to fetch the component
 * @returns It returns a promise to load the component
 */
function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise<{ default: any }>((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            return lazyComponent();
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject,
          );
        }, 700);
      });
  });
}

const loadable = <T extends React.ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
  { fallback = null }: Props = { fallback: null },
) => {
  const LazyComponent = lazy(() => componentLoader(importFunc, 3));

  return (props: React.ComponentProps<T>): JSX.Element => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
