export const EFeatures = {
  DAY: 'DAY',
  LOBBY: 'LOBBY',
  SCHEDULE: 'SCHEDULE',
  STAGE: 'STAGE',
  ROOM: 'ROOM',
  NETWORKING: 'NETWORKING',
  EXPO: 'EXPO',
  CUSTOM_ZONES: 'CUSTOM_ZONES',
  BACKSTAGE: 'BACKSTAGE',
  EVENT_INTERACTION: 'EVENT_INTERACTION',
  PVT_CHAT: 'PVT_CHAT',
  PVT_MEETING: 'PVT_MEETING',
  CALENDAR: 'CALENDAR',
  LEADERBOARD: 'LEADERBOARD',
  RECORDING: 'RECORDING',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  NOTIFICATION: 'NOTIFICATION',
  ON_BOARDING_CHECKS: 'ON_BOARDING_CHECKS',
};

export const ESubFeatures = {
  DYN_STAGE: 'DYN_STAGE',
  DYN_EXPO_ZONES: 'DYN_EXPO_ZONES',
  ACCESS_PVT_ROOMS: 'ACCESS_PVT_ROOMS',
  SHOW_RESERVED_ROOMS_ONLY: 'SHOW_RESERVED_ROOMS_ONLY',
  SHOW_NETWORKING_PROFILE: 'SHOW_NETWORKING_PROFILE',
  ALLOW_ALL_STAGES: 'ALLOW_ALL_STAGES',
  ALLOW_TAGGED_STAGES_ONLY: 'ALLOW_TAGGED_STAGES_ONLY',
  ENABLE_EVENT_INTERACTION_CHAT: 'ENABLE_EVENT_INTERACTION_CHAT',
  ENABLE_EVENT_INTERACTION_PEOPLE: 'ENABLE_EVENT_INTERACTION_PEOPLE',
  SHOW_LDB_RANKS: 'SHOW_LDB_RANKS',
  SHOW_LDB_POINT_DISTRIBUTION: 'SHOW_LDB_POINT_DISTRIBUTION',
  ALLOW_LDB_PROFILE_IN_RANKING: 'ALLOW_LDB_PROFILE_IN_RANKING',
};
