import React from 'react';
// styles + types
import { IIconProps } from '../types';
import { IContentColors } from '@/types';
import styles from '../styles.module.scss';

const Bin = (props: IIconProps) => {
  const { color = IContentColors.BLACK, size } = props;

  const fillClassName = styles[`fill-${color}`];
  return (
    <svg width={size} height={size} viewBox="0 0 11 11" className={fillClassName} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 2.70396H10V3.69738H9V10.1546C9 10.2864 8.94732 10.4127 8.85355 10.5058C8.75979 10.599 8.63261 10.6513 8.5 10.6513H1.5C1.36739 10.6513 1.24021 10.599 1.14645 10.5058C1.05268 10.4127 1 10.2864 1 10.1546V3.69738H0V2.70396H2.5V1.21383C2.5 1.08209 2.55268 0.955752 2.64645 0.8626C2.74021 0.769449 2.86739 0.717117 3 0.717117H7C7.13261 0.717117 7.25979 0.769449 7.35355 0.8626C7.44732 0.955752 7.5 1.08209 7.5 1.21383V2.70396ZM8 3.69738H2V9.65791H8V3.69738ZM3.5 5.18751H4.5V8.16778H3.5V5.18751ZM5.5 5.18751H6.5V8.16778H5.5V5.18751ZM3.5 1.71054V2.70396H6.5V1.71054H3.5Z" className={fillClassName} />
    </svg>
  );
};

export default Bin;
