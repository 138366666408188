import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/organization`;
const CORE_SERVICE_URL = `${SERVICE_URL}/core`;

export default {
  getAllTeamMembers() {
    return axios.get(`${BASE_URL}/member`);
  },
  addMemberToOrganization(memberObj) {
    return axios.post(`${CORE_SERVICE_URL}/organization/add-member`, memberObj);
  },
  deleteTeamMember(memberId) {
    return axios.delete(`${CORE_SERVICE_URL}/organization/member/${memberId}`);
  },
  addAttendeeUsageLimit(eventId: string) {
    return axios.post(`${BASE_URL}/usage/event/${eventId}/`);
  },
};
