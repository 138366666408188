import React from 'react';
//Components
import Image from '@/components/ui/media/Image';
import { RefreshCcw } from 'react-feather';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import Label from '@/components/ui/content/Label';
// styles + types
import styles from './styles.module.scss';
import { STANDARD_ICON_BUTTON_SIZE } from '@/styles/iconSizes';

const ErrorPage = () => {
  return (
    <div className={styles.errorPageContainer}>
      <Image
        src="https://phoenixlive.imgix.net/94223c0b-9c6a-42bb-89ba-d43dae114297.png"
        styleClass="errorPageBackground"
      />
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          <div className={styles.errorHeader}>It’s all good!</div>
          <div className={styles.errorTagLine}>
            Just <b>refresh this page</b> to continue attending the event!
          </div>
          <div
            className={styles.refreshButton}
            onClick={() => location.reload()}
          >
            <Button styleClass={IButtonTypes.NEW_DANGER} type="button">
              <Label
                value="Refresh Now"
                className={styles.hoverLabel}
                styleClass="buttonSecondaryWithIcon"
              />
              &nbsp; &nbsp;
              <RefreshCcw
                size={STANDARD_ICON_BUTTON_SIZE}
                className={styles['stroke-primary']}
              />
            </Button>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div>
            <Image
              src="https://phoenixlive.imgix.net/f339d54d-442f-4edf-a4e7-29e24559e1a4.png"
              styleClass="errorZuddlIcon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
