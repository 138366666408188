import axios from './axios';
import { SERVICE_URL } from '@/config';

export default {
  generateCertificate(eventId: string) {
    return axios.get(`${SERVICE_URL}/certificate/${eventId}/generate`);
  },

  reGenerateCertificate(eventId: string) {
    return axios.get(`${SERVICE_URL}/certificate/${eventId}/re-generate`);
  },
};
