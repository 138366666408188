import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/stream`;
export default {
  getStreamNames(eventId, streamIds) {
    return axios.post(`${BASE_URL}/${eventId}/name/list`, streamIds);
  },
  getChannelPresenceStreams(channelName) {
    return axios.get(`${BASE_URL}/presence/list`, {
      params: {
        channelName,
      },
    });
  },
  //create stage live streams
  createLiveStreams(stageId: string){
    return axios.post(`${BASE_URL}/${stageId}/live_stream/create`);
  },
  //get stage live streams
  getLiveStreams(stageId: string){
    return axios.get(`${BASE_URL}/${stageId}/live_stream/list`);
  },
};
