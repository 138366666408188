import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/recording`;
export default {
  startRecording(channelId: string, sessionId: string) {
    return axios.post(
      `${SERVICE_URL}/channel/${channelId}/session/${sessionId}/recording/start`,
    );
  },
  stopRecording(channelId: string, sessionId: string) {
    return axios.post(
      `${SERVICE_URL}/channel/${channelId}/session/${sessionId}/recording/stop`,
    );
  },
  pauseRecording(channelId: string, sessionId: string) {
    return axios.post(
      `${SERVICE_URL}/channel/${channelId}/session/${sessionId}/recording/pause`,
    );
  },
  resumeRecording(channelId: string, sessionId: string) {
    return axios.post(
      `${SERVICE_URL}/channel/${channelId}/session/${sessionId}/recording/resume`,
    );
  },
  getRecordingList(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/list`);
  },
  getRecordingFileSingedUrl(recordingFileId: string) {
    return axios.get(`${BASE_URL}/${recordingFileId}/signed_get`);
  },
  publishRecordingFiles(eventId: string, publishRecordings) {
    return axios.post(`${BASE_URL}/${eventId}/publish`, publishRecordings);
  },
  getRecordingsCountPublic(eventId: string) {
    return axios.get(`${BASE_URL}/${eventId}/count`);
  },
  startRecordingWithDummySession(channelId: string) {
    return axios.post(
      `${SERVICE_URL}/channel/${channelId}/session/recording/start`,
    );
  },
  unPublishRecordingFile(eventId: string, unpublishList) {
    return axios.post(`${BASE_URL}/${eventId}/unpublish`, unpublishList);
  },
  getFileUploadUrl(fileName: string, eventId: string) {
    return axios({
      method: 'post',
      url: `${BASE_URL}/${eventId}/signed_put`,
      data: fileName,
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },

  saveRecordingFile(body, eventId: string) {
    return axios.post(`${BASE_URL}/${eventId}/save`, body);
  },
  deleteRecordingFile(eventId: string, recordingFileId: string) {
    return axios.delete(`${BASE_URL}/${eventId}/delete/${recordingFileId}`);
  },
  renameRecording(eventId: string, recordingFileId: string, newName: string) {
    return axios.put(
      `${BASE_URL}/${eventId}/rename/${recordingFileId}?newName=${newName}`,
    );
  },
  createRecordingFile(eventId: string, recordingFile) {
    return axios.post(`${BASE_URL}/${eventId}/save`, recordingFile);
  },
  getRecordingFileSingedUrlForDownload(recordingFileId: string) {
    return axios.get(`${BASE_URL}/${recordingFileId}/download`);
  },
  orderingRecordingFiles(eventId: string, recordingFiles) {
    return axios.post(`${BASE_URL}/${eventId}/ordering`, recordingFiles);
  },
  getDraftRecordings(eventId: string, segmentId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${segmentId}/draft/list`);
  },
  deleteDraftRecording(eventId: string, recordingFileId: string) {
    return axios.delete(
      `${BASE_URL}/${eventId}/${recordingFileId}/draft/delete`,
    );
  },
  makeDraftRecordingsStatusToLive(eventId: string, recordings) {
    return axios.post(`${BASE_URL}/${eventId}/draft/live`, recordings);
  },
  getSessionAllRecordingFilesSingedUrl(eventId: string, segmentId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${segmentId}/signed_get/all`);
  },
  initializeMultipartUpload(eventId: string, fileName: string) {
    return axios.post(
      `${SERVICE_URL}/file-upload/${eventId}/recording/multipart_upload/initialize`,
      fileName,
    );
  },
  getMultipartPreSignedUrls(eventId: string, inputBody: any) {
    return axios.post(
      `${SERVICE_URL}/file-upload/${eventId}/recording/multipart_upload/get_signed_urls`,
      inputBody,
    );
  },
  finalizeMultipartUpload(eventId: string, inputBody: any) {
    return axios.post(
      `${SERVICE_URL}/file-upload/${eventId}/recording/multipart_upload/finalize`,
      inputBody,
    );
  },
  //get all published public recordings
  getWebinarPublicRecordings(eventId: string){
    return axios.get(`${SERVICE_URL}/recording/webinar/landing_page/${eventId}/list`)
  }
};
