import axios from './axios';
import { SERVICE_URL } from '@/config';

const BASE_URL = `${SERVICE_URL}/connection`;

export default {
  getConnectionRequestChannel() {
    return axios.get(`${BASE_URL}/config/channels`, {
      params: {
        type: 'connection_request',
      },
    });
  },
  existingConnectionWith(eventId: string, accountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/${accountId}/exists`);
  },
  getConnectionStatus(connectionId: string) {
    return axios.get(`${BASE_URL}/${connectionId}/status`);
  },
  getConnectionDetails(connectionId: string) {
    return axios.get(`${BASE_URL}/${connectionId}/status`, {
      params: {
        connection_details: 1,
      },
    });
  },
  getConnectionList(eventId: string) {
    return axios.get(`${BASE_URL}/list`, {
      params: {
        eventId,
      },
    });
  },
  downloadConnectionsList(eventId: string) {
    return axios.get(`${BASE_URL}/list/download`, {
      responseType: 'blob',
      params: {
        eventId,
      },
    });
  },
  getActiveConnectionRequestWith(eventId: string, accountId: string) {
    return axios.get(`${BASE_URL}/${eventId}/request/${accountId}/active`);
  },
  sendConnectionRequest(accountId: string, eventId: string) {
    return axios.post(`${BASE_URL}/${eventId}/request/${accountId}/send`);
  },
  cancelConnectionRequest(requestId: string) {
    return axios.post(`${BASE_URL}/request/${requestId}/cancel`);
  },
  acceptConnectionRequest(requestId: string) {
    return axios.post(`${BASE_URL}/request/${requestId}/accept`);
  },
  declineConnectionRequest(requestId: string) {
    return axios.post(`${BASE_URL}/request/${requestId}/decline`);
  },
  getConnectionRequestStatus(requestId: string) {
    return axios.get(`${BASE_URL}/request/${requestId}/status`);
  },
  getConnectionRequestDetails(requestId: string) {
    return axios.get(`${BASE_URL}/request/${requestId}/status`, {
      params: {
        connection_details: 1,
      },
    });
  },
};
